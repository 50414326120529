import hash from "string-hash";
import { updateUserDocMain } from "../db/user_docs";
import firebase from "firebase/app";
import { db } from "lib-fullstack";
import { UpsetModel } from "typesaurus/upset";
import { validateAndNormalizeEmail } from "./emails";

/**
 * Convert email to a hash string. Removes whitespace and capitalization
 * to help with de-dup'ing. Reasonably collision-free and space-efficient
 * for collections of less than a few thousand emails.
 * E.g., rob@yoodli.ai maps to 169y350
 * @param email
 */
export function emailToHash(email: string): string {
  return hash(email.trim().toLowerCase()).toString(36);
}

export async function addOrUpdateContact(
  siteId: string,
  email: string,
  name?: string,
  userId?: string,
): Promise<void> {
  const contactFields = { contacts: {} };

  const uid = firebase.auth().currentUser?.uid;
  if (!uid) {
    console.error(`User ID ${uid} not found. Failing to add contact.`);
    return;
  }
  email = validateAndNormalizeEmail(email);
  if (!email) {
    console.error(`Email ${email} for user id ${uid} not valid. Failing to add contact.`);
    return;
  }

  const emailHash = emailToHash(email);
  contactFields.contacts[emailHash] = {
    email,
    ...(name && { name }),
    ...(userId && { userId }),
  };

  await Promise.all([
    updateUserDocMain(siteId, uid, contactFields),
    (async () => {
      const update = { email, name, userId } as UpsetModel<db.Contact>;
      await db.upset(db.userContacts([siteId, uid]), emailHash, update);
    })(),
  ]);
}
