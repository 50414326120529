import { isElectron } from "react-device-detect";

// Utils
import { getClientEnvConfig } from "lib-fullstack/client_env";
import type { IVariant } from "unleash-proxy-client" with { "resolution-mode": "import" };

let UnleashClient;
const moduleImported = new Promise((resolve) => {
  import("unleash-proxy-client")
    .then((module) => {
      UnleashClient = module.UnleashClient;
      resolve(null);
    })
    .catch((error) => console.error(`failed to import unleash-proxy-client: ${error}`));
});

const envConfig = getClientEnvConfig();

/**
 *
 * Feature Flags & Experimentation
 *
 * Configured at https://us.app.unleash-hosted.com/uscc1011
 *
 * See https://martinfowler.com/articles/feature-toggles.html for general
 * background.
 *
 * See Unleash docs for specific usage.
 *
 * More docs TBD.
 *
 */

export type FeatureFlag =
  | "app-banner-message"
  | "auto-remarks-pane"
  | "autogen"
  | "b2b-nudge-banner"
  | "beta-languages"
  | "coach-checklist"
  | "deepgram-kill-switch"
  | "enable-org-content-spaces"
  | "encoder-override"
  | "file-library-ux"
  | "input-preprocessor"
  | "logrocket_tracing"
  | "multilang"
  | "nav-pricing-promo-code"
  | "new-transcript-rendering"
  | "patient-counseling"
  | "tts-extra-delay"
  | "tts-min-duration-sec";

export type ABExperiment = "p-exp-version";

const UNLEASH_TIMEOUT_S = 5; // Stop insisting on waiting for unleash toggles after this long

let unleashClient: typeof UnleashClient;
export let unleashTogglesResolvedPromise: Promise<boolean>; // resolves to true if fetched; false if timed out and potentially unresolved.
let unleashTogglesResolvedBool = false; // true if promise resolved

/**
 * Options for starting Unleash, so we can keep this file's imports lightweight
 * to be shared between webclient and nextjs.
 * @param promiseToAwait - Promise to await before starting Unleash
 * @param userEmail - User email for context
 * @param userId - User ID for context
 */
type UnleashStartOptions = {
  promiseToAwait?: Promise<void>;
  userEmail: string;
  userId: string;
};

/**
 * Initialize Unleash. Don't call this until user ID is resolved.
 */
export async function startUnleash(options?: UnleashStartOptions): Promise<boolean> {
  await moduleImported;
  if (unleashClient) return unleashTogglesResolvedPromise;
  if (options?.promiseToAwait instanceof Promise) {
    await options.promiseToAwait;
  }

  const clientEnv = getClientEnvConfig();
  const unleashUrl = `${clientEnv.url.WEB_SERVER}/unleash/proxy`;

  const context =
    options?.userId || options?.userEmail
      ? {
          userId: options?.userId,
          userEmail: options?.userEmail ?? "",
          userEmailDomain: (options?.userEmail ?? "").split("@").pop(),
        }
      : undefined;

  console.log(`Initializing unleash at ${unleashUrl}`);
  unleashClient = new UnleashClient({
    url: unleashUrl,
    clientKey: "web-client-unleash",
    refreshInterval: isElectron ? 3600 : 0, // 1 hr for electron, no refreshing for webclient
    disableRefresh: !isElectron,
    appName: "yoodli-client",
    environment: envConfig.envName,
    context,
  });

  const startPromise = unleashClient.start();
  // This weird promise resolves as 'true' if toggles are resolved/fetched within
  //  the alotted timeout (e.g. 5 seconds), and 'false' if it times out (to make
  //  sure we never completely block a render)
  const startPromiseWithTimeout = Promise.race([
    startPromise.then(() => {
      unleashTogglesResolvedBool = true;
      return true;
    }),
    new Promise<boolean>((resolve) => setTimeout(() => resolve(false), UNLEASH_TIMEOUT_S * 1000)),
  ]);
  unleashTogglesResolvedPromise = startPromiseWithTimeout; // set global var

  startPromiseWithTimeout
    .then((fetched) => {
      if (!fetched) {
        console.error("Timeout on unleash toggles.");
      }
    })
    .catch(console.error);

  return startPromiseWithTimeout;
}

// Prevents sending multiple exposures to GA for a single feature in a single
//  session.
const alreadySentFlagExposure: { [k: string]: boolean } = {};
const alreadySentPayloadExposure: { [k: string]: boolean } = {};

const experimentGroupCache: { [experimentName: string]: string } = {};
/**
 * Get experiment group name (e.g., "A", "B", "treatment", "control", etc.
 *
 * NOTE: In Unleashed,
 *  * experimentName is 'Feature Toggle' name.
 *  * experimentGroup is 'Variant Name'.
 */
export function getExperimentGroup(experimentName: ABExperiment): string | undefined {
  if (!unleashTogglesResolvedBool) return;
  if (experimentName in experimentGroupCache) {
    return experimentGroupCache[experimentName];
  }

  try {
    const variant = unleashClient.getVariant(experimentName) as IVariant & {
      enabled?: boolean;
    };
    if (!variant.enabled) {
      console.log("Experiment group unavailable/disabled for " + experimentName);
      experimentGroupCache[experimentName] = undefined;
      return;
    }
    const experimentGroup = variant.name;
    if (experimentGroup && !alreadySentPayloadExposure[experimentName]) {
      alreadySentPayloadExposure[experimentName] = true;
      console.log(`EXPERIMENT EXPOSURE: Exp ${experimentName}, Group ${experimentGroup}`);
    }
    experimentGroupCache[experimentName] = experimentGroup;
    return experimentGroup;
  } catch (err) {
    console.error(err);
    return;
  }
}

/**
 * Async version of `getExperimentGroup`; use this when possible if you want to
 *   make sure the toggles are ready.
 * @param experimentName
 */
export async function genExperimentGroup(
  experimentName: ABExperiment,
): Promise<string | undefined> {
  await unleashTogglesResolvedPromise;
  return getExperimentGroup(experimentName);
}

const enabledCache: { [featureName: string]: boolean } = {};
/**
 * Get a boolean flag from Unleash, falling back to the defaults
 *  Exposures are logged to GA+BQ if enabled in Unleash.
 */
export function getEnabledFlag(featureName: FeatureFlag): boolean | undefined {
  if (!unleashTogglesResolvedBool) return;
  if (!isElectron && featureName in enabledCache) {
    return enabledCache[featureName];
  }

  try {
    const enabled = unleashClient.isEnabled(featureName);
    if (!alreadySentFlagExposure[featureName]) {
      alreadySentFlagExposure[featureName] = true;
    }
    enabledCache[featureName] = enabled;
    return enabled;
  } catch (err) {
    console.error(err);
    return;
  }
}

const stringPayloadCache: { [featureName: string]: string } = {};
/**
 * Get a string payload from Unleash, falling back to the defaults
 * set in `config.ts`. Exposures are logged to GA+BQ if enabled in Unleash.
 */
export function getStringPayload(featureName: FeatureFlag): string | undefined {
  if (!unleashTogglesResolvedBool) return;
  if (featureName in stringPayloadCache) {
    return stringPayloadCache[featureName];
  }

  try {
    const variant = unleashClient.getVariant(featureName) as IVariant & {
      enabled?: boolean;
    };
    if (!variant.enabled) {
      console.log("Payload unavailable/disabled for " + featureName);
      return;
    }
    if (!alreadySentPayloadExposure[featureName]) {
      alreadySentPayloadExposure[featureName] = true;
    }
    stringPayloadCache[featureName] = variant.payload?.value;
    return variant.payload?.value;
  } catch (err) {
    console.error(err);
    return;
  }
}

/**
 * Like `getStringPayload`, but parses the JSON into an object.
 */
export function getJsonPayload(featureName: FeatureFlag): unknown | undefined {
  const stringPayload = getStringPayload(featureName);
  if (stringPayload === undefined) return;
  return JSON.parse(stringPayload);
}
