import firebase from "firebase/app";
import React from "react";

// Components
import { Button, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { OrgDetailCardItemLabel } from "./AdminConfigOrgDetails";
import { UpdateOrgDetailCardProps } from "./UpdateOrgModal";
import { useValidateUserEmailSync, useValidateUserEmail } from "auth/utils/validator";
import {
  createOrgUserV2,
  deleteOrgUserV2,
  listOrgUsersV2,
  updateOrgUserV2,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { OrgRole } from "lib-fullstack/utils/enums";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";

export const UpdateOwnerForm = ({
  orgId,
  card,
  setRerenderKey,
  close,
}: UpdateOrgDetailCardProps): React.ReactElement => {
  const initialOwnerEmail =
    (card.items.find((item) => item.label === OrgDetailCardItemLabel.OWNER).data as string) || "";
  const initialAdminEmails = card.items.find((item) => item.label === OrgDetailCardItemLabel.ADMINS)
    .data as string[];
  const currentUserEmail = firebase.auth().currentUser?.email || "";
  const initialIsOrgAdmin = initialAdminEmails.includes(currentUserEmail);

  // Hooks
  const [ownerEmail, setOwnerEmail] = React.useState<string>(initialOwnerEmail);
  const [isOrgAdmin, setIsOrgAdmin] = React.useState<boolean>(initialIsOrgAdmin);
  const [adminEmails, setAdminEmails] = React.useState<string[]>([...initialAdminEmails]);

  const [loadingText, setLoadingText] = React.useState<string>("");
  const [errorText, setErrorText] = React.useState<string>("");
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState<boolean>(false);

  const errors = {
    ownerEmail: useValidateUserEmailSync(ownerEmail, true).error,
  };

  const isFormValid = () => {
    return ![...Object.values(errors)].some((error) => !!error);
  };

  const addRemoveMeFromOrgAdmins = async () => {
    // Get current owners and admins.
    setLoadingText(`Getting current organization owners and admins...`);
    const orgMembers = await listOrgUsersV2(orgId, {
      start: "0",
      limit: "100",
      effective_roles: [OrgRole.OWNER, OrgRole.ADMIN].join(","),
    });
    const currentUser = orgMembers.users.find((user) => user.email === currentUserEmail);
    if (!isOrgAdmin) {
      // Make the current user an org admin.
      if (currentUser) {
        console.log("Setting you to admin role...");
        await updateOrgUserV2(orgId, currentUser.user_id, { role: OrgRole.ADMIN });
      } else {
        console.log("Creating you in org as admin...");
        await createOrgUserV2(orgId, {
          emails: [currentUserEmail],
          org_role: OrgRole.ADMIN,
          hub_role: null,
          hub_ids: null,
          send_invite_email: true,
        });
      }
      setLoadingText(`Making you an org admin...`);
      setAdminEmails(adminEmails.filter((email) => email !== currentUserEmail));
      setIsOrgAdmin(true);
    } else {
      // Completely remove the current user from the organization
      console.log("Removing you from the org...");
      setLoadingText(`Removing you from the org...`);
      await deleteOrgUserV2(orgId, currentUser.user_id);
      setAdminEmails([...adminEmails, currentUserEmail]);
      setIsOrgAdmin(false);
    }
    setLoadingText("");
  };

  const handleUpdateOrg = async () => {
    setErrorText("");
    try {
      setLoadingText(`Updating ${card.label}`);

      // Get current owners and admins.
      setLoadingText(`Getting current organization owners and admins...`);
      const orgMembers = await listOrgUsersV2(orgId, {
        start: "0",
        limit: "100",
        effective_roles: [OrgRole.OWNER, OrgRole.ADMIN].join(","),
      });
      console.log("orgMembers", orgMembers);

      if (initialOwnerEmail !== ownerEmail) {
        // Demote initial owner to admin.
        const initialOwner = orgMembers.users.find((user) => user.email === initialOwnerEmail);
        if (initialOwner) {
          setLoadingText("Setting current owner as admin...");
          await updateOrgUserV2(orgId, initialOwner.user_id, { role: OrgRole.ADMIN });
        }

        // Promote new owner.
        setLoadingText("Promoting new owner...");
        const userResult = await useValidateUserEmail(ownerEmail, true);
        if (userResult.error) {
          throw new Error(userResult.error);
        }
        const newOwner = orgMembers.users.find((user) => user.email === userResult.user.email);
        if (!newOwner) {
          console.log("Creating user in org as admin...");
          await createOrgUserV2(orgId, {
            emails: [userResult.user.email],
            org_role: OrgRole.ADMIN,
            hub_role: null,
            hub_ids: null,
            send_invite_email: true,
          });
        }
        // Make them the owner.
        console.log("Seting user to owner role...");
        await updateOrgUserV2(orgId, userResult.user.id, { role: OrgRole.OWNER });
      }
      setRerenderKey((prevKey: number) => prevKey + 1);
      close();
    } catch (e) {
      console.log(`error updating ${card.label}: ${e}`);
      setErrorText(`Error Updating ${card.label}: ${e}`);
    }
    setLoadingText("");
  };

  return (
    <Stack width="100%" gap={2} p={3}>
      {errorText && (
        <Typography p={1} color="error" fontWeight={600} fontSize="12px">
          {errorText}
        </Typography>
      )}
      <Stack>
        {!isOrgAdmin ? (
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 400,
              fontFamily: "poppins",
              color: getDynamicColor("dark5"),
            }}
          >
            This will add you to the organization as an org admin if the org allows it.
          </Typography>
        ) : (
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 400,
              fontFamily: "poppins",
              color: getDynamicColor("dark5"),
            }}
          >
            This will completely remove you from the organization.
          </Typography>
        )}
        <Button
          onClick={async () => await addRemoveMeFromOrgAdmins()}
          variant="gradient"
          disabled={loadingText.length > 0}
          sx={{
            fontFamily: "poppins",
            fontSize: "16px",
            marginTop: 2,
            fontWeight: 700,
            "&:disabled": {
              color: getDynamicColor("light1"),
              background: getDynamicColor("dark4"),
            },
          }}
          endIcon={
            loadingText.length > 0 && (
              <CircularProgress size={12} sx={{ color: getDynamicColor("light1") }} />
            )
          }
        >
          {isOrgAdmin ? "Remove me from organization" : "Add me to org admins"}
        </Button>
      </Stack>
      <Divider />
      <form
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault();
          setOpenConfirmationModal(true);
        }}
      >
        <Stack gap={3}>
          <Stack>
            <YoodliLabeledInput
              label={"Update the organization owner"}
              inputEl={
                <YoodliTextfield
                  id="owner-item-add"
                  type="text"
                  placeholder="Update owner email"
                  required={false}
                  error={!!errors.ownerEmail}
                  helperText={errors.ownerEmail}
                  fullWidth
                  multiline={false}
                  value={ownerEmail}
                  onChange={(e) => setOwnerEmail(e.target.value)}
                  InputProps={{
                    sx: {
                      fontSize: "16px",
                      fontWeight: 500,
                    },
                  }}
                />
              }
            />
          </Stack>
        </Stack>
        <input type="submit" style={{ display: "none" }} />
      </form>
      <Button
        startIcon={
          loadingText.length > 0 ? (
            <CircularProgress
              size={20}
              sx={{
                color: getDynamicColor("dark1"),
              }}
            />
          ) : (
            <></>
          )
        }
        onClick={() => setOpenConfirmationModal(true)}
        variant="contained"
        disabled={
          !isFormValid() ||
          !!loadingText ||
          (initialOwnerEmail.toLowerCase() === ownerEmail.toLowerCase() &&
            initialIsOrgAdmin === isOrgAdmin)
        }
        sx={{
          fontFamily: "poppins",
          fontSize: "16px",
          background: getDynamicColor("redError"),
          marginTop: 3,
          fontWeight: 700,
          "&:disabled": {
            color: getDynamicColor("light1"),
            background: getDynamicColor("dark4"),
          },
          "&:hover": {
            background: getDynamicColor("redErrorDark"),
          },
        }}
      >
        {loadingText.length == 0 ? `Update Owner` : loadingText}
      </Button>
      <YoodliCtaModal
        theme={YoodliCtaModalTheme.Danger}
        hideCloseButton
        // never use drawer
        breakpointPx={0}
        open={openConfirmationModal}
        close={() => setOpenConfirmationModal(false)}
        loading={loadingText.length > 0}
        ctaBody={{
          title: "Warning: Changing Organization Owner",
          subtitle:
            "This operation will remove the organization owner and replace it with the new owner. This action is immediate and cannot be undone.",
        }}
        buttons={
          {
            primary: {
              text: "Confirm",
              handler: async () => await handleUpdateOrg(),
            },
            secondary: { text: "Cancel", handler: () => setOpenConfirmationModal(false) },
          } as CtaButtonHandlers
        }
      />
    </Stack>
  );
};
