import { Record, Static, String, Number, Optional, Array as RTArray } from "runtypes";
import { RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";

/**
 * Request to create a new team.
 */
export const CreateTeamRequest = Record({
  /**
   * Team name.
   */
  name: String,
});

/**
 * Request to create a new team.
 */
export type CreateTeamRequest = Static<typeof CreateTeamRequest>;

/**
 * Response to creating a new team.
 */
export const CreateTeamResponse = Record({
  /**
   * Team ID.
   */
  id: String,
});

/**
 * Response to creating a new team.
 */
export type CreateTeamResponse = Static<typeof CreateTeamResponse>;

/**
 * Sort options for getting a team list.
 */
export enum GetTeamListSortOptionEnum {
  NameAsc = "name",
  NameDesc = "-name",
  NumMembersAsc = "numMembers",
  NumMembersDesc = "-numMembers",
  LastUpdatedAsc = "lastUpdated",
  LastUpdatedDesc = "-lastUpdated",
}

/**
 * Sort options for getting a team list.
 */
export const GetTeamListSortOption = RTStringEnum(GetTeamListSortOptionEnum);

/**
 * Query parameters for the get team list API.
 */
export const GetTeamListRequestQueryParams = Record({
  /**
   * Sort order.
   */
  sort: Optional(GetTeamListSortOption),
  /**
   * Pagination start index.
   */
  start: Optional(String),
  /**
   * Maximum number of items to return in one page of results.
   */
  limit: Optional(String),
  /**
   * If specified, perform case-insensitive search on team name, treating this
   * as the beginning of the team name.
   */
  name: Optional(String),
});

/**
 * Query parameters for the get team list API.
 */
export type GetTeamListRequestQueryParams = Static<typeof GetTeamListRequestQueryParams>;

/**
 * Individual response item in a list of teams.
 */
export const GetTeamListResponseItem = Record({
  /**
   * Team ID.
   */
  id: String,
  /**
   * Team name.
   */
  name: String,
  /**
   * ISO 8601 date/time when the team was last updated.
   */
  modifiedDate: String,
  /**
   * Number of pending team memberships.
   */
  pendingMembershipCount: Number,
  /**
   * Number of current team memberships.
   */
  currentMembershipCount: Number,
  /**
   * Total number of team memberships.
   */
  membershipCount: Number,
});

/**
 * Individual response item in a list of teams.
 */
export type GetTeamListResponseItem = Static<typeof GetTeamListResponseItem>;

/**
 * Response to getting a paginated list of teams.
 */
export const GetTeamListResponse = Record({
  /**
   * One page of teams.
   */
  teams: RTArray(GetTeamListResponseItem),
  /**
   * Total number of teams.
   */
  total: Number,
});

/**
 * Response to getting a paginated list of teams.
 */
export type GetTeamListResponse = Static<typeof GetTeamListResponse>;

/**
 * Response to getting a single team. For now, it's the same as GetTeamListResponseItem.
 */
export const GetTeamResponse = GetTeamListResponseItem;

/**
 * Response to getting a single team.
 */
export type GetTeamResponse = Static<typeof GetTeamResponse>;

/**
 * Request to update a team.
 */
export const UpdateTeamRequest = Record({
  /**
   * Updated team name.
   */
  name: String,
});

/**
 * Request to update a team.
 */
export type UpdateTeamRequest = Static<typeof UpdateTeamRequest>;
