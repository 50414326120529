import { db } from "lib-fullstack";
import { useNavigate } from "react-router";

// Components
import { Stack, Typography, Link } from "@mui/material";

// Utils
import { usePricingUsage } from "hooks";
import { handleDeleteSpeech } from "utils/EditVideoItemUtilities";
import { WebServerInternalPath } from "utils/paths";
import { UsagePlanType } from "lib-fullstack/utils/pricingTypes";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";

type DeleteYoodliModalProps = {
  open: boolean;
  onClose: () => void;
  dbSpeech: db.Doc<db.Speech>;
};

export default function DeleteYoodliModal({
  open,
  onClose,
  dbSpeech,
}: DeleteYoodliModalProps): JSX.Element {
  const { usagePlanType } = usePricingUsage();
  const navigate = useNavigate();
  /**
   * Deletes recording. Shows VideoJournal afterwards
   */
  function handleDeleteSpeechClick() {
    handleDeleteSpeech(dbSpeech)
      .then(() => {
        navigate(WebServerInternalPath.LIBRARY);
      })
      .catch(console.error);
  }

  const getSpeechType = () => {
    switch (dbSpeech?.data?.type) {
      case "LIVE":
        return "Practice Session";
      case "UPLOADED":
        return "Upload";
      case "POODLI":
      case "ZOOM_RECALL":
        return "Meeting Report";
    }
  };

  return (
    <YoodliCtaModal
      ctaBody={{
        title: <>Are you sure you want to delete this {getSpeechType()}?</>,
        subtitle: (
          <Stack>
            <Typography sx={{ fontFamily: "poppins", fontSize: "16px" }}>
              You'll lose your recording, transcription, and analytics.
            </Typography>
            {usagePlanType === UsagePlanType.FREE && (
              <Typography sx={{ fontFamily: "poppins", fontSize: "16px" }}>
                Deleting a Yoodli will <b>not</b> change your recording quota. To increase your
                quota, consider{" "}
                <Link href={WebServerInternalPath.CHECKOUT} sx={{ textDecoration: "underline" }}>
                  upgrading
                </Link>{" "}
                instead.
              </Typography>
            )}
          </Stack>
        ),
      }}
      open={open}
      theme={YoodliCtaModalTheme.Danger}
      loading={false}
      hideCloseButton={true}
      close={onClose}
      buttons={
        {
          primary: {
            text: `Delete ${getSpeechType()}`,
            handler: async () => {
              handleDeleteSpeechClick();
            },
          },
          secondary: { text: "Cancel", handler: onClose },
        } as CtaButtonHandlers
      }
    />
  );
}
