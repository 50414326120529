import { RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";

/**
 * Enum indicating the role of a user in a team.
 */
export enum TeamMemberRoleEnum {
  /**
   * User is a team lead.
   */
  Lead = "lead",
  /**
   * User is a team member.
   */
  Member = "member",
}

/**
 * Enum indicating the role of a user in a team.
 */
export const TeamMemberRole = RTStringEnum(TeamMemberRoleEnum);
