import React from "react";

// Components
import { Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { LandingPageExternalPath, getLandingPageExternalUrl } from "lib-fullstack/utils/paths";

const TermsAndPrivacyAgreementCopy = (props: { orgSignUp: boolean }): JSX.Element => {
  const config = getClientEnvConfig();
  const tosNoun = props.orgSignUp ? "Yoodli's" : "our";
  return (
    <Typography
      fontSize="12px"
      textAlign="center"
      color={getDynamicColor("dark4")}
      fontWeight={600}
      width="min(505px, 100%)"
      sx={{ "& a": { color: getDynamicColor("dark4") }, px: 1 }}
    >
      By signing up, you agree to {tosNoun}{" "}
      <a
        href={getLandingPageExternalUrl(config, LandingPageExternalPath.TERMS_OF_SERVICE)}
        target="_blank"
        style={{ whiteSpace: "nowrap" }}
      >
        Terms of Service
      </a>
      ,{" "}
      <a
        href={getLandingPageExternalUrl(config, LandingPageExternalPath.COPYRIGHT)}
        target="_blank"
        style={{ whiteSpace: "nowrap" }}
      >
        Copyright Policy
      </a>
      ,{" "}
      <a
        href="#"
        className="cky-banner-element"
        style={{ textDecoration: "underline", fontSize: "12px", cursor: "pointer" }}
      >
        Cookie Preferences
      </a>
      {", " + (props.orgSignUp ? "to receive marketing information, " : "")}
      and acknowledge you’ve read {tosNoun}{" "}
      <a
        href={getLandingPageExternalUrl(config, LandingPageExternalPath.PRIVACY)}
        target="_blank"
        style={{ whiteSpace: "nowrap" }}
      >
        Privacy Policy
      </a>
      .
    </Typography>
  );
};

export default TermsAndPrivacyAgreementCopy;
