import { CreateContentRequestKind } from "../db";
import {
  RTOrgFileSourceType,
  RTOrgFileContentType,
  RTOrgFileState,
  RTOrgFileProcessMode,
} from "lib-fullstack/utils/enums";
import { NullableOptional } from "lib-fullstack/utils/runtypesHelpers";
import { Record, Static, String, Optional, Union, Null, Array as RTArray, Literal } from "runtypes";

export const CreateOrgFileRequestItem = Record({
  source_type: RTOrgFileSourceType,
  name: String,
  /** This is needed for OrgFileSourceType.Upload */
  mime_type: Optional(String),
});
export type CreateOrgFileRequestItem = Static<typeof CreateOrgFileRequestItem>;

export const CopyOrgFileRequest = Record({
  request_type: Optional(Literal(CreateContentRequestKind.Copy)),
  source_file_id: String,
  space_id: String,
  destination_space_id: String,
});
export type CopyOrgFileRequest = Static<typeof CopyOrgFileRequest>;

export const CreateNewOrgFileRequest = Record({
  request_type: Optional(Literal(CreateContentRequestKind.New)),
  space_id: String,
  files: RTArray(CreateOrgFileRequestItem),
});
export type CreateNewOrgFileRequest = Static<typeof CreateNewOrgFileRequest>;

export const CreateOrgFileRequest = CopyOrgFileRequest.Or(CreateNewOrgFileRequest);
export type CreateOrgFileRequest = Static<typeof CreateOrgFileRequest>;

export function requestIsCopyOrgFileRequest(
  request: CreateOrgFileRequest,
): request is CopyOrgFileRequest {
  return request.request_type === CreateContentRequestKind.Copy;
}

export function requestIsCreateNewOrgFileRequest(
  request: CreateOrgFileRequest,
): request is CreateNewOrgFileRequest {
  return request.request_type === CreateContentRequestKind.New;
}

export const CreateOrgFileResponseItem = Record({
  id: String,
  /** This is provided for upload type */
  upload_url: Optional(String),
});
export type CreateOrgFileResponseItem = Static<typeof CreateOrgFileResponseItem>;

export const CreateOrgFileResponse = Record({
  files: RTArray(CreateOrgFileResponseItem),
});
export type CreateOrgFileResponse = Static<typeof CreateOrgFileResponse>;

export const GetOrgFileQueryParams = Record({
  space_id: String,
});
export type GetOrgFileQueryParams = Static<typeof GetOrgFileQueryParams>;

export const GetOrgFilesQueryParams = Record({
  space_id: String,
});
export type GetOrgFilesQueryParams = Static<typeof GetOrgFilesQueryParams>;

export const OrgFileItem = Record({
  id: String,
  space_id: String,
  source_type: RTOrgFileSourceType,
  name: String,
  updated_date: String,
  mime_type: String,
  state: RTOrgFileState,
  error_reason: NullableOptional(String),
  content_type: Union(RTOrgFileContentType, Null),
  coach_bot_content_ids: RTArray(String),
  ai_preread_scenario_ids: RTArray(String),
  custom_goal_ids: RTArray(String),
  signed_url: String,
});
export type OrgFileItem = Static<typeof OrgFileItem>;

export const GetOrgFilesResponse = Record({
  files: RTArray(OrgFileItem),
});
export type GetOrgFilesResponse = Static<typeof GetOrgFilesResponse>;

export const UpdateOrgFileRequest = Record({
  /** This needs to match the target space ID, not for updating the space. */
  space_id: String,
  name: Optional(String),
  /**
   * This param cannot be passed together with other properties (only name at this time)
   * "pending" is accepted when the current state is "uploading"
   */
  state: Optional(RTOrgFileState),
  /**
   * This param is needed when state=pending is passed
   */
  process_mode: Optional(RTOrgFileProcessMode),
});
export type UpdateOrgFileRequest = Static<typeof UpdateOrgFileRequest>;

export const DeleteOrgFilesQueryParams = Record({
  space_id: String,
});
export type DeleteOrgFilesQueryParams = Static<typeof DeleteOrgFilesQueryParams>;
