import React from "react";

// Components
import { CheckCircleRounded as CheckCircleRoundedIcon } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { EmailBank } from "components/Orgs/BulkInviteByCSVWizard/EmailBank";
import { WizardSubTitle, WizardTitle } from "components/Wizard/WizardTitles";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { HubV2Response } from "lib-fullstack/api/orgApiTypes";

type RemoveImportCompleteProps = {
  emails: string[];
  handleRemoveEmail: (email: string) => void;

  currentHub?: HubV2Response;
  fallbackHubId: string;
  setFallbackHubId: (hubId: string) => void;
};

export const RemoveImportComplete = ({
  emails,
  handleRemoveEmail,

  currentHub,
  fallbackHubId,
  setFallbackHubId,
}: RemoveImportCompleteProps): JSX.Element => {
  const {
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);
  let subTitle = `We found ${emails?.length} emails. Any invalid or existing/duplicate emails were ignored.`;
  if (currentHub) {
    subTitle += " All members listed below will be removed from this group.";
  } else {
    subTitle +=
      " All members listed below will be removed from the organization, and their seats will be released.";
  }

  const hubOptions = React.useMemo(() => {
    return defaultOrg?.hubs.map((hub) => ({
      label: hub.name,
      value: hub.id,
    }));
  }, [defaultOrg?.hubs, currentHub?.id]);

  return (
    <Stack
      gap={{ xs: 2, md: 5 }}
      sx={{
        fontFamily: "poppins",
      }}
    >
      <Stack gap={1}>
        <WizardTitle
          icon={
            <CheckCircleRoundedIcon
              sx={{
                color: getDynamicColor("greenSuccess"),
              }}
            />
          }
        >
          Import Complete
        </WizardTitle>
        <WizardSubTitle
          overrideSx={{
            color: getDynamicColor("dark5"),
          }}
        >
          {subTitle}
        </WizardSubTitle>
      </Stack>
      <EmailBank emails={emails} title="Email addresses" handleRemoveEmail={handleRemoveEmail} />
      {currentHub && (
        <Stack sx={{ gap: 1 }}>
          <WizardSubTitle>
            All members must be in at least one group. Select a group to send members to, if{" "}
            {currentHub?.name ?? "this group"} is the only group they are part of.
          </WizardSubTitle>
          <Box sx={{ width: { xs: "100%", sm: "250px", md: "50%" } }}>
            <YoodliSelect
              value={fallbackHubId}
              onChange={(e) => {
                setFallbackHubId(e.target.value as string);
              }}
              sx={{ minHeight: 48 }}
              options={hubOptions}
            />
          </Box>
        </Stack>
      )}
    </Stack>
  );
};
