import React from "react";

// Components
import { Stack, Typography, Button, Checkbox } from "@mui/material";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { CourseResponse } from "lib-fullstack/api/hubApiTypes";
import { HubV2Response } from "lib-fullstack/api/orgApiTypes";

type CreateOrEditCourseModalProps = {
  closeModal: () => void;
  allHubs: HubV2Response[];
  handleCreateCourse?: UseMutateAsyncFunction<
    void,
    Error,
    {
      title: string;
      description: string;
      availableHubs: string[];
    }
  >;
  handleUpdateCourse?: UseMutateAsyncFunction<
    void,
    Error,
    {
      courseId: string;
      title: string;
      description: string;
      availableHubs: string[];
    }
  >;
  isEditing?: boolean;
  course?: CourseResponse;
};

export default function CreateOrEditCourseModal({
  course,
  isEditing = false,
  closeModal,
  allHubs,
  handleCreateCourse,
  handleUpdateCourse,
}: CreateOrEditCourseModalProps): JSX.Element {
  const [courseName, setCourseName] = React.useState<string>(course?.title ?? "");
  const [description, setDescription] = React.useState<string>(course?.description ?? "");
  const [availableHubs, setAvailableHubs] = React.useState<string[]>(course?.available_hubs ?? []);
  const allHubsAvailable = availableHubs.length === allHubs.length;

  const handleSetSelectedHubs = (hubId) => {
    const newAvailableHubs = [...availableHubs];
    if (newAvailableHubs.includes(hubId)) {
      const indexToRemove = newAvailableHubs.indexOf(hubId);
      newAvailableHubs.splice(indexToRemove, 1);
    } else {
      newAvailableHubs.push(hubId);
    }

    setAvailableHubs(newAvailableHubs);
  };

  const handleSelectAll = () => {
    if (allHubsAvailable) {
      setAvailableHubs([]);
    } else {
      setAvailableHubs(allHubs.map((hub) => hub.id));
    }
  };

  const createOrSaveCourse = async () => {
    if (isEditing) {
      await handleUpdateCourse({
        courseId: course.id,
        title: courseName,
        description,
        availableHubs,
      });
    } else {
      await handleCreateCourse({ title: courseName, description, availableHubs });
    }
    closeModal();
  };

  const creationDisabled = !courseName.length;

  return (
    <>
      <Typography
        color={getDynamicColor("purple3")}
        fontFamily="poppins"
        fontSize="16px"
        fontWeight={600}
      >
        {isEditing ? "Edit" : "Create a new"} course
      </Typography>
      <Stack
        sx={{
          textAlign: "start",
          width: "100%",
          pt: 2,
        }}
        gap={4}
      >
        <Stack gap={1}>
          <Typography
            color={getDynamicColor("purple3")}
            fontFamily="poppins"
            fontSize="14px"
            fontWeight={700}
          >
            Course name<span style={{ color: getDynamicColor("redError") }}>*</span>
          </Typography>
          <YoodliTextfield
            value={courseName}
            onChange={(e) => setCourseName(e.target.value)}
            placeholder="Speaking course 1"
            InputProps={{
              sx: {
                fontSize: "16px",
                fontWeight: 500,
              },
            }}
          />
        </Stack>
        <Stack gap={1}>
          <Typography
            color={getDynamicColor("purple3")}
            fontFamily="poppins"
            fontSize="14px"
            fontWeight={700}
          >
            Description
          </Typography>
          <YoodliTextfield
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Add a description"
            multiline
            rows={2}
            InputProps={{
              sx: {
                fontWeight: 500,
              },
            }}
          />
        </Stack>
        <Stack gap={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography
              color={getDynamicColor("purple3")}
              fontFamily="poppins"
              fontSize="14px"
              fontWeight={700}
            >
              Available in
            </Typography>
            <Button
              onClick={handleSelectAll}
              sx={{ color: getDynamicColor("primary"), fontSize: "12px", fontWeight: 700 }}
            >
              {allHubsAvailable ? "Deselect all" : "Select all"}
            </Button>
          </Stack>
          <Stack
            sx={{
              overflowY: "auto",
              background: getDynamicColor("light1"),
              py: 1,
              px: 2.5,
              maxHeight: "210px",
              borderRadius: "4px",
              border: `1px solid ${getDynamicColor("dark3")}`,
            }}
          >
            {allHubs.map((hub, index) => (
              <Stack
                key={hub.id}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  borderBottom:
                    index === allHubs.length - 1
                      ? "hidden"
                      : `1px solid ${getDynamicColor("dark3")}`,
                  py: 2,
                }}
              >
                <Typography>{hub.name}</Typography>
                <Checkbox
                  onClick={() => {
                    handleSetSelectedHubs(hub.id);
                  }}
                  checked={availableHubs.includes(hub.id)}
                  sx={{
                    color: getDynamicColor("purple3"),
                  }}
                />
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
      <Typography
        style={{
          color: getDynamicColor("dark5"),
          fontSize: "12px",
          fontWeight: 700,
        }}
      >
        Fields with asterisks (<span style={{ color: getDynamicColor("redError") }}>*</span>) are
        required
      </Typography>
      <Button
        onClick={createOrSaveCourse}
        disabled={creationDisabled}
        sx={{
          height: "44px",
          mt: 1.5,
          px: 4,
          py: 2,
          borderRadius: "50px",
          background: getDynamicColor("primary"),
          fontFamily: "poppins",
          fontSize: "16px",
          fontWeight: 700,
          color: getDynamicColor("light1"),
          "&:hover": {
            background: getDynamicColor("primaryHover"),
          },
          "&:disabled": {
            background: getDynamicColor("dark4"),
            color: getDynamicColor("light1"),
          },
        }}
      >
        {isEditing ? "Save" : "Create new"} course
      </Button>
    </>
  );
}
