import parse, { Element } from "html-react-parser";

// Components
import { Check as CheckIcon } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Divider, Stack, SxProps, Typography } from "@mui/material";

// Assets
import { ReactComponent as UpgradeIcon } from "images/icons/icon-upgrade.svg";

// Utils
import { usePricingUsage } from "hooks";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { getLiveUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { PRICING_EXPERIMENT_CONFIG, PricingCardType } from "lib-frontend/utils/PricingData";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { LandingPageExternalPath, getLandingPageExternalUrl } from "lib-fullstack/utils/paths";
import {
  PricingExperiment,
  UsagePlanDuration,
  UsagePlanType,
} from "lib-fullstack/utils/pricingTypes";
import { PricingReimbursementLetterLinkLocation } from "lib-fullstack/utils/productAnalyticEvents";
import { handlePlanCheckout, isPricingPlanRecommended } from "utils/PricingUtils";

export const PricingFeatureBullet = ({
  info,
  overrideSx = {},
}: {
  info: string;
  overrideSx?: SxProps;
}): JSX.Element => {
  return (
    <Stack
      flexDirection="row"
      key={info.toString()}
      gap={1}
      sx={{
        ...overrideSx,
      }}
    >
      <CheckIcon
        sx={{ height: "20px", width: "16px", top: "50px", fontSize: "16px" }}
        color="primary"
      />
      <Typography
        sx={{
          a: {
            fontWeight: 600,
            textDecoration: "none",
            "&:visited": {
              color: getDynamicColor("primary"),
            },
          },
        }}
      >
        {parse(info, {
          replace(domNode: Element) {
            if (domNode.name === "a") {
              return (
                <a
                  {...domNode.attribs}
                  onClick={() =>
                    Instrumentation.logPricingReimbursementLetterClicked(
                      PricingReimbursementLetterLinkLocation.WEBCLIENT_PRICING_MODAL,
                    )
                  }
                >
                  Reimbursable
                </a>
              );
            }
          },
        })}
      </Typography>
    </Stack>
  );
};

type PricingProps = {
  cardData: PricingCardType;
  isAnnually?: boolean;
  handleOptionSelected?: () => void;
  setDowngradeSelection: (type: UsagePlanType) => void;
  setDurationChange: (val: boolean) => void;
  setLoading: (loading: boolean) => void;
  onClose?: () => void;
};

function PricingCard({
  cardData,
  isAnnually,
  handleOptionSelected = () => null,
  setDowngradeSelection,
  setDurationChange,
  setLoading,
  onClose,
}: PricingProps): JSX.Element {
  const { title, pricingSubtext, description, bulletPoints, sectionHeader, cost, type } = cardData;
  const { usagePlanType, nextUsagePlanType, nextUsagePlanStart, usagePlanDuration } =
    usePricingUsage();
  const cardPlanDuration = isAnnually ? UsagePlanDuration.YEARLY : UsagePlanDuration.MONTHLY;
  const isSelectedPlan =
    usagePlanType === type &&
    ([PricingExperiment.PRO_ADV].includes(getLiveUserDocMain().pExpVersion as PricingExperiment) ||
      cardPlanDuration === usagePlanDuration ||
      [UsagePlanType.FREE, UsagePlanType.ENTERPRISE].includes(usagePlanType));
  const isRecommended = isPricingPlanRecommended(usagePlanType, cardData);
  const userDocMain = getLiveUserDocMain();
  const isUpcomingPlan = nextUsagePlanType === type;
  const isAnnualToMonthly =
    getLiveUserDocMain().pExpVersion === PricingExperiment.CRUNCH_GROWTH &&
    usagePlanDuration === UsagePlanDuration.YEARLY &&
    cardPlanDuration === UsagePlanDuration.MONTHLY;

  const handleSelectPlan = async () => {
    if (type === UsagePlanType.ENTERPRISE) {
      window.location.href = getLandingPageExternalUrl(
        getClientEnvConfig(),
        LandingPageExternalPath.TALK_TO_SALES,
      );
      onClose?.();
    } else {
      try {
        handleOptionSelected();
        setLoading(true);
        const costNumber = parseInt(
          (cost ? (isAnnually ? cost.annually : cost.monthly) : "0").replace(/\D/g, "") ?? "0",
          10,
        );
        const currIdx = PRICING_EXPERIMENT_CONFIG[userDocMain.pExpVersion].cards.findIndex(
          (plan) => plan.type === usagePlanType,
        );
        const nextIdx = PRICING_EXPERIMENT_CONFIG[userDocMain.pExpVersion].cards.findIndex(
          (plan) => plan.type === type,
        );
        const isDowngrade = nextIdx < currIdx;
        const isDurationChange = type === usagePlanType && cardPlanDuration !== usagePlanDuration;
        if (isDurationChange) {
          setDurationChange(true);
        } else if (isDowngrade) {
          setDowngradeSelection(type as UsagePlanType);
          Instrumentation.logPricingModalDowngradeClicked(type, costNumber, isAnnually);
        } else {
          Instrumentation.logPricingModalUpgradeClicked(type, costNumber, isAnnually);
          await handlePlanCheckout(
            type,
            isAnnually ? UsagePlanDuration.YEARLY : UsagePlanDuration.MONTHLY,
            true,
          );
        }
      } catch (er) {
        console.error(
          `Error in handleSelectPlan: tried to select plan of type ${type} but got an error of "${er}"`,
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const getCtaVariant = () => {
    if (isSelectedPlan || isUpcomingPlan || isAnnualToMonthly) {
      return "contained";
    }
    if (isRecommended) {
      return "gradient";
    }
    return "outlined";
  };

  const getCtaText = () => {
    if (isSelectedPlan) {
      return "Your plan";
    }

    if (isUpcomingPlan) {
      return "Upcoming plan";
    }

    if (isAnnualToMonthly) {
      return "Unavailable";
    }

    if (type === UsagePlanType.ENTERPRISE) {
      return "Talk to Sales";
    }

    const orderedPlans = Object.values(UsagePlanType);

    if (orderedPlans.indexOf(type) < orderedPlans.indexOf(usagePlanType as UsagePlanType)) {
      return "Downgrade";
    }

    return "Upgrade";
  };

  const getNextPlanText = () => {
    if (nextUsagePlanStart) {
      const formattedDate = new Date(nextUsagePlanStart).toLocaleDateString(undefined, {
        month: "numeric",
        day: "numeric",
        year: "numeric",
      });
      if (isSelectedPlan) {
        return `Until ${formattedDate}`;
      } else if (isUpcomingPlan) {
        return `Starts ${formattedDate}`;
      }
    }
  };

  const renderShowExtraSavingsText = () => {
    if ([UsagePlanType.PRO_V1, UsagePlanType.UNLIMITED].includes(type)) {
      return `, billed ${isAnnually ? "annually" : "monthly"}`;
    } else {
      return "";
    }
  };

  return (
    <Card
      sx={{
        display: "flex",
        border: isRecommended ? "double 1px transparent" : `1px solid ${getDynamicColor("dark3")}`,
        backgroundImage:
          isRecommended &&
          `linear-gradient(white, white), ${getDynamicColor("gradient.slantedReversed")}`,
        width: "100%",
        minHeight: { xs: "unset", md: "540px" },
        borderRadius: "12px",
        WebkitBorderRadius: "12px",
        boxShadow: Y_SHADOWS.box_shadow_1,
        MozBorderRadius: "12px",
        backgroundClip: "padding-box, border-box",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          transform: "scale(1.05)",
        },
        p: 0,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: getDynamicColor("light1"),
          padding: { xs: "24px 12px 24px 12px", sm: "28px 22px 0 22px" },
          py: { xs: isRecommended ? 3 : 1, md: isRecommended ? 3.5 : 1 },
          px: 2,
          width: "100%",
          position: "relative",
          alignItems: { xs: "flex-start", sm: "center" },
          flex: { xs: 1 },
          "&:last-child": {
            paddingBottom: "16px",
          },
        }}
      >
        {isRecommended && (
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              width: "100%",
              top: 0,
              left: 0,
              right: 0,
              height: "28px",
              justifyContent: "flex-start",
              alignItems: "center",
              background: getDynamicColor("gradient.slanted"),
            }}
          >
            <Typography sx={{ fontSize: 12, color: getDynamicColor("light1"), pl: 2 }}>
              RECOMMENDED
            </Typography>
          </Box>
        )}
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            pt: 1,
            width: "100%",
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 700,
                color: getDynamicColor("purple3"),
                fontSize: { xs: "24px", sm: "24px" },
              }}
            >
              {title}
            </Typography>
            {(isSelectedPlan || isUpcomingPlan) && (
              <Typography
                sx={{
                  color: getDynamicColor("dark4"),
                  fontSize: "12px",
                  fontWeight: 700,
                  letterSpacing: "0.17px",
                  textAlign: "right",
                }}
              >
                {getNextPlanText()}
              </Typography>
            )}
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "baseline",
              gap: 1,
              pb: "16px",
              height: "64px",
              color: getDynamicColor("purple3"),
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: { xs: "24px", sm: "28px" },
                fontFamily: "Poppins",
                maxWidth: 220,
              }}
            >
              {isAnnually ? cost.annually : cost.monthly}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px" },
                fontWeight: 700,
                maxWidth: 220,
              }}
            >
              {pricingSubtext}
              {renderShowExtraSavingsText()}
            </Typography>
          </Stack>
          <Stack gap={2} sx={{ width: "100%", alignItems: "center", pb: 2 }}>
            <Button
              onClick={handleSelectPlan}
              variant={getCtaVariant()}
              disabled={isSelectedPlan || isUpcomingPlan || isAnnualToMonthly}
              startIcon={isRecommended && <UpgradeIcon />}
              sx={{
                fontSize: 14,
                height: "40px",
                width: "100%",
                maxWidth: { xs: "224px", sm: "226px" },
                alignSelf: "center",
                color: getDynamicColor(isRecommended ? "light1" : "primary"),
                textTransform: isRecommended ? "uppercase" : "none",

                svg: {
                  height: 16,
                  width: 16,
                },
              }}
            >
              {getCtaText()}
            </Button>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: "14px",
                textAlign: "center",
                lineHeight: 1.3,
                height: "32px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {description}
            </Typography>
          </Stack>

          <Divider />
          <Stack gap={"24px"} sx={{ minHeight: "226px", width: "226px", pt: 2 }}>
            {bulletPoints.map((info) => (
              <PricingFeatureBullet key={info.toString()} info={info} />
            ))}
          </Stack>
          {sectionHeader}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default PricingCard;
