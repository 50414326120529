import React from "react";
import { NavLink, useLocation } from "react-router-dom";

// Components
import { ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { NavigationItem } from "utils/navUtils";
import { WebServerInternalPath } from "utils/paths";
import { OrgContentQueryParams } from "lib-fullstack/utils/queryParams";

type NavItemProps = {
  item: NavigationItem;
  navOpen: boolean;
  handleDrawerClose?: () => void;
  isGroupItem?: boolean;
};

// Item on navbar, loads in from enums
export const NavItem = React.memo(
  ({ item, navOpen, handleDrawerClose, isGroupItem }: NavItemProps): JSX.Element => {
    const isSmallScreen = useIsSmallScreen();
    const location = useLocation();
    const siteConf = getStaticFullSiteConf();

    const isNavBarSelectedUnderlined = siteConf?.featureDiffs?.underlineSelectedNavBarItem ?? false;
    const navBarSelectedTextColor =
      siteConf?.colors?.specialAccessibility2 ?? getDynamicColor("primary");

    if (!item) {
      return null;
    }

    const isNavItemSelected = (
      item: NavigationItem,
      location: ReturnType<typeof useLocation>,
    ): boolean => {
      const pathMatches = location.pathname === item.path;
      if (!pathMatches) return false;

      // Special case for the OrgManageContent tabs
      if (item.path.startsWith(WebServerInternalPath.ORG_CONTENT)) {
        const currentTab = new URLSearchParams(location.search).get(OrgContentQueryParams.TAB);
        const itemTab = new URLSearchParams(item.search || "").get(OrgContentQueryParams.TAB);
        return currentTab === itemTab;
      }

      return true;
    };

    const isSelected = React.useMemo(
      () => isNavItemSelected(item, location),
      [item.path, item.search, location.pathname, location.search],
    );

    return (
      <YoodliTooltip
        title={!navOpen && (item?.tooltip ?? item?.text)}
        placement="right"
        enterDelay={500}
      >
        <ListItemButton
          component={NavLink}
          to={{
            pathname: item.path,
            search: item.search,
          }}
          onClick={() => {
            if (isNavItemSelected(item, location)) {
              handleDrawerClose?.();
            }
          }}
          selected={isSelected}
          aria-label={item.text}
          sx={{
            py: "auto",
            height: isGroupItem ? 32 : 40,
            color: isToastmasters() ? getDynamicColor("light1") : getDynamicColor("purple3"),
            minWidth: "100%",
            "&.Mui-selected": {
              backgroundColor: "transparent",
              color: navBarSelectedTextColor,
              "&.active span.MuiTypography-root": {
                color: navBarSelectedTextColor,
                textDecoration: isNavBarSelectedUnderlined ? "underline" : "none",
              },
              "& .MuiListItemIcon-root": {
                color: navBarSelectedTextColor,
                fill: navBarSelectedTextColor,
              },
            },
          }}
        >
          <Stack direction="row" gap={2} alignItems="center" sx={{ height: "100%", width: "100%" }}>
            {item.icon && (
              <ListItemIcon
                sx={{
                  alignItems: "center",
                  color: "currentColor",
                  fill: "currentColor",
                  minWidth: "unset",
                  svg: {
                    display: "block",
                    fill: "currentColor",
                    path: {
                      fill: "currentColor",
                    },
                  },
                  width: 24,
                  height: 24,
                  flexGrow: 0,
                  ...item.iconOverrideSx,
                }}
              >
                {React.cloneElement(item.icon, {
                  role: "img",
                  "aria-label": item.text,
                  focusable: "false",
                })}
              </ListItemIcon>
            )}
            {navOpen && (
              <ListItemText
                sx={{
                  display: "block",
                  fontSize: isGroupItem ? 12 : 14,
                  fontWeight: 400,
                  lineHeight: isSmallScreen ? "32px" : "24px",
                  fontFamily: "poppins",
                  whiteSpace: "nowrap",
                  pl: !item.icon ? 5 : undefined, // add spacing if icon isn't present
                }}
                data-testid={item.dataTestId}
              >
                {item.text}
              </ListItemText>
            )}
          </Stack>
        </ListItemButton>
      </YoodliTooltip>
    );
  },
);
