// Utils
import { AuthorizationAPIHeaders, getAxiosInstance } from "./AxiosInstance";
import * as api from "lib-fullstack/api/orgTeamApiTypes";

/**
 * Create a new team.
 * @param orgId Org ID.
 * @param body Request describing the team name.
 * @returns The response containing the ID of the created team.
 */
export async function createTeam(
  orgId: string,
  body: api.CreateTeamRequest,
): Promise<api.CreateTeamResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await getAxiosInstance().post(`/v2/orgs/${orgId}/teams`, body, {
      headers: headers,
    });
    return api.CreateTeamResponse.check(ret.data);
  } catch (err) {
    throw Error("Bad createTeam request, status " + err.response?.status);
  }
}

/**
 * List all teams in an organization.
 * @param orgId Org ID.
 * @param queryParams Query parameters including pagination and filtering.
 * @returns List of teams in the organization.
 */
export async function listTeams(
  orgId: string,
  queryParams: api.GetTeamListRequestQueryParams,
): Promise<api.GetTeamListResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await getAxiosInstance().get(`/v2/orgs/${orgId}/teams`, {
      headers: headers,
      params: queryParams,
    });
    return api.GetTeamListResponse.check(ret.data);
  } catch (err) {
    throw Error("Bad listTeams request, status " + err.response?.status);
  }
}

/**
 * Retrieve a team.
 * @param orgId Org ID.
 * @param teamId Team ID.
 * @returns The team.
 */
export async function getTeam(orgId: string, teamId: string): Promise<api.GetTeamResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await getAxiosInstance().get(`/v2/orgs/${orgId}/teams/${teamId}`, {
      headers: headers,
    });
    return api.GetTeamResponse.check(ret.data);
  } catch (err) {
    throw Error("Bad getTeam request, status " + err.response?.status);
  }
}

/**
 * Update a team.
 * @param orgId Org ID.
 * @param teamId Team ID.
 * @param body Properties to update.
 * @returns Nothing.
 */
export async function updateTeam(
  orgId: string,
  teamId: string,
  body: api.UpdateTeamRequest,
): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await getAxiosInstance().patch(`/v2/orgs/${orgId}/teams/${teamId}`, body, {
      headers: headers,
    });
  } catch (err) {
    throw Error("Bad updateTeam request, status " + err.response?.status);
  }
}

/**
 * Delete a team.
 * @param orgId Org ID.
 * @param teamId Team ID.
 * @returns Nothing.
 */
export async function deleteTeam(orgId: string, teamId: string): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    await getAxiosInstance().delete(`/v2/orgs/${orgId}/teams/${teamId}`, {
      headers: headers,
    });
  } catch (err) {
    throw Error("Bad deleteTeam request, status " + err.response?.status);
  }
}
