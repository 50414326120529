import { KeyboardEvent } from "react";

/**
 * Handles Enter or Space key press events with propagation stopping
 * @param e Keyboard event
 * @returns True if the event was handled (Enter or Space was pressed)
 */
export const handleEnterOrSpaceKey = (e: KeyboardEvent): boolean => {
  if (e.key === "Enter" || e.key === " ") {
    e.preventDefault();
    e.stopPropagation();
    return true;
  }
  return false;
};
