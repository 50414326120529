import { useLocation, Link } from "react-router-dom";

// Components
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Stack, Typography } from "@mui/material";

// Utils
import { currentUserFirstName } from "lib-frontend/utils/AccountUtils";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { ProgramResponse } from "lib-fullstack/api/programApiTypes";
import { ProgramState } from "lib-fullstack/utils/enums";
import { OrgProgramsQueryParams } from "lib-fullstack/utils/queryParams";
import { ProgramInfoTab } from "./ProgramInfo";

type ProgramFtuxChecklistProps = {
  program: ProgramResponse;
  handleCloseChecklist: () => void;
};

export const ProgramFtuxChecklist = ({
  program,
  handleCloseChecklist,
}: ProgramFtuxChecklistProps): JSX.Element => {
  const location = useLocation();
  const programChecklistSteps = [
    {
      title: "Name and describe your program",
      completed: !!program.name,
    },
    {
      title: "Add scenarios",
      completed: program.plan_steps.length,
      linkTo: `${location.pathname}?${new URLSearchParams({
        ...Object.fromEntries(new URLSearchParams(location.search)),
        [OrgProgramsQueryParams.PROGRAM_TAB]: ProgramInfoTab.Roleplays,
      })}`,
    },
    {
      title: "Assign to groups",
      completed: program.hub_ids.length,
      linkTo: `${location.pathname}?${new URLSearchParams({
        ...Object.fromEntries(new URLSearchParams(location.search)),
        [OrgProgramsQueryParams.PROGRAM_TAB]: ProgramInfoTab.Settings,
      })}`,
    },
    {
      title: "Publish",
      completed: program.state !== ProgramState.Draft,
    },
  ];

  return (
    <Box sx={{ background: getDynamicColor("light2") }}>
      <Stack gap={3} sx={{ px: { xs: 2, md: 7 }, maxWidth: "xxl", pt: { xs: 3, md: 6 }, pb: 4 }}>
        <Stack
          direction="row"
          gap={1}
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontSize: { xs: "16px", md: "18px" },
              fontWeight: 700,
            }}
          >
            Hey {currentUserFirstName()}, create your program
          </Typography>
          <IconButton onClick={handleCloseChecklist}>
            <CloseIcon sx={{ color: getDynamicColor("purple3") }} />
          </IconButton>
        </Stack>
        <Stack
          sx={{
            borderRadius: "12px",
            border: `1px solid ${getDynamicColor("dark2")}`,
            background: getDynamicColor("light1"),
            p: { xs: 1, md: 3 },
          }}
        >
          {programChecklistSteps.map((step, index) => (
            <Stack
              key={step.title}
              direction="row"
              gap={{ xs: 2, md: 3 }}
              sx={{
                alignItems: "center",
                p: 2,
                borderRadius: "12px",
                background: getDynamicColor(
                  !step.completed && programChecklistSteps[index - 1]?.completed
                    ? "light2"
                    : "light1",
                ),
              }}
            >
              {step.completed ? (
                <CheckCircleIcon sx={{ color: getDynamicColor("greenSuccess") }} />
              ) : (
                <CircleOutlinedIcon
                  sx={{
                    color: getDynamicColor(
                      programChecklistSteps[index - 1]?.completed ? "primary" : "dark3",
                    ),
                  }}
                />
              )}
              <Typography
                sx={{
                  color: getDynamicColor(
                    step.completed || programChecklistSteps[index - 1].completed
                      ? "primary"
                      : "purple3",
                  ),
                  fontSize: { xs: "14px", md: "16px" },
                  fontWeight:
                    step.completed || programChecklistSteps[index - 1].completed ? 700 : 400,
                  fontFamily: "poppins",
                  a: {
                    textDecoration: "none",
                    color: getDynamicColor("primary"),
                  },
                }}
              >
                {step.linkTo ? (
                  <Link
                    to={step.linkTo}
                    onClick={() => {
                      window.scrollTo({
                        top: document.body.scrollHeight,
                        behavior: "smooth",
                      });
                    }}
                  >
                    {step.title}
                  </Link>
                ) : (
                  step.title
                )}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};
