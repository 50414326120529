import parse from "html-react-parser";

// Components
import { Stack, Typography, Box } from "@mui/material";

// Assets
import { ReactComponent as CalendarIcon } from "images/icons/icon-calendar.svg";

// Utils
import { ProgramStateIndicator } from "./ProgramStateIndicator";
import { StepProgressTracker } from "./StepProgressTracker";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsMediumScreen } from "lib-frontend/utils/themeUtils";
import { ProgramMemberViewItem } from "lib-fullstack/api/programApiTypes";
import { formatTimeOfDay, getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { ProgramRecordState, UITestId } from "lib-fullstack/utils/enums";
import React from "react";

type MemberProgramItemProps = {
  program: ProgramMemberViewItem;
  cta: JSX.Element;
};

const headerMapping = {
  [ProgramRecordState.NotStarted]: getDynamicColor("blueLight"),
  [ProgramRecordState.InProgress]: getDynamicColor("chartPurple"),
  [ProgramRecordState.Completed]: getDynamicColor("light1"),
};

export const MemberProgramItem = ({ program, cta }: MemberProgramItemProps): JSX.Element => {
  const isMediumScreen = useIsMediumScreen();
  const numAttempts = React.useMemo(() => {
    return program.plan_step_results.reduce((acc, step) => {
      return acc + step.attempts.length;
    }, 0);
  }, [program.plan_step_results]);

  const programDetails = React.useMemo(() => {
    return {
      icon: <CalendarIcon />,
      text: program.completion_date
        ? `Completed ${getHumanReadableDate(program.completion_date)}`
        : program.last_progress_date
          ? `Last practice ${getHumanReadableDate(program.last_progress_date)} at ${formatTimeOfDay(new Date(program.last_progress_date))}`
          : undefined,
    };
  }, [program?.completion_date, program?.last_progress_date]);

  return (
    <Stack
      data-testid={`${UITestId.ProgramCarouselCard}-${program.id}`}
      gap={1}
      sx={{
        px: { xs: 1, md: 2 },
        py: { xs: 2, md: 3 },
        borderRadius: "8px",
        border: `1px solid ${getDynamicColor("dark3")}`,
        borderTop:
          program.state === ProgramRecordState.Completed
            ? `1px solid ${getDynamicColor("dark3")}`
            : "none",

        position: "relative",
      }}
    >
      {program.state !== ProgramRecordState.Completed && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: "-1px",
            right: "-1px",
            height: "8px",
            borderRadius: "8px 8px 0 0",
            backgroundColor: headerMapping[program.state],
          }}
        />
      )}
      <Stack
        direction="row"
        gap={3}
        sx={{
          justifyContent: "space-between",
          display: "flex",
          mb: 1,
          height: "100%",
        }}
      >
        <Stack gap={2} sx={{ flex: 1 }}>
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontSize: { xs: "16px", md: "18px" },
              fontWeight: 700,
            }}
          >
            {program.name}
          </Typography>
          {programDetails.text && (
            <Stack
              gap={1}
              direction="row"
              sx={{ alignItems: "center", color: getDynamicColor("dark4") }}
            >
              {programDetails.icon}
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                {programDetails.text}
              </Typography>
            </Stack>
          )}
          {program.description && (
            <Typography
              component="div"
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 500,
                textAlign: "start",
              }}
            >
              {parse(program.description)}
            </Typography>
          )}
          {cta}
        </Stack>
        {!isMediumScreen && (
          <Box
            sx={{
              pb: 1.5,
              borderLeft: `1px solid ${getDynamicColor("dark3")}`,
              pl: 2,
              mt: -1,
              mb: -1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              alignSelf: "stretch",
              height: "auto",
              minHeight: "100%",
            }}
          >
            <Stack gap={2}>
              <ProgramStateIndicator state={program.state} />
              {program.state === ProgramRecordState.Completed ? (
                <Stack gap={2}>
                  <StepProgressTracker planStepResults={program.plan_step_results} />
                  <Stack sx={{ fontWeight: 500, fontSize: "12px" }}>
                    <Typography>Attempts: {numAttempts}</Typography>
                    {program.most_recent_score && (
                      <Typography>Final Score: {program.most_recent_score}</Typography>
                    )}
                  </Stack>
                </Stack>
              ) : (
                <StepProgressTracker planStepResults={program.plan_step_results} />
              )}
            </Stack>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
