import { useState } from "react";
import { TextField, TextFieldProps, InputAdornment } from "@mui/material";

import { getDynamicColor } from "lib-frontend/utils/Colors";
import { handleEnterOrSpaceKey } from "lib-frontend/utils/keyboard";

import PasswordOnIcon from "@mui/icons-material/VisibilityOutlined";
import PasswordOffIcon from "@mui/icons-material/VisibilityOffOutlined";

const INPUT_BORDER_RADIUS = "4px";

export default function AuthInput(props: TextFieldProps & { errorText?: string }): JSX.Element {
  const [textVisible, setTextVisible] = useState(props.type !== "password");
  const { errorText, ...rest } = props;

  return (
    <TextField
      data-logrocket-hidden
      sx={{
        borderRadius: INPUT_BORDER_RADIUS,
        fontSize: "14px",
        color: errorText ? getDynamicColor("redError") : "inherit",
        outline: errorText && getDynamicColor("redError"),
      }}
      {...rest} // forwarded props will overwrite sx, but not any of the props below.
      error={!!errorText}
      helperText={errorText}
      type={textVisible ? "text" : "password"}
      InputProps={{
        endAdornment: props.type === "password" && (
          <InputAdornment
            tabIndex={0}
            position="end"
            sx={{ backgroundColor: "transparent", cursor: "pointer" }}
            onClick={() => setTextVisible(!textVisible)}
            onKeyDown={(e) => handleEnterOrSpaceKey(e) && setTextVisible(!textVisible)}
          >
            {textVisible ? <PasswordOnIcon /> : <PasswordOffIcon />}
          </InputAdornment>
        ),
        sx: {
          borderRadius: INPUT_BORDER_RADIUS,
          backgroundColor: getDynamicColor("light1"),
          border: `1px solid ${getDynamicColor("dark2")}`,
        },
      }}
    />
  );
}
