import React from "react";
// React Slick Carousel
import Slider from "react-slick";

// Components
import { Box, Stack, Typography } from "@mui/material";
import { OrgProgramsQueryKeys, ScenarioSubQueryKeys } from "components/Orgs/Programs/OrgPrograms";

// Utils
import { UseQueryResult, useQuery as useApiQuery } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { listScenariosMemberView } from "lib-frontend/modules/AxiosInstance";
import { ProgramMemberViewItem } from "lib-fullstack/api/programApiTypes";
import { ProgramRecordState } from "lib-fullstack/utils/enums";
import { MemberProgramCarouselItem } from "./MemberProgramCarouselItem";
import { ProgramCarouselControls } from "./ProgramCarouselControls";
import { HOMEPAGE_SLIDER_STYLES } from "./Home";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { ErrorOutline } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";

type ProgramsCarouselProps = {
  programMemberViewQuery: UseQueryResult<ProgramMemberViewItem[]>;
};

export const ProgramsCarousel = ({
  programMemberViewQuery,
}: ProgramsCarouselProps): JSX.Element => {
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);
  const { defaultOrgId } = React.useContext(UserOrgContext);

  const scenariosQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.ScenarioList, defaultOrgId, ScenarioSubQueryKeys.Member],
    queryFn: () => listScenariosMemberView(defaultOrgId),
    enabled: !!defaultOrgId,
  });

  const activePrograms = React.useMemo(() => {
    return (
      programMemberViewQuery?.data?.sort((a, b) => {
        // sorting such that completed programs are at the end but the order is otherwise unchanged.
        if (a.state === ProgramRecordState.Completed && b.state !== ProgramRecordState.Completed) {
          return 1;
        }
        if (a.state !== ProgramRecordState.Completed && b.state === ProgramRecordState.Completed) {
          return -1;
        }
        return 0;
      }) ?? []
    );
  }, [programMemberViewQuery?.data]);

  const sliderRef = React.useRef<Slider>(null);
  const settings = {
    dots: false,
    slidesToShow: 1,
    initialSlide: 0,
    infinite: activePrograms.length > 1,
    beforeChange: (current, next) => setCurrentStepIndex(next),
  };

  if (
    programMemberViewQuery.isLoading ||
    scenariosQuery.isLoading ||
    programMemberViewQuery?.data?.length === 0 ||
    !defaultOrgId
  ) {
    return;
  }

  if (programMemberViewQuery.error) {
    return (
      <Stack
        direction="column"
        sx={{
          backgroundColor: getDynamicColor("light2"),
          borderRadius: "8px",
          p: 2,
          color: getDynamicColor("dark4"),
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100px",
        }}
      >
        <Stack direction="row" sx={{ gap: 1, alignItems: "center", justifyContent: "center" }}>
          <ErrorOutline />
          <Typography>There was a problem loading your programs</Typography>
        </Stack>
        <Stack>
          <Typography sx={{ fontSize: "12px" }}>
            Please contact <Link to={WebServerExternalPath.SUPPORT}>support</Link> or your
            organization admin if this persists.
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      gap={2}
      sx={{
        pb: activePrograms?.length > 1 ? 4.5 : 3,
      }}
    >
      <Box sx={HOMEPAGE_SLIDER_STYLES}>
        <Slider ref={sliderRef} {...settings}>
          {activePrograms.map((program) => (
            <MemberProgramCarouselItem
              key={program.id}
              program={program}
              scenarios={scenariosQuery.data?.contentArray ?? []}
            />
          ))}
        </Slider>
        <ProgramCarouselControls
          totalSteps={activePrograms.length}
          currentStepIndex={currentStepIndex}
          sliderRef={sliderRef}
        />
      </Box>
    </Stack>
  );
};
