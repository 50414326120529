// Components
import {
  HomeOutlined as HomeIcon,
  Addchart as DashboardIcon,
  SettingsOutlined as SettingsIcon,
  VideoLibraryOutlined as VideoLibraryOutlinedIcon,
} from "@mui/icons-material";
import { SxProps } from "@mui/material";
import { SHARED } from "components/VideoJournal/VideoJournalUtils";

// Assets
import { ReactComponent as BuilderIcon } from "images/icons/icon-builder.svg";
import { ReactComponent as ContentIcon } from "images/icons/icon-content.svg";
import { ReactComponent as HubsIcon } from "images/icons/icon-hubs.svg";
import { ReactComponent as MembersIcon } from "images/icons/icon-members.svg";
import { ReactComponent as OverviewIcon } from "images/icons/icon-overview.svg";
import { ReactComponent as ProgramsIcon } from "images/icons/icon-programs.svg";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

// Utils
import { WebServerInternalPath } from "./paths";
import { OrgSettingsQueryParams, VideoLibraryQueryParams } from "lib-fullstack/utils/queryParams";
import { EffectiveRole, UITestId } from "lib-fullstack/utils/enums";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { OrgSettingsTabs } from "lib-frontend/utils/orgUtils";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";

enum NavItemLabel {
  HOME = "Home",
  OVERVIEW = "Overview",
  MEMBERS = "Members",
  DASHBOARD = "Dashboard",
  HUBS = "Groups",
  SHARED_WITH_ME = "Shared with me",
  CONTENT = "Content",
  MY_YOODLIS = "My Yoodlis",
  MY_RECORDINGS = "My Recordings",
  MY_LEARNING = "My Learning",
  BUILDER = "Builder",
  ORG_SETTINGS = "Org settings",
  EXERCISES = "Exercises",
  PROGRAMS = "Programs",
  PEOPLE = "People",
  ROLEPLAYS = "Roleplays", // Top level nav drawer string for CUSTOMIZE_PRACTICE tab
  LEARNING = "Learning",
  COACH_BOT = "Coach Bot",
  FILES = "Files",
  PERMISSIONS = "Permissions",
  HELP = "Help",
  REPORTING_TEAMS = "Reporting Teams",
}

export type NavigationItem = {
  text: NavItemLabel;
  icon?: React.ReactElement;
  path: string;
  search?: string;
  dataTestId: string;
  pageTitle?: string;
  tooltip?: string;
  iconOverrideSx?: SxProps;
};

export type NavigationItemGroup = {
  text: NavItemLabel;
  icon: React.ReactElement;
  dataTestId: string;
  items: NavigationItem[];
};

export type NavigationEntity = NavigationItem | NavigationItemGroup;

interface INavigationItem {
  [key: string]: NavigationEntity;
}

export type NavRole =
  | EffectiveRole.ORG_ADMIN
  | EffectiveRole.SPACE_ADMIN
  | EffectiveRole.HUB_ADMIN
  | EffectiveRole.HUB_MEMBER
  | "none";

export const hideTmiPaths = [WebServerInternalPath.BUILDER];

const peopleGroup = {
  text: NavItemLabel.PEOPLE,
  icon: <MembersIcon />,
  dataTestId: UITestId.NavItemPeopleGroup,
  items: [
    {
      text: NavItemLabel.MEMBERS,
      path: WebServerInternalPath.ORG_MEMBERS,
      dataTestId: UITestId.NavItemOrgMembers,
    },
    {
      text: NavItemLabel.HUBS,
      path: WebServerInternalPath.ORG_GROUPS,
      dataTestId: UITestId.NavItemOrgGroups,
    },
    {
      text: NavItemLabel.REPORTING_TEAMS,
      path: WebServerInternalPath.ORG_REPORTING_TEAMS,
      dataTestId: UITestId.NavItemReportingTeams,
    },
  ],
};

const getContentGroup = (coachbotEnabled?: boolean): NavigationEntity => {
  return {
    text: NavItemLabel.CONTENT,
    icon: <ContentIcon />,
    dataTestId: UITestId.NavItemOrgContent,
    items: [
      {
        text: NavItemLabel.ROLEPLAYS,
        path: `${WebServerInternalPath.ORG_CONTENT}`,
        search: `${OrgSettingsQueryParams.TAB}=${OrgSettingsTabs.CUSTOMIZE_PRACTICE}`,
        dataTestId: UITestId.NavItemOrgCustomizePractice,
      },
      {
        text: NavItemLabel.PROGRAMS,
        path: `${WebServerInternalPath.ORG_CONTENT}`,
        search: `${OrgSettingsQueryParams.TAB}=${OrgSettingsTabs.PROGRAMS}`,
        dataTestId: UITestId.NavItemOrgPrograms,
      },
      coachbotEnabled && {
        text: NavItemLabel.COACH_BOT,
        path: `${WebServerInternalPath.ORG_CONTENT}`,
        search: `${OrgSettingsQueryParams.TAB}=${OrgSettingsTabs.COACHBOT}`,
        dataTestId: UITestId.NavItemOrgCoachBot,
      },
      {
        text: NavItemLabel.FILES,
        path: `${WebServerInternalPath.ORG_CONTENT}`,
        search: `${OrgSettingsQueryParams.TAB}=${OrgSettingsTabs.FILES}`,
        dataTestId: UITestId.NavItemOrgFiles,
      },
      {
        text: NavItemLabel.LEARNING,
        path: `${WebServerInternalPath.ORG_CONTENT}`,
        search: `${OrgSettingsQueryParams.TAB}=${OrgSettingsTabs.LEARNING_MATERIALS}`,
        dataTestId: UITestId.NavItemOrgLearningMaterials,
      },
      {
        text: NavItemLabel.PERMISSIONS,
        path: `${WebServerInternalPath.ORG_CONTENT}`,
        search: `${OrgSettingsQueryParams.TAB}=${OrgSettingsTabs.PERMISSIONS}`,
        dataTestId: UITestId.NavItemOrgPermissions,
      },
    ].filter(Boolean),
  };
};

export const getNavItems = (navRole: NavRole, coachbotEnabled?: boolean): NavigationEntity[] => {
  const navItems: Record<NavRole, NavigationEntity[]> = {
    [EffectiveRole.ORG_ADMIN]: [
      {
        text: NavItemLabel.OVERVIEW,
        icon: <OverviewIcon />,
        path: WebServerInternalPath.ORG_OVERVIEW,
        dataTestId: UITestId.NavItemOrgOverview,
      },
      getContentGroup(coachbotEnabled),
      peopleGroup,
      {
        text: NavItemLabel.SHARED_WITH_ME,
        icon: <VideoLibraryOutlinedIcon />,
        path: WebServerInternalPath.LIBRARY,
        search: `${VideoLibraryQueryParams.TAB}=${SHARED}&${VideoLibraryQueryParams.PAGE}=1`,
        dataTestId: UITestId.NavItemSharedWithMe,
      },
      {
        text: NavItemLabel.ORG_SETTINGS,
        icon: <SettingsIcon />,
        path: WebServerInternalPath.ORG_SETTINGS,
        dataTestId: UITestId.NavItemOrgSettings,
      },
    ],
    [EffectiveRole.SPACE_ADMIN]: [
      getContentGroup(coachbotEnabled),
      {
        text: NavItemLabel.HUBS,
        icon: <HubsIcon />,
        path: WebServerInternalPath.ORG_GROUPS,
        dataTestId: UITestId.NavItemOrgGroups,
      },
      {
        text: NavItemLabel.SHARED_WITH_ME,
        icon: <VideoLibraryOutlinedIcon />,
        path: WebServerInternalPath.LIBRARY,
        search: `${VideoLibraryQueryParams.TAB}=${SHARED}`,
        dataTestId: UITestId.NavItemSharedWithMe,
      },
    ],
    [EffectiveRole.HUB_ADMIN]: [
      {
        text: NavItemLabel.HUBS,
        icon: <HubsIcon />,
        path: WebServerInternalPath.ORG_GROUPS,
        dataTestId: UITestId.NavItemOrgGroups,
      },
      {
        text: NavItemLabel.SHARED_WITH_ME,
        icon: <VideoLibraryOutlinedIcon />,
        path: WebServerInternalPath.LIBRARY,
        search: `${VideoLibraryQueryParams.TAB}=${SHARED}`,
        dataTestId: UITestId.NavItemSharedWithMe,
      },
    ],
    [EffectiveRole.HUB_MEMBER]: [
      {
        text: NavItemLabel.HOME,
        icon: <HomeIcon />,
        path: WebServerExternalPath.HOME_LOGGED_IN,
        dataTestId: UITestId.NavItemMemberHome,
      },
      {
        text: NavItemLabel.BUILDER,
        icon: <BuilderIcon />,
        path: WebServerInternalPath.BUILDER,
        dataTestId: UITestId.NavItemMemberBuilder,
        iconOverrideSx: {
          svg: {
            mx: "auto",
            path: {
              fill: "transparent",
            },
          },
        },
      },
      {
        text: NavItemLabel.DASHBOARD,
        icon: <DashboardIcon />,
        path: WebServerExternalPath.DASHBOARD,
        dataTestId: UITestId.NavItemMemberDashboard,
      },
      {
        text: NavItemLabel.MY_RECORDINGS,
        icon: <VideoLibraryOutlinedIcon />,
        path: WebServerInternalPath.LIBRARY,
        dataTestId: UITestId.NavItemMemberLibrary,
      },
      {
        text: NavItemLabel.MY_LEARNING,
        icon: <ProgramsIcon />,
        path: WebServerExternalPath.MY_LEARNING,
        dataTestId: UITestId.NavItemMemberLearning,
      },
    ],
    ["none"]: [
      {
        text: NavItemLabel.HOME,
        icon: <HomeIcon />,
        path: WebServerExternalPath.HOME_LOGGED_IN,
        dataTestId: UITestId.NavItemHome,
      },
      {
        text: NavItemLabel.BUILDER,
        icon: <BuilderIcon />,
        path: WebServerInternalPath.BUILDER,
        dataTestId: UITestId.NavItemBuilder,
        iconOverrideSx: {
          svg: {
            mx: "auto",
            path: {
              fill: "transparent",
            },
          },
        },
      },
      {
        text: NavItemLabel.DASHBOARD,
        icon: <DashboardIcon />,
        path: WebServerExternalPath.DASHBOARD,
        dataTestId: UITestId.NavItemDashboard,
      },
      {
        text: NavItemLabel.MY_YOODLIS,
        icon: <VideoLibraryOutlinedIcon />,
        path: WebServerInternalPath.LIBRARY,
        dataTestId: UITestId.NavItemLibrary,
      },
      {
        text: NavItemLabel.MY_LEARNING,
        icon: <ProgramsIcon />,
        path: WebServerExternalPath.MY_LEARNING,
        dataTestId: UITestId.NavItemLearning,
      },
    ],
  };

  return navItems[navRole] ?? [];
};

export const getNavigationItems = (effectiveRole?: EffectiveRole): INavigationItem => {
  let role = effectiveRole;
  if (!effectiveRole) {
    role = EffectiveRole.HUB_MEMBER;
  }
  const siteConf = getStaticFullSiteConf();
  const dashboardItem = {
    text: NavItemLabel.DASHBOARD,
    tooltip: NavItemLabel.DASHBOARD,
    icon: <DashboardIcon />,
    path: WebServerExternalPath.DASHBOARD,
    dataTestId: UITestId.NavItemDashboard,
  };
  const homeItem = {
    text: NavItemLabel.HOME,
    tooltip: NavItemLabel.HOME,
    icon: siteConf?.imageDiffs?.navBarHome ? (
      <img src={siteConf.imageDiffs.navBarHome} alt="Home page" />
    ) : (
      <HomeIcon />
    ),
    path: WebServerExternalPath.HOME_LOGGED_IN,
    dataTestId: UITestId.NavItemHome,
  };
  const libraryItem = {
    text: role === EffectiveRole.HUB_MEMBER ? NavItemLabel.MY_YOODLIS : NavItemLabel.SHARED_WITH_ME,
    tooltip: "Speech Library",
    icon: siteConf?.imageDiffs?.navBarLibrary ? (
      <img src={siteConf.imageDiffs.navBarLibrary} alt="Library page" />
    ) : (
      <VideoLibraryOutlinedIcon />
    ),
    path: WebServerInternalPath.LIBRARY,
    dataTestId:
      role === EffectiveRole.HUB_MEMBER ? UITestId.NavItemMemberLibrary : UITestId.NavItemLibrary,
  };
  const settingsItem = {
    text: NavItemLabel.ORG_SETTINGS,
    tooltip: NavItemLabel.ORG_SETTINGS,
    icon: <SettingsIcon />,
    path: WebServerExternalPath.ACCOUNT,
    dataTestId: UITestId.NavItemSettings,
  };
  const supportItem = {
    text: NavItemLabel.HELP,
    tooltip: "Get Support",
    icon: <HelpOutlineOutlinedIcon />,
    path: WebServerExternalPath.SUPPORT,
    dataTestId: UITestId.NavItemSupport,
  };
  return {
    DASHBOARD: dashboardItem,
    HOME: homeItem,
    LIBRARY: libraryItem,
    SETTINGS: settingsItem,
    SUPPORT: supportItem,
  };
};

export const isValidUrl = (url: string): boolean => {
  const urlRegex =
    /^(https?:\/\/)?((([a-zA-Z0-9$_.+!*'(),;?&=-]|%[0-9a-fA-F]{2})+:)*([a-zA-Z0-9$_.+!*'(),;?&=-]|%[0-9a-fA-F]{2})+@)?(((\d{1,3}\.){3}\d{1,3})|((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})))(:\d+)?(\/([a-zA-Z0-9$_.+!*'(),;:@&=-]|%[0-9a-fA-F]{2})*)*(\?([a-zA-Z0-9$_.+!*'(),;:@&=-]|%[0-9a-fA-F]{2})*)?(#([a-zA-Z0-9$_.+!*'(),;:@&=-]|%[0-9a-fA-F]{2})*)?$/;
  return urlRegex.test(url);
};
