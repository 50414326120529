import { parseFullName } from "parse-full-name";

export const filterToStrings = (arr: Array<string | number>): string[] => {
  return arr.filter((x) => typeof x === "string") as string[];
};

export const filterToIntegers = (arr: Array<string | number>): number[] => {
  return arr.map((x) => parseInt(x as string, 10)).filter((x) => !isNaN(x)) as number[];
};

export const pluralize = (word: string, count: number): string => {
  return count === 1 ? word : `${word}s`;
};

export const moveElementToStart = (
  arr: string[],
  element: string,
  appendToStart: boolean,
): string[] => {
  const index = arr.findIndex((x) => x === element);
  if (index > -1) {
    arr = [arr[index], ...arr.filter((x) => x !== element)];
  } else if (appendToStart) {
    arr = [element, ...arr];
  }
  return arr;
};

export function getFirstNameFromDisplayName(displayName: string): string {
  if (!displayName?.length) {
    return undefined;
  }

  const name = parseFullName(displayName);
  // If user passes in one name to displayName, the name parser above
  // will assign it to name.last and leave name.first as empty string.
  // If first name is empty, swap first and last names
  return name.first.length ? name.first : name.last;
}

export function getLastNameFromDisplayName(displayName: string): string {
  if (!displayName?.length) {
    return undefined;
  }

  const name = parseFullName(displayName);
  // If user passes in one name to displayName, the name parser above
  // will assign it to name.last and leave name.first as empty string.
  // If first name is empty, swap first and last names
  return name.last.length ? name.last : "";
}

export function sanitizeXML(xml: string): string {
  return xml
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&apos;");
}
