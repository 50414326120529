import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Components
import {
  Circle as CircleIcon,
  DragIndicator as DragIndicatorIcon,
  EditOutlined as EditIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import {
  DEFAULT_INTERVIEW_BANK,
  MAX_DND_INPUT_LENGTH,
  MAX_NUM_QUESTION_BANKS,
} from "components/ConvoScenarios/convoScenarioUtils";
import {
  StackedMenu,
  StackedMenuItem,
} from "components/Orgs/ManageContent/CustomGoals/StackedMenu";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { WizardType } from "./CreateConvoScenarioWizard";
import { CreateQuestionBankForm } from "./CreateQuestionBankForm";
import { OneOffQuestionList } from "./OneOffQuestionList";
import { QuestionBankList } from "./QuestionBankList";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { DraggableStyles } from "lib-frontend/utils/orgUtils";
import { InterviewBankResponse, UpdateInterviewBankRequest } from "lib-fullstack/api/hubApiTypes";
import { reorder } from "utils/Utilities";

type InterviewQuestionsSelectorProps = {
  showCreateQuestionBank: boolean;
  setShowCreateQuestionBank: (value: boolean) => void;
  banks: InterviewBankResponse[];
  selectedBankIds: string[];
  handleUpdateQuestionBankIds: React.Dispatch<React.SetStateAction<string[]>>;
  currStepIndex: number;
  setQuestionBankToCreate: React.Dispatch<React.SetStateAction<UpdateInterviewBankRequest>>;
  aiConcerns: string[];
  handleUpdateAIConcerns: React.Dispatch<React.SetStateAction<string[]>>;
  extraQuestionSuggestions: string[];
  wizardType: WizardType;
};

export const InterviewQuestionsSelector = ({
  showCreateQuestionBank,
  setShowCreateQuestionBank,
  banks,
  selectedBankIds,
  handleUpdateQuestionBankIds,
  currStepIndex,
  setQuestionBankToCreate,
  aiConcerns,
  handleUpdateAIConcerns,
  extraQuestionSuggestions,
  wizardType,
}: InterviewQuestionsSelectorProps): JSX.Element => {
  const [newQuestionBank, setNewQuestionBank] =
    React.useState<InterviewBankResponse>(DEFAULT_INTERVIEW_BANK);
  const menuRef = React.useRef(null);

  const [bankItems, setBankItems] = React.useState<InterviewBankResponse[]>([]);
  const [editingQuestionIdx, setEditingQuestionIdx] = React.useState<number>(undefined);
  const [editingQuestionText, setEditingQuestionText] = React.useState<string>(undefined);

  React.useEffect(() => {
    setNewQuestionBank(DEFAULT_INTERVIEW_BANK);
  }, [currStepIndex]);

  React.useEffect(() => {
    const newBankItems = [];
    selectedBankIds.forEach((bankId) => {
      const bank = banks.find((b) => b.id === bankId);
      if (bank) {
        newBankItems.push(bank);
      }
    });
    setBankItems(newBankItems);
    // filtering out bank ids that don't exist for the user for whatever reason
    if (newBankItems.length !== selectedBankIds.length) {
      handleUpdateQuestionBankIds(newBankItems.map((bank) => bank.id));
    }
  }, [selectedBankIds, banks]);

  React.useEffect(() => {
    if (newQuestionBank.name && newQuestionBank.interview_questions.length > 0) {
      setQuestionBankToCreate(newQuestionBank);
    }
  }, [newQuestionBank]);

  React.useEffect(() => {
    if (!showCreateQuestionBank) {
      setNewQuestionBank(DEFAULT_INTERVIEW_BANK);
    }
  }, [showCreateQuestionBank]);

  const handleCreateQuestionBankClicked = () => {
    setShowCreateQuestionBank(true);
  };

  const handleEditQuestionBank = (bank: InterviewBankResponse) => {
    setNewQuestionBank(bank);
    setShowCreateQuestionBank(true);
  };

  const handleEditSaveQuestion = (idx: number) => {
    setEditingQuestionIdx(undefined);
    if (aiConcerns.includes(editingQuestionText)) {
      return;
    }
    const newAiConcerns = aiConcerns.map((item, index) =>
      index === idx ? editingQuestionText : item,
    );
    handleUpdateAIConcerns(newAiConcerns);
  };

  const handleUpdateNewQuestionBank = (
    key: keyof InterviewBankResponse,
    value: string | string[],
  ) => {
    setNewQuestionBank((prev) => ({ ...prev, [key]: value }));
  };

  const handleQuestionBankSelectionChange = (bankId: string, action: "add" | "remove") => {
    if (action === "remove") {
      handleUpdateQuestionBankIds((bankIds) => bankIds.filter((b) => b !== bankId));
    } else if (!selectedBankIds.includes(bankId)) {
      handleUpdateQuestionBankIds((bankIds) => [...bankIds, bankId]);
    }
  };

  const handleDrag = (result) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }
    const reorderedQuestions = reorder(aiConcerns, result.source.index, result.destination.index);
    handleUpdateAIConcerns(reorderedQuestions);
  };

  const handleOneOffQuestionSelectionChange = (question: string, action: "add" | "remove") => {
    if (action === "remove") {
      handleUpdateAIConcerns((questions) => questions.filter((q) => q !== question));
    } else if (!aiConcerns.includes(question)) {
      handleUpdateAIConcerns((questions) => [...questions, question]);
    }
  };

  const openMenu = (ind: number) => {
    // This will open the menu from the parent
    if (menuRef.current) {
      menuRef.current.openMenu(ind);
    }
  };

  const nestedMenuItems = React.useMemo(() => {
    return [
      wizardType === WizardType.Org &&
        banks.length > 0 && {
          title: "Add an existing question bank",
          subtitle: "Choose from your library",
          disabled: selectedBankIds.length >= MAX_NUM_QUESTION_BANKS,
          disabledTooltip: `You may only have a maximum of ${MAX_NUM_QUESTION_BANKS} question banks`,
          subMenuContent: (
            <QuestionBankList
              allBanks={banks}
              selectedBankIds={selectedBankIds}
              handleBankSelectionChange={handleQuestionBankSelectionChange}
              showSearchBar
              handleEditBank={handleEditQuestionBank}
            />
          ),
        },
      wizardType === WizardType.Org && {
        title: "Create new question bank",
        subtitle: "Create now and add to your library",
        disabled: selectedBankIds.length >= MAX_NUM_QUESTION_BANKS,
        disabledTooltip: `You may only have a maximum of ${MAX_NUM_QUESTION_BANKS} question banks`,
        onClick: handleCreateQuestionBankClicked,
      },
      {
        title: "Add one-off questions",
        subtitle: "Add one-off questions",
        subMenuContent: (
          <OneOffQuestionList
            extraQuestionSuggestions={extraQuestionSuggestions}
            questions={aiConcerns}
            handleUpdateQuestions={handleUpdateAIConcerns}
            handleQuestionSelectionChange={handleOneOffQuestionSelectionChange}
          />
        ),
      },
    ].filter(Boolean) as StackedMenuItem[];
  }, [banks, selectedBankIds, aiConcerns, wizardType]);

  return (
    <Stack justifyContent="flex-start" gap={3}>
      {showCreateQuestionBank ? (
        <CreateQuestionBankForm
          selectedBank={newQuestionBank}
          handleUpdateInterviewBank={handleUpdateNewQuestionBank}
          hideGroupSelector
        />
      ) : (
        <Stack sx={{ gap: 2 }}>
          <StackedMenu
            menuItems={nestedMenuItems}
            buttonContent={
              <Stack direction="row" sx={{ gap: 1.5, alignItems: "center" }}>
                <Typography>Add questions</Typography>
                <Typography sx={{ fontSize: 22, fontWeight: 500 }}>+</Typography>
              </Stack>
            }
            buttonSx={{
              alignItems: "center",
              justifyContent: "center",
              px: { xs: 3, md: 6 },
            }}
            ref={menuRef}
          />
          <Stack
            sx={{
              gap: 2,
              mt: 1,
              px: 1,
              fontFamily: "poppins",
              color: getDynamicColor("purple3"),
            }}
          >
            {bankItems.map((bank) => (
              <Stack
                key={bank.id}
                sx={{
                  borderRadius: "4px",
                  border: `1px solid ${getDynamicColor("dark3")}`,
                  px: 4,
                  py: 2,
                }}
              >
                <Stack
                  direction="row"
                  sx={{ alignItems: "center", justifyContent: "space-between", gap: 2 }}
                >
                  <Typography sx={{ fontSize: 14, fontWeight: 700 }}>{bank.name}</Typography>
                  <Stack direction="row" sx={{ gap: 1 }}>
                    <Button
                      sx={{ fontSize: 14 }}
                      onClick={() => {
                        handleEditQuestionBank(bank);
                      }}
                    >
                      Edit question bank
                    </Button>
                    <IconButton
                      onClick={() => {
                        handleQuestionBankSelectionChange(bank.id, "remove");
                      }}
                    >
                      <CloseIcon sx={{ color: getDynamicColor("primary") }} />
                    </IconButton>
                  </Stack>
                </Stack>
                <List>
                  {bank.interview_questions.map((question, index) => (
                    <ListItem key={`${question}-${index}`} sx={{ p: 0 }}>
                      <ListItemIcon sx={{ minWidth: "24px" }}>
                        <CircleIcon
                          sx={{ width: 8, height: 8, color: getDynamicColor("purple3") }}
                        />
                      </ListItemIcon>
                      <ListItemText sx={{ fontSize: 12 }}>{question}</ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Stack>
            ))}
            {aiConcerns.length > 0 && (
              <Stack
                sx={{
                  gap: 2,
                  borderRadius: "4px",
                  border: `1px solid ${getDynamicColor("dark3")}`,
                  px: 4,
                  py: 2,
                }}
              >
                <Stack
                  direction="row"
                  sx={{ alignItems: "center", justifyContent: "space-between", gap: 2 }}
                >
                  <Typography sx={{ fontSize: 14, fontWeight: 700 }}>One-off questions</Typography>
                  <Stack direction="row" sx={{ gap: 1 }}>
                    <Button
                      sx={{ fontSize: 14 }}
                      onClick={() => {
                        openMenu(
                          nestedMenuItems.findIndex(
                            (item) => item.title === "Add one-off questions",
                          ),
                        );
                      }}
                    >
                      Add
                    </Button>
                    <IconButton
                      onClick={() => {
                        handleUpdateAIConcerns([]);
                      }}
                    >
                      <CloseIcon sx={{ color: getDynamicColor("primary") }} />
                    </IconButton>
                  </Stack>
                </Stack>
                <DragDropContext onDragEnd={handleDrag}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <Stack
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        direction="column"
                      >
                        {aiConcerns.map((question, index) => {
                          if (question) {
                            return (
                              <Draggable
                                key={index}
                                draggableId={index.toString()} // According to docs, this needs to be a string
                                index={index}
                              >
                                {(provided) => (
                                  <Box
                                    key={`${question}-${index}`}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                      ...DraggableStyles,
                                      backgroundColor: getDynamicColor("dark1"),
                                      border: "none",
                                    }}
                                  >
                                    {provided.placeholder}
                                    <DragIndicatorIcon
                                      style={{
                                        cursor: "grab",
                                        height: "20px",
                                        width: "20px",
                                      }}
                                    />
                                    <Stack direction="column">
                                      <Stack
                                        direction="row"
                                        sx={{
                                          width: "100%",
                                          gap: 1,
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {editingQuestionIdx === index ? (
                                          <YoodliTextfield
                                            value={editingQuestionText}
                                            multiline
                                            inputProps={{
                                              className: "blockEnterToNavigate",
                                            }}
                                            fadeInCharCountPct={75}
                                            minRows={1}
                                            maxRows={3}
                                            maxChars={MAX_DND_INPUT_LENGTH}
                                            onChange={(e) => setEditingQuestionText(e.target.value)}
                                          />
                                        ) : (
                                          <Typography
                                            sx={{
                                              color: getDynamicColor("purple3"),
                                              fontSize: "14px",
                                            }}
                                          >
                                            {question}
                                          </Typography>
                                        )}
                                        <Stack
                                          direction="row"
                                          sx={{
                                            alignItems: "center",
                                          }}
                                        >
                                          {editingQuestionIdx === index ? (
                                            <>
                                              <Button
                                                size="small"
                                                sx={{ fontSize: 14 }}
                                                onClick={() => handleEditSaveQuestion(index)}
                                                variant="text"
                                              >
                                                Save
                                              </Button>
                                              <Button
                                                size="small"
                                                sx={{ fontSize: 14 }}
                                                onClick={() => {
                                                  setEditingQuestionIdx(undefined);
                                                  setEditingQuestionText(undefined);
                                                }}
                                                variant="text"
                                              >
                                                Cancel
                                              </Button>
                                            </>
                                          ) : (
                                            <>
                                              <IconButton
                                                onClick={() => {
                                                  setEditingQuestionText(question);
                                                  setEditingQuestionIdx(index);
                                                }}
                                                aria-label="edit practice questions"
                                              >
                                                <EditIcon sx={{ width: 20, height: 20 }} />
                                              </IconButton>
                                              <IconButton
                                                onClick={() => {
                                                  handleOneOffQuestionSelectionChange(
                                                    question,
                                                    "remove",
                                                  );
                                                }}
                                              >
                                                <CloseIcon sx={{ width: 20, height: 20 }} />
                                              </IconButton>
                                            </>
                                          )}
                                        </Stack>
                                      </Stack>
                                    </Stack>
                                  </Box>
                                )}
                              </Draggable>
                            );
                          }
                        })}
                        {provided.placeholder}
                      </Stack>
                    )}
                  </Droppable>
                </DragDropContext>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
