import { getDynamicColor } from "lib-frontend/utils/Colors";

import { Avatar, Box } from "@mui/material";

import { UIPersonaGroup } from "../PracticeRecorderTypes";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

const sizingTheme = {
  small: {
    [1]: {
      avatarSize: 36,
    },
    [2]: {
      avatarSize: 26,
      containerWidth: 40,
      containerHeight: 26,
    },
    [3]: {
      avatarSize: 24,
      containerWidth: 42,
      containerHeight: 44,
    },
  },
  medium: {
    [1]: {
      avatarSize: 48,
    },
    [2]: {
      avatarSize: 42,
      containerWidth: 66,
      containerHeight: 44,
    },
    [3]: {
      avatarSize: 40,
      containerWidth: 70,
      containerHeight: 74,
    },
  },
};

export const PersonaGroupAvatar = ({
  personaGroup,
  size = "small",
}: {
  personaGroup: UIPersonaGroup;
  size: "small" | "medium";
}): JSX.Element => {
  const { personas } = personaGroup;

  if (!personas || personas.length === 0) {
    return null;
  }

  const sizing = sizingTheme[size];

  // Single persona case
  if (personas.length === 1) {
    return (
      <Avatar
        alt={personas[0]?.name}
        src={personas[0]?.profile_picture_signed_url}
        key={personas[0]?.profile_picture_signed_url}
        sx={{
          height: sizing[personas.length].avatarSize,
          width: sizing[personas.length].avatarSize,
          backgroundColor: !personas[0]?.profile_picture_signed_url && getDynamicColor("purple2"),
        }}
      />
    );
  }

  // Handle multiple personas (2 or more)
  const isVennDiagram = personas.length === 2;
  const isPyramid = personas.length >= 3;

  // Size adjustments based on number of personas
  const avatarSize = sizing[Math.max(personas.length, 3)].avatarSize;
  const containerWidth = sizing[Math.max(personas.length, 3)].containerWidth;
  const containerHeight = sizing[Math.max(personas.length, 3)].containerHeight;

  return (
    <Box
      sx={{
        position: "relative",
        width: containerWidth,
        height: containerHeight,
        minWidth: containerWidth,
        minHeight: containerHeight,
        mr: 0.5,
        top: isVennDiagram ? 0 : 2,
      }}
    >
      {personas.map((persona, index) => {
        let positionStyle = {};

        if (isVennDiagram) {
          // Venn diagram style (2 personas)
          positionStyle =
            index === 0
              ? { left: -3, zIndex: 2 }
              : { top: 0, right: -4, zIndex: 1, position: "absolute" };
        } else if (isPyramid) {
          // Pyramid style (3 or more personas, show max 3)
          if (index === 0) {
            // Top avatar
            positionStyle = {
              top: 0,
              left: "calc(50% + 1px)",
              transform: "translateX(-50%)",
              zIndex: 3,
            };
          } else if (index === 1) {
            // Bottom left
            positionStyle = {
              position: "absolute",
              bottom: 4,
              left: -2,
              zIndex: 1,
            };
          } else if (index === 2) {
            // Bottom right
            positionStyle = {
              position: "absolute",
              bottom: 4,
              right: -4,
              zIndex: 2,
            };
          } else {
            // Don't render more than 3 avatars
            return null;
          }
        }

        return (
          <YoodliTooltip title={persona?.name} key={persona?.profile_picture_signed_url || index}>
            <Avatar
              alt={persona?.name}
              src={persona?.profile_picture_signed_url}
              sx={{
                position: "relative",
                height: avatarSize,
                width: avatarSize,
                backgroundColor: !persona?.profile_picture_signed_url
                  ? getDynamicColor("purple2")
                  : getDynamicColor("light1"),
                ...positionStyle,
              }}
            />
          </YoodliTooltip>
        );
      })}
    </Box>
  );
};
