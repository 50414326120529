import { ProgramMemberViewItem, ProgramUserItem } from "lib-fullstack/api/programApiTypes";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { scenarioTypeMap } from "lib-fullstack/utils/orgUtils";
import { getScenarioPracticePath, MyLearningSection } from "./orgUtils";
import { NavigateFunction } from "react-router";
import { Instrumentation } from "./ProductAnalyticsUtils";
import { ProgramMemberNextStepCtaWheres } from "lib-fullstack/utils/productAnalyticEvents";
import { PlanStepState } from "lib-fullstack/utils/enums";
import { GetScenarioMemberViewResponse } from "lib-fullstack/api/scenarioApiTypes";
import { formatTimeOfDay, getHumanReadableDate } from "lib-fullstack/utils/dateUtils";

export const navigateToProgramPractice = (
  orgId: string,
  program: ProgramMemberViewItem,
  scenarios: GetScenarioMemberViewResponse[],
  navigate: NavigateFunction,
): void => {
  Instrumentation.logProgramMemberNextStepCtaClicked(
    orgId,
    program.id,
    ProgramMemberNextStepCtaWheres.HOME,
  );
  const programStepToPractice = program.plan_step_results.find(
    (step) => step.state !== PlanStepState.Completed,
  );
  const scenario = scenarios.find((s) => s.id === programStepToPractice.scenario_id);
  navigate(
    getScenarioPracticePath(scenario.id, orgId, false, scenarioTypeMap[scenario.scenarioTypeId]),
  );
};

export const navigateToProgramDetailsMemberView = (
  program: ProgramMemberViewItem,
  navigate: NavigateFunction,
): void => {
  navigate(
    `${WebServerExternalPath.MY_LEARNING}?section=${MyLearningSection.ProgramInfo}&programId=${program.id}`,
  );
};

export const parseProgramProgressDate = (
  item: ProgramUserItem | ProgramMemberViewItem,
  includeTimeOfDay = false,
): string => {
  if (item.completion_date) {
    return `Completed on ${getHumanReadableDate(item.completion_date)}${
      includeTimeOfDay ? ` at ${formatTimeOfDay(new Date(item.completion_date))}` : ""
    }`;
  } else if (item.last_progress_date) {
    return `Last practice ${getHumanReadableDate(item.last_progress_date)} ${
      includeTimeOfDay ? `at ${formatTimeOfDay(new Date(item.last_progress_date))}` : ""
    }`;
  } else {
    return "No progress";
  }
};
