import React from "react";
import { useDropzone } from "react-dropzone";

// Components
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Box, Button, CircularProgress, Link, Stack, Typography } from "@mui/material";

// Utils
import { FileTable } from "./FileTable";
import { useContentUpload } from "hooks/useContentUpload";
import { useOrgFilesQuery } from "hooks/useOrgFilesQuery";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { OrgFileState } from "lib-fullstack/utils/enums";
import { getAcceptedFileTypes } from "utils/orgContentUtils";
import { SupportPages } from "lib-frontend/utils/paths";

export type UploadingFile = {
  id: string;
  progress: number;
  state: OrgFileState;
};

export const OrgFileLibrary = (): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const { uploadingFiles, handleUploadContent, handleCancelFileUpload } = useContentUpload({});

  const fileInputRef = React.useRef(null);

  const onDropCallback = React.useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      handleUploadContent(acceptedFiles);
    }
  }, []);

  const fileTypes = getAcceptedFileTypes();
  const acceptString = fileTypes.map((fileType) => fileType.mime).join(",");

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropCallback,
    accept: acceptString,
  });

  const { orgFilesQuery } = useOrgFilesQuery();

  const handleUploadFileClick = () => {
    // Trigger the hidden file input element when the button is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const renderContent = () => {
    if (orgFilesQuery.isLoading) {
      return <CircularProgress sx={{ mx: "auto" }} />;
    } else if (orgFilesQuery.data?.files?.length) {
      return (
        <FileTable
          files={orgFilesQuery.data?.files}
          uploadingFiles={uploadingFiles}
          handleCancelFileUpload={handleCancelFileUpload}
        />
      );
    } else {
      return (
        <Stack
          gap="5px"
          sx={{
            alignItems: "center",
            justifyContent: "center",
            height: "220px",
            border: `1px dashed ${getDynamicColor(isDragActive ? "primary" : "dark3")}`,
            borderRadius: "8px",
          }}
        >
          <CloudUploadOutlinedIcon
            sx={{
              width: 24,
              height: 24,
              color: getDynamicColor(isDragActive ? "primary" : "dark4"),
            }}
          />
          <Typography
            sx={{
              fontFamily: "poppins",
              fontWeight: 500,
              color: getDynamicColor(isDragActive ? "primary" : "dark4"),
              fontSize: "12px",
            }}
          >
            {isSmallScreen ? "Click" : "Drag your files here or click"} "Add from device" to browse
          </Typography>
        </Stack>
      );
    }
  };

  return (
    <Stack
      gap={{ xs: "50px", md: 5 }}
      sx={{ pt: { xs: 5, md: "50px" }, pl: { xs: 1, md: "60px" }, pr: { xs: 1, md: "50px" } }}
    >
      <Stack
        gap="20px"
        direction={{ xs: "column", md: "row" }}
        sx={{
          alignItems: { xs: "flex-start", md: "center" },
          justifyContent: "space-between",
          px: { xs: "20px", md: 0 },
        }}
      >
        <Stack gap={1}>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontWeight: 700,
              color: getDynamicColor("purple3"),
              fontSize: { xs: 14, md: 16 },
            }}
          >
            Your files
          </Typography>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontWeight: 600,
              color: getDynamicColor("dark5"),
              fontSize: "12px",
              maxWidth: 600,
            }}
          >
            Upload or drag-and-drop documents (max 50 MB) and audio/video files (max 5 GB) for use
            in scenarios, coaching guides, and custom goal creation. Uploaded files will only show
            up in this content space.
          </Typography>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontWeight: 600,
              fontSize: 10,
              color: getDynamicColor("dark5"),
            }}
          >
            Want to learn more about files? Check out this{" "}
            <Link href={SupportPages.CONTENT_SPACES} target="_blank">
              support article.
            </Link>
          </Typography>
        </Stack>
        <Button
          onClick={handleUploadFileClick}
          variant="contained"
          size="xlarge"
          sx={{ whiteSpace: "nowrap" }}
        >
          Add from device
        </Button>
      </Stack>
      <Box {...getRootProps()}>{renderContent()}</Box>
      <input {...getInputProps()} type="file" accept={acceptString} ref={fileInputRef} />
    </Stack>
  );
};
