import React from "react";

// Components
import { Box, Stack, Typography, CircularProgress, Button } from "@mui/material";
import {
  CUSTOM_GOAL_AI_EXPLANATION_MAX_CHARS,
  CUSTOM_GOAL_OVERALL_AI_EXPLANATION_MAX_CHARS,
  CUSTOM_GOAL_MAX_SCORE,
  CUSTOM_GOAL_MIN_SCORE,
  CUSTOM_GOAL_TEXTFIELD_MIN_ROWS,
  GoalHooks,
} from "components/ConvoScenarios/convoScenarioUtils";
import { AddContentModal } from "components/Orgs/ManageContent/ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotUploadContent/AddContentModal";
import { UploadedFiles } from "components/Orgs/ManageContent/ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotUploadContent/UploadedFiles";
import { UploadingFile } from "components/Orgs/ManageContent/OrgFileLibrary/OrgFileLibrary";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { RephraseOrGenerateButton } from "../../RephraseOrGenerateButton";
import { ConvoScenarioStepWrapper } from "./ConvoScenarioStepWrapper";
import { MinMaxScoreSelector } from "./MinMaxScoreSelector";
import { useOrgFilesQuery } from "hooks/useOrgFilesQuery";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { CreateScoreGoalRequest } from "lib-fullstack/api/scenarioApiTypes";
import { ScoreGoal } from "lib-fullstack/db";
import { getGoalRephrase } from "utils/GPTUtils";

export type ScoreGoalEvaluationStepProps = {
  scoreGoal: CreateScoreGoalRequest;
  setScoreGoal: React.Dispatch<React.SetStateAction<CreateScoreGoalRequest>>;
  goalHooks: GoalHooks;
  title: string;
  subTitle: string | JSX.Element;
  uploadingFiles?: UploadingFile[];
  handleUploadContent?: (files: File[]) => void;
  handleCancelFileUpload?: (fileId: string) => void;
  handleClearUploadedFiles?: () => void;
  handleAddNewContent?: (filesIds: string[]) => void;
  maxFiles?: number;
};

export const ScoreGoalEvaluationStep = ({
  scoreGoal,
  setScoreGoal,
  goalHooks,
  title,
  subTitle,
  uploadingFiles,
  handleUploadContent,
  handleCancelFileUpload,
  handleClearUploadedFiles,
  handleAddNewContent,
  maxFiles,
}: ScoreGoalEvaluationStepProps): JSX.Element => {
  const [showAddContentModal, setShowAddContentModal] = React.useState(false);
  const { orgFilesQuery } = useOrgFilesQuery();

  const content = React.useMemo(() => {
    if (!orgFilesQuery?.data?.files) return [];

    return (scoreGoal.documentBasisIds ?? []).map((fileId) => ({
      id: fileId,
      name: orgFilesQuery?.data?.files?.find((file) => file.id === fileId)?.name ?? "",
    }));
  }, [orgFilesQuery?.data?.files, scoreGoal.documentBasisIds]);

  const handleUpdateScoreGoal = (key: keyof ScoreGoal, val: string | number | string[]) => {
    setScoreGoal((prev) => ({ ...prev, [key]: val }));
  };

  const handleClearAllFiles = () => {
    handleUpdateScoreGoal("documentBasisIds", []);
    handleClearUploadedFiles();
  };

  const cancelFileUpload = async (fileId: string) => {
    const updatedDocumentBasisIds = scoreGoal.documentBasisIds.filter((id) => id !== fileId);
    handleUpdateScoreGoal("documentBasisIds", updatedDocumentBasisIds);
    handleCancelFileUpload(fileId);
  };

  return (
    <>
      <ConvoScenarioStepWrapper title={title} subTitle={subTitle}>
        <Stack gap={4} sx={{ color: getDynamicColor("purple3") }}>
          {scoreGoal.documentBasisType && (
            <Stack gap={1}>
              <Stack
                direction="row"
                gap={1}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <Button
                  onClick={() => setShowAddContentModal(true)}
                  disableRipple
                  sx={{ fontSize: 14, px: 0 }}
                >
                  Add files
                </Button>
                {scoreGoal?.documentBasisIds?.length > 0 && (
                  <Button
                    onClick={handleClearAllFiles}
                    sx={{
                      fontSize: 12,
                      color: getDynamicColor("purple3"),
                    }}
                  >
                    Clear all
                  </Button>
                )}
              </Stack>
              <UploadedFiles files={content} handleCancelFileUpload={cancelFileUpload} />
            </Stack>
          )}
          <YoodliLabeledInput
            label={
              <Stack direction="row" alignItems="center">
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: "poppins",
                    color: getDynamicColor("dark5"),
                    mb: 0.5,
                  }}
                >
                  Explanation
                </Typography>
                <RephraseOrGenerateButton
                  lengthToCheck={scoreGoal.aiDescription.length}
                  onClick={async () => {
                    goalHooks.setGoalDescriptionLoading(true);
                    const rephrase = await getGoalRephrase(
                      scoreGoal.aiDescription,
                      "description",
                      scoreGoal.name,
                      "",
                    );
                    handleUpdateScoreGoal("aiDescription", rephrase);
                    goalHooks.setGoalDescriptionLoading(false);
                  }}
                />
              </Stack>
            }
            labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
            inputEl={
              <Box sx={{ position: "relative" }}>
                {goalHooks.goalDescriptionLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <YoodliTextfield
                  multiline
                  minRows={CUSTOM_GOAL_TEXTFIELD_MIN_ROWS}
                  inputProps={{
                    className: "blockEnterToNavigate",
                  }}
                  maxChars={CUSTOM_GOAL_OVERALL_AI_EXPLANATION_MAX_CHARS}
                  placeholder="e.g. Seeking feedback can consist of asking follow-up questions, asking open-ended questions, or asking for examples where something was done well or poorly."
                  InputLabelProps={{ shrink: true }}
                  value={scoreGoal.aiDescription}
                  onChange={(e) => handleUpdateScoreGoal("aiDescription", e.target.value)}
                  disabled={goalHooks.goalDescriptionLoading}
                />
              </Box>
            }
          />
          <Stack>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              Score
            </Typography>
            <Stack
              direction="row"
              gap={3}
              sx={{ justifyContent: "space-between", flexWrap: "wrap" }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 14,
                  fontWeight: 400,
                  color: getDynamicColor("dark5"),
                  maxWidth: { sm: "100%", md: "400px" },
                }}
              >
                Set the max score members can achieve for this goal (max 10). Define failure (lowest
                possible score) and success (highest possible score) below.
              </Typography>
              <MinMaxScoreSelector
                min={{ value: CUSTOM_GOAL_MIN_SCORE, disabled: true }}
                max={{
                  value: scoreGoal.maxScore,
                  minValue: CUSTOM_GOAL_MIN_SCORE,
                  maxValue: CUSTOM_GOAL_MAX_SCORE,
                  onChange: (value: string) => {
                    if (value) {
                      localStorage.setItem("customGoalMaxScore", value);
                    }
                    handleUpdateScoreGoal("maxScore", value ? Number(value) : undefined);
                  },
                }}
              />
            </Stack>
          </Stack>
          <YoodliLabeledInput
            label={
              <Stack direction="row" alignItems="center">
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: "poppins",
                    color: getDynamicColor("dark5"),
                    mb: 0.5,
                  }}
                >
                  {`Define a score of ${CUSTOM_GOAL_MIN_SCORE} (minimum score)`}
                </Typography>
                <RephraseOrGenerateButton
                  lengthToCheck={scoreGoal.lowScoreDescription.length}
                  onClick={async () => {
                    goalHooks.setGoalLowScoreLoading(true);
                    const rephrase = await getGoalRephrase(
                      scoreGoal.aiDescription,
                      "low",
                      scoreGoal.name,
                      scoreGoal.aiDescription,
                    );
                    handleUpdateScoreGoal("lowScoreDescription", rephrase);
                    goalHooks.setGoalLowScoreLoading(false);
                  }}
                />
              </Stack>
            }
            labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
            inputEl={
              <Box sx={{ position: "relative" }}>
                {goalHooks.goalLowScoreLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <YoodliTextfield
                  multiline
                  minRows={CUSTOM_GOAL_TEXTFIELD_MIN_ROWS}
                  maxChars={CUSTOM_GOAL_AI_EXPLANATION_MAX_CHARS}
                  inputProps={{
                    className: "blockEnterToNavigate",
                  }}
                  placeholder="e.g. The member never asks any open-ended or follow-up questions asking for feedback, and does not receive direction and advice."
                  InputLabelProps={{ shrink: true }}
                  value={scoreGoal.lowScoreDescription}
                  onChange={(e) => handleUpdateScoreGoal("lowScoreDescription", e.target.value)}
                  disabled={goalHooks.goalLowScoreLoading}
                />
              </Box>
            }
          />
          <YoodliLabeledInput
            label={
              <Stack direction="row" alignItems="center">
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: "poppins",
                    color: getDynamicColor("dark5"),
                    mb: 0.5,
                  }}
                >
                  {`Define a score of ${scoreGoal.maxScore ?? ""} (maximum score)`}
                </Typography>
                <RephraseOrGenerateButton
                  lengthToCheck={scoreGoal.highScoreDescription.length}
                  onClick={async () => {
                    goalHooks.setGoalHighScoreLoading(true);
                    const rephrase = await getGoalRephrase(
                      scoreGoal.aiDescription,
                      "high",
                      scoreGoal.name,
                      scoreGoal.aiDescription,
                    );
                    handleUpdateScoreGoal("highScoreDescription", rephrase);
                    goalHooks.setGoalHighScoreLoading(false);
                  }}
                />
              </Stack>
            }
            labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
            inputEl={
              <Box sx={{ position: "relative" }}>
                {goalHooks.goalHighScoreLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <YoodliTextfield
                  multiline
                  minRows={CUSTOM_GOAL_TEXTFIELD_MIN_ROWS}
                  maxChars={CUSTOM_GOAL_AI_EXPLANATION_MAX_CHARS}
                  inputProps={{
                    className: "blockEnterToNavigate",
                  }}
                  placeholder="e.g. The member asked open-ended and/or follow-up questions asking for feedback, and receives direction and advice."
                  InputLabelProps={{ shrink: true }}
                  value={scoreGoal.highScoreDescription}
                  onChange={(e) => handleUpdateScoreGoal("highScoreDescription", e.target.value)}
                  disabled={goalHooks.goalHighScoreLoading}
                />
              </Box>
            }
          />
        </Stack>
      </ConvoScenarioStepWrapper>
      <AddContentModal
        uploadingFiles={uploadingFiles}
        handleUploadContent={handleUploadContent}
        amtOfFilesLeft={maxFiles - (scoreGoal?.documentBasisIds?.length ?? 0)}
        handleCancelFileUpload={cancelFileUpload}
        showAddContentModal={showAddContentModal}
        setShowAddContentModal={setShowAddContentModal}
        content={content}
        handleAddNewContent={handleAddNewContent}
      />
    </>
  );
};
