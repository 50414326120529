// Utils
/**
 * This file contains helper functions that do not belong to any specific category.
 * It is not desired that this file becomes larger.
 * Whenever a new helper function is added, it should be considered if it belongs to any existing
 * category or it's worth grouping several functions here, and put into a separate file.
 */
import * as db from "lib-fullstack/db";
import * as randomstring from "randomstring";

export const delay = (ms: number): Promise<unknown> => new Promise((res) => setTimeout(res, ms));

export const filterToIntegers = (arr: Array<string | number>): number[] => {
  return arr.map((x) => parseInt(x as string, 10)).filter((x) => !isNaN(x)) as number[];
};

export const chunkArray = <T>(array: T[], size: number): T[][] => {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size));
  }
  return chunkedArray;
};

export const moveArrayElementToStart = (
  arr: string[],
  element: string,
  appendToStart: boolean,
): string[] => {
  const index = arr.findIndex((x) => x === element);
  if (index > -1) {
    arr = [arr[index], ...arr.filter((x) => x !== element)];
  } else if (appendToStart) {
    arr = [element, ...arr];
  }
  return arr;
};

export const roundToPrecision = (value: number, precision: number): number => {
  if (isNaN(value) || value === 0) return 0;
  const power = Math.pow(10, precision || 0);
  return Math.round((value + Number.EPSILON) * power) / power;
};

export const secondsToHms = (d: number): string => {
  d = Number(d);

  if (d === 0) {
    return "0s ";
  }

  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + "h " : "";
  const mDisplay = m > 0 ? m + "m " : "";
  const sDisplay = s > 0 ? s + "s " : "";

  return hDisplay + mDisplay + sDisplay;
};

export const unpackPromiseSettledResult = <T>(
  result: PromiseSettledResult<T>,
  defaultValue: T,
  logFunction: (...data: unknown[]) => void,
): T => {
  if (result.status === "fulfilled") {
    return result.value;
  } else {
    logFunction(`Promise rejected: `, result.reason);
    return defaultValue;
  }
};

export const wordListToString = (words: db.Word[], wordSeparator = " "): string => {
  let result = "";
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    result += word.value;
    if (i < words.length - 1) {
      const nextWord = words[i + 1];
      if (nextWord.value.match(/^[.,!?;: ]/)) {
        result += nextWord.value;
        if (nextWord.value !== wordSeparator) {
          result += wordSeparator;
        }
        i++;
      } else {
        result += wordSeparator;
      }
    }
  }
  return result;
};

/**
 * Generate base 58 (alphanumeric, excluding 0OIl) random string.
 *
 * 22 digits: max 6.2e38, 128 bits
 * 20 digits: max 1.8e35, 117 bits
 *  8 digits: max 1.2e14, 46 bits
 *  4 digits: max 1.1e7, 23 bits
 *
 * Note: this is cryptographically random.
 * randomstring package uses randombytes package underneath,
 * and randombytes package polyfills crypto (node.js) and
 * window.crypto via crypto-browserify package (browser).
 * This polyfill is difficult to configure with webpack,
 * and it's much easier to use randombytes package.
 */
export const getRandomBase58String = (numDigits: number): string => {
  return randomstring.generate({
    length: numDigits,
    charset: "alphanumeric",
    readable: true, // excluding 0OIl
  });
};

/**
 * Get all values of a string enum as string array.
 *
 * enum Colors {
 *  Red = "red",
 *  Green = "green",
 *  Blue = "blue",
 * }
 * getStringEnumValues(Colors) => ["red", "green", "blue"]
 */
export function getStringEnumValues<T>(enumObj: T): string[] {
  return Object.values(enumObj).filter((v) => typeof v === "string") as string[];
}

/**
 * Remove undefined fields from an object.
 * Return type is kept as same as input statically.
 * Logic does not check if each field is typed as undefine-able
 * and it is the responsibility of the caller to ensure existence
 * of mandatory fields in advance or by runtime type checking.
 *
 * Note: this code was generated by gpt-4o with some iterations.
 * I did not find a potentially original code / article of this.
 */
export function removeUndefinedFields<T extends object>(obj: T): T {
  const result: object = {};
  (Object.keys(obj) as Array<keyof T>).forEach((key) => {
    if (obj[key] !== undefined) {
      result[key as string] = obj[key];
    }
  });
  return result as T;
}

/**
 * Parse a string value to a boolean.
 * @param value - The string value to parse (i.e. "true" or "false").
 * @returns The parsed boolean value.
 * @throws An error if the value is not "true" or "false".
 */
export const parseBool = (value: string): boolean => {
  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  } else {
    throw new Error(`parseBool: Invalid boolean value: ${value}`);
  }
};
