// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { parse as parseHTMLString } from "node-html-parser";

let gql, ApolloClient, InMemoryCache;
const moduleImported = new Promise((resolve) => {
  import("@apollo/client")
    .then((module) => {
      gql = module.gql;
      ApolloClient = module.ApolloClient;
      InMemoryCache = module.InMemoryCache;
      resolve(null);
    })
    .catch((error) => console.error(`wordpress: failed to import @apollo/client: ${error}`));
});

const prodDomain = "yoodli";
const devDomain = "yoodliDev";
let client: typeof ApolloClient | null = null;

const initializeClient = async (): Promise<typeof ApolloClient> => {
  if (!client) {
    const BASE_URL = `https://${
      process?.env?.NODE_ENV === "production" ? prodDomain : devDomain
    }.wpcomstaging.com/graphql`;

    await moduleImported;

    client = new ApolloClient({
      uri: BASE_URL,
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          // This effectively prevents cache usage, which allows it to be handled entirely by nextjs/webclient
          fetchPolicy: "no-cache",
        },
      },
    });
  }
  return client;
};

export const BLOG_FALLBACK_IMAGE =
  "https://storage.googleapis.com/yoodli-public/landing-page-assets/blog_fallback.svg";
export const BLOG_FALLBACK_ALT_TEXT =
  "The Yoodli blog has speech coaching tips to help you improve your communication skills and sound confident, anytime you speak";
const BLOG_POSTS_PER_PAGE = 40;
const CASE_STUDIES_PER_PAGE = 200;
const FEATURE_ANNOUNCEMENTS_CATEGORY_ID = 2028;
const WEBINAR_CATEGORY_ID = 2209;

const EXCLUDE_SPECIAL_CATEGORIES = `categoryNotIn: [${FEATURE_ANNOUNCEMENTS_CATEGORY_ID}, ${WEBINAR_CATEGORY_ID}]`;

// For individual category queries
const INCLUDE_FEATURE_ANNOUNCEMENTS = `categoryIn: [${FEATURE_ANNOUNCEMENTS_CATEGORY_ID}]`;
const INCLUDE_WEBINARS = `categoryIn: [${WEBINAR_CATEGORY_ID}]`;

export enum WordpressTag {
  Featured = "featured",
  CaseStudy = "casestudy",
  OrgAdmin = "org-admin",
  Partnership = "partnership",
}

export enum WordpressTagId {
  CaseStudy = "dGVybToxOTE1",
  Partnership = "2208",
  OrgAdmin = "2114",
  Unlisted = "2136",
}

const DEFAULT_POST = {
  id: "",
  slug: "",
  date: "",
  excerpt: "",
  title: "",
  content: "",
  readingTime: 0,
  wordCount: 0,
  featuredImage: {
    url: BLOG_FALLBACK_IMAGE,
    alt: BLOG_FALLBACK_ALT_TEXT,
  },
};

type dbFeaturedImage = {
  altText: string;
  mediaItemUrl: string;
};
type FeaturedImage = {
  alt: string;
  url: string;
};
export type BlogPostCard = {
  date: string;
  slug: string;
  id: string;
  title: string;
  excerpt?: string;
  featuredImage?: FeaturedImage;
  cursor?: string;
};
type AuthorAvatar = {
  url: string;
  alt?: string;
};
export type Author = {
  name: string;
  slug: string;
  url?: string;
  description: string;
  avatar: AuthorAvatar;
};

type dbBlogPost = {
  cursor: string;
  author?: { node: Author };
  date: string;
  slug: string;
  id: string;
  content: string;
  excerpt: string;
  title: string;
  tags?: {
    nodes: {
      id: string;
      name: string;
    }[];
  };
  featuredImage: {
    node: dbFeaturedImage;
  };
};

export type BlogPost = BlogPostCard & {
  content?: string;
  tags?: string[];
  author?: Author;
  readingTime?: number;
  wordCount?: number;
  headline?: string;
};

export type FeatureUpdateResponse = {
  slug: string;
  date: string;
  content: string; // HTML string from WYSWIG, will require parsing
  featureAnnouncementUrl: string;
  isOrgAdmin?: boolean;
};

const getTimeAndLength = (content: string) => {
  const div = parseHTMLString(content);
  const textContent = div.textContent;
  return {
    readingTime: Math.floor(textContent.split(" ").length / 200) || 1,
    wordCount: textContent?.split(" ").length ?? 0,
  };
};

const parseExcerpt = (excerpt: string) => {
  const root = parseHTMLString(excerpt);
  return root.querySelector("p")?.textContent ?? "";
};

// just to add alt text for now - will update when author links exist
const parseAuthor = (author: Author) => {
  return {
    ...author,
    avatar: {
      ...author.avatar,
      alt: author?.description,
    },
  };
};

const parseFeaturedImage = (featuredImage: dbFeaturedImage): FeaturedImage => {
  return {
    url: featuredImage?.mediaItemUrl ?? BLOG_FALLBACK_IMAGE,
    alt: featuredImage?.altText ?? BLOG_FALLBACK_ALT_TEXT,
  };
};

const parseContent = (
  post: dbBlogPost,
): { content: string; readingTime: number; wordCount: number } => {
  const document = parseHTMLString(post.content);

  // remove the title from content (first h3) - it exists on the page already
  const leadingTitle = document.querySelector(".graf--leading.graf--title");
  if (leadingTitle && leadingTitle.textContent === post.title) {
    leadingTitle.remove();
  }

  // center images
  const images = document.querySelectorAll("img");
  if (images.length > 0) {
    images.forEach((image) => {
      image.setAttribute("style", "margin: 0 auto");
    });
  }

  // style blockquotes with a purple border left and quotation marks
  const quotes = document.querySelectorAll("blockquote");
  if (quotes.length > 0) {
    quotes.forEach((blockquote) => {
      blockquote.setAttribute(
        "style",
        `
        border-left: 5px solid ${getDynamicColor("purple2")};
        background-color: ${getDynamicColor("light1")};
        margin-left: 8px;
        padding-left: 24px;
        padding-right: 24px;
        font-style: italic;
        color: ${getDynamicColor("dark5")}
      `,
      );
      const quoteTexts = blockquote.querySelectorAll("p");
      quoteTexts.forEach((quoteText) => {
        quoteText.setAttribute("style", "font-size: 17px");
      });
      if (quoteTexts.length === 1) {
        quoteTexts[0].innerHTML = `<span class="quote">"</span>${quoteTexts[0].innerHTML}<span class="quote">"</span>`;
      } else if (quoteTexts[0] && quoteTexts[quoteTexts.length - 1]) {
        quoteTexts[0].innerHTML = `<span class="quote">"</span>${quoteTexts[0].innerHTML}`;
        quoteTexts[quoteTexts.length - 1].innerHTML = `${
          quoteTexts[quoteTexts.length - 1].innerHTML
        }<span class="quote">"</span>`;
      }
    });
  }
  // remove the sharing programmatically for now - temporary, until we get our socials connected in Wordpress
  const share = document.querySelectorAll(".sharedaddy");
  share.forEach((s) => s.remove());

  const { wordCount, readingTime } = getTimeAndLength(post.content);

  const youtubeEmbedRegex = /www\.youtube\.com\/embed/g;
  const noYoutubeCookieContent: string = document
    .toString()
    .replace(youtubeEmbedRegex, "www.youtube-nocookie.com/embed");

  return {
    content: noYoutubeCookieContent,
    readingTime,
    wordCount,
  };
};

const transformPost = (post: dbBlogPost): BlogPost => {
  try {
    const { id, slug, date, title } = post;
    const { content, readingTime, wordCount } = parseContent(post);
    const featuredImage = parseFeaturedImage(post.featuredImage?.node);
    const author = parseAuthor(post.author?.node);
    const excerpt = parseExcerpt(post.excerpt);
    return {
      id,
      slug,
      date,
      excerpt,
      title,
      content,
      readingTime,
      wordCount,
      featuredImage,
      author,
    };
  } catch (er) {
    console.error(
      `Error transforming blog post: ID=${post?.id} TITLE=${post?.title} SLUG=${post?.slug}`,
      er,
    );
    return DEFAULT_POST;
  }
};

const transformPosts = (posts: dbBlogPost[]): BlogPost[] => {
  return posts.map((item) => {
    const { slug, date, id, title, cursor, tags } = item;
    const featuredImage = parseFeaturedImage(item?.featuredImage?.node);
    const excerpt = parseExcerpt(item?.excerpt);
    const newTags = (tags?.nodes ?? [])?.map((tag) => tag?.name);
    return {
      id,
      featuredImage,
      slug,
      date,
      excerpt,
      title,
      cursor,
      tags: newTags,
    };
  });
};

/*  QUERIES  */

export const getPostsByQuery = async (query: string): Promise<{ posts: BlogPost[] }> => {
  const client = await initializeClient();
  const { data } = await client.query({
    variables: { query },
    query: gql`
      query GetPostsByQuery($query: String!) {
        posts(first: 100, where: { search: $query, tagNotIn: ["${WordpressTagId.CaseStudy}", "${WordpressTagId.Partnership}", "${WordpressTagId.Unlisted}"], ${EXCLUDE_SPECIAL_CATEGORIES} }) {
          edges {
            node {
              date
              slug
              excerpt
              title
              featuredImage {
                node {
                  altText
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    `,
  });
  const {
    posts: { edges: posts },
  } = data;
  return {
    posts: transformPosts(posts.map(({ node }) => ({ ...node }))),
  };
};

export const getFeaturedPosts = async (filterCaseStudies?: boolean): Promise<BlogPost[]> => {
  let extraFiltersString = `, tagNotIn: "${WordpressTagId.Unlisted}"`;
  if (filterCaseStudies) {
    extraFiltersString = `, tagNotIn: ["${WordpressTagId.CaseStudy}", "${WordpressTagId.Partnership}", "${WordpressTagId.Unlisted}"], ${EXCLUDE_SPECIAL_CATEGORIES}`;
  }
  const client = await initializeClient();
  const { data } = await client.query({
    query: gql`
      query GetFeaturedPosts {
        posts(where: { tagSlugIn: "${WordpressTag.Featured}"${extraFiltersString} }) {
          edges {
            cursor
            node {
              date
              slug
              excerpt
              tags {
                nodes {
                  id
                  name
                }
              }
              id
              title
              featuredImage {
                node {
                  altText
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    `,
  });
  const {
    posts: { edges: posts },
  } = data;
  return transformPosts(posts.map(({ node, cursor }) => ({ ...node, cursor })));
};

export const getAllAuthors = async (): Promise<Author[]> => {
  const client = await initializeClient();
  const { data } = await client.query({
    query: gql`
      query GetAllAuthors {
        users(where: { hasPublishedPosts: POST }) {
          nodes {
            name
            slug
            description
            url
            avatar {
              url
            }
          }
        }
      }
    `,
  });
  const {
    users: { nodes: users },
  } = data;
  return users;
};

export const getPostsForAuthor = async (
  authorId: string,
  token?: string,
): Promise<{ authorInfo: Author; posts: BlogPost[]; hasNextPage: boolean }> => {
  const client = await initializeClient();
  const { data } = await client.query({
    variables: { authorId, token },
    query: gql`
      query GetFeaturedPosts($authorId: ID!, $token: String) {
        user(id: $authorId, idType: SLUG) {
          avatar {
            url
          }
          slug
          name
          description
          url
          posts(first: 40, after: $token, where: { ${EXCLUDE_SPECIAL_CATEGORIES} }) {
            pageInfo {
              hasNextPage
            }
            edges {
              cursor
              node {
                date
                slug
                excerpt
                id
                title
                featuredImage {
                  node {
                    altText
                    mediaItemUrl
                  }
                }
              }
            }
          }
        }
      }
    `,
  });
  const { user } = data;
  return {
    authorInfo: {
      name: user.name,
      slug: user.slug,
      description: user.description,
      url: user.url,
      avatar: {
        ...user.avatar,
        alt: user?.description,
      },
    },
    posts: transformPosts(
      user?.posts?.edges.map((item) => ({ ...item.node, cursor: item.cursor })),
    ),
    hasNextPage: user?.posts?.pageInfo?.hasNextPage,
  };
};

export const getPost = async (id: string): Promise<BlogPost> => {
  try {
    const client = await initializeClient();
    const { data } = await client.query({
      variables: { id },
      query: gql`
        query GetPost($id: ID!) {
          post(id: $id, idType: SLUG) {
            date
            slug
            id
            excerpt
            content
            title
            featuredImage {
              node {
                altText
                mediaItemUrl
              }
            }
            author {
              node {
                slug
                avatar {
                  url
                }
                name
                url
                description
              }
            }
          }
        }
      `,
    });
    const { post } = data;
    if (post === null) {
      return null;
    }
    return transformPost(post);
  } catch (er) {
    console.error(`Error fetching blog post from wordpress: ID=${id}`, er);
  }
};

export const getPostsForSitemap = async (): Promise<{ slug: string; id: string }[]> => {
  let fetchNextPage = true;
  let token = null;
  let allPosts = [];
  const client = await initializeClient();
  // loop through all posts 100 at a time to fetch all pages for sitemap
  while (fetchNextPage) {
    const { data } = await client.query({
      variables: { token },
      query: gql`
        query GetAllPosts($token: String) {
          posts(first: 100, after: $token) {
            pageInfo {
              hasNextPage
            }
            edges {
              cursor
              node {
                slug
              }
            }
          }
        }
      `,
    });
    const {
      posts: {
        edges: posts,
        pageInfo: { hasNextPage },
      },
    } = data;
    // add the posts to the array
    allPosts = allPosts.concat(posts.map((item) => item.node));
    // update hasNextPage and token
    fetchNextPage = hasNextPage;
    token = posts[posts.length - 1].cursor;
  }
  return allPosts;
};

export const getPostsFeed = async (
  token?: string,
  limit = BLOG_POSTS_PER_PAGE,
): Promise<{ posts: BlogPostCard[]; hasNextPage: boolean }> => {
  const client = await initializeClient();
  const { data } = await client.query({
    variables: { token, limit },
    query: gql`
      query GetAllPosts($token: String, $limit: Int) {
        posts(first: $limit, after: $token, where: { ${EXCLUDE_SPECIAL_CATEGORIES}, tagNotIn: ["${WordpressTagId.CaseStudy}", "${WordpressTagId.Partnership}", "${WordpressTagId.Unlisted}"] }) {
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              date
              slug
              id
              title
              excerpt
              tags {
                nodes {
                  id
                  name
                }
              }
              featuredImage {
                node {
                  altText
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    `,
  });
  const {
    posts: {
      edges: posts,
      pageInfo: { hasNextPage },
    },
  } = data;

  return {
    posts: transformPosts(posts.map((item) => ({ ...item.node, cursor: item.cursor }))),
    hasNextPage,
  };
};

export const getWebinarsFeed = async (
  token?: string,
): Promise<{ posts: BlogPostCard[]; hasNextPage: boolean }> => {
  const client = await initializeClient();
  const { data } = await client.query({
    variables: { token, limit: BLOG_POSTS_PER_PAGE },
    query: gql`
      query GetAllPostsByCategory($token: String, $limit: Int) {
        posts(first: $limit, after: $token, where: { ${INCLUDE_WEBINARS} }) {
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              date
              slug
              id
              title
              excerpt
              tags {
                nodes {
                  id
                  name
                }
              }
              featuredImage {
                node {
                  altText
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    `,
  });
  const {
    posts: {
      edges: posts,
      pageInfo: { hasNextPage },
    },
  } = data;

  return {
    posts: transformPosts(posts.map((item) => ({ ...item.node, cursor: item.cursor }))),
    hasNextPage,
  };
};

export const getWebinarSlugs = async (): Promise<string[]> => {
  const client = await initializeClient();
  const { data } = await client.query({
    query: gql`
      query GetAllPostsByCategory {
        posts(where: { ${INCLUDE_WEBINARS} }) {
          edges {
            node {
              slug
            }
          }
        }
      }
    `,
  });
  const {
    posts: { edges: posts },
  } = data;
  return posts.map(({ node }) => node.slug);
};

export const getFeatureAnnouncementsFeed = async (
  token?: string,
): Promise<{ posts: BlogPostCard[]; hasNextPage: boolean }> => {
  const client = await initializeClient();
  const { data } = await client.query({
    variables: { token, limit: BLOG_POSTS_PER_PAGE },
    query: gql`
      query GetAllPostsByCategory($token: String, $limit: Int) {
        posts(first: $limit, after: $token, where: { ${INCLUDE_FEATURE_ANNOUNCEMENTS} }) {
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              date
              slug
              id
              title
              excerpt
              tags {
                nodes {
                  id
                  name
                }
              }
              featuredImage {
                node {
                  altText
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    `,
  });
  const {
    posts: {
      edges: posts,
      pageInfo: { hasNextPage },
    },
  } = data;

  return {
    posts: transformPosts(posts.map((item) => ({ ...item.node, cursor: item.cursor }))),
    hasNextPage,
  };
};

export const getFeatureAnnouncementSlugs = async (): Promise<string[]> => {
  const client = await initializeClient();
  // call the same query as getFeatureAnnouncementsFeed, but only get the slugs
  const { data } = await client.query({
    query: gql`
      query GetAllPostsByCategory {
        posts(where: { ${INCLUDE_FEATURE_ANNOUNCEMENTS} }) {
          edges {
            node {
              slug
            }
          }
        }
      }
    `,
  });
  const {
    posts: { edges: posts },
  } = data;
  return posts.map(({ node }) => node.slug);
};

export const getCaseStudySlugs = async (): Promise<string[]> => {
  const client = await initializeClient();
  const { data } = await client.query({
    query: gql`
      query GetCaseStudySlugs {
        posts(where: { tagSlugIn: ["${WordpressTag.CaseStudy}", "${WordpressTag.Partnership}"] }) {
          edges {
            node {
              slug
            }
          }
        }
      }
    `,
  });
  const {
    posts: { edges: posts },
  } = data;
  return posts.map(({ node }) => node.slug);
};

export const getPostSlugs = async (): Promise<string[]> => {
  const client = await initializeClient();
  const { data } = await client.query({
    query: gql`
      query GetPostSlugs {
        posts {
          edges {
            node {
              slug
            }
          }
        }
      }
    `,
  });
  const {
    posts: { edges: posts },
  } = data;
  return posts.map(({ node }) => node.slug);
};

export const getCaseStudiesFeed = async (
  token?: string,
  limit = CASE_STUDIES_PER_PAGE,
): Promise<{ posts: BlogPostCard[]; hasNextPage: boolean }> => {
  const client = await initializeClient();
  const { data } = await client.query({
    variables: { token, limit },
    query: gql`
      query GetAllPosts($token: String, $limit: Int) {
        posts(first: $limit, after: $token, where: { tagSlugIn: ["${WordpressTag.CaseStudy}", "${WordpressTag.Partnership}"], ${EXCLUDE_SPECIAL_CATEGORIES}, tagNotIn: "${WordpressTagId.Unlisted}" }) {
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              date
              slug
              id
              title
              excerpt
              tags {
                nodes {
                  id
                  name
                }
              }
              featuredImage {
                node {
                  altText
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    `,
  });
  const {
    posts: {
      edges: posts,
      pageInfo: { hasNextPage },
    },
  } = data;
  return {
    posts: transformPosts(
      (posts ?? [])

        .map((item) => {
          return { ...item.node, cursor: item.cursor };
        })
        .sort((postA, postB) => {
          // sort by date, newest at the top, for now, until we get featured/non featured
          const dateA = new Date(postA.date);
          const dateB = new Date(postB.date);
          return dateB.getTime() - dateA.getTime();
        }),
    ),
    hasNextPage,
  };
};

export const getMostRecentFeatureUpdate = async (
  isOrgAdmin?: boolean,
): Promise<FeatureUpdateResponse | null> => {
  const filter = isOrgAdmin ? "tagSlugIn" : "tagNotIn";
  const thingToFilter = isOrgAdmin ? WordpressTag.OrgAdmin : WordpressTagId.OrgAdmin;
  const client = await initializeClient();
  // Add the tag filter if isOrgAdmin is true
  const tagFilter = `, ${filter}: "${thingToFilter}"`;
  const { data } = await client.query({
    query: gql`
      query WhatsNewPosts {
        whatsNewPosts(
          first: 1
          where: { 
            orderby: { field: DATE, order: DESC }
            ${tagFilter}
          }
        ) {
          nodes {
            slug
            date
            whatsNewPosts {
              content
              featureAnnouncementUrl
            }
          }
        }
      }
    `,
  });
  const {
    whatsNewPosts: { nodes: posts },
  } = data;
  const post = posts?.[0];
  if (!post) {
    return null;
  }
  return {
    slug: post.slug,
    date: post.date,
    content: post.whatsNewPosts?.content,
    featureAnnouncementUrl: post.whatsNewPosts?.featureAnnouncementUrl,
    isOrgAdmin,
  };
};
