import {
  Boolean,
  Null,
  Number,
  Array as RTArray,
  Record,
  Static,
  String,
  Optional,
  Union,
  Literal,
} from "runtypes";
import { NullableOptional, RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";
import {
  RTOrgRole,
  RTHubRole,
  RTEffectiveRole,
  RTOrgCreationMethod,
  RTOrgSubscriptionType,
  RTOrgCustomerType,
  RTLLMChoices,
  RTOBQ1Option,
  OrgSettingType,
  RTIOrgSettingType,
  RTContentVideoState,
  RTContentVideoType,
  RTAuthProvider,
} from "lib-fullstack/utils/enums";
import { RemarkIDType, SpeechAnalyticIDsType } from "lib-fullstack/db/db_runtypes";
import { TeamMemberRole } from "lib-fullstack/utils/enums/teamMemberRole";

export enum OrgSharingVisibilityEnum {
  Public = "Public",
  Private = "Private",
  OrgOnly = "OrgOnly",
}

export const OrgSharingVisibility = RTStringEnum(OrgSharingVisibilityEnum);

export const Org = Record({
  orgId: String,
  name: String,
  defaultHubId: String,
  hubQuota: Optional(Number),
  emailDomains: RTArray(String),
  ssoOptions: Optional(RTArray(String)),
  /** If true, sign-up/sign-in proceeds to ssoOptions[0] without end user's click */
  autoSso: Optional(Boolean),
  signInOptions: Optional(RTArray(RTAuthProvider)),
  unaffiliated: Boolean,
  /** ordered list of video IDs */
  demoVideos: Optional(RTArray(String)),
  demoVideoQuota: Optional(Number),
  courseQuota: Optional(Number),
  courseVideoQuota: Optional(Number),
  stripeId: Optional(String),
  stripeLastSync: Optional(String),
  /**
   * License count.
   * Synchronized from authoritative data in Stripe.
   * Clients should check this value is >0 if the subscription is active.
   */
  numSeatsLicensed: Optional(Number),
  numSeatsUsed: Optional(Number),
  /**
   * Later of orgRenewalDate or orgCancellationDate.
   * Typically, it is orgCancellationDate.
   * If orgCancellationDate is not set, which may happen only with PAYG, it is renewal date.
   */
  licenseEndDate: NullableOptional(String),
  /** Stripe subscription renewal date. Synchronized from authoritative data in Stripe. */
  orgRenewalDate: NullableOptional(String),
  /** Stripe subscription cancellation date. Synchronized from authoritative data in Stripe. */
  orgCancellationDate: NullableOptional(String),
  disableDataCollection: Boolean,
  logoExtension: Optional(String),
  secondaryLogoExtension: Optional(String),
  creationDate: Optional(String),
  creationMethod: Optional(RTOrgCreationMethod),
  presentationFtuxPromptOverride: Optional(String), // no longer just ftux, now "always prompt" override
  selfRemovalAllowed: Optional(Boolean),
  /** if undefined, we route to openai */
  llmChoice: Optional(RTLLMChoices),
  programQuota: Optional(Number),
  /**
   * 1-30: force this specific number of days, 0: user config
   * This is ignored if numSeatsLicensed is 0 or non-existence
   */
  dataRedactionDays: Optional(Number),
  subscriptionType: RTOrgSubscriptionType,
  customerType: RTOrgCustomerType,
  isTrial: Optional(Boolean),
  trialEndsSoonEmailSentDate: Optional(String),
  trialEndsTomorrowEmailSentDate: Optional(String),
  trialEndedEmailSentDate: Optional(String),
  dateTestResource: Optional(String), // This is set only when the org is created for unit tests
  paymentMethodAdded: Optional(Boolean),
  defaultSharingVisibility: Optional(OrgSharingVisibility),

  /**
   * Number of the licenses which are given by base contract of a flexible plan.
   * Synchronized from authoritative data in Stripe.
   * Prepaid orgs have inappropriate value and should be overridden.
   * This is null or non-existence if a valid Stripe subscription is not associated.
   */
  numBaseSeats: NullableOptional(Number),
  /**
   * The price dollars per seat for a flexible plan.
   * Synchronized from authoritative data in Stripe.
   * Prepaid orgs have inappropriate value and should be overridden.
   * This is null or non-existence if a valid Stripe subscription is not associated.
   */
  perSeatDollars: NullableOptional(Number),

  // onboarding / new user options
  defaultOnboarding: NullableOptional(RTOBQ1Option),
  onboardingChecklistEnabled: Optional(Boolean),

  //
  // Module disable/enablement
  //
  interviewEnabled: Optional(Boolean),
  zoodliForUsersEnabled: Optional(Boolean),
  roleplayEnabled: Optional(Boolean),
  presentationEnabled: Optional(Boolean),
  desktopAppEnabled: Optional(Boolean),
  memberBuilderEnabled: Optional(Boolean),
  pronunciationFeedbackEnabled: Optional(Boolean),
  videoUploadEnabled: Optional(Boolean),
  supportAccessEnabled: Optional(Boolean),

  /**
   * Flag indicating whether the org has the Accenture 1.0 integration
   * enabled.
   */
  accenture10Enabled: Optional(Boolean),
  /**
   * Host name allow-list for CSP frame-ancestors policy.
   */
  accenture10HostNames: Optional(RTArray(String)),

  // enable "hotel seats" number of inactive days before a members seat is released
  numSeatInactiveDays: Optional(Number),

  orgCustomVocab: Optional(
    Record({
      fillerWords: Optional(RTArray(String)),
      fillerRegex: Optional(RTArray(String)),
      weakWords: Optional(RTArray(String)),
      weakRegex: Optional(RTArray(String)),
    }),
  ),

  disabledAnalytics: Optional(RTArray(SpeechAnalyticIDsType)),
  disabledRemarks: Optional(RTArray(RemarkIDType)),

  /*
   * By default we use Deepgram for speech-to-text. Override here to use Rev instead
   * Only applies to async processing--sync will still use Deepgram
   */
  useRev: Optional(Boolean),

  /**
   * List of email domains verified to belong to the customer for this org.
   * Wildcard subdomains are supported. Thise are used a few different purposes:
   * automatic user creation and signin, direct addition to the org and hubs.
   */
  autoUserCreationAllowedDomains: Optional(RTArray(String)),

  /**
   * If true, hide all AI feedback in the Speech Summary
   * this include rephrasals, remarks, and conciseness metric
   * This is a single customer specific setting we are likely to remove later
   * https://github.com/Yoodli/yoodli/issues/13868
   */
  disableAiFeedback: Optional(Boolean),

  /**
   * ID of the default content space for the org
   */
  defaultSpaceId: String,

  /**
   * If true, this org will have live event scaling enabled, meaning the LLM strategy will be significantly shifted
   * with an eye towards high concurrency.
   */
  liveEventScaling: Optional(Boolean),

  /**
   * If true, this org will use a custom help page
   */
  customHelpPage: Optional(Boolean),

  /**
   * If true, Yoodli is allowed to provide frontline support via a support widget
   */
  allowYoodliSupport: Optional(Boolean),

  /**
   * Custom content for the help page when customHelpPage is true
   */
  customHelpPageContent: Optional(String),
});
export type Org = Static<typeof Org>;

export const OrgMembership = Record({
  userKey: String,
  role: Union(RTOrgRole, Null),
  effectiveRole: RTEffectiveRole,
  dateJoined: String,
  name: String,
  email: String,
  numStartedSpeeches: Number,
  dateLastActivity: Union(String, Null),
  evaluatorEmails: Optional(RTArray(String)),
  spaceIds: Optional(RTArray(String)),
});
export type OrgMembership = Static<typeof OrgMembership>;

export const AbstractOrgSetting = Record({
  createdAt: String,
  modifiedAt: Optional(String),
  createdBy: String,
  modifiedBy: Optional(String),
  type: RTIOrgSettingType,
});
export type AbstractOrgSetting = Static<typeof AbstractOrgSetting>;

export const EmailBranding = AbstractOrgSetting.extend({
  welcomeMessage: String,
  goodbyeMessage: String,
  disableGoodbyeEmail: Optional(Boolean),
  type: Literal(OrgSettingType.EMAIL_BRANDING),
});
export type EmailBranding = Static<typeof EmailBranding>;

export const SignUpNotice = AbstractOrgSetting.extend({
  message: String,
  type: Literal(OrgSettingType.SIGN_UP_NOTICE),
});
export type SignUpNotice = Static<typeof SignUpNotice>;

export const OrgSettingDoc = Union(EmailBranding, SignUpNotice);

export type OrgSettingDoc = Static<typeof OrgSettingDoc>;

export const InviteHistoryEntry = Record({
  date: String,
  userId: Union(String, Null),
  orgRole: Union(RTOrgRole, Null),
  hubRole: Union(RTHubRole, Null),
  hubIdList: RTArray(String),
});
export type InviteHistoryEntry = Static<typeof InviteHistoryEntry>;

export const OrgInviteV2 = Record({
  email: String,
  name: Union(String, Null),
  orgId: String,
  orgRole: Union(RTOrgRole, Null),
  hubIdList: RTArray(String),
  hubRoleList: RTArray(RTHubRole),
  /** If this is prior to 2024-08-24, it is actually the creation date */
  lastModifiedDate: String,
  /** empty string means generated by non-user operation, or prior to 2024-08-24. */
  lastModifiedUserId: Union(String, Null),
  /** this array is copied to org membership's field when a user is created */
  evaluatorEmails: Optional(RTArray(String)),
  spaceIds: Optional(RTArray(String)),
  history: RTArray(InviteHistoryEntry),
  /**
   * Teams to which this user is invited.
   * Must be the same length as teamRoles.
   */
  teamIds: Optional(RTArray(String)),
  /**
   * Team member roles for the teams to which this user is invited.
   * Must be the same length as teamIds.
   */
  teamRoles: Optional(RTArray(TeamMemberRole)),
});
export type OrgInviteV2 = Static<typeof OrgInviteV2>;

export const Hub = Record({
  hubId: String,
  name: String,
  orgDefault: Boolean,
  creationDate: String,
  demoVideos: Optional(RTArray(String)), // ordered list of video IDs
  courses: Optional(RTArray(String)), // ordered list of course IDs
  interviewBanks: Optional(RTArray(String)), // ordered list of interview bank IDs
  defaultOnboarding: Optional(RTOBQ1Option),
});
export type Hub = Static<typeof Hub>;

export const HubMembershipV2 = Record({
  hubId: String,
  userKey: String,
  role: RTHubRole,
  dateJoined: String,
  name: String,
  email: String,
  numStartedSpeeches: Number,
  dateLastActivity: String,
});
export type HubMembershipV2 = Static<typeof HubMembershipV2>;

// This was used for individual invitation before OHRv2,
// but it is now used only for invite link.
// email field remains with Null is the only available value at this time.
export const HubInvite = Record({
  email: Null,
  role: RTHubRole,
  invitedAt: String,
  invitedBy: String,
  valid: Boolean,
});
export type HubInvite = Static<typeof HubInvite>;

export const ContentVideo = Record({
  videoId: String,
  title: String,
  description: String,
  state: RTContentVideoState,
  stateModiefiedAt: String,
  createdAt: String,
  extension: String,
  type: RTContentVideoType,
  jobId: Union(String, Null),
  spaceId: String,
});
export type ContentVideo = Static<typeof ContentVideo>;

export const Course = Record({
  courseId: String,
  title: String,
  description: String,
  videos: RTArray(String), // ordered list of video IDs
  createdAt: String,
  spaceId: String,
});
export type Course = Static<typeof Course>;

export const InterviewBank = Record({
  bankId: String,
  name: String,
  description: Optional(String),
  interviewQuestions: RTArray(String),
  createdDate: Optional(String),
  createdByEmail: Optional(String),
  modifiedDate: Optional(String),
  usage: Optional(Number),
  spaceId: String,
});
export type InterviewBank = Static<typeof InterviewBank>;
