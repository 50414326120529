// Components
// import { db } from "lib-fullstack";
// import { useQuery as useApiQuery } from "@tanstack/react-query";
import { CircularProgress, Box, Grid, Typography } from "@mui/material";

// Utils
// import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { OrgMemberResponse } from "lib-fullstack/api/orgApiTypes";

type OverallStatsProps = {
  user: OrgMemberResponse;
};

// const getOverallStatsQueryKey = (user_id: string) => [
//   "orgUserDashboardSpeechesQueryKey",
//   getSiteId(),
//   user_id,
// ];

export const OverallStats = ({ user }: OverallStatsProps): JSX.Element => {
  // const speechesQuery = useApiQuery({
  //   queryKey: getOverallStatsQueryKey(user.user_id),
  //   queryFn: async () => {
  //     // const speeches = await db.query<db.Speech>(db.userSpeeches([getSiteId(), user.user_id]), [
  //     //   db.where("lifecycleState", "in", [db.LifecycleState.CREATED, db.LifecycleState.REDACTED]),
  //     // ]);
  //     const speeches = [];
  //     return speeches || [];
  //   },
  //   enabled: !!user?.user_id, // Only run query when we have a user ID
  // });

  // let totalMinutes = speechesQuery.data?.reduce(
  //   (acc, speech) => acc + (speech.data.totalTimeS || 0),
  //   0
  // );
  // if (isNaN(totalMinutes)) {
  //   totalMinutes = undefined;
  // }
  const renderStatCard = (title: string, value: number | string) => {
    return (
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            p: 2.5,
            borderRadius: "4px",
            backgroundColor: getDynamicColor("light2"),
            minHeight: 94,
          }}
        >
          <Typography
            sx={{
              textTransform: "uppercase",
              fontSize: 12,
              fontWeight: 400,
            }}
          >
            {title}
          </Typography>
          {value !== undefined ? (
            <Typography
              sx={{
                fontSize: 24,
                fontWeight: 600,
              }}
            >
              {value}
            </Typography>
          ) : (
            <CircularProgress
              size={24}
              sx={{
                position: "relative",
                top: 8,
              }}
            />
          )}
        </Box>
      </Grid>
    );
  };
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      sx={{
        width: "100%",
        maxWidth: "unset",
      }}
    >
      {renderStatCard("Started speeches", user?.num_started_speeches)}
      {/* TODO @dwiegand: Revisit in a bit once decision is made on scalability of below calcs
          context in spec: https://docs.google.com/document/d/1zaIYems-sZsAHH4-mIvc6YwCaNJgFjsbJ9kDlEYOOuM/edit?tab=t.0
          Tracked by: https://github.com/Yoodli/yoodli/issues/15216
      {renderStatCard("Completed speeches", speechesQuery?.data?.length)}
      {renderStatCard(
        "Minutes recorded",
        speechesQuery.isLoading || totalMinutes === undefined ? undefined : Math.round(totalMinutes)
      )} */}
    </Grid>
  );
};
