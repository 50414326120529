// Components
import { Button, Stack, Typography } from "@mui/material";

// Utils
import { MemberProgramItem } from "./MemberProgramItem";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { ProgramMemberViewItem } from "lib-fullstack/api/programApiTypes";
import { ProgramRecordState, UITestId } from "lib-fullstack/utils/enums";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router";
import { navigateToProgramPractice } from "lib-frontend/utils/programUtils";
import { listScenariosMemberView } from "lib-frontend/modules/AxiosInstance";
import { OrgProgramsQueryKeys, ScenarioSubQueryKeys } from "./OrgPrograms";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
type MemberProgramLibraryProps = {
  programs: ProgramMemberViewItem[];
  handleViewProgram: (programId: string) => void;
};

export const MemberProgramLibrary = ({
  programs,
  handleViewProgram,
}: MemberProgramLibraryProps): JSX.Element => {
  const { defaultOrgId } = React.useContext(UserOrgContext);

  const navigate = useNavigate();
  const scenariosQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.ScenarioList, defaultOrgId, ScenarioSubQueryKeys.Member],
    queryFn: () => listScenariosMemberView(defaultOrgId),
    enabled: !!defaultOrgId,
  });

  const activePrograms = React.useMemo(() => {
    return programs
      .filter((program) => program.state !== ProgramRecordState.Completed)
      .sort((a, b) => {
        // First sort by state - NotStarted comes first
        if (a.state === ProgramRecordState.NotStarted && b.state !== ProgramRecordState.NotStarted)
          return -1;
        if (a.state !== ProgramRecordState.NotStarted && b.state === ProgramRecordState.NotStarted)
          return 1;

        // Then sort by lastProgressDate
        if (!a.last_progress_date && !b.last_progress_date) return 0;
        if (!a.last_progress_date) return 1;
        if (!b.last_progress_date) return -1;
        return new Date(b.last_progress_date).getTime() - new Date(a.last_progress_date).getTime();
      });
  }, [programs]);

  const completedPrograms = React.useMemo(() => {
    return programs.filter((program) => program.state === ProgramRecordState.Completed);
  }, [programs]);

  return (
    <Stack gap={3} sx={{ py: { xs: 5, md: 7 }, px: { xs: 2, md: 7 } }}>
      {programs?.length ? (
        <Stack gap={{ xs: 5, md: 7 }}>
          <Stack gap={3}>
            {activePrograms.map((program) => (
              <MemberProgramItem
                key={program.id}
                program={program}
                cta={
                  <Stack direction="row" gap={2}>
                    <Button
                      data-testid={`${UITestId.ProgramCarouselCardPracticeButton}-${program.id}`}
                      variant="outlined"
                      onClick={() =>
                        navigateToProgramPractice(
                          defaultOrgId,
                          program,
                          scenariosQuery.data?.contentArray ?? [],
                          navigate,
                        )
                      }
                      sx={{ fontSize: "14px" }}
                    >
                      {program.state === ProgramRecordState.NotStarted ? "Start" : "Continue"}
                    </Button>
                    <Button
                      data-testid={`${UITestId.MyLearningProgramCardButton}-${program.id}`}
                      onClick={() => handleViewProgram(program.id)}
                      sx={{ fontSize: "14px" }}
                    >
                      View program
                    </Button>
                  </Stack>
                }
              />
            ))}
          </Stack>
          {completedPrograms.length > 0 && (
            <Stack gap={3}>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: "18px",
                  fontWeight: 700,
                  color: getDynamicColor("purple3"),
                }}
              >
                Completed
              </Typography>
              {completedPrograms.map((program) => (
                <MemberProgramItem
                  key={program.id}
                  program={program}
                  cta={
                    <Button
                      disableRipple
                      data-testid={`${UITestId.MyLearningProgramCardButton}-${program.id}`}
                      onClick={() => handleViewProgram(program.id)}
                      sx={{ fontSize: "14px", p: 0 }}
                    >
                      View program
                    </Button>
                  }
                />
              ))}
            </Stack>
          )}
        </Stack>
      ) : (
        <Typography
          sx={{
            fontFamily: "poppins",
            fontSize: "14px",
            color: getDynamicColor("dark4"),
            fontWeight: 600,
          }}
        >
          You are not currently enrolled in any programs
        </Typography>
      )}
    </Stack>
  );
};
