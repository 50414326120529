import { db } from "lib-fullstack";

// Assets
import { ReactComponent as ConversationIcon } from "images/icons/icon-conversation.svg";
import { ReactComponent as InterviewIcon } from "images/icons/icon-interview.svg";
import { ReactComponent as SpeechIcon } from "images/icons/icon-presentation.svg";

// Utils
import { PracticeTypeId } from "lib-frontend/utils/practiceRecorderUtils";
import { toTitleCase } from "lib-frontend/utils/Utilities";
import { ConversationAIPersona, InterviewAIPersona, SpeechAIPersona } from "lib-fullstack/db";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { PersonaMemberViewResponse } from "lib-fullstack/api/scenarioApiTypes";

export enum PersonaCopyMap {
  INTERVIEW = "Interviewer",
  SPEECH = "Audience",
  CONVERSATION = "Personality",
}

export type UIPersonaGroup = {
  name: string;
  personas: PersonaMemberViewResponse[];
};

export type PersonaOption = {
  value: db.AIPersona;
  label: string;
  // sometimes we want to set a value but dont want something to show up in the options dropdown
  // but with MUI only things that exist in the options array are allowed as values, so we just hide them on the FE in that case
  hidden?: boolean;
};
export type Persona = {
  label: PersonaCopyMap;
  infoCopy?: string;
  options: PersonaOption[];
};

export type PracticeTypeInstructions = {
  title: {
    ftux: string;
    default: string;
  };
  body: {
    ftux: string;
    default: string;
  };
};

export type PracticeType = {
  label: string;
  name: string;
  id: PracticeTypeId;
  icon?: JSX.Element;
  description: string;
  instructions?: PracticeTypeInstructions;
  mobileDescription?: string;
  path: string;
  persona: Persona;
};

export const PracticeTypes: PracticeType[] = [
  {
    label: "Interview practice",
    name: "interview",
    id: PracticeTypeId.INTERVIEW,
    icon: <InterviewIcon />,
    instructions: {
      title: {
        ftux: "Welcome {DISPLAY_NAME}! 👋",
        default: "Hi {DISPLAY_NAME}! 👋 Let’s continue your learning journey",
      },
      body: {
        ftux: "Type any role and company or select from the dropdowns, and select your Interviewer type. Edit the starter questions below, or add your own!",
        default:
          "For today’s session, please select at least 2 starter questions. After each answer, 1 additional question will be generated based on your response.",
      },
    },
    description: "Let's get your dream job!",
    path: WebServerExternalPath.PRACTICE_INTERVIEW,
    persona: {
      label: PersonaCopyMap.INTERVIEW,
      infoCopy: "Yoodli will interview you as your selected interviewer type",
      options: InterviewAIPersona.alternatives
        .filter((x) => x.value !== "ftux")
        .map((persona) => ({
          value: persona.value,
          label: toTitleCase(persona.value.replace(/_/g, " ")),
        })),
    },
  },
  {
    label: "Presentation practice",
    name: "presentation",
    id: PracticeTypeId.SPEECH,
    icon: <SpeechIcon />,
    instructions: {
      title: {
        ftux: "Welcome {DISPLAY_NAME}! 👋",
        default: "Hi {DISPLAY_NAME}! 👋 Let’s continue your learning journey",
      },
      body: {
        ftux: "Get instant feedback for your next presentation",
        default: "Get instant feedback for your next presentation",
      },
    },
    description: "Wow your audience",
    path: WebServerExternalPath.PRACTICE_SPEECH,
    persona: {
      label: PersonaCopyMap.SPEECH,
      infoCopy: "Yoodli will respond with follow-up questions as your selected audience",
      options: SpeechAIPersona.alternatives.map((persona) => {
        return {
          value: persona.value,
          label: toTitleCase(persona.value.replace(/_/g, " ")),
        };
      }),
    },
  },
  {
    label: "Roleplay practice",
    name: "roleplay",
    id: PracticeTypeId.CONVERSATION,
    icon: <ConversationIcon />,
    instructions: {
      title: {
        ftux: "Welcome {DISPLAY_NAME}! 👋",
        default: "Hi {DISPLAY_NAME}! 👋 Let’s continue your learning journey",
      },
      body: {
        ftux: "Choose from the list of scenarios or type any topic and personalize your conversation",
        default:
          "Choose from the list of scenarios or type any topic and personalize your conversation",
      },
    },
    description: "Warm up your conversational muscle",
    path: WebServerExternalPath.PRACTICE_CONVERSATION,
    persona: {
      label: PersonaCopyMap.CONVERSATION,
      infoCopy: "Yoodli will converse in the tone of your selected personality",
      options: ConversationAIPersona.alternatives.map((persona) => ({
        value: persona.value,
        label: toTitleCase(persona.value.replace(/_/g, " ")),
      })),
    },
  },
];
