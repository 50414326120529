import parse from "html-react-parser";
import React from "react";
import { DragHandleProps } from "react-beautiful-dnd";

// Components
import {
  DragIndicator as DragIndicatorIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  Stack,
  Typography,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";
import { TEMPLATE_SUB_TYPE_DATA } from "components/ConvoScenarios/convoScenarioUtils";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";

// Assets
import { ReactComponent as DownloadIcon } from "images/icons/icon-download.svg";
import { ReactComponent as RubricIcon } from "images/icons/icon-scale.svg";
import { ReactComponent as ScenarioIcon } from "images/icons/icon-scenario.svg";
import { ReactComponent as TrophyIcon } from "images/icons/icon-star-trophy.svg";

// Utils
import { getDownloadableReport, getReportProcessing } from "../Reports/reportsUtils";
import { EditProgramStep } from "./EditProgramStep";
import { OrgProgramsQueryKeys } from "./OrgPrograms";
import { ProgramDetails } from "./ProgramDetails";
import { useQuery as useApiQuery, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { usePrevious } from "lib-frontend/hooks";
import { createReport, listReports } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getScenarioPracticePath } from "lib-frontend/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { useIsMediumScreen } from "lib-frontend/utils/themeUtils";
import { copyToMyClipboard } from "lib-frontend/utils/Utilities";
import { GetScenarioResponse } from "lib-fullstack/api/scenarioApiTypes";
import { ProgramResponse, ProgramScenarioStep } from "lib-fullstack/api/programApiTypes";
import { GoalItemWithUsage } from "lib-fullstack/api/scenarioApiTypes";
import { getHoursToDate, getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { GoalLabels } from "lib-fullstack/utils/defaultGoals";
import { ProgramState, ReportParameter, ReportState, ReportType } from "lib-fullstack/utils/enums";
import { isEqualDistribution } from "lib-fullstack/utils/isEqualWeightDistribution";
import { scenarioTypeMap } from "lib-fullstack/utils/orgUtils";
import { OrgReportWheres } from "lib-fullstack/utils/productAnalyticEvents";

type ProgramStepProps = {
  step: ProgramScenarioStep;
  program: ProgramResponse;
  scenarios: GetScenarioResponse[];
  customGoals: GoalItemWithUsage[];
  disableAction: boolean;
  programState: ProgramState;
  handleRemoveStep: () => void;
  handleEditStep: () => void;
  handleSaveEdits: (newStep: ProgramScenarioStep) => void;
  handleCopyPracticeLink: () => void;
  dragHandleProps: DragHandleProps;
  showLine: boolean;
  stepIndex: number;
  programId: string;
};

export const ProgramStep = ({
  step,
  program,
  scenarios,
  customGoals,
  disableAction,
  programState,
  handleRemoveStep,
  handleEditStep,
  handleSaveEdits,
  handleCopyPracticeLink,
  dragHandleProps,
  showLine,
  stepIndex,
  programId,
}: ProgramStepProps): JSX.Element => {
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const queryClient = useQueryClient();
  const isMediumScreen = useIsMediumScreen();

  const programScenario = React.useMemo(
    () => scenarios.find((s) => s.id === step.scenario_id),
    [scenarios],
  );

  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const reportsQuery = useApiQuery({
    queryKey: [`${OrgProgramsQueryKeys.ProgramStep}${stepIndex}`, defaultOrgId, programId],
    queryFn: async () =>
      await listReports(defaultOrgId, {
        reportType: ReportType.ProgramScenarioStep,
        states: [ReportState.Created, ReportState.Rendered, ReportState.Expired],
        parameters: JSON.stringify(getRequestParams()),
      }),
    refetchInterval: isProcessing ? 2000 : 0,
    refetchOnWindowFocus: false,
  });

  const prevIsProcessing = usePrevious(isProcessing);
  React.useEffect(() => {
    const newIsProcessing = !!getReportProcessing(reportsQuery?.data);
    setIsProcessing(newIsProcessing);
    // if we moved from processing to not processing, automatically download the new report
    if (prevIsProcessing && !newIsProcessing) {
      // void this function because since we are providing a downloadUrl, it skips any async code
      void handleReportCtaClick(getDownloadableReport(reportsQuery?.data)?.downloadUrl);
    }
  }, [reportsQuery?.data]);

  const stepDetails = [
    {
      icon: <ScenarioIcon fill={getDynamicColor("purple3")} />,
      text: TEMPLATE_SUB_TYPE_DATA[programScenario.templateSubType]?.label,
    },
    {
      icon: (
        <Box
          sx={{
            svg: {
              "path, rect": {
                stroke: getDynamicColor("purple3"),
              },
              width: 20,
              height: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          <TrophyIcon />
        </Box>
      ),
      text: `${step.completion_score ? `${step.completion_score}%` : ""}${
        step.completion_score && step.completion_attempts ? " or " : ""
      }${
        step.completion_attempts
          ? `${step.completion_attempts} attempt${step.completion_attempts !== 1 ? "s" : ""}`
          : ""
      }${step.completion_human_evaluation ? ` and grading` : ""}`.trim(),
    },
    !isEqualDistribution(programScenario?.goalWeights) && {
      icon: <RubricIcon />,
      text: "Weighted rubric",
    },
  ].filter(Boolean);

  const getScenarioGoals = () => {
    return programScenario.goalIds
      .map((goalId) => {
        if (Object.keys(GoalLabels).includes(goalId)) {
          return GoalLabels[goalId];
        } else {
          return customGoals.find((goal) => goal.id === goalId)?.name;
        }
      })
      .join(", ");
  };

  const getRequestParams = () => {
    return {
      [ReportParameter.ProgramId]: programId,
      [ReportParameter.ProgramPlanStepIndex]: stepIndex,
      [ReportParameter.OrgId]: defaultOrgId,
    };
  };

  const handleReportCtaClick = async (downloadUrl?: string) => {
    if (downloadUrl) {
      // download report
      window.location.assign(downloadUrl);
    } else {
      await createReport(defaultOrgId, {
        type: ReportType.ProgramScenarioStep,
        parameters: JSON.stringify(getRequestParams()),
      });
      Instrumentation.logOrgReportGenerated(defaultOrgId, OrgReportWheres.PROGRAM);

      await queryClient.invalidateQueries({
        queryKey: [`${OrgProgramsQueryKeys.ProgramStep}${stepIndex}`],
      });
    }
  };

  const renderRequestedByCopy = () => {
    const mostRecentReportRendered =
      reportsQuery?.data?.reports[0]?.state === ReportState.Rendered &&
      !!reportsQuery?.data?.reports[0]?.renderDate;
    const mostRecentReportRequested =
      reportsQuery?.data?.reports[0]?.state === ReportState.Created &&
      !reportsQuery?.data?.reports[0]?.renderDate;
    if (
      (!mostRecentReportRendered && !mostRecentReportRequested) ||
      programState === ProgramState.Draft
    ) {
      return null;
    }
    return (
      <Typography
        sx={{
          color: getDynamicColor("dark5"),
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Requested on{" "}
        {getHumanReadableDate(
          reportsQuery?.data?.reports[0]?.creationDate ?? new Date().toISOString(),
          {
            month: "numeric",
            day: "numeric",
            year: "2-digit",
          },
        )}{" "}
        by {reportsQuery?.data?.reports[0]?.requestorDisplayName}
      </Typography>
    );
  };

  const renderReportsCta = () => {
    // is the step is in draft, return null
    if (programState === ProgramState.Draft) {
      return null;
    }
    let buttonText = "Download report";
    let buttonIcon = <DownloadIcon />;
    let color = getDynamicColor("primary");
    const downloadableReport = getDownloadableReport(reportsQuery?.data);
    const reportProcessing = getReportProcessing(reportsQuery?.data);
    if (reportProcessing) {
      buttonText = "Report processing";
      buttonIcon = (
        <Box
          sx={{
            position: "relative",
            height: 16,
            width: 16,
          }}
        >
          <CircularProgress
            size={14}
            thickness={6}
            sx={{
              position: "absolute",
              zIndex: 1,
              color: getDynamicColor("primary"),
              top: 0,
              left: 0,
            }}
          />
          <CircularProgress
            size={14}
            thickness={6}
            variant="determinate"
            value={100}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 0,
              color: getDynamicColor("purple1"),
            }}
          />
        </Box>
      );
      color = getDynamicColor("dark5");
    }

    return (
      <Button
        sx={{
          p: 0,
          fontSize: 12,
          whiteSpace: "nowrap",
          color,
          pointerEvents: reportProcessing ? "none" : "auto",
          mt: isMediumScreen ? 1 : 0,
        }}
        disableRipple
        variant="text"
        startIcon={buttonIcon}
        onClick={() => handleReportCtaClick(downloadableReport?.downloadUrl)}
      >
        {buttonText}
      </Button>
    );
  };

  if (isEditing) {
    return (
      <Box sx={{ mt: 1 }}>
        <EditProgramStep
          isAddingScenario={false}
          programState={programState}
          scenarios={scenarios}
          customGoals={customGoals}
          handleRemoveScenario={handleRemoveStep}
          handleSaveScenario={(step) => {
            handleSaveEdits(step);
            setIsEditing(false);
          }}
          stepToEdit={step}
          blockStepRemoval={
            programState === ProgramState.Published && program?.plan_steps?.length === 1
          }
        />
      </Box>
    );
  }

  const renderReadyToDownloadCopy = () => {
    const downloadableReport = getDownloadableReport(reportsQuery?.data);

    if (!downloadableReport || programState === ProgramState.Draft) {
      return null;
    }
    return (
      <Typography
        sx={{
          color: getDynamicColor("greenSuccess"),
          fontSize: 12,
          fontWeight: 400,
        }}
      >
        Ready to download, expires in {getHoursToDate(downloadableReport.expirationDate)} hour
        {getHoursToDate(downloadableReport.expirationDate) === 1 ? "" : "s"}
      </Typography>
    );
  };

  return (
    <>
      <Stack
        sx={{
          position: "relative",
          zIndex: 2,
          borderRadius: "8px",
          border: `1px solid ${getDynamicColor("dark3")}`,
          pr: { xs: 2, md: 3 },
          pl: disableAction ? { xs: 2, md: 3 } : 5,
          pt: { xs: 1, md: 2 },
          pb: { xs: 2, md: 3 },
          backgroundColor: getDynamicColor("light1"),
        }}
      >
        {!disableAction && (
          <Box
            sx={{
              position: "absolute",
              top: 32,
              left: 8,
              display: "block",
            }}
            {...dragHandleProps}
          >
            <DragIndicatorIcon sx={{ color: getDynamicColor("primary") }} />
          </Box>
        )}
        <Stack gap={1}>
          <Stack
            direction="row"
            sx={{
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Stack columnGap={1} rowGap={0}>
              <Stack
                columnGap={2}
                rowGap={2}
                direction="row"
                alignItems="center"
                flexWrap="wrap"
                sx={{
                  height: 40,
                }}
              >
                <Typography
                  sx={{
                    color: getDynamicColor("purple3"),
                    fontFamily: "poppins",
                    fontSize: { xs: "16px", md: "18px" },
                    fontWeight: 700,
                  }}
                >
                  {programScenario.title}
                </Typography>
                {!isMediumScreen && renderReadyToDownloadCopy()}
              </Stack>
              {!isMediumScreen && renderRequestedByCopy()}
            </Stack>
            <Stack gap={{ xs: 0, md: 2 }} direction="row" sx={{ alignItems: "center" }}>
              {programState !== ProgramState.Draft && !isMediumScreen && renderReportsCta()}
              {programState !== ProgramState.Archived ? (
                <YoodliMenu
                  type={YoodliMenuButtonType.Icon}
                  buttonSx={{ border: "none" }}
                  menuItems={[
                    {
                      title: "Edit step",
                      onClick: () => {
                        handleEditStep();
                        setIsEditing(true);
                      },
                      type: YoodliMenuItemType.Default,
                      disabled: disableAction,
                    },
                    {
                      title: "Copy practice link",
                      onClick: () => {
                        copyToMyClipboard(
                          getScenarioPracticePath(
                            programScenario.id,
                            defaultOrgId,
                            true,
                            scenarioTypeMap[programScenario.scenarioTypeId],
                          ),
                        );
                        handleCopyPracticeLink();
                      },
                      type: YoodliMenuItemType.Default,
                      disabled: disableAction,
                    },
                    {
                      title: "Remove",
                      onClick: handleRemoveStep,
                      type: YoodliMenuItemType.Warning,
                      disabled:
                        disableAction ||
                        (programState === ProgramState.Published &&
                          program?.plan_steps?.length === 1),
                      disabledTooltip:
                        programState === ProgramState.Published && program?.plan_steps?.length === 1
                          ? "You cannot remove the last step in a published program."
                          : "",
                    },
                  ]}
                />
              ) : (
                !isMediumScreen && renderReportsCta()
              )}
              <IconButton
                onClick={() => setExpanded(!expanded)}
                sx={{
                  color: getDynamicColor("primary"),
                }}
              >
                {
                  <ExpandLessIcon
                    sx={{
                      transform: expanded ? "rotate(0deg)" : "rotate(180deg)",
                      transition: "transform 0.2s ease-out",
                    }}
                  />
                }
              </IconButton>
            </Stack>
          </Stack>
          <ProgramDetails programDetails={stepDetails} />
          {isMediumScreen && (
            <Stack gap={1}>
              {renderReportsCta()}
              {renderReadyToDownloadCopy()}
              {renderRequestedByCopy()}
            </Stack>
          )}
        </Stack>
        <Collapse in={expanded}>
          <Stack
            gap={1}
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontSize: "14px",
              pt: 2,
            }}
          >
            {programScenario.persona && (
              <Typography>
                <strong>Default persona: </strong>
                {programScenario.persona.name}, {programScenario.persona.job_title} (
                {programScenario.persona.demeanor})
              </Typography>
            )}
            <Typography>
              <strong>Goals: </strong>
              {programScenario.goalIds.length > 0 ? getScenarioGoals() : "None"}
            </Typography>
            {programScenario.goalWeights && !isEqualDistribution(programScenario.goalWeights) && (
              <Stack
                direction="row"
                sx={{
                  gap: 1,
                  alignItems: "center",
                  fontSize: "10px",
                  fontWeight: 600,
                }}
              >
                <RubricIcon />
                <Typography>Custom weighted rubric</Typography>
              </Stack>
            )}
            <Typography component="div">
              <strong>Description: </strong>
              {parse(programScenario.description)}
            </Typography>
          </Stack>
        </Collapse>
      </Stack>
      {showLine && (
        <Box
          sx={{
            height: "35px",
            borderLeft: `2px solid ${getDynamicColor("dark3")}`,
            ml: 2,
            position: "relative",
            zIndex: 0,
          }}
        />
      )}
    </>
  );
};
