import React from "react";
// Components
import {
  CheckCircleRounded as CheckCircleRoundedIcon,
  MoreVert as MoreVertIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Stack,
  Typography,
  Avatar,
  Box,
} from "@mui/material";
import parse from "html-react-parser";
// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { GetScenarioMemberViewResponse } from "lib-fullstack/api/scenarioApiTypes";
import { PersonaMemberViewResponse } from "lib-fullstack/api/scenarioApiTypes";
import { ScenarioTemplateSubType } from "lib-fullstack/db";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { TemplateSubTypeChip } from "components/ConvoScenarios/TemplateSubTypeChip";
import {
  CustomizePracticeTabEnum,
  getScenarioPracticePath,
  OrgSettingsTabs,
} from "lib-frontend/utils/orgUtils";
import { copyToMyClipboard } from "lib-frontend/utils/Utilities";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import IconMenu from "lib-frontend/components/IconMenu";
import { scenarioTypeMap } from "lib-fullstack/utils/orgUtils";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { WebServerInternalPath } from "utils/paths";
import { useNavigate } from "react-router";
import { OrgContentQueryParams } from "lib-fullstack/utils/queryParams";
import { ScenarioType } from "lib-fullstack/utils/enums";
type CustomScenarioCardProps = {
  scenario: GetScenarioMemberViewResponse;
  selectedOrgId: string;
  persona?: PersonaMemberViewResponse;
  personaLoading?: boolean;
};

export const ReadOnlyScenarioCard = ({
  scenario,
  persona,
  personaLoading,
}: CustomScenarioCardProps): JSX.Element => {
  const { spaces, selectSpace } = React.useContext(ContentSpacesContext);
  const navigate = useNavigate();
  const [descriptionOpen, setDescriptionOpen] = React.useState(false);
  const [practiceLinkCopied, setPracticeLinkCopied] = React.useState(false);
  const practiceLinkCopiedTimeout = React.useRef<NodeJS.Timeout>(null);
  const { defaultOrgId, effectiveRole } = React.useContext(UserOrgContext);
  const isSmallScreen = useIsSmallScreen();
  const contentSpace = React.useMemo(() => {
    return spaces.find((space) => space.id === scenario.spaceId);
  }, [spaces, scenario, effectiveRole]);

  const goToManageContentSpace = async () => {
    const scenarioType = scenarioTypeMap[scenario.scenarioTypeId];

    const qp = new URLSearchParams({
      [OrgContentQueryParams.TAB]: OrgSettingsTabs.CUSTOMIZE_PRACTICE,
    });

    switch (scenarioType) {
      case ScenarioType.Interview:
        qp.set(OrgContentQueryParams.SUBTAB, CustomizePracticeTabEnum.InterviewScenarios);
        break;
      case ScenarioType.Roleplay:
      default:
        break;
    }

    await selectSpace(contentSpace.id);
    navigate({
      pathname: WebServerInternalPath.ORG_CONTENT,
      search: qp.toString(),
    });
  };

  const renderPersona = () => {
    if (personaLoading) {
      return <CircularProgress size={16} />;
    } else if (persona) {
      return (
        <Stack direction="row" gap={0.5} alignItems="center">
          <Avatar
            alt={persona?.name}
            src={persona?.profile_picture_signed_url}
            key={persona?.profile_picture_signed_url}
            sx={{
              height: 20,
              width: 20,
            }}
          />
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 500,
              fontFamily: "poppins",
            }}
          >
            {persona?.name}
          </Typography>
        </Stack>
      );
    }
    return null;
  };

  const renderDescriptionText = () => {
    return (
      <Stack
        direction="row"
        gap={1}
        alignItems="flex-end"
        sx={{
          fontSize: 12,
          fontWeight: 400,
          lineHeight: 1.4,
        }}
      >
        <Box
          sx={{
            "> *": {
              margin: "0px !important",
              width: "fit-content",
              wordBreak: { xs: "break-word", md: "unset" },
            },
          }}
        >
          {parse(scenario.description)}
        </Box>
      </Stack>
    );
  };

  const renderScenarioOptionsMenu = () => {
    const menuItems = [
      {
        title: practiceLinkCopied ? (
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography>Link copied</Typography>
            <CheckCircleRoundedIcon
              sx={{
                color: getDynamicColor("greenSuccess"),
                height: 20,
                width: 20,
              }}
            />
          </Stack>
        ) : (
          "Copy practice link"
        ),
        onClick: () => {
          if (practiceLinkCopiedTimeout.current) {
            clearTimeout(practiceLinkCopiedTimeout.current);
          }

          copyToMyClipboard(
            getScenarioPracticePath(
              scenario.id,
              defaultOrgId,
              true,
              scenarioTypeMap[scenario.scenarioTypeId],
            ),
          );
          setPracticeLinkCopied(true);
          practiceLinkCopiedTimeout.current = setTimeout(() => {
            setPracticeLinkCopied(false);
          }, 3000);
        },
        disableCloseOnClick: true,
      },
      isSmallScreen && {
        title: `Manage in ${contentSpace?.name}`,
        onClick: async () => {
          await goToManageContentSpace();
        },
        disableCloseOnClick: true,
      },
    ].filter(Boolean);
    return (
      <IconMenu
        title="Manage File"
        hideCaret
        hideTooltip
        disableDrag
        menuItems={menuItems}
        iconButtonSx={{
          height: 24,
          width: 24,
          svg: {
            color: getDynamicColor("primary"),
          },
        }}
        menuItemSx={{
          fontSize: 14,
          fontWeight: 600,
          fontFamily: "poppins",
          px: 2,
          py: 1.5,
          color: getDynamicColor("primary"),
        }}
        minWidth={"140px"}
        paperSx={{
          borderRadius: "12px",
          minWidth: 190,
        }}
        icon={<MoreVertIcon />}
      />
    );
  };

  return (
    <Stack
      alignItems="flex-start"
      justifyContent="space-between"
      direction={{ xs: "column", sm: "row" }}
      gap={1}
      sx={{
        fontFamily: "poppins",
        p: { xs: 1.5, md: 2.5 },
        border: `1px solid ${getDynamicColor("dark3")}`,
        borderRadius: "8px",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={1}
        sx={{
          width: "100%",
          flexGrow: 1,
        }}
      >
        <Stack
          gap={1}
          sx={{
            flexGrow: 1,
            flexWrap: "wrap",
            width: "calc(100% - 36px)",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            sx={{
              maxWidth: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                lineHeight: 1.3,
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              {scenario.title}
            </Typography>
            <Stack direction="row" sx={{ alignItems: "center", gap: 2 }}>
              {!isSmallScreen && contentSpace && (
                <Typography
                  component="div"
                  fontSize={14}
                  sx={{ color: getDynamicColor("primary"), cursor: "pointer" }}
                  onClick={async () => {
                    await goToManageContentSpace();
                  }}
                >
                  Manage in{" "}
                  <Typography component="span" sx={{ fontWeight: 700 }}>
                    {contentSpace.name}
                  </Typography>
                </Typography>
              )}
              {renderScenarioOptionsMenu()}
            </Stack>
          </Stack>
          {scenario.description?.length > 160 ? (
            <Accordion
              sx={{
                boxShadow: "none",
                fontSize: 12,
                my: "0px !important",
                color: getDynamicColor("purple3"),
                "::before": {
                  display: "none !important",
                  position: "relative",
                },
              }}
              onChange={(_, expanded) => {
                setDescriptionOpen(expanded);
              }}
            >
              <AccordionSummary
                sx={{
                  my: 0,
                  px: 0,
                  width: "fit-content",
                  minHeight: "unset !important",
                  ".MuiAccordionSummary-content, .Mui-expanded": {
                    my: "0px !important",
                    mr: 0.5,
                  },
                  "&::before": {
                    display: "none",
                  },
                }}
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: getDynamicColor("primary"),
                      height: 20,
                      width: 20,
                    }}
                  />
                }
              >
                {descriptionOpen ? "Hide" : "Show"} description
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  lineHeight: 1.4,
                  pb: "8px !important",
                }}
              >
                {renderDescriptionText()}
              </AccordionDetails>
            </Accordion>
          ) : scenario.description?.length > 0 ? (
            renderDescriptionText()
          ) : null}
          <Stack
            direction="row"
            columnGap={2}
            rowGap={1}
            alignItems="center"
            sx={{
              flexWrap: "wrap",
            }}
          >
            <TemplateSubTypeChip
              templateSubType={scenario.templateSubType as ScenarioTemplateSubType}
            />
            <Typography
              sx={{
                fontSize: 12,
                color: getDynamicColor("dark4"),
              }}
            >
              Created by: {scenario.createdByEmail} ({getHumanReadableDate(scenario.createdAt)})
            </Typography>
            {renderPersona()}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
