import { getDynamicColor } from "lib-frontend/utils/Colors";
import React from "react";
import { Stack, Typography, Button, Box } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { ReactComponent as PDFIcon } from "images/icons/pdf-icon.svg";
import { parse as csvParse } from "csv-parse/sync";
import { isEmail } from "validator";

const MAX_EMAILS = 1000;

type CSVUploadDropzoneProps = {
  handleEmailsAdded: (emails: string[]) => void;
  error: string;
  setError: (error: string) => void;
};

export const CSVUploadDropzone = ({
  handleEmailsAdded,
  error,
  setError,
}: CSVUploadDropzoneProps): JSX.Element => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      await handleFileChange(acceptedFiles[0]);
    },
    accept: ".csv",
    maxFiles: 1,
  });

  const handleFileChange = async (file) => {
    if (!file) {
      setError("Please upload a CSV file");
      return;
    }
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target?.result;
      if (text) {
        const records: { email: string }[] = csvParse(text.toString(), {
          columns: ["email"],
          relaxColumnCount: true,
          bom: true,
          cast: true,
          skipEmptyLines: true,
          skipRecordsWithError: true,
          trim: true,
        });

        // Convert records to email array.
        // Filter out any invalid emails, and remove dupes using Set
        const filteredEmails = Array.from(
          new Set(records.map((record) => record.email ?? "").filter((email) => isEmail(email))),
        );

        if (filteredEmails.length > MAX_EMAILS) {
          setError(
            `You can only upload up to ${MAX_EMAILS} emails at a time, you uploaded ${records.length}`,
          );
          return;
        }

        if (filteredEmails.length === 0) {
          setError("No valid emails found in the CSV file provided");
        } else {
          setError(null);
          handleEmailsAdded(filteredEmails);
        }
      }
    };
    reader.readAsText(file);
  };

  return (
    <Stack
      alignItems="center"
      {...getRootProps()}
      onClick={undefined}
      sx={{
        borderRadius: "20px",
        border: `2px dashed ${getDynamicColor("dark3")}`,
        py: { xs: 10, md: 5.5 },
        width: "100%",
        transition: "all .5s ease",
      }}
    >
      <Stack
        gap={2}
        alignItems="center"
        sx={{
          transition: "all .7s ease",
        }}
      >
        <Box
          sx={{
            color: getDynamicColor("primary"),
            svg: {
              height: 36,
              width: 36,
              transition: "all .7s ease",
            },
          }}
        >
          <PDFIcon />
        </Box>
        <Typography
          sx={{
            color: getDynamicColor("dark4"),
            fontSize: 14,
            fontFamily: "poppins",
            display: { xs: "none", md: "block" },
            opacity: 1,
            transition:
              "margin .5s ease 0.2s, padding .5s ease 0.2s, height .5s ease 0.2s, opacity 0.4s ease",
            overflow: "visible",
          }}
        >
          Drag your CSV file here
        </Typography>
        {error ? (
          <Typography color={getDynamicColor("redError")} fontSize="14px" fontWeight={700}>
            {error}
          </Typography>
        ) : (
          <Typography
            sx={{
              color: getDynamicColor("dark4"),
              fontSize: 14,
              fontFamily: "poppins",
              display: { xs: "none", md: "block" },
            }}
          >
            or
          </Typography>
        )}

        <Button
          onClick={(e) => {
            getRootProps()?.onClick(e);
          }}
          variant="outlined"
          sx={{
            px: 4,
            height: 32,
            mt: 1,
            opacity: 1,
            border: `2px solid ${getDynamicColor("primary")}`,
            borderRadius: "50px",
            fontFamily: "poppins",
            fontSize: "10px",
            fontWeight: 700,
            width: { xs: "100%", md: "max-content" },
            transition:
              "margin .5s ease 0.2s, padding .5s ease 0.2s, height .5s ease 0.2s, opacity 0.4s ease, border .5s ease 0.2s",
          }}
        >
          Upload from device
        </Button>
      </Stack>
      <input multiple={false} ref={fileInputRef} style={{ display: "none" }} {...getInputProps()} />
    </Stack>
  );
};
