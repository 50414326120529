// Utils
import { getDynamicColor } from "./Colors";
import {
  getHubContentAdminViewV2,
  listHubUsersV2,
  listOrgInvitesV2,
  listOrgUsersV2,
} from "lib-frontend/modules/AxiosInstance";
import { HubV2Response, OrgV2Response } from "lib-fullstack/api/orgApiTypes";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { asyncMap } from "lib-fullstack/utils/asyncMap";
import { MONTH_IN_MS } from "lib-fullstack/utils/constants";
import {
  HubRole,
  EffectiveRole,
  OrgSubscriptionType,
  ScenarioType,
} from "lib-fullstack/utils/enums";
import { IntegrationTypeEnum } from "lib-fullstack/utils/enums/integrationType";
import { getWebServerExternalUrl, WebServerExternalPath } from "lib-fullstack/utils/paths";
import {
  MyLearningQueryParams,
  OrgInviteQueryParams,
  PracticeRecorderQueryParams,
} from "lib-fullstack/utils/queryParams";
import { saveAs } from "file-saver";
import { stringify as csvStringify } from "csv-stringify/sync";
import { CSV_STRINGIFY_DEFAULT_OPTIONS } from "lib-fullstack/utils/constants";

const LIST_HUB_CONCURRENCY = 5;
const MAX_LIST_HUB_RETRY = 1;

export const ORG_TABS_HEIGHT = 30;

export enum CustomizePracticeTabEnum {
  RoleplayScenarios = "roleplay_scenarios",
  Personas = "personas",
  CustomGoals = "custom_goals",
  InterviewScenarios = "interview_scenarios",
  QuestionBanks = "question_banks",
}

export enum MyLearningSection {
  Default = "Default",
  ProgramInfo = "ProgramInfo", // NOTE (2024-08-19): This is used as a query param for CIO email template CTA (see sendReminder in programHandlers.ts)
}

export enum OrgSettingsTabs {
  CUSTOMIZE_PRACTICE = "customize-practice",
  PROGRAMS = "programs",
  COACHBOT = "coachbot",
  FILES = "files",
  LEARNING_MATERIALS = "learning-materials",
  PERMISSIONS = "permissions",
}

export const OrgSettingsTabLabel = {
  [OrgSettingsTabs.CUSTOMIZE_PRACTICE]: "Customize Practice",
  [OrgSettingsTabs.COACHBOT]: "Coach Bot",
  [OrgSettingsTabs.FILES]: "Files",
  [OrgSettingsTabs.LEARNING_MATERIALS]: "Learning Materials",
  [OrgSettingsTabs.PERMISSIONS]: "Permissions",
  [OrgSettingsTabs.PROGRAMS]: "Programs",
};

export enum AvailableContentTabEnum {
  RoleplayScenarios = "roleplay_scenarios",
  InterviewScenarios = "interview_scenarios",
  LearningMaterials = "learning_materials",
  QuestionBanks = "question_banks",
}

export enum OrgIntegrationQueryKeys {
  ORG_INTEGRATIONS = "orgIntegrations",
  ORG_INTEGRATION_DETAILS = "orgIntegrationDetails",
  ORG_INTEGRATION_SECRETS = "orgIntegrationSecrets",
  ORG_INTEGRATION_CONTEXTS = "orgIntegrationContexts",
}

export enum TableTabLabel {
  Members = "members",
  Invites = "invites",
}

export const AvailableContentTabLabels = {
  [AvailableContentTabEnum.LearningMaterials]: "Learning Materials",
  [AvailableContentTabEnum.RoleplayScenarios]: "Roleplay Scenarios",
  [AvailableContentTabEnum.InterviewScenarios]: "Interview Scenarios",
  [AvailableContentTabEnum.QuestionBanks]: "Question Banks",
};

export const LtiIntegrations = [IntegrationTypeEnum.LTI_1_1, IntegrationTypeEnum.LTI_1_3];

export const getHubUserCount = async (orgId: string, hubId: string): Promise<number> => {
  return (
    await listHubUsersV2(orgId, hubId, {
      limit: "1",
    })
  ).total;
};

export const getHubInviteCount = async (orgId: string, hubId: string): Promise<number> => {
  return (await listOrgInvitesV2(orgId, { hub_id: hubId, limit: "1" })).total;
};

export const getOrgUserCount = async (orgId: string): Promise<number> => {
  return (await listOrgUsersV2(orgId, { limit: "1" })).total;
};

export const getOrgInviteCount = async (orgId: string): Promise<number> => {
  return (await listOrgInvitesV2(orgId, { limit: "1" })).total;
};

export type HubData = {
  id: string;
  name: string;
  isDefault: boolean;
  creationDate: string;
  numMembers: number;
  numCourses: number;
};

/**
 * Get hub data for an organization to display in org overview
 */
export const getHubDataForOrg = async (
  org: OrgV2Response,
  getHubContent = true,
): Promise<HubData[]> => {
  const response = [];
  await asyncMap(
    org.hubs,
    async (hub) => {
      // limit 1 because we just want the count
      const hubUsers = await listHubUsersV2(org.id, hub.id, { limit: "1" });
      let hubContent = null;
      if (getHubContent) {
        hubContent = await getHubContentAdminViewV2(org.id, hub.id);
      }
      response.push({
        id: hub.id,
        name: hub.name,
        isDefault: hub.org_default,
        creationDate: hub.creation_date,
        numMembers: hubUsers.total,
        numCourses: getHubContent ? hubContent.courses.length : undefined,
      });
    },
    LIST_HUB_CONCURRENCY,
    MAX_LIST_HUB_RETRY,
  );

  return response;
};

export const isOrgTrialEnded = (org?: OrgV2Response): boolean => {
  return org?.license_count === 0 && org?.subscription_type === OrgSubscriptionType.PAYG;
};

// return true if org is flexible and seats within a month of contract expiring, meaning the seats should not
// be allowed to be edited any further
export const isOrgFlexibleAndSeatsUneditable = (org?: OrgV2Response): boolean => {
  return (
    org?.subscription_type === OrgSubscriptionType.FLEXIBLE &&
    new Date(org?.cancellation_date).getTime() - Date.now() < MONTH_IN_MS
  );
};

export const isOrgOwnerAdmin = (org?: OrgV2Response): boolean => {
  return (
    org?.effective_role === EffectiveRole.ORG_OWNER ||
    org?.effective_role === EffectiveRole.ORG_ADMIN
  );
};

export const isHubAdmin = (hub?: HubV2Response): boolean => {
  return hub.hub_role === HubRole.ADMIN;
};

export const isUserMinimumEffectiveRole = (
  userRole: EffectiveRole,
  minRole: EffectiveRole,
): boolean => {
  if (!userRole || !minRole) {
    return false;
  }
  const effectiveRoleHierarchy: { [key in EffectiveRole]: number } = {
    [EffectiveRole.ORG_OWNER]: 4,
    [EffectiveRole.ORG_ADMIN]: 3,
    [EffectiveRole.SPACE_ADMIN]: 2,
    [EffectiveRole.HUB_ADMIN]: 1,
    [EffectiveRole.HUB_MEMBER]: 0,
  };
  return effectiveRoleHierarchy[userRole] >= effectiveRoleHierarchy[minRole];
};

export const parseOrgRole = (role?: EffectiveRole): string => {
  switch (role) {
    case EffectiveRole.ORG_OWNER:
      return "Org owner";
    case EffectiveRole.ORG_ADMIN:
      return "Org admin";
    case EffectiveRole.HUB_ADMIN:
      return "Hub admin";
    default:
      return "Member";
  }
};

export const parseHubRole = (role: HubRole): string => {
  switch (role) {
    case HubRole.ADMIN:
      return "Group admin";
    case HubRole.MEMBER:
    default:
      return "Member";
  }
};

export const getScenarioPracticePath = (
  scenarioId: string | null,
  defaultOrgId: string | null,
  returnFullPath: boolean,
  scenarioType: ScenarioType = ScenarioType.Roleplay,
): string => {
  let practiceLink = "";
  switch (scenarioType) {
    case ScenarioType.Roleplay:
      practiceLink = WebServerExternalPath.PRACTICE_CONVERSATION;
      break;
    case ScenarioType.Interview:
      practiceLink = WebServerExternalPath.PRACTICE_INTERVIEW;
      break;
  }
  if (returnFullPath) {
    practiceLink = getWebServerExternalUrl(
      getClientEnvConfig(),
      practiceLink as WebServerExternalPath,
    );
  }
  let url = practiceLink;
  if (scenarioId) {
    url += `?${PracticeRecorderQueryParams.SCENARIO}=${scenarioId}`;
    if (defaultOrgId !== null) {
      url += `&${OrgInviteQueryParams.OVERRIDE_ORG_ID}=${defaultOrgId}`;
    }
  }

  return url;
};

export const getProgramMemberPath = (programId: string, orgId: string): string => {
  const programLink = WebServerExternalPath.MY_LEARNING;
  const baseUrl = getWebServerExternalUrl(
    getClientEnvConfig(),
    programLink as WebServerExternalPath,
  );

  const params = new URLSearchParams({
    [MyLearningQueryParams.SECTION]: MyLearningSection.ProgramInfo,
    [MyLearningQueryParams.PROGRAM_ID]: programId,
    overrideOrgId: orgId || "",
  });

  return `${baseUrl}?${params.toString()}`;
};

export const getInterviewQuestionBankPracticePath = (
  bankId: string,
  defaultOrgId: string | null,
  returnFullPath: boolean,
): string => {
  let practiceLink = `${WebServerExternalPath.PRACTICE_INTERVIEW}`;

  if (returnFullPath) {
    practiceLink = getWebServerExternalUrl(
      getClientEnvConfig(),
      practiceLink as WebServerExternalPath,
    );
  }

  let url = `${practiceLink}?${PracticeRecorderQueryParams.QUESTION_BANK}=${bankId}`;
  if (defaultOrgId !== null) {
    url += `&${OrgInviteQueryParams.OVERRIDE_ORG_ID}=${defaultOrgId}`;
  }

  return url;
};

// returns a time difference string representing the time difference between the given date and the current date
// in the format of "1 day ago" or "3 min ago"
export const getTimeDifference = (isoString: string): string => {
  const givenDate = new Date(isoString);
  const givenDateTime = givenDate.getTime();
  const currentDate = new Date();
  const diffInMs = currentDate.getTime() - givenDateTime;

  const diffInSeconds = Math.floor(diffInMs / 1000);
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const diffInMonths = Math.floor(diffInDays / 30);

  if (isNaN(givenDateTime) || givenDateTime === 0) {
    return "Never";
  } else if (diffInSeconds < 60) {
    return "Less than a minute ago";
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
  } else if (diffInDays <= 100) {
    return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
  } else if (diffInMonths <= 11) {
    return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
  } else {
    return "Over a year ago";
  }
};

export const DraggableStyles = {
  display: "flex",
  flexDirection: "row",
  gap: 3,
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: 1.5,
  backgroundColor: getDynamicColor("light1"),
  border: `1px solid ${getDynamicColor("dark4")}`,
  padding: 2,
  minHeight: 60,
  svg: {
    color: getDynamicColor("primary"),
  },
  "> p": {
    width: "100%",
  },
  // use mb not gap here so the placeholder while dragging renders correctly
  mb: 1,
};

export enum ReportingTeamsQueryKeys {
  TeamsList = "TeamsList",
  Team = "Team",
  TeamMembersList = "TeamMembersList",
}

export const downloadSampleUserCSV = (): void => {
  const rows = [
    { email: "employee1@business.com" },
    { email: "employee2@business.com" },
    { email: "employee3@business.com" },
    { email: "employee4@business.com" },
  ];
  const csvContent = csvStringify(rows, CSV_STRINGIFY_DEFAULT_OPTIONS);
  saveAs(new Blob([csvContent], { type: "text/csv;charset=utf-8" }), "sample_emails.csv");
};
