import React from "react";
import { ReactComponent as ZoomLogo } from "lib-frontend/ui/assets/logo-zoom.svg";
import { ReactComponent as VowelLogo } from "lib-frontend/ui/assets/logo-vowel.svg";
import { ReactComponent as WebexLogo } from "lib-frontend/ui/assets/logo-webex.svg";
import { ReactComponent as SlackLogo } from "lib-frontend/ui/assets/logo-slack.svg";
import { ReactComponent as GoogleMeetLogo } from "lib-frontend/ui/assets/logo-gmeet.svg";
import { ReactComponent as MicrosoftTeamsLogo } from "lib-frontend/ui/assets/logo-msteams.svg";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { getDynamicColor } from "lib-frontend/utils/Colors";

type WorksWithOption = {
  label: string;
  renderIcon: () => JSX.Element;
  poodli?: boolean;
  zoodli?: boolean;
  landingPage?: boolean;
};

export const WORKS_WITH_X_OPTIONS: WorksWithOption[] = [
  {
    label: "Zoom",
    renderIcon: () => <ZoomLogo aria-label="zoom logo" role="img" />,
    poodli: true,
    zoodli: true,
    landingPage: true,
  },

  {
    label: "Google Meet",
    renderIcon: () => <GoogleMeetLogo aria-label="google meet logo" role="img" />,
    poodli: true,
    zoodli: true,
    landingPage: true,
  },
  {
    label: "Microsoft Teams",
    renderIcon: () => <MicrosoftTeamsLogo aria-label="microsoft teams logo" role="img" />,
    poodli: true,
    zoodli: true,
    landingPage: true,
  },
  {
    label: "Slack",
    renderIcon: () => <SlackLogo aria-label="slack logo" role="img" />,
    poodli: true,
  },
  {
    label: "Vowel",
    renderIcon: () => <VowelLogo aria-label="vowel logo" role="img" />,
    poodli: true,
  },
  {
    label: "Webex",
    renderIcon: () => <WebexLogo aria-label="webex logo" role="img" />,
    poodli: true,
    landingPage: true,
  },
];

type WorksWithXProps = {
  context: "poodli" | "zoodli";
  moreComing?: boolean;
};

const WorksWithX = ({
  context,
  moreComing = context === "poodli",
}: WorksWithXProps): JSX.Element => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      sx={{
        flexWrap: "wrap",
      }}
    >
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 500,
          whiteSpace: "nowrap",
        }}
      >
        Works with
      </Typography>
      <Stack
        direction="row"
        gap={1.5}
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          flexWrap: "wrap",
        }}
      >
        {WORKS_WITH_X_OPTIONS.map((option) => {
          if (!option[context]) return null;
          return (
            <Tooltip key={option.label} title={option.label} enterDelay={300}>
              <Box
                key={option.label}
                component="span"
                sx={{
                  position: "relative",
                  height: 24,
                  width: 24,
                  svg: {
                    height: "100%",
                  },
                }}
                aria-label={`Works with ${option.label}`}
                role="img"
                tabIndex={0}
              >
                {option.renderIcon()}
              </Box>
            </Tooltip>
          );
        })}
        {moreComing && (
          <Typography
            sx={{
              color: getDynamicColor("dark4"),
              fontSize: 12,
              fontWeight: 500,
              width: { xs: "100%", md: "auto" },
            }}
          >
            + hundreds more
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default WorksWithX;
