import React from "react";

// Components
import { Stack, Button, Divider, Typography, Box } from "@mui/material";
import { YoodliNotificationBannerVariant } from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";

// Utils
import OrgSectionWrapper from "../OrgSectionWrapper";
import { HubsTable } from "./HubsTable";
import { MemberBlurb } from "./MemberBlurb";
import { SpaceAdminsTable } from "./SpaceAdminsTable";
import { useMutation } from "@tanstack/react-query";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { YoodliNotificationOptions } from "lib-frontend/contexts/NotificationContext";
import {
  createOrgUserV2,
  deleteHubUserV2,
  updateHubUserV2,
  updateOrgUserV2,
  updateOrgUserSpaces,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { parseOrgRole } from "lib-frontend/utils/orgUtils";
import { SupportPages } from "lib-frontend/utils/paths";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { OrgUserSpacesUpsetRequest } from "lib-fullstack/api/hubApiTypes";
import { OrgMemberResponse, OrgV2Response } from "lib-fullstack/api/orgApiTypes";
import { HubRole, OrgRole, EffectiveRole } from "lib-fullstack/utils/enums";

type ManageRoleProps = {
  org: OrgV2Response;
  handleBack: () => void;
  backCopy: string;
  user: OrgMemberResponse;
  handleShowNotificationBanner: (notificationData: {
    message: string | JSX.Element;
    options: YoodliNotificationOptions;
  }) => void;
};

export type HubTableRowData = {
  id: string;
  name: string;
  role?: HubRole;
};

export type SpaceAdminsRowData = {
  spaceId: string;
  spaceName: string;
  isAdmin: boolean;
};

export const ManageRole = ({
  org,
  handleBack,
  backCopy,
  user,
  handleShowNotificationBanner,
}: ManageRoleProps): JSX.Element => {
  const [groupSearchText, setGroupSearchText] = React.useState<string>("");
  const [spacesSearchText, setSpacesSearchText] = React.useState<string>("");
  const [userRole, setUserRole] = React.useState<EffectiveRole>(user.effective_role);
  const [rowData, setRowData] = React.useState<HubTableRowData[]>(
    org.hubs.map((hub) => {
      const hubRole =
        (user.hubs.find((userHub) => userHub.hub_id === hub.id)?.role as HubRole) ?? undefined;
      return {
        id: hub.id,
        name: hub.name,
        role: hubRole,
      };
    }),
  );

  const { spaces, invalidateContentSpacesQueries } = React.useContext(ContentSpacesContext);

  const currentSpaceIds = spaces
    .filter((space) => !!space.admins.find((admin) => admin.email === user.email))
    .map((space) => space.id)
    .sort();

  const [spacesRowData, setSpacesRowData] = React.useState<SpaceAdminsRowData[]>([]);

  const targetSpaceIds = spacesRowData
    .filter((row) => !!row.isAdmin)
    .map((row) => row.spaceId)
    .sort();

  const spaceAdminsChanged =
    currentSpaceIds.length !== targetSpaceIds.length ||
    currentSpaceIds.some((id, index) => id !== targetSpaceIds[index]);

  const userRoleChanged = userRole !== user.effective_role;
  const updateRoleMutation = useMutation({
    mutationFn: () => handleSave(),
    onSuccess: () => {
      handleBack();
      // only really need to show this message if the user role changed since the last save
      if (userRoleChanged) {
        handleShowNotificationBanner({
          message: (
            <>
              <Box component="span" sx={{ fontWeight: 700 }}>
                {user.email}
              </Box>
              's role has been changed to{" "}
              <Box component="span" sx={{ fontWeight: 700 }}>
                {parseOrgRole(userRole)}
              </Box>
            </>
          ),
          options: { variant: YoodliNotificationBannerVariant.Success },
        });
      }

      if (spaceAdminsChanged) {
        handleShowNotificationBanner({
          message: (
            <>
              <Box component="span" sx={{ fontWeight: 700 }}>
                Content space admin role(s) updated for {user.email}.
              </Box>
            </>
          ),
          options: { variant: YoodliNotificationBannerVariant.Success },
        });
      }
    },
    onError: (_) => {
      handleBack();

      if (userRoleChanged) {
        handleShowNotificationBanner({
          message: (
            <>
              Error updating role for{" "}
              <Box component="span" sx={{ fontWeight: 700 }}>
                {user.email}
              </Box>
            </>
          ),
          options: { variant: YoodliNotificationBannerVariant.Danger },
        });
      }
      if (spaceAdminsChanged) {
        handleShowNotificationBanner({
          message: (
            <>
              Error updating spaces for{" "}
              <Box component="span" sx={{ fontWeight: 700 }}>
                {user.email}
              </Box>
            </>
          ),
          options: { variant: YoodliNotificationBannerVariant.Danger },
        });
      }
    },
  });

  const handleSave = async () => {
    await handleSaveRole();
    await handleSaveSpaceAdmins();
  };

  const handleSaveSpaceAdmins = async () => {
    if (spaceAdminsChanged) {
      const body: OrgUserSpacesUpsetRequest = {
        space_ids: targetSpaceIds,
      };

      await updateOrgUserSpaces(org.id, user.user_id, body);
      await invalidateContentSpacesQueries();
    }
  };

  const handleSaveRole = async () => {
    if (userRole !== user.effective_role) {
      await updateOrgUserV2(org.id, user.user_id, {
        role: userRole === EffectiveRole.ORG_ADMIN ? OrgRole.ADMIN : null,
      });
    }

    // saving deletions for end
    const hubsToRemove = [];
    for (const hub of rowData) {
      const userHub = user.hubs.find((userHub) => userHub.hub_id === hub.id);
      if (!hub.role && !!userHub?.role) {
        // Remove user from hub if role is removed
        hubsToRemove.push(hub.id);
      } else if (!!hub.role && !userHub) {
        // Add user to hub if role is added
        await createOrgUserV2(org.id, {
          emails: [user.email],
          org_role: null,
          hub_role: hub.role,
          hub_ids: [hub.id],
          send_invite_email: true,
        });
      } else if (hub.role !== userHub?.role) {
        // Update user role in hub if role is changed
        await updateHubUserV2(org.id, hub.id, user.user_id, hub.role);
        Instrumentation.logHubMemberStatusUpdated(hub.role, user.user_id, hub.id, org.id);
      }
    }
    // save deletions for end to avoid situation there is a problem removing the user's last hub
    await Promise.all(
      hubsToRemove.map((hubId) => {
        deleteHubUserV2(org.id, hubId, user.user_id, false).catch((err) => {
          console.error("Error deleting user from group", err);
        });
      }),
    );
  };

  React.useEffect(() => {
    const rowData: SpaceAdminsRowData[] = spaces.map((space) => {
      const isAdmin = !!space.admins.find((admin) => admin.email === user.email);
      return { spaceId: space.id, spaceName: space.name, isAdmin };
    });

    setSpacesRowData(rowData);
  }, [spaces, user]);

  return (
    <OrgSectionWrapper
      headerProps={{
        backCopy: backCopy,
        handleBack: handleBack,
      }}
      loading={updateRoleMutation.isPending}
      ctas={
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mx: { xs: 2, md: 6 },
            height: 60,
          }}
        >
          <Button
            onClick={() => window.open(SupportPages.ORG_OVERVIEW_ROLES, "_blank")}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            disabled={updateRoleMutation.isPending}
            sx={{ px: 0 }}
          >
            Learn more about roles
          </Button>
          <Button
            variant="contained"
            onClick={() => updateRoleMutation.mutate()}
            disabled={updateRoleMutation.isPending || rowData.every((hub) => !hub.role)}
          >
            Save changes
          </Button>
        </Stack>
      }
      contentWrapperSx={{
        overflow: "unset",
        py: 0,
      }}
    >
      <Stack gap={4} sx={{ py: { xs: 4, md: 8 }, height: "100%", overflow: "auto" }}>
        <Stack sx={{ px: { xs: 2, md: 6 } }}>
          <MemberBlurb user={user} />

          <Stack
            columnGap={15}
            rowGap={2}
            direction={{ xs: "column", md: "row" }}
            sx={{ alignItems: { xs: "flex-start", md: "center" }, mt: 4 }}
          >
            <Stack>
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: "16px",
                  fontWeight: 700,
                  letterSpacing: "0.17px",
                }}
              >
                Organization membership
              </Typography>
              <Typography
                sx={{
                  color: getDynamicColor("dark5"),
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                Update organization-level role
              </Typography>
            </Stack>
            <YoodliSelect
              value={
                userRole === EffectiveRole.ORG_ADMIN
                  ? EffectiveRole.ORG_ADMIN
                  : EffectiveRole.HUB_MEMBER
              }
              onChange={(e) => setUserRole(e.target.value as EffectiveRole)}
              options={[
                { label: "Org admin", value: EffectiveRole.ORG_ADMIN },
                { label: "Org member", value: EffectiveRole.HUB_MEMBER },
              ]}
            />
          </Stack>
        </Stack>
        <Divider />
        <Stack gap={2} sx={{ px: { xs: 2, md: 6, flexShrink: 0 }, overflow: "auto" }}>
          <Stack
            gap={1}
            direction={{ xs: "column", md: "row" }}
            sx={{
              pt: 0.5,
              alignItems: { xs: "flex-start", md: "center" },
              justifyContent: "space-between",
            }}
          >
            <Stack>
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: "16px",
                  fontWeight: 700,
                  letterSpacing: "0.17px",
                }}
              >
                Content space
              </Typography>
              <Typography
                sx={{
                  color: getDynamicColor("dark5"),
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                Select content spaces to add {user.name} to as admin
              </Typography>
            </Stack>
            <YoodliSearchBar
              value={spacesSearchText}
              label="Search"
              onChange={(e) => setSpacesSearchText(e.target.value)}
              clearSearch={() => setSpacesSearchText("")}
            />
          </Stack>
          <SpaceAdminsTable
            rowData={spacesRowData}
            setRowData={setSpacesRowData}
            searchText={spacesSearchText}
            setSearchText={setSpacesSearchText}
          />
        </Stack>
        <Divider />
        <Stack gap={2} sx={{ px: { xs: 2, md: 6, flexShrink: 0 }, overflow: "auto" }}>
          <Stack
            gap={1}
            direction={{ xs: "column", md: "row" }}
            sx={{
              pt: 0.5,
              alignItems: { xs: "flex-start", md: "center" },
              justifyContent: "space-between",
            }}
          >
            <Stack>
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: "16px",
                  fontWeight: 700,
                  letterSpacing: "0.17px",
                }}
              >
                Group membership
              </Typography>
              <Typography
                sx={{
                  color: getDynamicColor("dark5"),
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                Select a group to add {user.name} to, then select their role
              </Typography>
            </Stack>
            <YoodliSearchBar
              value={groupSearchText}
              label="Search"
              onChange={(e) => setGroupSearchText(e.target.value)}
              clearSearch={() => setGroupSearchText("")}
            />
          </Stack>
          <HubsTable
            rowData={rowData}
            setRowData={setRowData}
            searchText={groupSearchText}
            setSearchText={setGroupSearchText}
          />
        </Stack>
      </Stack>
    </OrgSectionWrapper>
  );
};
