import {
  adminGetHubSpotCompanyById,
  adminGetHubSpotDealById,
  adminGetUserList,
} from "lib-frontend/modules/AxiosInstance";
import { HUBSPOT_NOOP_COMPANY_ID, HUBSPOT_NOOP_DEAL_ID } from "lib-fullstack/utils/constants";
import React from "react";

// Utils
import isEmail from "validator/lib/isEmail";
import isStrongPassword from "validator/lib/isStrongPassword";
import { regexDisallowedCharsInDisplay } from "lib-fullstack/utils/verificationSanitization";

export const DEBOUNCE_MS = 500 as const;

// Ensures we don't show validation errors on first render
// also debounce useEffect by DEBOUNCE_MS
// so the input doesn't jump around while typing

// TODO: useDefferedValue() from React 18 instead after upgrade

/**
 *
 * @param callback function to call when the value changes
 * @param deps values to trigger callback on change
 * Debounced callback ensure effect is not called on first mount,
 * and a only called a maximum of once per DEBOUNCE_MS ms
 *
 */
export const useValidationEffect = (
  callback: React.EffectCallback,
  deps: React.DependencyList,
  debounceMs: number = DEBOUNCE_MS,
): void => {
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    let timeout;
    if (mounted) {
      timeout = setTimeout(() => {
        callback();
      }, debounceMs);
    }
    setMounted(true);

    return () => {
      clearTimeout(timeout);
    };
  }, deps);
};

export function useValidateEmail(email: string): string {
  const [error, setError] = React.useState<string>("");

  useValidationEffect(() => {
    if (email === "") return setError("Email is required.");

    if (!isEmail(email)) return setError("Invalid email.");

    setError("");
  }, [email]);

  return error;
}

const BASE_PASSWORD_REQUIREMENTS = {
  minLength: 8,
  minLowercase: 1,
  minUppercase: 1,
  minNumbers: 1,
  minSymbols: 0,
};

export function useValidatePassword(password: string): string {
  const [error, setError] = React.useState<string>("");

  useValidationEffect(() => {
    if (!password) {
      return setError("Password is required");
    }

    if (password.length > 100) {
      return setError("Password must be less than 100 characters");
    }

    if (!isStrongPassword(password, BASE_PASSWORD_REQUIREMENTS)) {
      return setError(
        "Password must be at least 8 characters and must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number",
      );
    }

    setError("");
  }, [password]);

  return error;
}

const STRONG_PASSWORD = {
  minLength: 8,
  minLowercase: 1,
  minUppercase: 1,
  minNumbers: 1,
  minSymbols: 0,
  returnScore: true,
  pointsPerUnique: 2,
  pointsPerRepeat: 1,
  pointsForContainingLower: 15,
  pointsForContainingUpper: 15,
  pointsForContainingNumber: 15,
  pointsForContainingSymbol: 15,
};

// Password strength MAX 100
export function useValidateStrongPassword(password: string): number {
  const [score, setScore] = React.useState<number>(0);

  useValidationEffect(
    () => {
      const newScore = isStrongPassword(password, { ...STRONG_PASSWORD, returnScore: true });
      if (newScore >= 100) return setScore(100);

      setScore(newScore);
    },
    [password],
    100,
  );

  return score;
}

export function useValidateDisplayString(str: string): string {
  const [error, setError] = React.useState<string>();

  useValidationEffect(() => {
    str = str.trim();
    if (str === "") return setError("This field is required.");
    // allowing users to specify valid emails as display names
    if (str.match(regexDisallowedCharsInDisplay) && !isEmail(str))
      return setError("Invalid character.");

    setError("");
  }, [str]);

  return error;
}

const isStrPositiveInt = (str: string): boolean => {
  const n = Math.floor(Number(str));
  return n !== Infinity && String(n) === str && n > 0;
};

export function useValidatePositiveInt(numberStr: string): string {
  const [error, setError] = React.useState<string>("");
  useValidationEffect(() => {
    if (!isStrPositiveInt(numberStr)) {
      return setError("Valid number is required.");
    }
    setError("");
  }, [numberStr]);

  return error;
}

/** Determine if an input string is a number and within a range, including min and max values */
export function useValidateNumberInRangeInclusive(
  numberStr: string,
  min: number,
  max: number,
): string {
  const [error, setError] = React.useState<string>("");
  useValidationEffect(() => {
    const number = Number(numberStr);
    if (isNaN(number) || number < min || number > max) {
      return setError(`The value must be between ${min} and ${max}`);
    }
    setError("");
  }, [numberStr]);

  return error;
}

export function useValidateNotEmptyList(list: string[]): string {
  const [error, setError] = React.useState<string>("");
  useValidationEffect(() => {
    if (!list || list.length === 0) {
      return setError("List must not be empty.");
    }
    setError("");
  }, [list]);

  return error;
}

export function useValidateUserEmailList(emails: string[]): string {
  const [error, setError] = React.useState<string>("");

  useValidationEffect(() => {
    if (!emails || emails.length === 0) {
      // Empty email is valid.
      setError("");
    } else {
      const errors: string[] = [];
      emails.forEach(async (email) => {
        useValidateUserEmail(email, true)
          .then((result) => {
            if (result.error) {
              errors.push(result.error);
            }
          })
          .catch((e) => {
            errors.push(`Problem checking if user email exists: ${email} ${e}`);
          });
      });
      setError(errors.join("\n"));
    }
  }, [emails]);

  return error;
}

export function useValidateDate(date: moment.Moment): string {
  const [error, setError] = React.useState<string>("");
  useValidationEffect(() => {
    if (!date || !date.isValid()) {
      return setError("Date is required.");
    }

    setError("");
  }, [date]);

  return error;
}

export type UserError = {
  error: string;
  user: Record<string, string>;
};

/**
 *
 * @param email email to validate
 * @param isRequired if an empty email is a valid state
 * Performs same action as validateEmail but does a check on if that email exists
 * as a valid user. Can only be called with current user with
 * PRODUCT_ADMIN privileges or higher
 *
 */
export function useValidateUserEmailSync(email: string, isRequired: boolean): UserError {
  const [error, setError] = React.useState<UserError>({ error: "", user: null });

  const validateEmail = (email, isRequired) => {
    useValidateUserEmail(email, isRequired)
      .then((result) => setError(result))
      .catch((e) => setError({ error: e, user: null }));
  };

  useValidationEffect(() => validateEmail(email, isRequired), [email]);
  return error;
}

export async function useValidateUserEmail(email: string, isRequired: boolean): Promise<UserError> {
  let result = { error: `Validating email: ${email}`, user: null };

  if (email === null || email === undefined || email.length === 0) {
    if (isRequired) {
      result = { error: "Email is required.", user: null };
    } else {
      result = { error: "", user: null };
    }
  } else if (!isEmail(email)) {
    result = { error: `Invalid email: ${email}`, user: null };
  } else {
    try {
      const resp = await adminGetUserList({ email });
      if (resp.users.length == 0) {
        result = { error: `Email does not belong to a valid user: ${email}`, user: null };
      } else {
        result = { error: "", user: resp.users[0] };
      }
    } catch (e) {
      result = { error: `Problem checking if user email exists: ${email} ${e}`, user: null };
    }
  }

  return result;
}

/**
 * Validate a HubSpot deal ID for a new organization.
 * @param dealId HubSpot deal ID to validate.
 * @param companyId HubSpot company ID to validate.
 * @returns Error message if deal ID is invalid, empty string otherwise.
 */
export function useValidateHubSpotDealIdForNewOrgSync(dealId: string, companyId: string): string {
  const [error, setError] = React.useState<string>("");

  const validate = (dealId, companyId) => {
    useValidateHubSpotDealIdForNewOrg(dealId, companyId)
      .then((result) => setError(result))
      .catch((e) => setError(e));
  };

  useValidationEffect(() => validate(dealId, companyId), [dealId, companyId]);
  return error;
}

/**
 * Validate a HubSpot deal ID for a new organization.
 * @param dealId HubSpot deal ID to validate.
 * @param companyId HubSpot company ID to validate.
 * @returns Error message if deal ID is empty or invalid, empty string otherwise.
 */
export async function useValidateHubSpotDealIdForNewOrg(
  dealId: string,
  companyId: string,
): Promise<string> {
  if (dealId === null || dealId === undefined || dealId.trim().length === 0) {
    return "Deal ID is empty";
  }

  if (dealId.toLowerCase().trim() === HUBSPOT_NOOP_DEAL_ID) {
    console.log("Deal ID is no-op");
    return "";
  }

  const deal = await adminGetHubSpotDealById(dealId);

  if (!deal || !deal.dealId) {
    return "Deal not found";
  }

  // Deal is not associated with any company; doesn't matter what the provided company ID is.
  if (!deal.companyId) {
    return "";
  }

  // If the provide company ID is empty, that's OK.
  if (companyId === null || companyId === undefined || companyId.trim().length === 0) {
    return "";
  }

  // Make sure the deal is associated with the provided company ID.
  if (deal.companyId !== companyId) {
    const company = await adminGetHubSpotCompanyById(deal.companyId);
    return `Deal is associated with company ${deal.companyId} ${company?.companyName}`;
  }

  return "";
}

/**
 * Validate a HubSpot deal ID for a new organization.
 * @param companyId HubSpot deal ID to validate.
 * @returns Error message if deal ID is invalid, empty string otherwise.
 */
export function useValidateHubSpotCompanyIdForNewOrgSync(companyId: string): string {
  const [error, setError] = React.useState<string>("");

  const validate = (companyId) => {
    useValidateHubSpotCompanyIdForNewOrg(companyId)
      .then((result) => setError(result))
      .catch((e) => setError(e));
  };

  useValidationEffect(() => validate(companyId), [companyId]);
  return error;
}

/**
 * Validate a HubSpot deal ID for a new organization.
 * @param companyId HubSpot deal ID to validate.
 * @returns Error message if deal ID is empty or invalid, empty string otherwise.
 */
export async function useValidateHubSpotCompanyIdForNewOrg(companyId: string): Promise<string> {
  if (companyId === null || companyId === undefined || companyId.trim().length === 0) {
    return "Company ID is empty";
  }

  if (companyId.toLowerCase().trim() === HUBSPOT_NOOP_COMPANY_ID) {
    console.log("Company ID is no-op");
    return "";
  }

  const company = await adminGetHubSpotCompanyById(companyId);

  if (!company || !company.companyId) {
    return "Company not found";
  }

  return "";
}

/**
 * Validate a HubSpot deal ID for an existing organization.
 * @param dealId HubSpot deal ID to validate.
 * @returns Error message if deal ID is invalid, empty string otherwise.
 */
export function useValidateHubSpotDealIdForExistingOrgSync(orgId: string, dealId: string): string {
  const [error, setError] = React.useState<string>("");

  const validate = (orgId, dealId) => {
    useValidateHubSpotDealIdForExistingOrg(orgId, dealId)
      .then((result) => setError(result))
      .catch((e) => setError(e));
  };

  useValidationEffect(() => validate(orgId, dealId), [dealId]);
  return error;
}

/**
 * Validate a HubSpot deal ID for an existing organization.
 * @param dealId HubSpot deal ID to validate.
 * @returns Error message if deal ID is invalid, empty string otherwise.
 */
export async function useValidateHubSpotDealIdForExistingOrg(
  orgId: string,
  dealId: string,
): Promise<string> {
  if (dealId === null || dealId === undefined || dealId.trim().length === 0) {
    console.log("Deal ID is empty");
    return "";
  }

  const deal = await adminGetHubSpotDealById(dealId);

  if (!deal || !deal.dealId) {
    return "Deal not found";
  }

  if (deal.orgId && deal.orgId !== orgId) {
    return "Deal is already associated with an organization";
  }

  return "";
}
