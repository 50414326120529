import firebase from "firebase/app";
import React from "react";

// Components
import { Box, CircularProgress, Stack } from "@mui/material";
import {
  CustomizePracticeQueryKey,
  WizardState,
} from "components/ConvoScenarios/convoScenarioUtils";
import { CustomizePracticeTabSelector } from "components/Orgs/ManageContent/CustomizePractice/CustomizePracticeTabSelector";
import { InterviewsTab } from "components/Orgs/ManageContent/CustomizePractice/InterviewBanks/InterviewsTab";

// Utils
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getAllPersonasAdmin } from "lib-frontend/modules/AxiosInstance";
import { GetScenarioResponse } from "lib-fullstack/api/scenarioApiTypes";
import { InterviewBankResponse } from "lib-fullstack/api/hubApiTypes";
import { OrgV2Response } from "lib-fullstack/api/orgApiTypes";
import { ScenariosTab } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/ScenariosTab";
import { PersonasTab } from "components/Orgs/ManageContent/CustomizePractice/Personas/PersonasTab";
import { CustomGoalLibrary } from "../CustomGoals/CustomGoalLibrary";
import { CustomizePracticeTabEnum } from "lib-frontend/utils/orgUtils";
import { useQueryParamState } from "hooks/useQueryParamState";
import { OrgContentQueryParams } from "lib-fullstack/utils/queryParams";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { usePrevious } from "lib-frontend/hooks/usePrevious";
import { isToastmasters } from "lib-frontend/utils/subdomain";

type CustomizePracticeTabProps = {
  selectedOrg: OrgV2Response;
  selectedOrgInterviewBanks: InterviewBankResponse[];
  shouldBlockNav: boolean;
  setShouldBlockNav: React.Dispatch<React.SetStateAction<boolean>>;
  setShowBackHeader: React.Dispatch<React.SetStateAction<boolean>>;
  showBackHeader: boolean;
  showScenarioTemplates: string;
  setShowScenarioTemplates: React.Dispatch<React.SetStateAction<string>>;
  templateSelected: GetScenarioResponse | null;
  setTemplateSelected: React.Dispatch<React.SetStateAction<GetScenarioResponse | null>>;
};

export const TabLabels = {
  [CustomizePracticeTabEnum.RoleplayScenarios]: "Roleplay Scenarios",
  [CustomizePracticeTabEnum.CustomGoals]: "Custom Goals",
  [CustomizePracticeTabEnum.Personas]: "Personas",
  [CustomizePracticeTabEnum.InterviewScenarios]: "Interview Scenarios",
  [CustomizePracticeTabEnum.QuestionBanks]: "Question Banks",
};

export const CustomizePracticeTab = ({
  selectedOrg,
  selectedOrgInterviewBanks,
  shouldBlockNav,
  setShouldBlockNav,
  setShowBackHeader,
  showBackHeader,
  showScenarioTemplates,
  setShowScenarioTemplates,
  templateSelected,
  setTemplateSelected,
}: CustomizePracticeTabProps): JSX.Element => {
  const [activeTab, setActiveTab] = useQueryParamState(
    OrgContentQueryParams.SUBTAB,
    CustomizePracticeTabEnum.RoleplayScenarios,
  );

  const {
    defaultOrgId,
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);
  const tabs = Object.values(CustomizePracticeTabEnum);
  if (!defaultOrg?.custom_goal_enabled || isToastmasters()) {
    tabs.splice(tabs.indexOf(CustomizePracticeTabEnum.CustomGoals), 1);
  }

  // fetch personas once here so i can use the same query data whether within the wizard, or the personas tab
  useApiQuery({
    queryKey: [CustomizePracticeQueryKey.Personas, defaultOrgId, curSpaceId],
    queryFn: async () =>
      (await getAllPersonasAdmin(defaultOrgId, { space_id: curSpaceId }))?.personas,
    enabled: !!firebase.auth().currentUser?.uid && !!defaultOrgId && !!curSpaceId,
    refetchOnWindowFocus: false,
  });

  const [hideTabsState, setHideTabsState] = React.useState<boolean>(!!showScenarioTemplates);

  const [personaIsSelected, setPersonaIsSelected] = React.useState<boolean>(false);
  const [customGoalIsSelected, setCustomGoalIsSelected] = React.useState<boolean>(false);
  const [interviewBankIsSelected, setInterviewBankIsSelected] = React.useState<boolean>(false);

  const [wizardState, setWizardState] = React.useState<WizardState>("create");

  // optionally allow scernarioId to be overwritten, this is so when selecting a template
  // we can overwrite the default id (i.e. cold_sales_call) with the newly created scenario-to-update's id
  const handleTemplateSelected = (scenario: GetScenarioResponse | null, scenarioId?: string) => {
    let newScenario = scenario;
    if (scenarioId) {
      newScenario = { ...scenario, id: scenarioId, enabled: false };
    }
    setTemplateSelected(newScenario);
    setShowBackHeader(!!newScenario || !!showScenarioTemplates);
  };

  const handlePersonaIsSelected = (selected: boolean) => {
    setPersonaIsSelected(selected);
    setShowBackHeader(selected);
  };

  const handleCustomGoalIsSelected = (selected: boolean) => {
    if (!showScenarioTemplates) {
      setShowBackHeader(selected);
    }
    setCustomGoalIsSelected(selected);
  };

  const handleInterviewBankIsSelected = (selected: boolean) => {
    setShowBackHeader(selected);
    setInterviewBankIsSelected(selected);
  };

  // reset the selected template whenever the user closes the scenario wizard
  React.useEffect(() => {
    if (!showScenarioTemplates) {
      handleTemplateSelected(null);
    }
  }, [showScenarioTemplates]);

  const prevTemplateSelected = usePrevious(templateSelected);
  React.useEffect(() => {
    if (templateSelected && prevTemplateSelected?.id !== templateSelected?.id) {
      setShouldBlockNav(templateSelected.isTemplate || ["duplicate", "edit"].includes(wizardState));
    }
  }, [templateSelected, wizardState, prevTemplateSelected]);

  const hideTabs =
    (hideTabsState ||
      templateSelected ||
      personaIsSelected ||
      customGoalIsSelected ||
      interviewBankIsSelected ||
      showScenarioTemplates) &&
    showBackHeader;

  const renderLoader = () => {
    return (
      <Box
        sx={{
          mx: "auto",
          py: 5,
          minHeight: 300,
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  const renderScenariosTab = (
    tab: CustomizePracticeTabEnum.RoleplayScenarios | CustomizePracticeTabEnum.InterviewScenarios,
  ) => {
    return (
      <ScenariosTab
        selectedOrg={selectedOrg}
        templateSelected={templateSelected}
        handleTemplateSelected={handleTemplateSelected}
        shouldBlockNav={shouldBlockNav}
        setShouldBlockNav={setShouldBlockNav}
        wizardState={wizardState}
        setWizardState={setWizardState}
        renderLoader={renderLoader}
        showBackHeader={showBackHeader}
        setShowBackHeader={setShowBackHeader}
        showScenarioTemplates={!!showScenarioTemplates}
        setShowScenarioTemplates={setShowScenarioTemplates}
        setHideTabsState={setHideTabsState}
        scenarioTabType={tab}
      />
    );
  };
  const renderPersonasTab = () => {
    return (
      <PersonasTab
        setPersonaIsSelected={handlePersonaIsSelected}
        renderLoader={renderLoader}
        showBackHeader={showBackHeader}
      />
    );
  };
  const renderInterviewBanksTab = () => {
    return (
      <InterviewsTab
        interviewBanks={selectedOrgInterviewBanks}
        renderLoader={renderLoader}
        setInterviewBankIsSelected={handleInterviewBankIsSelected}
        showBackHeader={showBackHeader}
      />
    );
  };

  const renderCustomGoalsTab = () => {
    return (
      <CustomGoalLibrary
        handleCustomGoalIsSelected={handleCustomGoalIsSelected}
        showBackHeader={showBackHeader}
      />
    );
  };

  return (
    <Stack
      gap={{ xs: 0, md: 4 }}
      sx={{
        p: { xs: 0, md: 4, lg: 5 },
        pb: { xs: 2, md: 6, lg: 8 },
      }}
    >
      {!hideTabs && (
        <CustomizePracticeTabSelector
          tabs={tabs}
          activeTab={activeTab as CustomizePracticeTabEnum}
          setActiveTab={setActiveTab}
        />
      )}
      {tabs.map((tab) => {
        return (
          <Box
            key={tab}
            sx={{
              display: activeTab === tab ? "initial" : "none",
            }}
          >
            {tab === CustomizePracticeTabEnum.RoleplayScenarios &&
              renderScenariosTab(CustomizePracticeTabEnum.RoleplayScenarios)}
            {tab === CustomizePracticeTabEnum.Personas && renderPersonasTab()}
            {tab === CustomizePracticeTabEnum.CustomGoals && renderCustomGoalsTab()}
            {tab === CustomizePracticeTabEnum.InterviewScenarios &&
              renderScenariosTab(CustomizePracticeTabEnum.InterviewScenarios)}
            {tab === CustomizePracticeTabEnum.QuestionBanks && renderInterviewBanksTab()}
          </Box>
        );
      })}
    </Stack>
  );
};
