// Components
import { Stack, Typography } from "@mui/material";

// Utils
import { ConvoScenarioStepWrapper } from "./ConvoScenarioStepWrapper";
import { ScoreGoalDescriptionStep } from "./ScoreGoalDescriptionStep";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { CreateScoreGoalRequest } from "lib-fullstack/api/scenarioApiTypes";
import { GoalDocument } from "lib-fullstack/db";

type KnowledgeBasedGoalDescriptionStepProps = {
  knowledgeBasedGoal: CreateScoreGoalRequest;
  setKnowledgeBasedGoal: React.Dispatch<React.SetStateAction<CreateScoreGoalRequest>>;
  title: string;
};

export const KnowledgeBasedGoalDescriptionStep = ({
  knowledgeBasedGoal,
  setKnowledgeBasedGoal,
  title,
}: KnowledgeBasedGoalDescriptionStepProps): JSX.Element => {
  const renderSelectDocumentType = () => {
    return (
      <ConvoScenarioStepWrapper title="Choose the goal type">
        <Stack gap="36px" sx={{ textAlign: "center" }}>
          <Stack
            onClick={() =>
              setKnowledgeBasedGoal({
                ...knowledgeBasedGoal,
                documentBasisType: GoalDocument.ObjectionHandling,
              })
            }
            gap={0.5}
            sx={{
              p: 2,
              alignItems: "center",
              borderRadius: "8px",
              border: `1px solid ${getDynamicColor("dark2")}`,
              boxShadow: Y_SHADOWS.box_shadow_1,
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontFamily: "poppins",
                fontSize: 16,
                color: getDynamicColor("purple3"),
              }}
            >
              Objection handling
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontFamily: "poppins",
                fontSize: 12,
                color: getDynamicColor("purple3"),
              }}
            >
              Add up to 5 documents providing common objections and expected responses for a sales
              representative to adhere to
            </Typography>
          </Stack>
          <Stack
            onClick={() =>
              setKnowledgeBasedGoal({
                ...knowledgeBasedGoal,
                documentBasisType: GoalDocument.TechnicalSpecification,
              })
            }
            gap={0.5}
            sx={{
              p: 2,
              alignItems: "center",
              borderRadius: "8px",
              border: `1px solid ${getDynamicColor("dark2")}`,
              boxShadow: Y_SHADOWS.box_shadow_1,
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontFamily: "poppins",
                fontSize: 16,
                color: getDynamicColor("purple3"),
              }}
            >
              Technical specification
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontFamily: "poppins",
                fontSize: 12,
                color: getDynamicColor("purple3"),
              }}
            >
              Add up to 5 documents that provide guidance on a specific list of features or
              technical details
            </Typography>
          </Stack>
          <Stack
            onClick={() =>
              setKnowledgeBasedGoal({
                ...knowledgeBasedGoal,
                documentBasisType: GoalDocument.Generic,
              })
            }
            gap={0.5}
            direction="row"
            sx={{ alignItems: "center", justifyContent: "center", cursor: "pointer" }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontFamily: "poppins",
                fontSize: 14,
                color: getDynamicColor("purple3"),
              }}
            >
              Other
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontFamily: "poppins",
                fontSize: 12,
                color: getDynamicColor("dark5"),
              }}
            >
              (Beta)
            </Typography>
          </Stack>
        </Stack>
      </ConvoScenarioStepWrapper>
    );
  };

  if (knowledgeBasedGoal.documentBasisType) {
    return (
      <ScoreGoalDescriptionStep
        scoreGoal={knowledgeBasedGoal}
        setScoreGoal={setKnowledgeBasedGoal}
        title={title}
      />
    );
  } else {
    return renderSelectDocumentType();
  }
};
