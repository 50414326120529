import React, { useEffect } from "react";

// Components
import { VolumeOff, VolumeUp } from "@mui/icons-material";
import {
  IconButton,
  Button,
  Popover,
  MenuList,
  MenuItem,
  Slider,
  Box,
  Collapse,
} from "@mui/material";

// Utils
import { Y_SHADOWS } from "lib-frontend/utils/Colors";
import { handleEnterOrSpaceKey } from "lib-frontend/utils/keyboard";

const playbackSpeeds = {
  "0.5x": 0.5,
  "0.75x": 0.75,
  "1x": 1,
  "1.25x": 1.25,
  "1.5x": 1.5,
  "2x": 2,
  "3x": 3,
};

export const VolumeSlider = (props: {
  muted: boolean;
  isAudio: boolean;
  volume: number;
  setVolume: (event: Event | React.ChangeEvent<HTMLInputElement>) => void;
  controlsHovered: boolean;
  toggleMuted: (
    event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
  ) => void;
}): React.ReactElement => {
  const [open, setOpen] = React.useState<boolean>(false);

  useEffect(() => {
    if (!props.controlsHovered) {
      handleMouseLeave();
    }
  }, [props.controlsHovered]);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <IconButton
        size="large"
        onClick={props.toggleMuted}
        onKeyDown={(e) => {
          if (handleEnterOrSpaceKey(e)) {
            props.toggleMuted(e as React.KeyboardEvent<HTMLButtonElement>);
          }
        }}
        tabIndex={0}
        title="Toggle mute"
        aria-label={props.muted ? "Unmute" : "Mute"}
      >
        {!props.muted ? <VolumeUp /> : <VolumeOff />}
      </IconButton>
      <Collapse orientation="horizontal" in={open}>
        <Slider
          aria-label="Volume"
          sx={{
            width: { xs: "60px", lg: "80px", xl: "100px" },
            mx: 1.5,
            mt: 0.5,
            color: "white",
            ".MuiSlider-thumb": {
              boxShadow: 0,
            },
          }}
          orientation="horizontal"
          step={0.05}
          min={0}
          max={1}
          value={props.volume}
          onChange={props.setVolume}
          defaultValue={1}
          size="small"
        />
      </Collapse>
    </Box>
  );
};

export const PlaybackSpeedMenu = (props: {
  onClick: (value: number) => void;
  value: number;
  isSmall?: boolean;
}): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleButtonKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (handleEnterOrSpaceKey(e)) {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (open) {
      // Focus first menu item when menu opens
      setTimeout(() => {
        const menuItems = document.querySelectorAll('[role="menuitem"]');
        if (menuItems.length > 0) {
          (menuItems[0] as HTMLElement).focus();
        }
      }, 0);
    }
  }, [open]);

  const handleChange = (val: string) => {
    props.onClick(playbackSpeeds[val]);
    handleClose();
  };

  return (
    <>
      <Button
        sx={{
          color: "white",
          minWidth: !props.isSmall && "25px",
          width: props.isSmall && "fit-content",
          px: props.isSmall && "16px",
        }}
        variant="text"
        onClick={handleClick}
        onKeyDown={handleButtonKeyDown}
        tabIndex={0}
        aria-label={`Playback speed ${props.value}x`}
        aria-haspopup="true"
        aria-expanded={open}
      >
        {props.value + "x"}
      </Button>
      <Popover
        role="menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
            maxWidth: "none",
            overflow: "visible",
            boxShadow: Y_SHADOWS.box_shadow_1,
            borderRadius: "8px",
            zIndex: 9001,
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          "&.MuiPopover-root": {
            zIndex: 2000,
          },
        }}
      >
        <MenuList
          sx={{
            backgroundColor: "white",
            paddingY: 0,
            borderRadius: "8px",
            overflow: "hidden",
          }}
          onKeyDown={(e) => {
            const menuItems = Array.from(
              e.currentTarget.querySelectorAll('[role="menuitem"]'),
            ) as HTMLElement[];

            // Get the closest menuitem element to the event target.
            const currentMenuItem = (e.target as HTMLElement).closest(
              '[role="menuitem"]',
            ) as HTMLElement;
            const currentIndex = menuItems.indexOf(currentMenuItem);

            if (e.key === "ArrowUp") {
              e.preventDefault();
              const prevIndex = currentIndex > 0 ? currentIndex - 1 : menuItems.length - 1;
              menuItems[prevIndex].focus();
            } else if (e.key === "ArrowDown") {
              e.preventDefault();
              const nextIndex = currentIndex < menuItems.length - 1 ? currentIndex + 1 : 0;
              menuItems[nextIndex].focus();
            }
          }}
          autoFocus
        >
          {Object.keys(playbackSpeeds).map((speed, i) => {
            return (
              <MenuItem
                key={`playback-${i}`}
                onClick={() => handleChange(speed)}
                onKeyDown={(e) => {
                  if (handleEnterOrSpaceKey(e)) {
                    handleChange(speed);
                  }
                  if (e.key === "Escape") {
                    e.preventDefault();
                    handleClose();
                  }
                }}
                tabIndex={-1}
                role="menuitem"
                aria-label={`Change speed to ${speed}`}
                selected={playbackSpeeds[speed] === props.value}
                aria-selected={playbackSpeeds[speed] === props.value}
              >
                {speed}
              </MenuItem>
            );
          })}
        </MenuList>
      </Popover>
    </>
  );
};
