// Utils
/**
 * Shared constants across the front and back end
 */
import type { PricingExperiment } from "./pricingTypes";

/**
 * Max allowed recording time in minutes, across all recording and upload sources
 */
export const RecordingMaxLengthMinutes = 120;

/**
 * change FALLBACK_SUBDOMAIN for local dev of a config.
 *  Should be 'app' in prod.
 *
 * NOTE: If testing toastmasters locally
 *  you will also need to change the subdomain check in Colors.ts to be "localhost" instead of "toastmasters"
 */
export const FALLBACK_SUBDOMAIN = "app";
export const DEFAULT_SITE_ID = "yoodli";

/**
 * A reusable string version of the max recording length
 */
export const RecordingMaxLengthString = "2 hours";
export const RecordingMaxSizeString = "3 GB";

export const ReferralCompletedMaxCount = 50;

export const DAY_IN_SEC = 24 * 60 * 60;
export const MONTH_IN_MS = 31 * 24 * 60 * 60 * 1000;
export const WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000;
export const DAY_IN_MS = 24 * 60 * 60 * 1000;
export const HOUR_IN_MS = 60 * 60 * 1000;
export const MIN_IN_MS = 60 * 1000;

/**
 * Amplitude reverse proxy full URL
 */
export const AMPLITUDE_REVERSE_PROXY = "https://api2.yoodli.ai/consumption";

/**
 * Threshold which both frontend (showing specific messages) and
 * backend (triggering emails) think the usage almost hits the quota.
 */
export const PLAN_THRESHOLD_ALMOST_HIT = 0.7;

export const ORG_INTERVIEW_BANK_MAX_QS = 20;

/**
 * Minimum quota of hubs in an org.
 */
export const MINIMUM_ORG_HUBS_V2_QUOTA = 1;
/**
 * Default quota of hubs in an org (including the default hub).
 */
export const DEFAULT_ORG_HUBS_V2_QUOTA = 30;
/**
 * Maximum quota of hubs in an org. This is a sane upper limit
 * so the maximum is bounded.
 */
export const MAXIMUM_ORG_HUBS_V2_QUOTA = 500;

/**
 * Default quota of programs in an org
 */
export const DEFAULT_ORG_PROGRAM_QUOTA = 100;

/**
 * Maximum length per welcome or course video
 */
export const MAX_ORG_VIDEO_LENGTH = 20 * 60;

/**
 * Only applies if the Seat Inactivity Feature in an org is toggled on (off by default).
 * Default number of days before a user's seat becomes inactive and is no longer counted against the org's seat count.
 */
export const DEFAULT_DAYS_SEAT_INACTIVE = 30;

/**
 * Only applies if the Seat Inactivity Feature in an org is toggled on (off by default).
 * Maximum number of days before a seat becomes inactive in an org. This is a sane upper limit
 * so the maximum is bounded.
 */
export const MAXIMUM_DAYS_SEAT_INACTIVE = 2 * 365;

/**
 * Hard coded limit of the number of plan steps in a program
 */
export const MAX_STEPS_IN_PROGRAM = 20;

// Pricing experiments that are no longer running
export const LEGACY_PRICING: PricingExperiment[] = [];

// Pricing experiment cookies
export const PRICING_EXPERIMENT_COOKIE = "pExpVersion";
export const ON_PAID_PLAN_COOKIE = "onPaidPlan";

// Cookie/localStorage/sessionStorage keys
export const AMPLITUDE_DEVICE_ID = "amplitudeDeviceId";
export const LOCAL_STORAGE_AUDIO_PERMISSIONS_BLOCKED = "cameraAudioPermissionsBlocked";
export const YOODLI_INDEED_HUID = "yoodli_indeed_huid";
export const YOODLI_REFERRAL_PROGRAM = "yoodli_referral_program";
export const YOODLI_REFERRER = "yoodli_referrer";

// Pricing default discount code
export const PRICING_DEFAULT_DISCOUNT_CODE = "SPRING15";

export const DATA_REDACTION_MAX_DAYS = 365;

export const ORG_TRIAL_ABOUT_TO_END_NOTIFICATION_DAYS = 7;
export const ORG_TRIAL_EXPIRE_NOTIFICATION_DAYS = 4;

export const ORG_SEAT_COST_DOLLARS = 20;

export const MAX_ORG_SEATS = 10000;

export const MAX_LICENSED_USERS_RESPONSE = 50;

export const THUMBNAIL_IN_EMAIL_EXPIRATION_MS = 7 * DAY_IN_MS;

export const DOWNLOAD_URL_EXPIRATION_MS = 60 * MIN_IN_MS;
export const VIEW_LOGO_URL_EXIPRATION_MS = 7 * DAY_IN_MS;

export const EMAIL_BRANDING_MESSAGE_MAX_LENGTH = 2000;
// allow for a long message max length as the formatting swells it up considerably
export const SIGN_UP_NOTICE_MESSAGE_MAX_LENGTH = 10000;
export const PROGRAM_REMINDER_MESSAGE_MAX_LENGTH = 2000;

export const DEFAULT_ORG_PER_USER_QUOTA = 1;
export const ORG_TRIAL_SEATS = 3;
export const ORG_TRIAL_DAYS = 7;

/**
 * Currently used for org and hub membership listings.
 * Recommending to use it for any future list "users" API.
 */
export const MAX_LIMIT_LIST_USERS = 10000;

export const ORG_MEMBER_FETCH_LIMIT = 50;

export const DEFAULT_ORG_MEMBER_FETCH_COUNT = 20;

export const DEFAULT_SHARED_WITH_ORG_FETCH_COUNT = 5;

export const DEFAULT_PROGRAM_MEMBER_RECORDING_FETCH_COUNT = 20;

export const MAX_UNICODE_STR = "\u10FFFF";

/**
 * HubSpot "no-op" deal ID. Use this when creating an org
 * and you don't want to associate it with an actual HubSpotDeal.
 */
export const HUBSPOT_NOOP_DEAL_ID = "n/a";

/**
 * HubSpot "no-op" company ID. Use this when creating an org
 * and you don't want to associate it with an actual HubSpotCompany.
 */
export const HUBSPOT_NOOP_COMPANY_ID = "n/a";

/**
 * Maximum age for an abandoned scenario in milliseconds.
 * Abandonded scenarios older than this age are subject to reaping.
 */
export const ABANDONED_ORG_SCENARIO_MAX_AGE = 7 * 24 * 60 * 60 * 1000; //

/**
 * Rendering interval for messages (AI responses) in milliseconds.
 */
export const MESSAGE_RENDERING_INTERVAL_MS = 50;

/**
 * TTS buffering in seconds.
 * This directly causes a delay of AI responses. Do not set this too high.
 */
export const TTS_BUFFERING_S = 0.2;

export const SAMPLE_SPEECH_URL = "https://app.yoodli.ai/share/nmusMqc6";

export const INFO_EMAIL = "info@yoodli.ai";
export const SALES_EMAIL = "sales@yoodli.ai";
export const SUPPORT_EMAIL = "support@yoodli.ai";
export const AFFILIATE_EMAIL = "affiliate@yoodli.ai";

export const SUPPORT_ARTICLES = {
  ORGANIZATION_REPORTS: "https://support.yoodli.ai/en/articles/9788330-organization-reports",
  AV_PERMS_TROUBLESHOOTING:
    "https://support.yoodli.ai/en/articles/9550465-practice-with-yoodli#h_4ac048e89b",
  COACHBOT: "https://support.yoodli.ai/en/articles/9628210-customizing-your-coach-bot",
};

export const PUBSUB_TOPIC_PROGRAM_PROVISIONING = "program-provisioning-tasks";
export const PUBSUB_TOPIC_PROGRAM_RECORDS_UPDATE = "program-records-update-tasks";

/**
 * Standard parameters for csv-stringify package to format CSV.
 * ref. https://docs.google.com/document/d/1OYT64jCOfHyc8O7W3GcUbYZ2MXHWwkygH0yX1Dlm8fg/edit
 */
export const CSV_STRINGIFY_DEFAULT_OPTIONS = {
  record_delimiter: "windows", // CR+LF
  bom: false,
  delimiter: ",",
  eof: true,
  escape: '"',
  quote: '"',
  quoted: false, // quote only when needed
  header: true,
};

/**
 * Maximum enforced length of a team name in characters.
 */
export const MAX_TEAM_NAME_LENGTH = 50;

/**
 * Maximum number of teams that can be fetched in a single paginated API call.
 */
export const MAX_LIMIT_LIST_TEAMS = 1000;

/**
 * Maximum number of team members that can be fetched in a single paginated API call.
 */
export const MAX_LIMIT_LIST_TEAM_MEMBERS = 1000;

/**
 * Default number of teams to fetch in a single paginated API call, if no limit is specified.
 */
export const DEFAULT_LIMIT_LIST_TEAMS = 20;

/**
 * Default number of team members to fetch in a single paginated API call, if no limit is specified.
 */
export const DEFAULT_LIMIT_LIST_TEAM_MEMBERS = 20;

/******************************************************************************
 *
 * HubSpot constants
 *
 *****************************************************************************/

/**
 * HubSpot company model ID.
 */
export const HUBSPOT_COMPANY_MODEL_ID = "0-2";

/**
 * HubSpot company model name.
 */
export const HUBSPOT_COMPANY_MODEL_NAME = "companies";

/**
 * HubSpot deal model ID.
 */
export const HUBSPOT_DEAL_MODEL_ID = "0-3";

/**
 * HubSpot deal model name.
 */
export const HUBSPOT_DEAL_MODEL_NAME = "deals";

/**
 * Name of the HubSpot association between companies and yoodli_instances.
 */
export const HUBSPOT_ASSOCIATION_COMPANY_TO_YOODLI_INSTANCE = "company_to_yoodli_instances";

/**
 * Name of the HubSpot association between deals and yoodli_instances.
 */
export const HUBSPOT_ASSOCIATION_DEAL_TO_YOODLI_INSTANCE = "deal_to_yoodli_instances";

/**
 * Name of the HubSpot association between deals and companies.
 */
export const HUBSPOT_ASSOCIATION_DEAL_TO_COMPANY = "deal_to_company";

/**
 * Name of the HubSpot Yoodli instance model.
 */
export const HUBSPOT_YOODLI_INSTANCES_NAME = "yoodli_instances";

/**
 * How long to wait after creating an object before it shows up
 * in HubSpot search results.
 */
export const HUBSPOT_OBJECT_CREATION_TIMEOUT_MILLISECONDS = 60 * 1000;

/**
 * HubSpot test timeout in milliseconds.
 */
export const HUBSPOT_TEST_TIMEOUT_MS = 10 * 60 * 1000;

/**
 * A constant array containing the names of all European Union (EU) countries.
 */
export const EU_COUNTRIES = [
  "austria",
  "belgium",
  "bulgaria",
  "croatia",
  "cyprus",
  "czech republic",
  "denmark",
  "estonia",
  "finland",
  "france",
  "germany",
  "greece",
  "hungary",
  "ireland",
  "italy",
  "latvia",
  "lithuania",
  "luxembourg",
  "malta",
  "netherlands",
  "poland",
  "portugal",
  "romania",
  "slovakia",
  "slovenia",
  "spain",
  "sweden",
];
