// Components
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";

// Assets
import { ReactComponent as CalendarIcon } from "images/icons/icon-calendar.svg";
import { ReactComponent as PeopleIcon } from "images/icons/icon-people.svg";
import { ReactComponent as ScenarioIcon } from "images/icons/icon-scenario.svg";
import { CheckCircleRounded as CheckCircleRoundedIcon } from "@mui/icons-material";
// Utils
import { ProgramDetails } from "./ProgramDetails";
import { ProgramStatus } from "./ProgramStatus";
import { UserActivity } from "./UserActivity";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { ProgramResponse } from "lib-fullstack/api/programApiTypes";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { ProgramState } from "lib-fullstack/utils/enums";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import { OrgProgramsQueryParams } from "lib-fullstack/utils/queryParams";
import { useLocation, useNavigate } from "react-router";
import { useProgramMutations } from "./hooks/useProgramMutations";
import React from "react";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalState,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import { getProgramMemberPath } from "lib-frontend/utils/orgUtils";
import { copyToMyClipboard } from "lib-frontend/utils/Utilities";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { useQueryClient } from "@tanstack/react-query";
import { OrgProgramsQueryKeys } from "./OrgPrograms";
type ProgramLibraryItemProps = {
  program: ProgramResponse;
  handleSelectProgram: (programId: string) => void;
};

export const ProgramLibraryItem = ({
  program,
  handleSelectProgram,
}: ProgramLibraryItemProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const [deleteModalState, setDeleteModalState] = React.useState<YoodliCtaModalState>(undefined);
  const [practiceLinkCopied, setPracticeLinkCopied] = React.useState(false);
  const practiceLinkCopiedTimeout = React.useRef<NodeJS.Timeout>(null);

  const programDetails = [
    {
      icon: <CalendarIcon style={{ color: getDynamicColor("purple3") }} />,
      text: `Created ${getHumanReadableDate(program.created_date)}`,
    },
    {
      icon: <ScenarioIcon fill={getDynamicColor("purple3")} />,
      text: `${program.plan_steps.length} scenarios`,
    },
    {
      icon: <PeopleIcon />,
      text: `${program.hub_ids.length} groups`,
    },
  ];

  const { deleteProgramMutation, duplicateProgramMutation } = useProgramMutations();

  return (
    <>
      <Stack
        direction="row"
        gap={1}
        sx={{
          position: "relative",
          borderRadius: "8px",
          border: `1px solid ${getDynamicColor("dark3")}`,
          alignItems: "center",
          justifyContent: "space-between",
          px: { xs: 2, md: 3 },
        }}
      >
        <Stack gap={2} sx={{ py: 3 }}>
          <Stack gap={1}>
            <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                {program.name}
              </Typography>
              <ProgramStatus state={program.state as ProgramState} />
            </Stack>
            <ProgramDetails programDetails={programDetails} />
          </Stack>
          <Typography
            sx={{
              color: getDynamicColor(program.description.length ? "purple3" : "dark4"),
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            {program.description.length ? program.description : "No description"}
          </Typography>
          <Button
            onClick={() => handleSelectProgram(program.id)}
            variant="outlined"
            sx={{ mt: { xs: 0, md: 2 }, fontSize: "12px" }}
          >
            View program
          </Button>
        </Stack>
        {!isSmallScreen && (
          <Box
            sx={{
              borderLeft: `1px solid ${getDynamicColor("dark3")}`,
              alignSelf: "stretch",
              display: "flex",
              alignItems: "center",
              pl: 3,
              width: "250px",
            }}
          >
            <UserActivity userActivity={program.user_activity} label="User activity" />
          </Box>
        )}
        <YoodliMenu
          type={YoodliMenuButtonType.Icon}
          menuItems={[
            {
              title: duplicateProgramMutation.isPending ? (
                <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
                  <CircularProgress size={16} />
                  <Typography>Duplicating...</Typography>
                </Stack>
              ) : (
                "Duplicate"
              ),
              onClick: () =>
                duplicateProgramMutation.mutate(program, {
                  onSuccess: () => {
                    const qp = new URLSearchParams(location.search);
                    qp.set(OrgProgramsQueryParams.SUBTAB, ProgramState.Draft);
                    navigate({ search: qp.toString() }, { replace: true });
                  },
                }),
              type: YoodliMenuItemType.Default,
              disabled: duplicateProgramMutation.isPending,
              keepOpen: true,
            },
            program?.state === ProgramState.Published && {
              title: practiceLinkCopied ? (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography>Link copied</Typography>
                  <CheckCircleRoundedIcon
                    sx={{
                      color: getDynamicColor("greenSuccess"),
                      height: 20,
                      width: 20,
                    }}
                  />
                </Stack>
              ) : (
                "Copy practice link"
              ),
              onClick: () => {
                if (practiceLinkCopiedTimeout.current) {
                  clearTimeout(practiceLinkCopiedTimeout.current);
                }
                copyToMyClipboard(getProgramMemberPath(program.id, defaultOrgId));
                setPracticeLinkCopied(true);
                practiceLinkCopiedTimeout.current = setTimeout(() => {
                  setPracticeLinkCopied(false);
                }, 3000);
              },
              disableCloseOnClick: true,
              keepOpen: true,
            },
            {
              title: "Delete",
              onClick: () => setDeleteModalState(YoodliCtaModalState.Cta),
              type: YoodliMenuItemType.Warning,
            },
          ].filter(Boolean)}
          buttonSx={{ border: "none", position: "absolute", right: "6px", top: "8px" }}
        />
      </Stack>
      <YoodliCtaModal
        open={!!deleteModalState}
        close={() => {
          if (deleteModalState === YoodliCtaModalState.Success) {
            void queryClient.invalidateQueries({
              queryKey: [OrgProgramsQueryKeys.OrgPrograms, defaultOrgId],
            });
          } else {
            setDeleteModalState(undefined);
          }
        }}
        state={deleteModalState}
        ctaBody={{
          title: `Are you sure you want to delete ${program.name}?`,
          subtitle:
            "This will remove it from your library and any progress you made will not be saved",
        }}
        successBody={{
          title: "Program deleted",
          subtitle: "You have successfully deleted this program",
        }}
        buttons={
          {
            primary: {
              text: "Confirm delete",
              handler: () =>
                deleteProgramMutation.mutate(program?.id, {
                  onSuccess: () => {
                    setDeleteModalState(YoodliCtaModalState.Success);
                  },
                }),
            },
            secondary: { text: "Cancel", handler: () => setDeleteModalState(undefined) },
          } as CtaButtonHandlers
        }
      />
    </>
  );
};
