import React from "react";

// Components
import {
  ExpandMore as ExpandMoreIcon,
  EditOutlined as EditOutlinedIcon,
  Check as CheckIcon,
  CheckCircleRounded as CheckCircleRoundedIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Stack,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { CustomizePracticeQueryKey } from "components/ConvoScenarios/convoScenarioUtils";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { GroupAssignmentMenu } from "../Scenarios/GroupAssignmentMenu";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  createOrgInterviewBank,
  deleteOrgInterviewBank,
  updateOrgInterviewBank,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getInterviewQuestionBankPracticePath } from "lib-frontend/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { copyToMyClipboard } from "lib-frontend/utils/Utilities";
import { CopyInterviewBankRequest, InterviewBankResponse } from "lib-fullstack/api/hubApiTypes";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { EventHubsWheres } from "lib-fullstack/utils/productAnalyticEvents";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { CreateContentRequestKind } from "lib-fullstack/db";

type InterviewBankCardProps = {
  interviewBank: InterviewBankResponse;
  handleSetInterviewBank: (bank: InterviewBankResponse | null) => void;
};

export const InterviewBankCard = ({
  interviewBank,
  handleSetInterviewBank,
}: InterviewBankCardProps): JSX.Element => {
  const queryClient = useQueryClient();

  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId, copyContentInfo } = React.useContext(ContentSpacesContext);
  const [descriptionOpen, setDescriptionOpen] = React.useState(false);
  const [updatedName, setUpdatedName] = React.useState<string>(undefined);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [practiceLinkCopied, setPracticeLinkCopied] = React.useState(false);
  const practiceLinkCopiedTimeout = React.useRef<NodeJS.Timeout>(null);

  const updateNameMutation = useMutation({
    mutationFn: async () => {
      await updateOrgInterviewBank(
        defaultOrgId,
        interviewBank.id,
        updatedName,
        interviewBank.description,
        interviewBank.interview_questions,
        interviewBank.available_hubs,
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CustomizePracticeQueryKey.InterviewBanks, defaultOrgId],
      });
      setUpdatedName(undefined);
    },
  });

  const { mutateAsync: handleInterviewBankDeletion, isPending: interviewBankDeletionLoading } =
    useMutation({
      mutationFn: async (bankId: string) => {
        await deleteOrgInterviewBank(defaultOrgId, bankId);
        return bankId;
      },
      onSuccess: async (bankId: string) => {
        await queryClient.invalidateQueries({
          queryKey: [CustomizePracticeQueryKey.InterviewBanks, defaultOrgId],
        });
        Instrumentation.logOrgInterviewBankDeleted(
          defaultOrgId,
          bankId,
          EventHubsWheres.ORG_SETTINGS,
        );
      },
      onError: (error) => {
        console.error("Error deleting interview bank: ", error);
      },
    });

  const handleUpdateGroupAssignment = async (groupIds: string[]) => {
    let error = null;
    try {
      // save the scenarioHubIds to the scenario in db
      await updateOrgInterviewBank(
        defaultOrgId,
        interviewBank.id,
        interviewBank.name,
        interviewBank.description,
        interviewBank.interview_questions,
        groupIds,
      );
    } catch (e) {
      console.error(
        `Error updating interview bank active groups with id ${interviewBank.id}: ${e}`,
      );
      error = e;
    } finally {
      void queryClient.refetchQueries({
        queryKey: [CustomizePracticeQueryKey.InterviewBanks, defaultOrgId],
      });
    }
    return error;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (updatedName !== undefined) {
        if (updatedName !== interviewBank.name && updatedName.length > 0) {
          updateNameMutation.mutate();
        } else {
          setUpdatedName(undefined);
        }
      } else {
        setUpdatedName(interviewBank.name);
      }
    }
  };

  return (
    <Stack
      alignItems={{ xs: "flex-start", sm: "center" }}
      justifyContent="space-between"
      direction={{ xs: "column", sm: "row" }}
      gap={1}
      sx={{
        position: "relative",
        fontFamily: "poppins",
        borderRadius: "4px",
        p: 2.5,
        border: `1px solid ${getDynamicColor("dark3")}`,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          width: "100%",
          flexGrow: 1,
        }}
      >
        <Stack
          gap={1}
          sx={{
            flexGrow: 1,
            flexWrap: "wrap",
            maxWidth: "100%",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            sx={{
              gap: { xs: 1, md: 4 },
              alignItems: { xs: "flex-start", sm: "center" },
              justifyContent: "space-between",
              position: "relative",
              width: "100%",
            }}
          >
            <Stack
              direction="row"
              sx={{ gap: 1, alignItems: "center", width: "100%", overflow: "hidden" }}
            >
              {updatedName !== undefined ? (
                <YoodliTextfield
                  disabled={updateNameMutation.isPending}
                  value={updatedName}
                  onChange={(e) => setUpdatedName(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="e.g. Hooli sales training"
                  maxChars={100}
                  InputProps={{ sx: { height: "35px", fontWeight: 600 } }}
                  fullWidth
                />
              ) : (
                <Typography
                  onClick={() => {
                    handleSetInterviewBank(interviewBank);
                  }}
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: 1.3,
                    "&:hover": {
                      cursor: "pointer",
                    },
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {interviewBank.name}
                </Typography>
              )}
              {updateNameMutation.isPending ? (
                <CircularProgress size={18} />
              ) : (
                <IconButton
                  disabled={updateNameMutation.isPending}
                  sx={{
                    color: getDynamicColor("primary"),
                    width: 20,
                    height: 20,
                  }}
                  onClick={() => {
                    if (updatedName !== undefined) {
                      if (updatedName !== interviewBank.name && updatedName.length > 0) {
                        updateNameMutation.mutate();
                      } else {
                        setUpdatedName(undefined);
                      }
                    } else {
                      setUpdatedName(interviewBank.name);
                    }
                  }}
                >
                  {updatedName !== undefined ? (
                    <CheckIcon sx={{ width: 18, height: 18 }} />
                  ) : (
                    <EditOutlinedIcon sx={{ width: 18, height: 18 }} />
                  )}
                </IconButton>
              )}
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={{ xs: "space-between", sm: "flex-end" }}
              gap={2}
              sx={{
                width: { xs: "100%", sm: "unset" },
              }}
            >
              <GroupAssignmentMenu
                activeGroupIds={interviewBank.available_hubs}
                handleUpdateGroupAssignment={handleUpdateGroupAssignment}
              />
              <YoodliMenu
                type={YoodliMenuButtonType.Icon}
                menuItems={[
                  {
                    title: "Edit",
                    type: YoodliMenuItemType.Primary,
                    onClick: () => {
                      handleSetInterviewBank(interviewBank);
                    },
                    keepOpen: true,
                  },
                  {
                    title: practiceLinkCopied ? (
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Typography>Link copied</Typography>
                        <CheckCircleRoundedIcon
                          sx={{
                            color: getDynamicColor("greenSuccess"),
                            height: 20,
                            width: 20,
                          }}
                        />
                      </Stack>
                    ) : (
                      "Copy practice link"
                    ),
                    type: YoodliMenuItemType.Primary,
                    onClick: () => {
                      if (practiceLinkCopiedTimeout.current) {
                        clearTimeout(practiceLinkCopiedTimeout.current);
                      }

                      copyToMyClipboard(
                        getInterviewQuestionBankPracticePath(interviewBank.id, defaultOrgId, true),
                      );
                      setPracticeLinkCopied(true);
                      practiceLinkCopiedTimeout.current = setTimeout(() => {
                        setPracticeLinkCopied(false);
                      }, 3000);
                    },
                    keepOpen: true,
                  },
                  {
                    title: "Duplicate",
                    type: YoodliMenuItemType.Primary,
                    onClick: async () => {
                      const bankRequest: CopyInterviewBankRequest = {
                        request_type: CreateContentRequestKind.Copy,
                        space_id: curSpaceId,
                        source_bank_id: interviewBank.id,
                        destination_space_id: curSpaceId,
                      };
                      await createOrgInterviewBank(defaultOrgId, bankRequest);
                      void queryClient.invalidateQueries({
                        queryKey: [
                          CustomizePracticeQueryKey.InterviewBanks,
                          defaultOrgId,
                          curSpaceId,
                        ],
                      });
                    },
                  },
                  {
                    title: "Copy to",
                    type: YoodliMenuItemType.Primary,
                    onClick: async () => {
                      copyContentInfo.handleIsCopyDrawerOpen(true);
                      copyContentInfo.handleSetCopyContentRequest({
                        copyHandler: async (destinationSpaceId: string) => {
                          const bankRequest: CopyInterviewBankRequest = {
                            request_type: CreateContentRequestKind.Copy,
                            space_id: curSpaceId,
                            source_bank_id: interviewBank.id,
                            destination_space_id: destinationSpaceId,
                          };
                          await createOrgInterviewBank(defaultOrgId, bankRequest);
                          if (curSpaceId === destinationSpaceId) {
                            void queryClient.invalidateQueries({
                              queryKey: [
                                CustomizePracticeQueryKey.InterviewBanks,
                                defaultOrgId,
                                curSpaceId,
                              ],
                            });
                          }
                        },
                        content: {
                          name: interviewBank.name,
                          type: "Question bank",
                        },
                      });
                    },
                  },
                  {
                    title: "Delete bank",
                    type: YoodliMenuItemType.Warning,
                    disabled: interviewBank.usage > 0,
                    disabledTooltip: "Question bank is in use",
                    onClick: () => {
                      setOpenDeleteModal(true);
                    },
                  },
                ]}
              />
            </Stack>
          </Stack>
          {interviewBank.description?.length > 100 ? (
            <Accordion
              sx={{
                boxShadow: "none",
                fontSize: 12,
                mb: "0px !important",
                color: getDynamicColor("purple3"),
                maxWidth: "100%",
                "&:before": {
                  display: "none",
                },
                "&.Mui-expanded": {
                  my: 0,
                },
              }}
              onChange={(e, expanded) => {
                setDescriptionOpen(expanded);
              }}
            >
              <AccordionSummary
                sx={{
                  my: 0,
                  px: 0,
                  width: "fit-content",
                  minHeight: "unset !important",
                  ".MuiAccordionSummary-content, .Mui-expanded": {
                    my: "0px !important",
                    mr: 0.5,
                  },
                }}
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: getDynamicColor("primary"),
                      height: 20,
                      width: 20,
                    }}
                  />
                }
              >
                {descriptionOpen ? "Hide" : "Show"} description
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  lineHeight: 1.4,
                  pb: "8px !important",
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {interviewBank.description}
              </AccordionDetails>
            </Accordion>
          ) : interviewBank.description?.length > 0 ? (
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 400,
                lineHeight: 1.4,
              }}
            >
              {interviewBank.description}
            </Typography>
          ) : null}
          <Stack
            direction="row"
            columnGap={2}
            rowGap={1}
            alignItems="center"
            sx={{
              flexWrap: "wrap",
              fontSize: "12px",
            }}
          >
            <Stack
              direction="row"
              sx={{
                gap: 1,
                color: getDynamicColor("purple3"),
                textTransform: "uppercase",
              }}
            >
              <Typography>
                {interviewBank.interview_questions.length} question
                {interviewBank.interview_questions.length > 1 && "s"}
              </Typography>
              {interviewBank.usage > 0 && (
                <>
                  <Typography>•</Typography>
                  <Typography>
                    Used by {interviewBank.usage} scenario
                    {interviewBank.usage > 1 && "s"}
                  </Typography>
                </>
              )}
            </Stack>
            <Typography
              sx={{
                color: getDynamicColor("dark4"),
              }}
            >
              Created by: {interviewBank.createdByEmail ?? "unknown"}{" "}
              {interviewBank.createdDate && (
                <Box component="span">({getHumanReadableDate(interviewBank.createdDate)})</Box>
              )}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <YoodliCtaModal
        ctaBody={{
          title: (
            <>
              Are you sure you want to delete{" "}
              <strong style={{ color: getDynamicColor("primary") }}>{interviewBank.name}</strong>?
            </>
          ),
          subtitle:
            "You will no longer be able to use this question bank in any scenario or interview. This action cannot be undone.",
        }}
        open={openDeleteModal}
        theme={YoodliCtaModalTheme.Danger}
        loading={interviewBankDeletionLoading}
        hideCloseButton={true}
        close={() => setOpenDeleteModal(false)}
        buttons={
          {
            primary: {
              text: "Delete question bank",
              handler: async () => {
                await handleInterviewBankDeletion(interviewBank.id).catch((e) => console.error(e));
              },
            },
            secondary: { text: "Cancel", handler: () => setOpenDeleteModal(false) },
          } as CtaButtonHandlers
        }
      />
    </Stack>
  );
};
