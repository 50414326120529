import React from "react";

// Utils
import { SortStatus, TableSortBase } from "./TableSortBase";

export type TableSortServerProps = {
  columnId: string;
  setSortFilter?: (columnId: string) => void;
  currentSort?: string;
};

export const TableSortServer = ({
  columnId,
  setSortFilter,
  currentSort,
}: TableSortServerProps): JSX.Element => {
  const getInitialSortStatus = React.useCallback((): SortStatus => {
    if (!currentSort) return SortStatus.NONE;

    const isCurrentColumn = currentSort.startsWith("-")
      ? currentSort.substring(1) === columnId
      : currentSort === columnId;

    if (!isCurrentColumn) return SortStatus.NONE;

    return currentSort.startsWith("-") ? SortStatus.DESC : SortStatus.ASC;
  }, [currentSort, columnId]);

  const [sortCreated, setSortCreated] = React.useState<SortStatus>(getInitialSortStatus());

  React.useEffect(() => {
    setSortCreated(getInitialSortStatus());
  }, [currentSort, columnId, getInitialSortStatus]);

  const handleSortCreated = () => {
    if (sortCreated === SortStatus.NONE) {
      setSortCreated(SortStatus.ASC);
      setSortFilter(columnId);
    } else if (sortCreated === SortStatus.ASC) {
      setSortCreated(SortStatus.DESC);
      setSortFilter("-" + columnId);
    } else {
      setSortCreated(SortStatus.NONE);
      setSortFilter(undefined);
    }
  };

  return (
    <TableSortBase
      sortCreated={sortCreated}
      setSortCreated={setSortCreated}
      handleSortCreated={handleSortCreated}
    />
  );
};
