import React from "react";
import { useNavigate, useLocation } from "react-router";

// Components
import {
  Check as CheckIcon,
  ChevronLeft as ChevronLeftIcon,
  EditOutlined as EditOutlinedIcon,
} from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { CustomizePracticeQueryKey } from "components/ConvoScenarios/convoScenarioUtils";
import { CustomizePracticeTab } from "components/Orgs/ManageContent/CustomizePractice/CustomizePracticeTab";
import { CoachBotLibrary } from "components/Orgs/ManageContent/ManageCoachBotTab/CoachBotLibrary";
import { LearningMaterialsTab } from "components/Orgs/ManageContent/VideosAndCourses/LearningMaterialsTab";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import { ADMIN_HEADER_HEIGHT, defaultHeaderSx } from "lib-frontend/ui/Theme";
import OrgPrograms, {
  OrgProgramsSectionStatus,
} from "webclient/src/components/Orgs/Programs/OrgPrograms";

// Assets
import { ReactComponent as ContentIcon } from "images/icons/icon-content.svg";
import { ReactComponent as StarIcon } from "images/icons/icon-star.svg";

// Utils
import { OrgLoading } from "../OrgLoading";
import { TrialEnded } from "../TrialEnded";
import { TrialEndedBanner } from "../TrialEndedBanner";
import ManageContentSpacesDrawer from "./ManageContentSpacesDrawer";
import { OrgContentPermissionsTab } from "./OrgContentPermissionsTab";
import { OrgCreateSpace } from "./OrgCreateSpace";
import { OrgFileLibrary } from "./OrgFileLibrary/OrgFileLibrary";
import { OrgManageContentModal } from "./OrgManageContentModal";
import { useQuery as useApiQuery, useQueryClient } from "@tanstack/react-query";
import useCourseContentAndDemoVideosV2 from "hooks/useCourseContentAndDemoVideosV2";
import { useQueryParamState } from "hooks/useQueryParamState";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getOrgContentView, listOrgInterviewBanks } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";
import { OrgSettingsTabLabel, OrgSettingsTabs, isOrgTrialEnded } from "lib-frontend/utils/orgUtils";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { GetScenarioResponse } from "lib-fullstack/api/scenarioApiTypes";
import { ContentVideoState } from "lib-fullstack/utils/enums";
import {
  OrgProgramsQueryParams,
  OrgContentQueryParams,
  OrgSettingsQueryParams,
} from "lib-fullstack/utils/queryParams";
import { HubModalStatus } from "utils/Enums";

export const VIDEOS_AND_COURSES_QUERY_KEY = "videosAndCourses";
export const INTERVIEW_BANKS_QUERY_KEY = "interviewBanks";

type EditableFieldProps = {
  value: string;
  onChange: (value: string) => void;
};

const EditableField = ({ value, onChange }: EditableFieldProps) => {
  const actionButton = React.useRef<HTMLButtonElement | null>(null);
  const [updatedValue, setUpdatedValue] = React.useState<string | null>(null);
  const [initialValue, setInitialValue] = React.useState<string | null>(null);

  const handleEditExit = () => {
    setUpdatedValue(null);
    setInitialValue(null);
  };

  return (
    <Stack
      direction="row"
      sx={{
        width: "100%",
        gap: 1,
        alignItems: "center",
        display: "inline-flex",
      }}
    >
      {updatedValue !== null ? (
        <YoodliTextfield
          value={updatedValue}
          onChange={(e) => setUpdatedValue(e.target.value)}
          placeholder=""
          maxChars={50}
          hideCharCount
          autoFocus
          fullWidth
          sx={{
            maxWidth: "50ch",
          }}
          InputProps={{
            sx: {
              height: 24,
              fontWeight: 500,
            },
          }}
          onBlur={(e) => {
            if (e.relatedTarget !== actionButton.current) {
              handleEditExit();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              handleEditExit();
            } else if (e.key === "Enter") {
              if (updatedValue !== initialValue && updatedValue.trim().length > 0) {
                onChange(updatedValue);
              }
              handleEditExit();
            }
          }}
        />
      ) : (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: getDynamicColor("light1"),
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "inline-block",
          }}
        >
          {value}
        </Typography>
      )}
      <IconButton
        ref={actionButton}
        sx={{
          color: getDynamicColor("light1"),
          width: 20,
          height: 20,
        }}
        onClick={() => {
          if (updatedValue) {
            if (updatedValue !== value && updatedValue.length > 0) {
              onChange(updatedValue);
            }
            handleEditExit();
          } else {
            setInitialValue(value);
            setUpdatedValue(value);
          }
        }}
      >
        {updatedValue?.length > 0 ? (
          <CheckIcon sx={{ width: 18, height: 18 }} />
        ) : updatedValue === null ? (
          <EditOutlinedIcon sx={{ width: 18, height: 18 }} />
        ) : null}
      </IconButton>
    </Stack>
  );
};

export default function OrgManageContent(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const {
    defaultOrgId,
    adminInfo: { defaultOrg, defaultOrgLoading },
  } = React.useContext(UserOrgContext);
  const { notifAnchorRef } = useNotification();

  const [selectedTab, setSelectedTab] = React.useState<OrgSettingsTabs>(
    OrgSettingsTabs.CUSTOMIZE_PRACTICE,
  );
  const [modalStatus, setModalStatus] = React.useState<HubModalStatus>(HubModalStatus.CLOSED);
  const [navGuardModalOpen, setNavGuardModalOpen] = React.useState<boolean>(false);

  const [shouldBlockNav, setShouldBlockNav] = React.useState<boolean>(false);

  const [showScenarioTemplates, setShowScenarioTemplates] = useQueryParamState(
    OrgContentQueryParams.SHOW_SCENARIO_TEMPLATES,
    undefined,
  );
  const [templateSelected, setTemplateSelected] = React.useState<GetScenarioResponse>(null);

  const [showBackHeader, setShowBackHeader] = React.useState<boolean>(!!showScenarioTemplates);

  const [contentSpacesDrawerOpen, setContentSpacesDrawerOpen] = React.useState<boolean>(false);

  const { spaces, curSpaceId, updateCurrentSpace, copyContentInfo } =
    React.useContext(ContentSpacesContext);

  const isSmallScreen = useIsSmallScreen();

  React.useEffect(() => {
    if (showScenarioTemplates) {
      setTemplateSelected(null);
      setShowBackHeader(true);
    }
  }, [showScenarioTemplates]);

  React.useEffect(() => {
    const qp = new URLSearchParams(location.search);
    const tab = qp.get(OrgContentQueryParams.TAB);

    if (tab && defaultOrg && tab === OrgSettingsTabs.COACHBOT && !defaultOrg?.coach_bot_enabled) {
      qp.set(OrgContentQueryParams.TAB, OrgSettingsTabs.CUSTOMIZE_PRACTICE);
      navigate(
        {
          pathname: location.pathname,
          search: qp.toString(),
        },
        { replace: true },
      );
      setSelectedTab(OrgSettingsTabs.CUSTOMIZE_PRACTICE);
      return;
    }

    setSelectedTab((prevTab) => {
      if (tab && tab !== prevTab) {
        window.scrollTo(0, 0);
      }

      if (tab && defaultOrg) {
        return tab as OrgSettingsTabs;
      }

      return prevTab;
    });
  }, [defaultOrg, location]);

  const handleBack = (fromNavGuardModal?: boolean) => {
    const qp = new URLSearchParams(location.search);
    // if user is trying to navigate back while blocked, show modal
    if (shouldBlockNav && !fromNavGuardModal) {
      setNavGuardModalOpen(true);
    }
    // return to scenario template selection
    else if (templateSelected) {
      setTemplateSelected(null);
    }
    // return to programs tab, with a program selected
    // this can happen if a user is creating a scenario from a program
    // in which case we want to nav back to the program they came from
    else if (
      qp.get(OrgProgramsQueryParams.PROGRAM_ID) &&
      qp.get(OrgSettingsQueryParams.TAB) !== OrgSettingsTabs.PROGRAMS
    ) {
      qp.delete(OrgContentQueryParams.SHOW_SCENARIO_TEMPLATES);
      qp.set(OrgSettingsQueryParams.TAB, OrgSettingsTabs.PROGRAMS);
      qp.set(OrgProgramsQueryParams.SECTION, OrgProgramsSectionStatus.ProgramInfo);
      navigate({
        pathname: location.pathname,
        search: qp.toString(),
      });
    }
    // return to customize practice tab (no template selected/default)
    // or if on programs tab, return to the correct section based on current section status
    else {
      setShowBackHeader(false);
      handleBackProgramsSection();
      setShowScenarioTemplates(undefined);
      setShowSpaceCreate(false);
    }
  };

  const handleBackProgramsSection = () => {
    switch (sectionStatus) {
      case OrgProgramsSectionStatus.SendProgramReminder:
        setSectionStatus(OrgProgramsSectionStatus.ProgramInfo);
        break;
      default:
        setSectionStatus(OrgProgramsSectionStatus.Default);
        break;
    }
  };

  const videosAndCoursesQueryResult = useApiQuery({
    queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, defaultOrgId, curSpaceId],
    queryFn: async () => getOrgContentView(defaultOrgId, { space_id: curSpaceId }),
    enabled: !!defaultOrg && !!curSpaceId,
  });

  const videosAndCourses = videosAndCoursesQueryResult.data;

  const interviewBanksQueryResult = useApiQuery({
    queryKey: [CustomizePracticeQueryKey.InterviewBanks, defaultOrg?.id, curSpaceId],
    queryFn: async () => listOrgInterviewBanks(defaultOrg?.id, { space_id: curSpaceId }),
    enabled: !!defaultOrg && !!curSpaceId,
  });

  const interviewBanks = interviewBanksQueryResult.data;

  const {
    handleUpdateCourseVideos,
    handleCopyCourseVideo,
    handleCopyCourse,
    handleCreateCourseVideos,
    handleCreateCourse,
    handleUpdateCourseVideo,
    handleDeleteCourseVideo,
    clearUploadingVideos,
    handleCreateDemoVideos,
    handleUpdateDemoVideo,
    handleDeleteDemoVideo,
    uploading,
    uploadingVideos,
    setSelectedCourse,
    selectedCourse,
    handleUpdateCourse,
    handleDeleteCourse,
    handleCopyDemoVideo,
  } = useCourseContentAndDemoVideosV2(
    defaultOrgId,
    defaultOrg?.hubs.find((hub) => hub.org_default)?.id,
  );

  // If there is a processing video, poll for updates
  React.useEffect(() => {
    if (
      !videosAndCourses ||
      (!videosAndCourses?.demo_videos.some(
        (video) => video.state === ContentVideoState.PROCESSING,
      ) &&
        !videosAndCourses.courses.some((course) =>
          course.videos.some((video) => video.state === ContentVideoState.PROCESSING),
        ))
    ) {
      return;
    }
    const intervalId = setInterval(() => {
      void queryClient.invalidateQueries({
        queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, defaultOrgId],
      });
    }, 5000);
    return () => clearInterval(intervalId);
  }, [videosAndCourses]);

  const [showSpaceCreate, setShowSpaceCreate] = React.useState<boolean>(false);

  const [sectionStatus, setSectionStatus] = useQueryParamState(
    OrgProgramsQueryParams.SECTION,
    OrgProgramsSectionStatus.Default,
  );

  // HACK: skip defaultOrgLoading while on OrgContentPermissionsTab to prevent it from unmounting during Invite Space Admin flow.
  const loading =
    !defaultOrg ||
    !curSpaceId ||
    (defaultOrgLoading && selectedTab !== OrgSettingsTabs.PERMISSIONS);
  if (loading) {
    return <OrgLoading prompts={["Loading organization content..."]} />;
  }

  const renderTab = () => {
    if (isOrgTrialEnded(defaultOrg)) {
      return (
        <Box sx={{ pt: 4 }}>
          <TrialEnded org={defaultOrg} />
        </Box>
      );
    }
    switch (selectedTab) {
      case OrgSettingsTabs.CUSTOMIZE_PRACTICE:
      default:
        return (
          <CustomizePracticeTab
            selectedOrg={defaultOrg}
            selectedOrgInterviewBanks={interviewBanks}
            shouldBlockNav={shouldBlockNav}
            setShouldBlockNav={setShouldBlockNav}
            setShowBackHeader={setShowBackHeader}
            showBackHeader={showBackHeader}
            showScenarioTemplates={showScenarioTemplates}
            setShowScenarioTemplates={setShowScenarioTemplates}
            templateSelected={templateSelected}
            setTemplateSelected={setTemplateSelected}
          />
        );
      case OrgSettingsTabs.LEARNING_MATERIALS:
        return (
          <LearningMaterialsTab
            loading={videosAndCoursesQueryResult.isPending || loading}
            setModalStatus={setModalStatus}
            handleCopyCourse={handleCopyCourse}
            contentView={videosAndCourses}
            selectedOrgId={defaultOrgId}
            setSelectedCourse={setSelectedCourse}
            isHubAdmin
          />
        );
      case OrgSettingsTabs.COACHBOT:
        if (defaultOrg?.coach_bot_enabled) {
          return <CoachBotLibrary setShouldBlockNav={setShouldBlockNav} />;
        }
        break;
      case OrgSettingsTabs.FILES:
        return <OrgFileLibrary />;
      case OrgSettingsTabs.PERMISSIONS:
        return (
          <OrgContentPermissionsTab
            showBackHeader={showBackHeader}
            setShowBackHeader={setShowBackHeader}
          />
        );
      case OrgSettingsTabs.PROGRAMS:
        return (
          <OrgPrograms
            sectionStatus={sectionStatus}
            setSectionStatus={setSectionStatus}
            setShowBackHeader={setShowBackHeader}
          />
        );
    }
  };

  const handleCreateContentSpace = () => {
    setContentSpacesDrawerOpen(false);
    setShowBackHeader(true);
    setShowSpaceCreate(true);
  };

  const handleContentSpaceClose = () => {
    setContentSpacesDrawerOpen(false);
    copyContentInfo.handleIsCopyDrawerOpen(false);
  };

  const renderBackHeader = () => {
    const labelKey = Object.values(OrgSettingsTabs).find((value) => value === selectedTab);
    const qp = new URLSearchParams(location.search);
    let label: string;
    if (
      (showSpaceCreate && selectedTab === OrgSettingsTabs.CUSTOMIZE_PRACTICE) ||
      selectedTab === OrgSettingsTabs.PERMISSIONS
    ) {
      label = spaces.find((space) => space.id === curSpaceId)?.name;
    } else if (qp.get(OrgProgramsQueryParams.PROGRAM_ID) && !templateSelected) {
      label = selectedTab !== OrgSettingsTabs.PROGRAMS ? "Back to program" : "Back to all programs";
    } else if (templateSelected) {
      label = "Back to scenario templates";
    } else {
      label = `Back to ${OrgSettingsTabLabel[labelKey].toLowerCase()}`;
    }
    return (
      <Button
        variant="text"
        sx={{
          color: getDynamicColor("light1"),
          fontSize: 14,
        }}
        startIcon={<ChevronLeftIcon />}
        onClick={() => {
          handleBack();
        }}
      >
        {label}
      </Button>
    );
  };

  const currentSpace = spaces.find((space) => space.id === curSpaceId);
  const currentSpaceName = currentSpace?.name;

  return (
    <>
      <Stack
        direction="column"
        sx={{
          background: getDynamicColor("light1"),
          minHeight: "100dvh",
          position: "relative",
        }}
      >
        {
          <ManageContentSpacesDrawer
            open={contentSpacesDrawerOpen}
            setOpen={setContentSpacesDrawerOpen}
            handleClose={handleContentSpaceClose}
            handleCreate={handleCreateContentSpace}
          />
        }
        {showBackHeader ? (
          <Box
            ref={notifAnchorRef}
            sx={{
              ...defaultHeaderSx,
              px: { xs: 2, md: 3, lg: 4, xl: 5 },
              flexWrap: "wrap",
              alignItems: "center",
              display: "flex",
              position: "sticky",
              top: {
                xs: WEBCLIENT_TOP_NAVBAR_HEIGHT,
                md: 0,
              },
              left: 0,
              right: 0,
            }}
          >
            {renderBackHeader()}
          </Box>
        ) : (
          <Stack
            sx={{
              ...defaultHeaderSx,
              position: "sticky",
              top: {
                xs: WEBCLIENT_TOP_NAVBAR_HEIGHT,
                md: 0,
              },
              left: 0,
              right: 0,
              zIndex: 51,
            }}
            ref={notifAnchorRef}
          >
            <Stack
              direction="row"
              gap={2}
              sx={{
                minHeight: ADMIN_HEADER_HEIGHT,
                alignItems: "center",
                justifyContent: "space-between",
                px: { xs: 2, md: 3, lg: 4, xl: 5 },
              }}
            >
              {isOrgTrialEnded(defaultOrg) ? (
                <Stack gap={2} alignItems={"center"} direction={"row"}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      color: getDynamicColor("light1"),
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                    }}
                  >
                    Organization Content
                  </Typography>
                  <TrialEndedBanner />
                </Stack>
              ) : (
                <>
                  <Stack
                    direction="row"
                    gap={1}
                    sx={{
                      maxWidth: "100%",
                      alignItems: "center",
                      flexGrow: 1,
                    }}
                  >
                    {/* isOrgTrialEnded(defaultOrg) ? <TrialEndedBanner />) */}
                    {currentSpace?.is_default && (
                      <Box
                        sx={{
                          svg: { display: "block", path: { fill: getDynamicColor("light1") } },
                        }}
                      >
                        <StarIcon style={{ height: 12, width: 12 }} />
                      </Box>
                    )}
                    <EditableField
                      value={currentSpaceName}
                      onChange={(name: string) => void updateCurrentSpace(name)}
                    />
                  </Stack>
                  <Button
                    onClick={() => setContentSpacesDrawerOpen(true)}
                    startIcon={
                      <Box
                        sx={{
                          svg: {
                            display: "block",
                            path: {
                              fill: "currentColor",
                            },
                          },
                        }}
                      >
                        <ContentIcon />
                      </Box>
                    }
                    sx={{
                      color: getDynamicColor("light1"),
                      fill: getDynamicColor("light1"),
                      borderRadius: "50px",
                      border: "2px solid",
                      fontSize: "14px",
                      lineHeight: "24px",
                    }}
                  >
                    {isSmallScreen ? "Manage" : "Manage Content Spaces"}
                  </Button>
                </>
              )}
            </Stack>
          </Stack>
        )}
        <Stack
          sx={{
            maxWidth: "xxl",
            width: "100%",
            mx: "auto",
          }}
        >
          {showSpaceCreate ? (
            <OrgCreateSpace onComplete={() => setShowSpaceCreate(false)} />
          ) : (
            renderTab()
          )}
        </Stack>
      </Stack>
      <OrgManageContentModal
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        allHubs={defaultOrg?.hubs ?? []}
        contentView={videosAndCourses}
        handleDeleteDemoVideo={handleDeleteDemoVideo}
        handleUpdateDemoVideo={handleUpdateDemoVideo}
        handleCreateDemoVideos={handleCreateDemoVideos}
        uploadingVideos={uploadingVideos}
        uploading={uploading}
        selectedCourse={selectedCourse}
        setSelectedCourse={setSelectedCourse}
        handleCreateCourseVideos={handleCreateCourseVideos}
        handleDeleteCourseVideo={handleDeleteCourseVideo}
        handleUpdateCourseVideo={handleUpdateCourseVideo}
        handleUpdateCourseVideos={handleUpdateCourseVideos}
        handleCopyCourseVideo={handleCopyCourseVideo}
        handleCreateCourse={handleCreateCourse}
        handleUpdateCourse={handleUpdateCourse}
        handleDeleteCourse={handleDeleteCourse}
        clearUploadingVideos={clearUploadingVideos}
        handleCopyDemoVideo={handleCopyDemoVideo}
      />
      <YoodliCtaModal
        ctaBody={{
          title: "Are you sure you want to leave this page?",
          subtitle: "Your changes might not have been saved!",
        }}
        open={navGuardModalOpen}
        theme={YoodliCtaModalTheme.Danger}
        hideCloseButton={true}
        close={() => setNavGuardModalOpen(false)}
        buttons={
          {
            primary: {
              text: "Discard changes",
              handler: () => {
                handleBack(true);
              },
            },
            secondary: { text: "Keep editing", handler: () => setNavGuardModalOpen(false) },
          } as CtaButtonHandlers
        }
      />
    </>
  );
}
