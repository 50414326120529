import React from "react";
// Components
import { WizardState } from "components/ConvoScenarios/convoScenarioUtils";
import {
  CreateConvoScenarioWizard,
  WizardType,
} from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CreateConvoScenarioWizard/CreateConvoScenarioWizard";
import { ManageConvoScenarios } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/ManageConvoScenarios";
import { SelectNewConvoScenarioType } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/SelectNewConvoScenarioType";
import { CustomizePracticeTabEnum } from "lib-frontend/utils/orgUtils";

// Utils
import { GetScenarioResponse } from "lib-fullstack/api/scenarioApiTypes";
import { OrgV2Response } from "lib-fullstack/api/orgApiTypes";

type ScenariosTabProps = {
  selectedOrg: OrgV2Response;
  templateSelected: GetScenarioResponse;
  wizardState: WizardState;
  setWizardState: React.Dispatch<React.SetStateAction<WizardState>>;
  handleTemplateSelected: (scenario: GetScenarioResponse, scenarioId?: string) => void;
  renderLoader: () => JSX.Element;
  showBackHeader: boolean;
  shouldBlockNav: boolean;
  setShowBackHeader: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldBlockNav: React.Dispatch<React.SetStateAction<boolean>>;
  showScenarioTemplates: boolean;
  setShowScenarioTemplates: React.Dispatch<React.SetStateAction<string>>;
  setHideTabsState: React.Dispatch<React.SetStateAction<boolean>>;
  scenarioTabType: CustomizePracticeTabEnum;
};
export const ScenariosTab = ({
  selectedOrg,
  templateSelected,
  wizardState,
  setWizardState,
  handleTemplateSelected,
  renderLoader,
  showBackHeader,
  setShowBackHeader,
  setShouldBlockNav,
  shouldBlockNav,
  showScenarioTemplates,
  setShowScenarioTemplates,
  setHideTabsState,
  scenarioTabType,
}: ScenariosTabProps): JSX.Element => {
  const handleCreateScenarioClicked = () => {
    setShowScenarioTemplates("true");
    setShowBackHeader(true);
    setHideTabsState(true);
  };

  const handleCloseWizard = () => {
    setShowScenarioTemplates(undefined);
    setShowBackHeader(false);
    setHideTabsState(false);
    handleTemplateSelected(null);
  };

  if (templateSelected && showBackHeader) {
    return (
      <CreateConvoScenarioWizard
        closeWizard={handleCloseWizard}
        wizardState={wizardState}
        templateSelected={templateSelected}
        handleTemplateSelected={handleTemplateSelected}
        setShouldBlockNav={setShouldBlockNav}
        shouldBlockNav={shouldBlockNav}
        wizardType={WizardType.Org}
      />
    );
  } else if (showScenarioTemplates) {
    return (
      <SelectNewConvoScenarioType
        handleTemplateSelected={handleTemplateSelected}
        selectedOrg={selectedOrg}
        setWizardState={setWizardState}
        scenarioTabType={scenarioTabType}
      />
    );
  } else {
    return (
      <ManageConvoScenarios
        handleTemplateSelected={handleTemplateSelected}
        selectedOrg={selectedOrg}
        setWizardState={setWizardState}
        wizardState={wizardState}
        renderLoader={renderLoader}
        showScenarioTemplates={showScenarioTemplates}
        handleCreateScenarioClicked={handleCreateScenarioClicked}
        scenarioTabType={scenarioTabType}
      />
    );
  }
};
