import React from "react";

// Components
import { Grid, Button, Divider, Stack, Typography, Box } from "@mui/material";
import {
  CustomizePracticeQueryKey,
  DEFAULT_PERSONA,
} from "components/ConvoScenarios/convoScenarioUtils";
import { CreatePersonaForm } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CreateConvoScenarioWizard/CreatePersonaForm";

// Utils
import { WizardType } from "../Scenarios/CreateConvoScenarioWizard/CreateConvoScenarioWizard";
import { PersonaCard } from "./PersonaCard";
import { WizardFormFooter } from "webclient/src/components/Orgs/ManageContent/WizardFormFooter";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { createPersona, deletePersona, updatePersona } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getLiveUserDocMain, updateThisUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { useIsExtraSmallScreen } from "lib-frontend/utils/themeUtils";
import {
  CreatePersonaRequest,
  PersonaResponse,
  UpdatePersonaRequest,
} from "lib-fullstack/api/scenarioApiTypes";
import { WIZARD_FOOTER_HEIGHT } from "utils/Constants";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { CreateContentRequestKind } from "lib-fullstack/db";
import {
  ScenarioSortOption,
  SortFilterMenu,
  SortFilterType,
  SortOption,
} from "components/Builder/SortFilterMenu";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";

type PersonasTabProps = {
  setPersonaIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
  renderLoader: () => JSX.Element;
  showBackHeader: boolean;
};
export const PersonasTab = ({
  setPersonaIsSelected,
  renderLoader,
  showBackHeader,
}: PersonasTabProps): JSX.Element => {
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);

  const queryClient = useQueryClient();
  const personasQueryState = queryClient.getQueryState([
    CustomizePracticeQueryKey.Personas,
    defaultOrgId,
    curSpaceId,
  ]);
  const isExtraSmallScreen = useIsExtraSmallScreen();

  const [saving, setSaving] = React.useState<boolean>(false);
  const [sortBy, setSortBy] = React.useState<SortOption>({
    value: ScenarioSortOption.UpdatedAt,
    label: "Updated At",
  });
  const [searchFilter, setSearchFilter] = React.useState<string>("");
  const [newPersona, setNewPersona] = React.useState<PersonaResponse | null>(null);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const filteredAndSortedPersonas = React.useMemo(() => {
    const personas = personasQueryState?.data as PersonaResponse[];
    const filteredPersonas = (personas ?? []).filter((persona) => {
      return !searchFilter || persona.name.toLowerCase().includes(searchFilter.toLowerCase());
    });

    switch (sortBy.value) {
      case ScenarioSortOption.Name:
        return filteredPersonas.sort((a, b) => a.name.localeCompare(b.name));
      case ScenarioSortOption.CreatedAt:
        return filteredPersonas.sort((a, b) => b.created_at.localeCompare(a.created_at));
      case ScenarioSortOption.UpdatedAt:
        return filteredPersonas.sort((a, b) => b.updated_at?.localeCompare(a.updated_at));
    }
  }, [personasQueryState, searchFilter, sortBy]);

  const deletePersonaMutation = useMutation({
    mutationFn: (personaId: string) => deletePersona(defaultOrgId, personaId),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [CustomizePracticeQueryKey.Personas, defaultOrgId],
      });
      Instrumentation.logOrgPersonaDeleted(defaultOrgId);
    },
  });

  const handleEditPersona = (persona: PersonaResponse) => {
    handleSetNewPersona(persona);
    setIsEditing(true);
  };

  const handleUpdateNewPersona = (key: keyof UpdatePersonaRequest, value: string | string[]) => {
    setNewPersona((prev) => ({ ...prev, [key]: value }));
  };

  const handleSetNewPersona = (persona: PersonaResponse | null) => {
    setPersonaIsSelected(!!persona);
    setNewPersona(persona);
  };

  const handleSavePersona = async () => {
    try {
      if (!getLiveUserDocMain().hasCreatedScenarioPersona) {
        updateThisUserDocMain({ hasCreatedScenarioPersona: true }).catch((er) => {
          console.log(`Error updating hasCreatedScenarioPersona in user doc main: ${er}`);
        });
      }
      setSaving(true);
      let personaId = newPersona.id;
      if (isEditing) {
        // handle updating persona
        await updatePersona(defaultOrgId, personaId, newPersona as UpdatePersonaRequest);
        Instrumentation.logOrgPersonaUpdated(defaultOrgId);
      } else {
        // handle creating new persona
        const personaRequest: CreatePersonaRequest = {
          ...newPersona,
          request_type: CreateContentRequestKind.New,
          space_id: curSpaceId,
        };
        personaId = await createPersona(defaultOrgId, personaRequest);
        Instrumentation.logOrgPersonaCreated(defaultOrgId);
      }
      void queryClient.invalidateQueries({
        queryKey: [CustomizePracticeQueryKey.OrgProfilePictures, defaultOrgId],
      });
      await queryClient.invalidateQueries({
        queryKey: [CustomizePracticeQueryKey.Personas, defaultOrgId],
      });

      // reset state upon completion
      setIsEditing(false);
      handleSetNewPersona(null);
    } catch (e) {
      console.error(`Error saving persona ${newPersona.id}: ${e}`);
    } finally {
      setSaving(false);
    }
  };
  const renderAddButton = () => {
    return (
      <Button
        variant="contained"
        size="xlarge"
        sx={{ whiteSpace: "nowrap" }}
        onClick={() => {
          handleSetNewPersona({ ...DEFAULT_PERSONA, id: "", is_template: false, is_active: true });
          setIsEditing(false);
        }}
      >
        Create{isExtraSmallScreen ? " New" : " Persona"}
      </Button>
    );
  };

  // dont show loader when refetching because we want it to just update the list in place
  if (
    personasQueryState?.status === "pending" ||
    (personasQueryState?.fetchStatus === "fetching" && personasQueryState?.status !== "success")
  ) {
    return renderLoader();
  }
  if (newPersona && showBackHeader) {
    return (
      <Box
        sx={{
          maxWidth: "lg",
          pb: WIZARD_FOOTER_HEIGHT,
        }}
      >
        <Stack
          gap={3}
          sx={{
            fontFamily: "poppins",
            p: { xs: 2, sm: 3, md: 0 },
          }}
        >
          <span>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontSize: { xs: 16, md: 18 },
                fontWeight: 700,
              }}
            >
              Customize a persona
            </Typography>
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              Change the persona details to make it your own
            </Typography>
          </span>
          <CreatePersonaForm
            newPersona={newPersona}
            handleUpdateNewPersona={handleUpdateNewPersona}
            wizardType={WizardType.Org}
          />
        </Stack>
        <WizardFormFooter
          saving={saving}
          disabled={
            saving ||
            !newPersona.name ||
            !newPersona.job_title ||
            !newPersona.demeanor ||
            !newPersona.voice_id ||
            !newPersona.profile_picture_id
          }
          handleSave={handleSavePersona}
        />
      </Box>
    );
  }

  const renderPersonaSortSearchControls = (): JSX.Element => {
    return (
      <Stack direction="row" gap={{ xs: 0, md: "20px" }} sx={{ alignItems: "center" }}>
        <SortFilterMenu
          sortFilterType={SortFilterType.Sort}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortOptions={[
            {
              value: ScenarioSortOption.Name,
              label: "Name",
            },
            {
              value: ScenarioSortOption.CreatedAt,
              label: "Created At",
            },
            {
              value: ScenarioSortOption.UpdatedAt,
              label: "Updated At",
            },
          ]}
        />
        <YoodliSearchBar
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
          clearSearch={() => setSearchFilter("")}
          placeholder="Search"
          InputProps={{
            sx: {
              width: { xs: "125px", md: "200px" },
              height: "35px",
              mr: 0,
            },
          }}
          sx={{
            minWidth: { xs: "125px", md: "200px" },
            height: "35px",
          }}
        />
      </Stack>
    );
  };

  return (
    <Stack
      sx={{
        fontFamily: "poppins",
        maxWidth: "xxl",
        p: { xs: 3, md: 0 },
        pb: { xs: 3, md: 1 },
        overflow: { xs: "hidden", md: "visible" },
      }}
      gap={6}
    >
      <Stack
        direction={{ xs: "column", md: "row" }}
        sx={{
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", md: "top" },
          gap: { xs: 2, md: 3 },
        }}
      >
        <Stack
          sx={{
            gap: "6px",
          }}
        >
          {
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontSize: { xs: 14, md: 16 },
                fontWeight: 700,
              }}
            >
              Create a new persona
            </Typography>
          }
          {
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontSize: 12,
                fontWeight: 600,
                maxWidth: 600,
              }}
            >
              Enhance your scenarios by creating customizable personas. Personas that are in use
              cannot be deleted.
            </Typography>
          }
        </Stack>
        {renderAddButton()}
      </Stack>
      <Divider
        sx={{
          mx: -8,
        }}
      />
      <Stack direction="column" gap={2}>
        <Stack
          sx={{ justifyContent: "space-between", alignItems: { xs: "flex-start", md: "top" } }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontSize: { xs: 14, md: 16 },
              fontWeight: 700,
            }}
          >
            My Personas
          </Typography>
          {renderPersonaSortSearchControls()}
        </Stack>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {filteredAndSortedPersonas.map((persona) => {
            return (
              <Grid item xs={12} sm={6} lg={4} key={persona.id}>
                <PersonaCard
                  persona={persona}
                  handleEditPersona={handleEditPersona}
                  handleDeletePersona={(personaId) => deletePersonaMutation.mutate(personaId)}
                />
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Stack>
  );
};
