import React from "react";

// Components
import { Button, CircularProgress, Dialog, Stack, Typography } from "@mui/material";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { getDynamicColor } from "lib-frontend/utils/Colors";

const DELETE_CONFIRMATION_STRING = "DELETE";

type YoodliDeleteModalProps = {
  open: boolean;
  targetDesc: string;
  deleteCta: string;
  subText: string | JSX.Element;
  loading?: boolean;
  onDelete: () => void;
  onClose: () => void;
};

export default function YoodliDeleteModal({
  open,
  targetDesc,
  deleteCta,
  subText,
  loading,
  onDelete,
  onClose,
}: YoodliDeleteModalProps): JSX.Element {
  const [input, setInput] = React.useState<string>("");

  const ready = input === DELETE_CONFIRMATION_STRING;
  const deleteButtonColor = ready ? getDynamicColor("redError") : getDynamicColor("primary");

  const { modalStyles } = useNavDrawerOffset();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        cursor: "default",
      }}
      PaperProps={{
        sx: {
          ...modalStyles,
          borderRadius: "12px",
          width: "min(600px, 100%)",
          maxHeight: "min(600px, 100%)",
          maxWidth: "unset",
          alignItems: "center",
          position: "relative",
          backgroundColor: getDynamicColor("light1"),
          overflowY: "auto",
          p: 4,
          px: { xs: 3, lg: 4 },
        },
      }}
    >
      <Stack gap={2}>
        <Stack direction="row" gap={1}>
          <Typography sx={{ fontFamily: "poppins", fontSize: "16px", fontWeight: 700 }}>
            {`Are you sure you want to delete "${targetDesc}"`}
          </Typography>
        </Stack>
        {subText && (
          <Typography sx={{ fontFamily: "poppins", fontSize: "16px" }}>{subText}</Typography>
        )}

        <YoodliLabeledInput
          inputEl={
            <YoodliTextfield
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={DELETE_CONFIRMATION_STRING}
              error={true}
            />
          }
        />
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={{ xs: 2, md: 0 }}
          sx={{ justifyContent: "space-between", pt: 3 }}
        >
          <Button
            startIcon={
              loading && (
                <CircularProgress
                  size={20}
                  sx={{
                    color: getDynamicColor("dark1"),
                  }}
                />
              )
            }
            onClick={() => {
              setInput("");
              onDelete();
            }}
            variant="outlined"
            disabled={!ready || loading}
            sx={{
              width: { xs: "100%", md: "unset" },
              fontSize: "16px",
              fontWeight: 700,
              letterSpacing: "0.4px",
              color: deleteButtonColor,
              border: `2px solid ${deleteButtonColor}`,
              "&:hover": {
                border: `2px solid ${deleteButtonColor}`,
              },
            }}
          >
            {deleteCta}
          </Button>
          <Button
            onClick={onClose}
            variant="contained"
            sx={{
              width: { xs: "100%", md: "unset" },
              fontSize: "16px",
              fontWeight: 700,
              letterSpacing: "0.4px",
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
