import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  createOrgProgram,
  deleteOrgProgram,
  updateOrgProgram,
  updateProgramUserRecordForStep,
} from "lib-frontend/modules/AxiosInstance";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import {
  ProgramResponse,
  UpdateProgramResponse,
  UpdateProgramUserRecordOperation,
} from "lib-fullstack/api/programApiTypes";
import React from "react";
import { OrgProgramsQueryKeys } from "../OrgPrograms";

type ProgramMutationsResponse = {
  deleteProgramMutation: UseMutationResult<void, Error, string>;
  duplicateProgramMutation: UseMutationResult<ProgramResponse, Error, ProgramResponse>;
  updateProgramUserRecordForStepMutation: UseMutationResult<
    void,
    Error,
    UpdateProgramUserRecordForStepParams
  >;
  updateProgramEvaluatorsMutation: UseMutationResult<
    UpdateProgramResponse,
    Error,
    { programId: string; leaderEmails: string[] }
  >;
};

export type UpdateProgramUserRecordForStepParams = {
  userId: string;
  programId: string;
  planStepId: string;
  operation: UpdateProgramUserRecordOperation;
};

/**
 * Program mutations that need to be shared across components
 * @returns deleteProgramMutation: delete program mutation
 * @returns duplicateProgramMutation: duplicate program mutation
 */
export const useProgramMutations = (): ProgramMutationsResponse => {
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);
  const queryClient = useQueryClient();

  const deleteProgramMutation = useMutation({
    mutationFn: (programId: string) => deleteOrgProgram(defaultOrgId, programId),
    onSuccess: async (_, programId: string) => {
      Instrumentation.logProgramDeleted(defaultOrgId, programId);
    },
  });

  const duplicateProgramMutation = useMutation({
    mutationFn: (program: ProgramResponse) =>
      createOrgProgram(defaultOrgId, {
        name: `${program.name} (copy)`,
        program_id_to_copy: program.id,
        space_id: curSpaceId,
      }),
    onSuccess: async (_, program: ProgramResponse) => {
      Instrumentation.logProgramCreated(defaultOrgId, program.id, true);
      void queryClient.invalidateQueries({
        queryKey: [OrgProgramsQueryKeys.OrgPrograms, defaultOrgId],
      });
    },
  });

  const updateProgramUserRecordForStepMutation = useMutation({
    mutationFn: (params: UpdateProgramUserRecordForStepParams) =>
      updateProgramUserRecordForStep(defaultOrgId, params.programId, params.userId, {
        planStepId: params.planStepId,
        operation: params.operation,
      }),
    onSuccess: async (_, params: UpdateProgramUserRecordForStepParams) => {
      void queryClient.invalidateQueries({
        queryKey: [OrgProgramsQueryKeys.ProgramUser, defaultOrgId, params.programId, params.userId],
      });
    },
  });

  const updateProgramEvaluatorsMutation = useMutation({
    mutationFn: async ({
      programId,
      leaderEmails,
    }: {
      programId: string;
      leaderEmails: string[];
    }) => await updateOrgProgram(defaultOrgId, programId, { leader_emails: leaderEmails }),
  });

  return {
    deleteProgramMutation,
    duplicateProgramMutation,
    updateProgramUserRecordForStepMutation,
    updateProgramEvaluatorsMutation,
  };
};
