import firebase from "firebase/app";
import { db } from "lib-fullstack";
import React from "react";
import { useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";

// Components
import NoSpeechesYetScreen from "../../ui/NoSpeechesYetScreen";
import {
  Box,
  Divider,
  Container,
  Pagination,
  Stack,
  Typography,
  Tooltip,
  CircularProgress,
  SxProps,
  IconButton,
} from "@mui/material";
import {
  LabelledValue,
  TableFilter,
} from "lib-frontend/components/YoodliComponents/TableComponents/TableFilter";
import { TableSortClient } from "lib-frontend/components/YoodliComponents/TableComponents/TableSortClient";
import { TableSortServer } from "lib-frontend/components/YoodliComponents/TableComponents/TableSortServer";
import { VideoListSkeleton } from "ui/Skeleton/VideoJournal";

// Assets
import PlaceholderThumbnail from "images/graphics/video-placeholder.svg";
import { ReactComponent as NoSpeechIcon } from "images/icons/NoSpeechIcon.svg";

// Utils
import "../../App.css";
import MobileSpeechRow from "./MobileSpeechRow";
import { VideoGridHeader, VIDEO_GRID_HEADER_HEIGHT } from "./VideoGridHeader";
import {
  CellTooltip,
  getHumanEvaluationState,
  getSpeechLabelFromEnum,
  videoIsDisplayable,
} from "./VideoJournalUtils";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { VideoListingItem, SpeechTableRow, TableRow } from "lib-frontend/utils/sharedTableUtils";
import { useIsScreenSmallerThanPx } from "lib-frontend/utils/themeUtils";
import { getIntFromString, lineClampStyles } from "lib-frontend/utils/Utilities";
import { GetSharedWithOrgSortOption } from "lib-fullstack/api/orgApiTypes";
import {
  FILLER_WORD_THRESHOLD,
  WPM_HIGH_THRESHOLD,
  WPM_LOW_THRESHOLD,
} from "lib-fullstack/utils/analyticThresholds";
import { getDisplayableTime } from "lib-fullstack/utils/dateUtils";
import { formatDateShort } from "lib-fullstack/utils/dateUtils";
import { HumanEvaluationState, UITestId } from "lib-fullstack/utils/enums";
import { NavigationAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";
import { VideoLibraryQueryParams } from "lib-fullstack/utils/queryParams";
import { generateSpeechSharePath, getCoachDemoSlug, getEndUserDemoSlug } from "utils/Utilities";
import YoodliErrorBoundary from "utils/YoodliErrorBoundary";
import { Notifications } from "@mui/icons-material";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";
import { ExclusiveTableFilter } from "lib-frontend/components/YoodliComponents/TableComponents/ExclusiveTableFilter";
import { GetProgramMemberRecordingsSortOption } from "lib-fullstack/api/programApiTypes";
import { handleEnterOrSpaceKey } from "lib-frontend/utils/keyboard";

export enum JournalView {
  Library = "library",
  SharedWithMe = "shared_with_me",
  SharedWithOrg = "shared_with_org",
  TeamMemberSharedWithOrg = "team_shared_with_org",
  Grading = "grading",
  ProgramMemberRecordings = "program_member_recordings",
}

const EMPTY_FIELD_PLACEHOLDER = "—";

const HEADER_TEXT_STYLES = {
  borderLeft: `2px solid ${getDynamicColor("dark2")}`,
  textAlign: "left",
  ml: -1,
  pl: 1,
  fontWeight: "500",
  fontSize: "14px",
  my: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  color: getDynamicColor("purple3"),
  fontFamily: "Poppins",
};

const COLUMN_VISIBILITY_CONFIG = {
  [JournalView.Library]: {
    type: true,
    sharedBy: false,
    isViewed: false,
    score: (isSmallerThan1260: boolean) => !isSmallerThan1260,
    pacing: (isSmallerThan1260: boolean) => !isSmallerThan1260,
    percentFillerWords: false,
    recordedByName: false,
    scenario: false,
    humanEvaluationState: false,
  },
  [JournalView.SharedWithOrg]: {
    type: true,
    sharedBy: false,
    isViewed: false,
    score: false,
    pacing: false,
    percentFillerWords: false,
    recordedByName: false,
    scenario: false,
    humanEvaluationState: false,
  },
  [JournalView.ProgramMemberRecordings]: {
    thumbnail: (isSmallerThan1260: boolean) => !isSmallerThan1260,
    sharedBy: false,
    humanEvaluationState: (isSmallerThan1260: boolean) => !isSmallerThan1260,
    recordedByName: true,
    totalTime: false,
    scenario: true,
    type: false,
    isViewed: false,
    score: true,
    pacing: false,
    percentFillerWords: false,
  },
  [JournalView.TeamMemberSharedWithOrg]: {
    type: true,
    sharedBy: false,
    isViewed: false,
    score: true,
    pacing: false,
    percentFillerWords: false,
  },
  [JournalView.SharedWithMe]: {
    sharedBy: true,
    isViewed: true,
    pacing: (isSmallerThan1260: boolean) => !isSmallerThan1260,
    percentFillerWords: false,
    type: true,
    score: (isSmallerThan1260: boolean) => !isSmallerThan1260,
    recordedByName: false,
    scenario: false,
    humanEvaluationState: false,
  },
  [JournalView.Grading]: {
    score: true,
    sharedBy: true,
    isViewed: false,
    pacing: false,
    type: false,
    percentFillerWords: false,
  },
};

type PaginationProps = {
  count: number;
  pageIndex: number;
  pageSize: number;
};

type VideoListingProps = {
  maxSpeechesPerPage?: number;
  simpleTable?: boolean;
  loadingFromProps?: boolean;
  journalView?: JournalView;
  libraryItems?: VideoListingItem[];
  sharedWithOrgItems?: VideoListingItem[];
  sharedWithMeItems?: VideoListingItem[];
  gradeNeededItems?: VideoListingItem[];
  programMemberRecordingsItems?: VideoListingItem[];
  loadingSharedSpeeches?: boolean;
  loadingLibrarySpeeches?: boolean;
  tableWrapperSx?: SxProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columnExtraContent?: Record<string, (variables: any) => void>;
  handlePageChangeProp?: (page: number) => void;
  serverSortHeaders?: string[];
  sortFilter?: string;
  setSortFilter?: (
    sortFilter: GetSharedWithOrgSortOption | GetProgramMemberRecordingsSortOption,
  ) => void;
  setTypeFilters?: React.Dispatch<React.SetStateAction<LabelledValue>>;
  typeFilters?: LabelledValue;
  paginationProps?: PaginationProps;
  hideTableFilter?: boolean;
  filterOptions?: {
    scenario?: LabelledValue[];
    humanEvaluationState?: LabelledValue[];
  };
};

/**
 * VideoListing displays all of the logged in users' speeches and other demo speeches.
 */
export default function VideoListing({
  maxSpeechesPerPage,
  simpleTable,
  loadingFromProps,
  journalView: startingJournalView,
  libraryItems,
  sharedWithOrgItems,
  sharedWithMeItems,
  gradeNeededItems,
  loadingSharedSpeeches,
  loadingLibrarySpeeches,
  tableWrapperSx,
  handlePageChangeProp,
  serverSortHeaders,
  columnExtraContent,
  sortFilter,
  setSortFilter,
  paginationProps,
  hideTableFilter,
  setTypeFilters,
  typeFilters,
  programMemberRecordingsItems,
  filterOptions,
}: VideoListingProps): React.ReactElement {
  // #region View State
  // when to show the mobile rows
  const isSmallerThan1100 = useIsScreenSmallerThanPx(1000);
  // when to remove certain columns like "pacing" to allow more space
  const isSmallerThan1260 = useIsScreenSmallerThanPx(1260);

  // Note, page is zero-indexed (to match Tan Table), but is displayed to
  //  user as 1-indexed in pagination component and in URL b/c that looks more
  //  natural.
  const [mobilePage, setMobilePage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [journalView, setJournalView] = React.useState<JournalView>(
    startingJournalView ?? JournalView.Library,
  );

  const speechesPerPage = maxSpeechesPerPage ?? 10;

  const items = React.useMemo(() => {
    switch (journalView) {
      case JournalView.Library:
        return libraryItems;
      case JournalView.SharedWithMe:
        return sharedWithMeItems;
      case JournalView.SharedWithOrg:
      case JournalView.TeamMemberSharedWithOrg:
        return sharedWithOrgItems;
      case JournalView.Grading:
        return gradeNeededItems;
      case JournalView.ProgramMemberRecordings:
        return programMemberRecordingsItems;
    }
  }, [
    journalView,
    sharedWithOrgItems,
    libraryItems,
    sharedWithMeItems,
    gradeNeededItems,
    programMemberRecordingsItems,
  ]);

  // #endregion

  // #region Pagination
  /** Basic pagination of the queried speeches... this doesn't
   * help the query speed, but it will help the render speed.
   */
  const nItems = (items ?? []).length;
  const nPages = Math.ceil(nItems / speechesPerPage);
  const location = useLocation();
  const navigate = useNavigate();
  const qp = new URLSearchParams(location.search);

  React.useLayoutEffect(() => {
    const tab = qp.get(VideoLibraryQueryParams.TAB);
    const page = Math.min(parseInt(qp.get(VideoLibraryQueryParams.PAGE), 10), nPages);
    if (tab && Object.values(JournalView).includes(tab as JournalView)) {
      setJournalView(tab as JournalView);
    }
    if (typeof page === "number" && !isNaN(page)) {
      const newPage = Math.max(page - 1, 0);
      setPageMobileAndDesktop(newPage);
    }
  }, [items]);

  React.useEffect(() => {
    if (paginationProps) {
      setPageMobileAndDesktop(paginationProps.pageIndex);
    }
  }, [paginationProps]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageMobileAndDesktop(Math.max(value - 1, 0));
    qp.set(VideoLibraryQueryParams.PAGE, Math.max(value, 1).toString());
    navigate({ search: qp.toString() });
    handlePageChangeProp?.(Math.max(value - 1, 0));
  };

  const setPageMobileAndDesktop = (newPage: number) => {
    setMobilePage(newPage);
    table.setPageIndex(newPage);
  };

  // Note that Tan Table does this calculation on its own given the number of pages.
  //  This is just for the mobile stack view.
  const mobileItems = React.useMemo(
    () =>
      paginationProps
        ? items
        : (items ?? []).slice(mobilePage * speechesPerPage, (mobilePage + 1) * speechesPerPage),
    [mobilePage, speechesPerPage, items, paginationProps],
  );

  // first/last item for displaying counts on screen.
  // It's okay that we're just using dbItemsToRender var for length because dbItemsToRender.length = listDbSpeechesToRender.length
  const firstItem = mobilePage * speechesPerPage + 1;
  const lastItem = firstItem + mobileItems.length - 1;

  const logOpenSpeechSummary = (speechData: db.Speech & { documentId?: string }) => {
    // Log the event to Amplitude when a user opens a speech summary.
    // Note this only happens when opening a speech in the same tab. Opening a speech in a new tab does not log this event!
    const slug = speechData.slug;
    const viewerId = `${getSiteId()}/${firebase.auth()?.currentUser?.uid}`;
    const speechAuthorId = `${getSiteId()}/${speechData.recordedBy}`;

    Instrumentation.logAmplitudeEvent(NavigationAnalyticsEvents.LIBRARY_CLICK_SPEECH, {
      slug,
      viewerId,
      speechAuthorId,
      isMySpeech: viewerId === speechAuthorId,
      isDemoSpeech: slug === getCoachDemoSlug() || slug === getEndUserDemoSlug(),
    });
  };

  // #endregion

  // #region Table Data Fetching

  // --- Tan Table ---

  const [tableData, setTableData] = React.useState<SpeechTableRow[]>([]);
  React.useEffect(() => {
    const availableItems = (items ?? []).filter((item) => item.dbSpeech.data);
    if (availableItems.length === 0) {
      setTableData([]);
      return;
    }

    const newTableData: SpeechTableRow[] = availableItems.map((item: VideoListingItem) => {
      const type = getSpeechLabelFromEnum(item.dbSpeech.data);

      return {
        id: item.dbSpeech.ref.id,
        thumbnail: item.thumbnailUrl || PlaceholderThumbnail,
        title: item.dbSpeech.data.name,
        created: item.dbSpeech.data.recordedDate,
        sharedBy: item.dbShare?.data.sharedByName ?? "",
        isViewed: item.dbShare?.data.isRead,
        type,
        recordedByName: item.dbSpeech.data.recordedByName,
        recordedByEmail: item.dbSpeech.data.recordedByEmail,
        scenario: item.dbSpeech.data.scenarioName,
        humanEvaluationState: item.dbSpeech.data.humanEvaluationState,
        totalTime: item.dbSpeech.data.totalTimeS ?? 0,
        fillerWordPercentage: item.dbSpeech.data.indexData?.percentFillerWords ?? -1,
        pacing: item.dbSpeech.data.indexData?.wpm ?? 0,
        score: item.dbSpeech.data.compositeScore ?? "",
        rowIsDisabled: !videoIsDisplayable(item.dbSpeech.data),
        metaData: {
          videoListingItem: item,
        },
      };
    });
    setTableData(newTableData);
  }, [items]);

  const sharedUsers = [
    ...new Set(
      sharedWithMeItems?.map((item) => item.dbShare.data.sharedByName).filter((name) => name),
    ),
  ] as string[];

  const FILTER_NAMES = {
    type: ["Interview", "Presentation", "Roleplay", "Live", "Uploaded"],
    sharedBy: sharedUsers,
  };

  // #endregion

  // #region Table Data Render

  // Renders the header (regardless of small or large screen)
  const headerElement = (title: string, width: string) => (
    <Tooltip title={title}>
      <Typography sx={{ ...HEADER_TEXT_STYLES, width: { width } }}>{title}</Typography>
    </Tooltip>
  );

  const columnHelper = createColumnHelper<SpeechTableRow>();

  const columns = [
    columnHelper.accessor("thumbnail", {
      id: "thumbnail",
      header: () => <div style={{ width: "96px" }} />,
      cell: (info) => {
        const { dbSpeech, thumbnailUrl } = info.row.original.metaData.videoListingItem;
        if (dbSpeech?.data?.lifecycleState === db.LifecycleState.REDACTED) {
          return (
            <Box
              sx={{
                width: "85px",
                height: "50px",
                ml: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: `1px solid ${getDynamicColor("dark3")}`,
                borderRadius: "4px",
              }}
            >
              <Box
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "30px",
                  background: getDynamicColor("dark2"),
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 0.5,
                }}
              >
                <NoSpeechIcon />
              </Box>
            </Box>
          );
        } else {
          return (
            <Box sx={{ width: "85px", ml: 1 }}>
              <img
                src={info.getValue() ? info.getValue() : PlaceholderThumbnail}
                style={{
                  alignSelf: "center",
                  height: "50px",
                  width: "100%",
                  border: info.getValue() ? `1px solid ${getDynamicColor("dark3")}` : "none",
                  borderRadius: "4px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  objectFit: "cover",
                  padding: 0,
                  marginBottom: "-6px",
                  // if the speech was recorded post canvas off, we need to mirror the thumbnail to match what was/is displayed
                  transform:
                    thumbnailUrl && (dbSpeech.data.noCanvasRecording || dbSpeech.data.mirrored)
                      ? "scaleX(-1)"
                      : null,
                }}
                alt="Speech video thumbnail"
              />
            </Box>
          );
        }
      },
    }),
    columnHelper.accessor("title", {
      id: "title",
      header: () => (
        <Tooltip title={"Title"}>
          <Typography
            sx={{
              ...HEADER_TEXT_STYLES,
              width: "220px",
              ...(isSmallerThan1260 && {
                borderLeft: "none",
                ml: 0,
              }),
            }}
          >
            Title
          </Typography>
        </Tooltip>
      ),
      cell: (info) => (
        <Stack direction="row">
          <Typography
            sx={{
              pr: "10px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "220px",
              ml: isSmallerThan1260 && 1,
            }}
          >
            {info.getValue()}
          </Typography>
        </Stack>
      ),
    }),
    columnHelper.accessor("created", {
      id: "created",
      header: () => (
        <>
          <Tooltip title={"Created"}>
            <Typography sx={{ ...HEADER_TEXT_STYLES, width: "78px" }}>Created</Typography>
          </Tooltip>
        </>
      ),
      cell: (info) => <Typography>{formatDateShort(info.getValue())}</Typography>,
      sortingFn: (rowA, rowB) =>
        new Date(rowA.original.created) < new Date(rowB.original.created) ? 1 : -1,
    }),
    columnHelper.accessor("sharedBy", {
      id: "sharedBy",
      header: () => headerElement("Shared by:", "110px"),
      cell: (info) => (
        <Typography style={{ color: getDynamicColor("primary") }}>{info.getValue()}</Typography>
      ),
      filterFn: "arrIncludesSome",
    }),
    columnHelper.accessor("recordedByName", {
      id: "recordedByName",
      header: () => headerElement("Shared by:", "110px"),
      cell: (info) => (
        <Stack sx={{ gap: 1 }}>
          <Typography sx={{ color: getDynamicColor("primary") }}>{info.getValue()}</Typography>
          <Typography sx={{ color: getDynamicColor("purple3"), fontSize: "12px" }}>
            {info.row.original.recordedByEmail}
          </Typography>
        </Stack>
      ),
    }),
    columnHelper.accessor("isViewed", {
      id: "isViewed",
      header: () => headerElement("Viewed", "90px"),
      cell: (info) => {
        const isViewed: boolean = info.getValue();
        return (
          <Typography
            style={{
              color: getDynamicColor(!isViewed ? "greenSuccessDark" : "purple3"),
            }}
          >
            {isViewed ? "Viewed" : "New"}
          </Typography>
        );
      },
    }),
    columnHelper.accessor("score", {
      id: "score",
      header: () => headerElement("Score", "90px"),
      cell: (info) => {
        const value = info.getValue().toString();
        const displayText = value.length > 0 ? value : EMPTY_FIELD_PLACEHOLDER;
        return <Typography>{displayText}</Typography>;
      },
    }),
    columnHelper.accessor("type", {
      id: "type",
      header: () => (
        <Stack direction="row">
          <Tooltip title={"Type"}>
            <Typography
              sx={{
                ...HEADER_TEXT_STYLES,
              }}
            >
              {"Type"}
            </Typography>
          </Tooltip>
        </Stack>
      ),
      cell: (info) => info.getValue(),
      filterFn: "arrIncludesSome",
    }),
    columnHelper.accessor("totalTime", {
      id: "totalTime",
      header: () => (
        <>
          <Tooltip title={"Total Time"}>
            <Typography
              sx={{
                ...HEADER_TEXT_STYLES,
              }}
            >
              Total Time
            </Typography>
          </Tooltip>
        </>
      ),
      cell: (info) => getDisplayableTime(info.getValue()),
    }),
    columnHelper.accessor("fillerWordPercentage", {
      id: "percentFillerWords",
      header: () => (
        <>
          <Tooltip title={"% Filler Words"}>
            <Typography
              sx={{
                ...HEADER_TEXT_STYLES,
              }}
            >
              % Filler Words
            </Typography>
          </Tooltip>
        </>
      ),
      cell: (info) => {
        const percentFillers = info.row.original.fillerWordPercentage;
        const textColor = getDynamicColor(
          percentFillers < FILLER_WORD_THRESHOLD ? "greenSuccess" : "redError",
        );
        const displayText = Math.round(percentFillers) + "%";

        // fillerWordPercentage is -1 if no analytic result.
        return info.row.original.totalTime > 30 && info.row.original.fillerWordPercentage >= 0 ? (
          <Typography style={{ color: textColor }}>{displayText}</Typography>
        ) : (
          <CellTooltip
            value={EMPTY_FIELD_PLACEHOLDER}
            emptyFieldPlaceholder={EMPTY_FIELD_PLACEHOLDER}
          />
        );
      },
      filterFn: "equals",
    }),

    columnHelper.accessor("pacing", {
      id: "pacing",
      header: () => (
        <>
          <Tooltip title={"Pacing"}>
            <Typography
              sx={{
                ...HEADER_TEXT_STYLES,
              }}
            >
              Pacing
            </Typography>
          </Tooltip>
        </>
      ),
      cell: (info) =>
        info.getValue() > 0 ? (
          <Typography
            style={{
              color: getDynamicColor(
                info.getValue() >= WPM_LOW_THRESHOLD && info.getValue() <= WPM_HIGH_THRESHOLD
                  ? "greenSuccess"
                  : "redError",
              ),
            }}
          >
            {info.getValue()}
          </Typography>
        ) : (
          EMPTY_FIELD_PLACEHOLDER
        ),
    }),

    columnHelper.accessor("humanEvaluationState", {
      id: "humanEvaluationState",
      header: () => (
        <>
          <Tooltip title={"Requires grading"}>
            <Typography
              sx={{
                ...HEADER_TEXT_STYLES,
              }}
            >
              Requires grading
            </Typography>
          </Tooltip>
        </>
      ),
      cell: (info) =>
        info.getValue() ? (
          <Stack direction="row" sx={{ alignItems: "center", gap: 1 }}>
            <Typography
              sx={{
                fontWeight: info.getValue() === HumanEvaluationState.Incomplete ? "600" : "inherit",
                color:
                  info.getValue() === HumanEvaluationState.Incomplete
                    ? getDynamicColor("redError")
                    : "unset",
              }}
            >
              {getHumanEvaluationState(info.getValue())}
            </Typography>
            {info.getValue() === HumanEvaluationState.Incomplete && (
              <YoodliTooltip title="Remind evaluators via email">
                <IconButton
                  sx={{
                    width: 24,
                    height: 24,
                    color: getDynamicColor("primary"),
                    backgroundColor: getDynamicColor("purple1"),
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    columnExtraContent?.humanEvaluationState({
                      query: {
                        userId:
                          info.row.original.metaData.videoListingItem.dbSpeech.data.recordedBy,
                        speechId: info.row.original.id,
                      },
                    });
                  }}
                >
                  <Notifications sx={{ width: 16, height: 16 }} />
                </IconButton>
              </YoodliTooltip>
            )}
          </Stack>
        ) : (
          EMPTY_FIELD_PLACEHOLDER
        ),
    }),

    columnHelper.accessor("scenario", {
      id: "scenario",
      header: () => (
        <>
          <Tooltip title={"Roleplay"}>
            <Typography
              sx={{
                ...HEADER_TEXT_STYLES,
              }}
            >
              Roleplay
            </Typography>
          </Tooltip>
        </>
      ),
      cell: (info) =>
        info.getValue() ? (
          <Stack direction="row">
            <Typography sx={{ width: "180px", ...lineClampStyles(3) }}>
              {info.getValue()}
            </Typography>
          </Stack>
        ) : (
          EMPTY_FIELD_PLACEHOLDER
        ),
    }),
  ];

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: false,
    initialState: {
      pagination: {
        pageSize: speechesPerPage,
        pageIndex: 0,
      },
    },
    pageCount: paginationProps
      ? Math.ceil(paginationProps.count / paginationProps.pageSize)
      : undefined,
    manualPagination: Boolean(paginationProps), // Tell the table you're handling pagination manually
  });

  // #endregion

  // #region Effects

  // Perform search (case is ignored)
  React.useEffect(() => {
    table.getColumn("title").setFilterValue(searchText);
  }, [searchText]);

  // determine which columns to show based on the view and the screen size
  React.useEffect(() => {
    const config = COLUMN_VISIBILITY_CONFIG[journalView];
    Object.entries(config).forEach(([columnId, visibility]) => {
      const isVisible =
        typeof visibility === "function" ? visibility(isSmallerThan1260) : visibility;
      table.getColumn(columnId).toggleVisibility(isVisible);
    });
  }, [journalView, isSmallerThan1260]);

  // make sure the table stays up to date with the speechesPerPage
  React.useEffect(() => {
    table.setPageSize(speechesPerPage);
  }, [speechesPerPage]);

  // #endregion
  const loading =
    loadingFromProps ??
    (() => {
      switch (journalView) {
        case JournalView.SharedWithMe:
          return loadingSharedSpeeches || sharedWithMeItems === undefined;
        case JournalView.Library:
          return loadingLibrarySpeeches || libraryItems === undefined;
        case JournalView.SharedWithOrg:
        case JournalView.TeamMemberSharedWithOrg:
          return loadingLibrarySpeeches || sharedWithOrgItems === undefined;
        case JournalView.Grading:
          return loadingLibrarySpeeches || gradeNeededItems === undefined;
        case JournalView.ProgramMemberRecordings:
          return loadingLibrarySpeeches || programMemberRecordingsItems === undefined;
        default:
          return false;
      }
    })();

  // #region Event Handlers

  const handleSwitchJournalView = (newView: JournalView) => {
    setJournalView(newView);
    qp.set(VideoLibraryQueryParams.TAB, newView);
    qp.set(VideoLibraryQueryParams.PAGE, "1");
    navigate({ search: qp.toString() });
  };
  // #endregion

  // #region Render
  const renderLoadingSpinner = () => (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        mt: 15,
        gap: 3,
      }}
    >
      <CircularProgress sx={{ mx: "auto" }} />
      <Typography>Loading shared speeches...</Typography>
    </Stack>
  );

  const renderSmallScreenPagination = () => {
    let itemCount = nItems;
    let pageCount = nPages;
    if (paginationProps) {
      itemCount = paginationProps.count;
      pageCount = Math.ceil(itemCount / paginationProps.pageSize);
    }
    const showPagination = itemCount > 0 && pageCount > 1;

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mt: 2,
        }}
      >
        {showPagination && (
          <Pagination
            count={pageCount}
            showFirstButton
            showLastButton
            size="small"
            page={mobilePage + 1}
            onChange={handlePageChange}
          />
        )}
        <Typography
          sx={{
            mt: 0.5,
            color: getDynamicColor("dark4"),
            fontSize: "12px",
          }}
        >
          {`Displaying items ${firstItem} - ${lastItem} of ${itemCount}`}
        </Typography>
      </Box>
    );
  };

  const renderSmallScreenList = () => (
    <Stack
      sx={{
        px: simpleTable ? 0 : 2,
        backgroundColor: getDynamicColor("light1"),
      }}
    >
      {mobileItems.map((item, idx) => (
        <div key={item.dbShare?.ref?.id ?? item.dbSpeech.ref.id}>
          <YoodliErrorBoundary>
            <MobileSpeechRow
              dbSpeech={item.dbSpeech}
              dbShare={item?.dbShare}
              thumbnailUrl={item.thumbnailUrl}
              journalView={journalView}
              disallowShare={
                journalView === JournalView.SharedWithOrg ||
                journalView === JournalView.TeamMemberSharedWithOrg
              }
            />
          </YoodliErrorBoundary>
          {idx !== mobileItems.length - 1 && <Divider sx={{ mt: 1, mb: 0.5 }} />}
        </div>
      ))}
    </Stack>
  );

  const renderSmallScreen = () => (
    <Box sx={{ width: "100%", ...tableWrapperSx }}>
      {/* Stack View (Mobile): */}
      {renderSmallScreenList()}
      {/* Pagination Below: */}
      {renderSmallScreenPagination()}
    </Box>
  );

  const renderLargeScreenPagination = () => {
    let itemCount = table.getFilteredRowModel().rows.length;
    let pageCount = Math.ceil(itemCount / speechesPerPage);
    if (paginationProps) {
      itemCount = paginationProps.count;
      pageCount = Math.ceil(itemCount / paginationProps.pageSize);
    }
    const showPagination = itemCount > 0 && pageCount > 1;
    return (
      <>
        {showPagination && (
          <Pagination
            count={pageCount}
            showFirstButton
            showLastButton
            page={table.getState().pagination.pageIndex + 1}
            onChange={handlePageChange}
          />
        )}
        <Typography
          sx={{
            mt: 0.5,
            color: getDynamicColor("dark4"),
            fontSize: "12px",
          }}
        >
          {`Displaying items ${
            table.getState().pagination.pageIndex * speechesPerPage + 1
          } - ${Math.min(
            table.getState().pagination.pageIndex * speechesPerPage + speechesPerPage,
            itemCount,
          )} of ${itemCount}`}
        </Typography>
      </>
    );
  };

  const renderLargeScreenTable = () => {
    return (
      <Box>
        <Box
          component="table"
          sx={{
            borderCollapse: "collapse",
            backgroundColor: "white",
            borderRadius: "4px",
            border: `1px solid ${getDynamicColor("dark2")}`,
            overflow: "hidden",
            width: "100%",
            borderSpacing: "0px",
            background:
              "linear-gradient(71deg, rgba(29, 169, 230, 0.2) -9%, rgba(105, 102, 254, 0.2) 79%)",
            marginBottom: "24px",
            ...tableWrapperSx,
          }}
        >
          <thead style={{ backgroundColor: getDynamicColor("light1") }}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{ borderBottom: `1px solid ${getDynamicColor("dark2")}` }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ mr: 1 }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                      {/* Note: the reason the <TableFilters> are here instead of as a columnHelper is because when placed in a  columnHelper,it would re-render the menu opening every time the filtering changed, which is undesirable. No re-render problems here.*/}
                      {serverSortHeaders
                        ? serverSortHeaders?.includes(header.id) && (
                            <TableSortServer
                              columnId={header.id}
                              setSortFilter={setSortFilter}
                              currentSort={sortFilter}
                            />
                          )
                        : [
                            "title",
                            "created",
                            "totalTime",
                            "percentFillerWords",
                            "pacing",
                            "isViewed",
                            "score",
                          ].includes(header.id) && (
                            <TableSortClient column={header.id} table={table as Table<TableRow>} />
                          )}

                      {!hideTableFilter && ["type", "sharedBy"].includes(header.id) && (
                        <TableFilter
                          column={(table as Table<TableRow>).getColumn(header.id)}
                          names={FILTER_NAMES[header.id]}
                          searchable={header.id === "sharedBy"}
                        />
                      )}
                      {!hideTableFilter &&
                        filterOptions &&
                        Object.keys(filterOptions).includes(header.id) && (
                          <ExclusiveTableFilter
                            setFilterState={(value) => {
                              setTypeFilters({
                                label: header.id,
                                value: value ? value : undefined,
                              });
                            }}
                            typeFilters={typeFilters}
                            columnId={header.id}
                            names={filterOptions[header.id]}
                            singleSelectOnly={header.id === "humanEvaluationState"}
                            searchable={false}
                          />
                        )}
                    </Stack>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              const isDisabled = !videoIsDisplayable(
                row.original.metaData.videoListingItem.dbSpeech.data,
              );
              const isRead = row.original.metaData.videoListingItem.dbShare?.data.isRead ?? true;

              return (
                <Box
                  component="tr"
                  key={row.id}
                  tabIndex={0}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (handleEnterOrSpaceKey(e)) {
                      const speech = row.original.metaData.videoListingItem.dbSpeech.data;
                      logOpenSpeechSummary(speech);
                      navigate(generateSpeechSharePath(speech));
                    }
                  }}
                  sx={{
                    backgroundColor: getDynamicColor("light1"),
                    borderBottom: `1px solid ${getDynamicColor("dark2")}`,
                    transition: "background-color 0.1s ease-out",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "&:focus": {
                      outline: `2px solid ${getDynamicColor("primary")}`,
                      outlineOffset: "-2px",
                    },
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        padding: 0,
                      }}
                    >
                      <Link
                        data-testid={UITestId.SpeechLink}
                        to={generateSpeechSharePath(
                          row.original.metaData.videoListingItem.dbSpeech.data,
                        )}
                        onClick={() =>
                          logOpenSpeechSummary(row.original.metaData.videoListingItem.dbSpeech.data)
                        }
                        style={{
                          paddingRight: "20px",
                          textDecoration: "none",
                          color: "inherit",
                          lineHeight: "100%",
                          pointerEvents: isDisabled ? "none" : null,
                          opacity: isDisabled ? "0.5" : null,
                          display: "inline-block",
                          width: "100%",
                        }}
                      >
                        <Box
                          // Outer box is expanded to fill the whole cell to make clickable link
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          display="flex"
                        >
                          <Box
                            // Inner box is centered vertically and start-aligned
                            style={{
                              height: "67px",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "14px",
                              fontWeight: isRead ? 400 : 600,
                            }}
                            display="flex"
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </Box>
                        </Box>
                      </Link>
                    </td>
                  ))}
                </Box>
              );
            })}
          </tbody>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {table.getFilteredRowModel().rows.length === 0 &&
            journalView === JournalView.ProgramMemberRecordings &&
            loadingLibrarySpeeches && (
              <Container
                maxWidth="xl"
                sx={{ height: "100%", px: simpleTable ? "0px !important" : "initial" }}
              >
                <VideoListSkeleton
                  numRows={Math.min(speechesPerPage, !loading ? speechesPerPage : speechesPerPage)}
                />
              </Container>
            )}
          {table.getFilteredRowModel().rows.length === 0 ? (
            <Typography sx={{ mt: 2 }}>No results found</Typography>
          ) : (
            renderLargeScreenPagination()
          )}
        </Box>
      </Box>
    );
  };

  const renderContent = () => {
    // My yoodlis does not need a spinner because the whole page waits to load until the speeches are loaded.
    // If we're loading shared speeches, show a loading spinner.
    if (journalView === JournalView.SharedWithMe && loadingSharedSpeeches) {
      return renderLoadingSpinner();
    } else if (nItems > 0) {
      return isSmallerThan1100 ? renderSmallScreen() : renderLargeScreenTable();
    } else if (nItems === 0 && journalView === JournalView.ProgramMemberRecordings) {
      return isSmallerThan1100 ? renderSmallScreen() : renderLargeScreenTable();
    } else {
      return (
        <NoSpeechesYetScreen
          journalView={journalView}
          loading={loading}
          simpleTable={simpleTable}
        />
      );
    }
  };

  return (
    <Box
      sx={{
        pb: 2,
        backgroundColor: getDynamicColor("light1"),
        ...(!simpleTable && {
          minHeight: {
            xs: `calc(100vh - ${
              getIntFromString(VIDEO_GRID_HEADER_HEIGHT) +
              getIntFromString(WEBCLIENT_TOP_NAVBAR_HEIGHT)
            }px)`,
            md: `100vh`,
          },
        }),
      }}
    >
      {!simpleTable && (
        <VideoGridHeader
          journalView={journalView}
          handleSwitchJournalView={handleSwitchJournalView}
          setPage={handlePageChange}
          searchText={searchText}
          setSearchText={setSearchText}
          requiredGradingCount={gradeNeededItems?.length}
        />
      )}
      <Container
        maxWidth="xl"
        sx={{ height: "100%", px: simpleTable ? "0px !important" : "initial" }}
      >
        {/* check if the pageIOndex * speeches per page is less than the total count, and if so, only show whats remaining */}
        {loading ? (
          <VideoListSkeleton
            numRows={Math.min(speechesPerPage, !loading ? nItems : speechesPerPage)}
          />
        ) : (
          renderContent()
        )}
      </Container>
    </Box>
  );
  // #endregion
}
