import { db } from "lib-fullstack";
import React from "react";

// Components
import { ExpandMoreRounded } from "@mui/icons-material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { MenuItem, Skeleton, Stack, Typography } from "@mui/material";
import {
  GoalTalkTime,
  MAX_CHAR_LENGTH_TALKING_POINT,
  MAX_TALKING_POINTS,
  distributeWeightEvenly,
  filterAndMergeArr,
  normalizeWeights,
} from "components/ConvoScenarios/convoScenarioUtils";
import { GoalMenuItem } from "components/Orgs/ManageContent/CustomGoals/CustomGoalLibrary";
import { renderCreateGoalMenuItem } from "components/Orgs/ManageContent/CustomGoals/CustomGoalLibrary";
import {
  StackedMenu,
  StackedMenuItem,
} from "components/Orgs/ManageContent/CustomGoals/StackedMenu";
import { IOSSwitch } from "components/Settings/Settings";
import { YoodliMenuItemType } from "lib-frontend/components/YoodliComponents/YoodliMenu";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Assets
import { ReactComponent as RubricIcon } from "images/icons/icon-scale.svg";

// Utils
import { WizardType } from "./CreateConvoScenarioWizard";
import { GoalList } from "./GoalList";
import { GoalWeightSelector } from "./GoalWeightSelector";
import { WizardAddDraggableItems } from "./WizardAddDraggableItems";
import { useScrollToTop } from "hooks/useScrollToTop";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { GoalItemWithUsage } from "lib-fullstack/api/scenarioApiTypes";
import { DefaultGoal, GoalKind, ScenarioTypeIdEnum } from "lib-fullstack/db";
import { GoalDescriptions, GoalLabels } from "lib-fullstack/utils/defaultGoals";
import { isEqualDistribution } from "lib-fullstack/utils/isEqualWeightDistribution";

const GOAL_OPTIONS = {
  [db.ScenarioTypeIdEnum.SALES_CALL]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.ActiveListening,
    DefaultGoal.BookFollowUp,
    DefaultGoal.EngageSmallTalk,
    DefaultGoal.IdentifyPainPoints,
    DefaultGoal.UseGROW,
  ],
  [db.ScenarioTypeIdEnum.COLD_CALL]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.ActiveListening,
    DefaultGoal.BookFollowUp,
    DefaultGoal.EngageSmallTalk,
    DefaultGoal.IdentifyPainPoints,
    DefaultGoal.UseGROW,
  ],
  [db.ScenarioTypeIdEnum.MANAGER_PERF_REVIEW]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.UseGROW,
    DefaultGoal.ActiveListening,
  ],
  [db.ScenarioTypeIdEnum.MANAGER_SKILLS_TRAINING]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.UseGROW,
    DefaultGoal.ActiveListening,
  ],
  [db.ScenarioTypeIdEnum.CUSTOMER_ENABLEMENT]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.ActiveListening,
  ],
  [db.ScenarioTypeIdEnum.MEDIA_TRAINING]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.ActiveListening,
  ],
  [db.ScenarioTypeIdEnum.GENERIC]: Object.values(DefaultGoal), // all goals
  [db.ScenarioTypeIdEnum.PITCH]: [DefaultGoal.HitTalkingPoints, DefaultGoal.HitTimeTarget],
  [db.ScenarioTypeIdEnum.SMALL_TALK]: [DefaultGoal.HitTalkingPoints, DefaultGoal.ActiveListening],
  [db.ScenarioTypeIdEnum.PATIENT_COUNSELING]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.ActiveListening,
  ],
  [db.ScenarioTypeIdEnum.NETWORKING]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.EngageSmallTalk,
    DefaultGoal.ActiveListening,
  ],
  [db.ScenarioTypeIdEnum.BEHAVIORAL_INTERVIEW]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.EngageSmallTalk,
    DefaultGoal.ActiveListening,
    DefaultGoal.UseSTAR,
  ],
  [db.ScenarioTypeIdEnum.TECHNICAL_INTERVIEW]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.EngageSmallTalk,
    DefaultGoal.ActiveListening,
    DefaultGoal.UseSTAR,
  ],
  [db.ScenarioTypeIdEnum.TUTOR]: [DefaultGoal.HitTalkingPoints, DefaultGoal.ActiveListening],
  [db.ScenarioTypeIdEnum.SKILL_ACCREDITATION]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.ActiveListening,
    DefaultGoal.UseGROW,
    DefaultGoal.UseSTAR,
  ],
};

type GoalSelectorProps = {
  goals: (DefaultGoal | string)[];
  handleUpdateGoals: React.Dispatch<React.SetStateAction<(DefaultGoal | string)[]>>;
  talkingPoints: string[];
  handleUpdateTalkingPoints: (talkingPoints: string[]) => void;
  handleUpdateCustomGoalType: (type: GoalKind, enableDocuments?: boolean) => void;
  goalTalkTime: GoalTalkTime;
  handleUpdateGoalTalkTime: (goalTalkTime: GoalTalkTime) => void;
  specialGoalIdSelected: string;
  setSpecialGoalIdSelected: (goalId: string) => void;
  scenarioTypeId: db.ScenarioTypeIdEnum;
  customGoals: GoalItemWithUsage[];
  customGoalsLoading: boolean;
  setCurrStepIndex: React.Dispatch<React.SetStateAction<number>>;
  handleEditCustomGoal: (goal: GoalItemWithUsage) => Promise<void>;
  goalWeights: Record<string, number>;
  goalOrder: (string | DefaultGoal)[];
  lockedWeightGoalIds: (string | DefaultGoal)[];
  handleUpdateLockedWeightGoalIds: React.Dispatch<React.SetStateAction<(string | DefaultGoal)[]>>;
  handleUpdateGoalOrder: React.Dispatch<React.SetStateAction<(DefaultGoal | string)[]>>;
  handleUpdateGoalWeights: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  wizardType: WizardType;
};

export type UIGoalItem = {
  name: string;
  id: DefaultGoal | string;
  goalKindDescription?: string;
  goalKind?: string;
  userDescription?: string;
};

const NUM_LOADING_SKELETON_GOALS = 4;

export const getCustomGoalKindDescription = (goal: GoalItemWithUsage): string => {
  switch (goal.goalKind) {
    case GoalKind.BinaryGoal:
      return "Binary goal: Achieved/Missed";
    case GoalKind.ScoreGoal:
      return `${goal.documentBasisType ? "Knowledge-based" : "Rated"} goal: Scored 1-${goal.maxScore}`;
    case GoalKind.CompoundGoal:
      return `Compound goal: Scored 1-${goal.maxScore}`;
  }
};

const GOAL_SUBTITLE_STYLES = {
  fontWeight: 500,
  fontSize: 12,
  color: getDynamicColor("dark4"),
  pl: 4,
  position: "relative",
  top: -4,
};

export const GoalSelector = ({
  goals,
  handleUpdateGoals,
  talkingPoints,
  handleUpdateTalkingPoints,
  handleUpdateCustomGoalType,
  goalTalkTime,
  handleUpdateGoalTalkTime,
  specialGoalIdSelected,
  setSpecialGoalIdSelected,
  scenarioTypeId,
  customGoals,
  customGoalsLoading,
  setCurrStepIndex,
  handleEditCustomGoal,
  goalWeights,
  lockedWeightGoalIds,
  handleUpdateLockedWeightGoalIds,
  handleUpdateGoalWeights,
  goalOrder,
  handleUpdateGoalOrder,
  wizardType,
}: GoalSelectorProps): JSX.Element => {
  useScrollToTop();
  const {
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);

  const [myCustomGoals, setMyCustomGoals] = React.useState<GoalItemWithUsage[]>([]);

  const [showGoalWeights, setShowGoalWeights] = React.useState<boolean>(
    !isEqualDistribution(goalWeights) || !goalWeights,
  );
  // Talking points and Target Time goal state
  const [talkTimeMinutes, setTalkTimeMinutes] = React.useState(goalTalkTime.minutes);
  const [talkTimeSeconds, setTalkTimeSeconds] = React.useState(goalTalkTime.seconds);
  const [talkTimeRange, setTalkTimeRange] = React.useState(`${goalTalkTime.range}`);

  const defaultGoals = React.useMemo(() => {
    const goalArr =
      GOAL_OPTIONS[scenarioTypeId]?.map((goal: DefaultGoal) => {
        return { name: GoalLabels[goal], id: goal, userDescription: GoalDescriptions[goal] };
      }) ?? [];
    // move talking points to the front if it exists
    const talkingPointsInd = goalArr.findIndex((goal) => goal.id === DefaultGoal.HitTalkingPoints);
    if (talkingPointsInd > -1) {
      return [
        goalArr[talkingPointsInd],
        ...goalArr.filter((goal) => goal.id !== DefaultGoal.HitTalkingPoints),
      ];
    }
    return goalArr;
  }, [GOAL_OPTIONS]);

  const getGoalsExtraDetails = (inMenu: boolean) => {
    const goalsExtraDetails = {
      [DefaultGoal.HitTalkingPoints]: (
        <>
          {!talkingPoints.length && (
            <Typography sx={GOAL_SUBTITLE_STYLES}>
              Add at least one talking point below to add this goal
            </Typography>
          )}
          {/* prevent clicks inside the draggable items from closing the goal */}
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              position: "relative",
              zIndex: 3,
              cursor: "default",
              marginTop: "8px",
            }}
          >
            <WizardAddDraggableItems
              items={talkingPoints}
              handleUpdateItems={(talkingPoints) => {
                handleGoalSelectionChange(DefaultGoal.HitTalkingPoints, "add");
                handleUpdateTalkingPoints(talkingPoints);
              }}
              disallowDuplicates
              inputPlaceholder="e.g.  Used by Fortune 500 CEOs"
              maxItems={MAX_TALKING_POINTS}
              maxInputLength={MAX_CHAR_LENGTH_TALKING_POINT}
              itemLabel="Talking points"
              wrapperSx={{ pb: 0, gap: 0 }}
              itemListSx={{ maxHeight: 300 }}
              scrollBehaviorOptions={{ inline: "nearest", block: inMenu ? "nearest" : "start" }}
            />
          </div>
        </>
      ),
      [DefaultGoal.HitTimeTarget]: (
        <Stack direction="row" sx={{ pt: 2, gap: 2, alignItems: "center" }}>
          <Stack direction="column" sx={{ gap: 0.5, flexBasis: "50%" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 12,
                color: getDynamicColor("purple3"),
                position: "relative",
              }}
            >
              {`Set how long the ${
                scenarioTypeId === ScenarioTypeIdEnum.PITCH ? "pitch" : "conversation"
              } should be (up to 60 minutes max)`}
            </Typography>
            <YoodliTextfield
              autoFocus
              value={goalTalkTime.displayString}
              onClick={(e) => e.stopPropagation()}
              onBlur={() => {
                const value = goalTalkTime.displayString;
                if (!value) {
                  handleUpdateGoalTalkTime({
                    ...goalTalkTime,
                    minutes: undefined,
                    seconds: undefined,
                    displayString: "",
                  });
                  return;
                }
                const minutes = Math.min(talkTimeMinutes, 60);
                const seconds = talkTimeSeconds;
                const formattedValue = `${minutes}:${seconds.toString().padStart(2, "0")}`;
                setTalkTimeMinutes(minutes);
                setTalkTimeSeconds(seconds);
                handleUpdateGoalTalkTime({
                  ...goalTalkTime,
                  displayString: formattedValue,
                });
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (/[^\d:]/.test(value)) {
                  return;
                }
                const timePattern = /^(\d+):(\d+)$/;
                const match = value.match(timePattern);
                let minutes = undefined;
                let seconds = undefined;
                if (match) {
                  minutes = parseInt(match[1], 10);
                  seconds = parseInt(match[2], 10);
                  if (match[1].length > 2 || match[2].length > 2) {
                    return;
                  }
                  if (minutes >= 0 && minutes <= 60 && seconds >= 0 && seconds < 60) {
                    setTalkTimeMinutes(minutes);
                    setTalkTimeSeconds(seconds);
                    handleUpdateGoalTalkTime({
                      ...goalTalkTime,
                      displayString: value,
                    });
                  }
                } else {
                  // Handle single number input for minutes
                  minutes = parseInt(value, 10);
                  minutes = isNaN(minutes) ? undefined : minutes;
                  if (minutes) {
                    seconds = 0;
                    handleUpdateGoalTalkTime({
                      ...goalTalkTime,
                      displayString: value,
                    });
                  } else {
                    handleUpdateGoalTalkTime({
                      ...goalTalkTime,
                      displayString: "",
                    });
                  }
                  setTalkTimeMinutes(minutes);
                  setTalkTimeSeconds(seconds);
                }
              }}
              sx={{
                width: 80,
              }}
            />
          </Stack>
          <Stack direction="column" sx={{ gap: 0.5, flexBasis: "50%" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 12,
                color: getDynamicColor("purple3"),
                position: "relative",
              }}
            >
              Set +/- time range for successful completion (in seconds):
            </Typography>
            <YoodliTextfield
              type="number"
              value={talkTimeRange}
              onClick={(e) => e.stopPropagation()}
              error={
                Math.floor((talkTimeMinutes * 60 + talkTimeSeconds) / 2) < parseInt(talkTimeRange)
              }
              onBlur={() => {
                const maxRange = Math.floor((talkTimeMinutes * 60 + talkTimeSeconds) / 2);
                const range = parseInt(talkTimeRange);
                setTalkTimeRange(`${Math.min(range, maxRange)}`);
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (!value) {
                  setTalkTimeRange("");
                  return;
                }
                // Return early if input contains anything other than numbers
                if (!/^\d*$/.test(value)) {
                  return;
                }
                setTalkTimeRange(value);
              }}
              sx={{
                width: 80,
              }}
            />
          </Stack>
        </Stack>
      ),
    };
    return goalsExtraDetails;
  };

  const goalsExtraTitleInfo = {
    [DefaultGoal.HitTalkingPoints]: (
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 600,
          lineHeight: 2,
          color: getDynamicColor("dark4"),
        }}
      >
        {talkingPoints.length} added
      </Typography>
    ),
    [DefaultGoal.HitTimeTarget]: (
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 600,
          lineHeight: 2,
          color: getDynamicColor("dark4"),
        }}
      >
        {talkTimeMinutes ?? "--"}:{talkTimeSeconds?.toString().padStart(2, "0") ?? "--"}
      </Typography>
    ),
  };

  React.useEffect(() => {
    if (!customGoalsLoading && customGoals) {
      const goals = customGoals.map((goal: GoalItemWithUsage) => {
        return {
          name: goal.name,
          id: goal.id,
          goalKind: goal.goalKind,
          userDescription: goal.userDescription,
          usage: goal.usage,
          documentBasisType: goal.documentBasisType,
          documentBasisIds: goal.documentBasisIds,
        } as GoalItemWithUsage;
      });
      const alphaSorted = goals.sort((a, b) =>
        a?.name.localeCompare(b?.name, undefined, { sensitivity: "base" }),
      );
      setMyCustomGoals(alphaSorted);
    }
  }, [customGoalsLoading, customGoals]);

  React.useEffect(() => {
    updateGoalWeightingStates();
  }, [goals]);

  const updateGoalWeightingStates = (): {
    newGoalWeights: { [key: string]: number };
    newGoalOrder: string[];
  } => {
    // this is a new scenario, set good defaults
    let newGoalOrder = [...goals];
    let rebalancedGoalWeights = distributeWeightEvenly(goals, 100);
    if (!goalWeights || Object.keys(goalWeights).length === 0) {
      handleUpdateGoalWeights((_) => rebalancedGoalWeights);
      handleUpdateGoalOrder([...goals]);
      // new scenarios should have goal weights displayed by default
      setShowGoalWeights(true);
    }
    // this is an existing scenario with updated goals so weights, order, locks need to be updated
    else if (
      Object.keys(goalWeights).length !== goals.length ||
      !Object.keys(goalWeights).every((goalId) => goals.includes(goalId))
    ) {
      let newGoalWeights: { [key: string]: number } = {};
      goals.forEach((goal) => {
        if (!goalWeights[goal]) {
          newGoalWeights[goal] = 0;
        } else {
          newGoalWeights[goal] = goalWeights[goal] ?? 0;
        }
      });
      const currentLockedWeightIds = lockedWeightGoalIds.filter((id) => goals.includes(id));
      // a goal was removed and there's no unlocked goals to give the extra weight to
      if (
        Object.keys(goalWeights).length > goals.length &&
        currentLockedWeightIds.length === Object.keys(newGoalWeights).length
      ) {
        newGoalWeights = normalizeWeights(newGoalWeights);
      }
      const otherUnlockedWeights = [];
      let currRemainingWeight = 100;
      Object.keys(newGoalWeights).forEach((currGoalId) => {
        if (!currentLockedWeightIds.includes(currGoalId)) {
          otherUnlockedWeights.push(currGoalId);
        }
        // get remaining weight for even distribution amongst unlocked goals
        else {
          currRemainingWeight -= goalWeights[currGoalId];
        }
      });
      const evenlyDistributedUnlockedWeights = distributeWeightEvenly(
        otherUnlockedWeights,
        currRemainingWeight,
      );
      rebalancedGoalWeights = {
        ...newGoalWeights,
        ...evenlyDistributedUnlockedWeights,
      };
      const totalSum = Object.entries(rebalancedGoalWeights).reduce(
        (sum, [, value]) => sum + value,
        0,
      );
      if (totalSum !== 100) {
        rebalancedGoalWeights = normalizeWeights(rebalancedGoalWeights);
      }
      newGoalOrder = filterAndMergeArr(goalOrder, goals);
      handleUpdateGoalWeights(rebalancedGoalWeights);
      handleUpdateGoalOrder(newGoalOrder);
      handleUpdateLockedWeightGoalIds(currentLockedWeightIds);
    }

    return { newGoalWeights: rebalancedGoalWeights, newGoalOrder };
  };

  const handleGoalSelectionChange = (goalId: string, action: "add" | "remove") => {
    if (action === "remove") {
      handleUpdateGoals((goals) => goals.filter((g) => g !== goalId));
      handleUpdateGoalOrder((order) => order.filter((g) => g !== goalId));
    } else if (!goals.includes(goalId)) {
      handleUpdateGoals((goals) => [...goals, goalId]);
      handleUpdateGoalOrder((order) => [...order, goalId]);
    }
  };

  const customGoalCreationMenuItems = [
    {
      title: renderCreateGoalMenuItem(GoalMenuItem.BinaryGoal),
      onClick: () => {
        handleUpdateCustomGoalType(GoalKind.BinaryGoal);
        setCurrStepIndex((prev) => prev + 1);
      },
      type: YoodliMenuItemType.Default,
    },
    {
      title: renderCreateGoalMenuItem(GoalMenuItem.ScoreGoal),
      onClick: () => {
        handleUpdateCustomGoalType(GoalKind.ScoreGoal);
        setCurrStepIndex((prev) => prev + 1);
      },
      type: YoodliMenuItemType.Default,
    },
    {
      title: renderCreateGoalMenuItem(GoalMenuItem.CompoundGoal),
      onClick: () => {
        handleUpdateCustomGoalType(GoalKind.CompoundGoal);
        setCurrStepIndex((prev) => prev + 1);
      },
      type: YoodliMenuItemType.Default,
    },
    {
      title: renderCreateGoalMenuItem(GoalMenuItem.KnowledgeBasedGoal),
      onClick: () => {
        handleUpdateCustomGoalType(GoalKind.ScoreGoal, true);
        setCurrStepIndex((prev) => prev + 1);
      },
      type: YoodliMenuItemType.Default,
    },
  ];

  const nestedMenuItems = React.useMemo(() => {
    return [
      {
        title: "Default goal",
        subtitle: "Use a pre-built goal",
        subMenuContent: (
          <GoalList
            allGoals={defaultGoals}
            selectedGoals={goals}
            customGoals={customGoals}
            talkingPoints={talkingPoints}
            handleUpdateGoalTalkTime={handleUpdateGoalTalkTime}
            specialGoalIdSelected={specialGoalIdSelected}
            setSpecialGoalIdSelected={setSpecialGoalIdSelected}
            talkTimeMinutes={talkTimeMinutes}
            talkTimeSeconds={talkTimeSeconds}
            talkTimeRange={talkTimeRange}
            goalTalkTime={goalTalkTime}
            goalsExtraDetails={getGoalsExtraDetails(true)}
            goalsExtraTitleInfo={goalsExtraTitleInfo}
            handleGoalSelectionChange={handleGoalSelectionChange}
            setCurrStepIndex={setCurrStepIndex}
            handleEditCustomGoal={handleEditCustomGoal}
            handleUpdateCustomGoalType={handleUpdateCustomGoalType}
            wizardType={wizardType}
          />
        ),
      },
      ((defaultOrg?.custom_goal_enabled && wizardType === WizardType.Org) ||
        wizardType === WizardType.User) &&
        customGoals?.length > 0 && {
          title: "From your library",
          subtitle: "Choose goals created by you or members of your organization",
          subMenuContent: (
            <GoalList
              showSearchBar
              allGoals={myCustomGoals}
              selectedGoals={goals}
              customGoals={customGoals}
              talkingPoints={talkingPoints}
              handleGoalSelectionChange={handleGoalSelectionChange}
              goalsExtraDetails={getGoalsExtraDetails(true)}
              goalsExtraTitleInfo={goalsExtraTitleInfo}
              setCurrStepIndex={setCurrStepIndex}
              handleEditCustomGoal={handleEditCustomGoal}
              handleUpdateCustomGoalType={handleUpdateCustomGoalType}
              wizardType={wizardType}
            />
          ),
        },
      defaultOrg?.custom_goal_enabled &&
        wizardType === WizardType.Org && {
          title: "Create a goal",
          subtitle: "Build a custom goal from scratch",
          subMenuContent: (
            <Stack
              width={"100%"}
              minWidth="150px"
              maxWidth="300px"
              py={1}
              gap={1}
              sx={{ background: getDynamicColor("light1") }}
            >
              {customGoalCreationMenuItems.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={item.onClick}
                  sx={{
                    fontFamily: "Poppins",
                    width: "100%",
                    gap: 1,
                    color: getDynamicColor("purple3"),
                  }}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Stack>
          ),
        },
    ].filter(Boolean) as StackedMenuItem[];
  }, [
    goals,
    customGoals,
    talkingPoints,
    talkTimeMinutes,
    talkTimeSeconds,
    myCustomGoals,
    goalTalkTime,
    talkTimeRange,
  ]);

  return (
    <Stack gap={2}>
      <Stack direction="row" sx={{ gap: 3, justifyContent: "space-between", alignItems: "center" }}>
        <StackedMenu
          menuItems={nestedMenuItems}
          buttonContent={
            <Stack direction="row" sx={{ gap: 0.5, alignItems: "center" }}>
              <Typography>Add a goal</Typography>
              <ExpandMoreRounded />
            </Stack>
          }
          buttonSx={{
            alignItems: "center",
            justifyContent: "center",
            px: { xs: 2, md: 3 },
          }}
        />
      </Stack>
      {goals.length > 1 && (
        <Stack direction="row" sx={{ gap: 3, alignItems: "center" }}>
          <Stack direction="row" sx={{ gap: 0.5, alignItems: "center" }}>
            <RubricIcon />
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: 12,
                fontWeight: 500,
                color: getDynamicColor("purple3"),
              }}
            >
              Custom goal weighting
            </Typography>
            <YoodliTooltip title="Set a custom weight for each goal in your rubric, totaling 100%">
              <InfoOutlined sx={{ width: 16, height: 16 }} />
            </YoodliTooltip>
          </Stack>
          <IOSSwitch
            sizeVariant="small"
            checked={showGoalWeights}
            disabled={!!specialGoalIdSelected}
            onChange={(e) => {
              if (e.target.checked) {
                handleUpdateGoalWeights(distributeWeightEvenly(goals, 100));
                handleUpdateLockedWeightGoalIds([]);
              }
              setShowGoalWeights(e.target.checked);
            }}
          />
        </Stack>
      )}
      {customGoalsLoading &&
        Array(NUM_LOADING_SKELETON_GOALS)
          .fill(0)
          .map((_, ind) => (
            <Skeleton
              component="div"
              variant="rectangular"
              key={ind}
              sx={{
                borderRadius: "4px",
                backgroundColor: getDynamicColor("dark1"),
                p: 2,
                transition: "background-color 0.2s",
                position: "relative",
              }}
              height="62px"
              animation="wave"
            />
          ))}
      <GoalWeightSelector
        disableEdit={wizardType === WizardType.User}
        goals={goals}
        showGoalWeights={showGoalWeights && goals.length > 1}
        customGoals={customGoals}
        customGoalsLoading={customGoalsLoading}
        goalWeights={goalWeights}
        handleUpdateGoalWeights={handleUpdateGoalWeights}
        lockedWeightGoalIds={lockedWeightGoalIds}
        handleUpdateLockedWeightGoalIds={handleUpdateLockedWeightGoalIds}
        goalOrder={goalOrder}
        handleUpdateGoalOrder={handleUpdateGoalOrder}
        talkingPoints={talkingPoints}
        handleUpdateTalkingPoints={handleUpdateTalkingPoints}
        goalTalkTime={goalTalkTime}
        specialGoalIdSelected={specialGoalIdSelected}
        setSpecialGoalIdSelected={setSpecialGoalIdSelected}
        handleEditCustomGoal={handleEditCustomGoal}
        handleUpdateCustomGoalType={handleUpdateCustomGoalType}
        setCurrStepIndex={setCurrStepIndex}
        handleUpdateGoalTalkTime={handleUpdateGoalTalkTime}
        handleRemoveGoal={(goalId) => {
          handleGoalSelectionChange(goalId, "remove");
        }}
        talkTimeMinutes={talkTimeMinutes}
        talkTimeSeconds={talkTimeSeconds}
        talkTimeRange={talkTimeRange}
        setTalkTimeMinutes={setTalkTimeMinutes}
        setTalkTimeSeconds={setTalkTimeSeconds}
        goalsExtraDetails={getGoalsExtraDetails(false)}
        goalsExtraTitleInfo={goalsExtraTitleInfo}
      />
    </Stack>
  );
};
