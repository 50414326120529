// Components
import { Box, Stack, Button, Typography } from "@mui/material";

// Assets
import { ReactComponent as HoistedAnalyticIcon } from "images/icons/icon-hoisted-analytic.svg";
import { ReactComponent as NoDataIcon } from "images/icons/icon-nodata.svg";

// Utils
import { HoistedAnalytic } from "../DashboardTypes";
import DOMPurify from "isomorphic-dompurify";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { DashboardAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";

export type NoDataCopy = {
  title: string;
  description: string;
  cta?: {
    text: string;
    linkTo: string;
    onClick?: (callback?: () => void) => void;
  };
};

const COPY_MAP: {
  [key: string]: NoDataCopy;
} = {
  noData: {
    title: "No data yet!",
    description: "Practice now for 30 seconds to get an initial diagnostic on your {{analytic}}",
    cta: {
      text: "Practice Now",
      linkTo: WebServerExternalPath.PRACTICE_SPEECH,
      onClick: undefined,
    },
  },
  noHoistedAnalytics: {
    title: "Choose your focus analytics",
    description:
      "Select up to four focus analytics and keep using Yoodli to get your trends over time. The more you Yoodle, the better!",
    cta: {
      text: "Choose focus analytics",
      linkTo: undefined,
      onClick: (openModal: () => void) => {
        openModal();
      },
    },
  },
};

type NoDataProps = {
  activeAnalytic?: HoistedAnalytic;
  noHoistedAnalytics: boolean;
  customNoDataCopy?: NoDataCopy;
  handleSetShowAnalyticsSelector: (show: boolean) => void;
  logDashboardAmplitudeEvent?: (...LogDashboardAmplitudeEventType) => void;
};
const reg = /\{\{(.+?)\}\}/g;
const NoData = ({
  activeAnalytic,
  noHoistedAnalytics,
  customNoDataCopy,
  handleSetShowAnalyticsSelector,
  logDashboardAmplitudeEvent,
}: NoDataProps): JSX.Element => {
  const logCtaClicked = () => {
    logDashboardAmplitudeEvent?.(
      DashboardAnalyticsEvents.EMPTY_STATE_CTA_CLICKED,
      {
        dashboard_cta_text: copy?.cta?.text ?? "Practice Now",
        dashboard_cta_url: copy?.cta?.linkTo ?? undefined,
      },
      true,
    );
  };
  const copy =
    customNoDataCopy ??
    (noHoistedAnalytics || !activeAnalytic ? COPY_MAP.noHoistedAnalytics : COPY_MAP.noData);
  const ctaProps = copy?.cta?.linkTo
    ? { href: copy?.cta?.linkTo, onClick: logCtaClicked }
    : {
        onClick: () => {
          copy?.cta?.onClick(() => {
            logCtaClicked();
            handleSetShowAnalyticsSelector(true);
          });
        },
      };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      gap={3}
      sx={{
        minHeight: 300,
        width: "100%",
        p: 3,
      }}
    >
      <Stack
        alignItems="center"
        direction="column"
        gap={1}
        sx={{
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            height: 48,
            width: 48,
            svg: {
              fill: getDynamicColor("dark3"),
            },
          }}
        >
          {noHoistedAnalytics ? <HoistedAnalyticIcon /> : <NoDataIcon />}
        </Box>
        <Typography
          variant="poppins"
          sx={{
            fontWeight: 700,
          }}
        >
          {copy.title}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              copy.description.replace(
                reg,
                activeAnalytic?.label
                  ? `${
                      activeAnalytic?.type === "goal" ? '"' : ""
                    }<strong>${activeAnalytic?.label.toLowerCase()}</strong>${
                      activeAnalytic?.type === "goal" ? '" goal' : ""
                    }`
                  : "speaking habits",
              ),
            ),
          }}
          sx={{
            width: "min(100%, 360px)",
            fontWeight: 500,
            lineHeight: "15px",
            fontSize: 12,
          }}
        />
      </Stack>
      {copy?.cta && (
        <Button
          {...ctaProps}
          variant="contained"
          href={copy?.cta?.linkTo ?? undefined}
          sx={{
            fontSize: 14,
          }}
        >
          {copy?.cta?.text ?? "Practice Now"}
        </Button>
      )}
    </Stack>
  );
};

export default NoData;
