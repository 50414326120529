import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate, useLocation } from "react-router";

// Components
import { DoNotDisturb as DoNotDisturbIcon, AddCircle as AddCircleIcon } from "@mui/icons-material";

import {
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { RouteLeavingGuard } from "components/RouteLeavingGuard";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Utils
import { EditProgramStep } from "./EditProgramStep";
import { OrgProgramsQueryKeys } from "./OrgPrograms";
import { ProgramStep } from "./ProgramStep";
import { useQuery as useApiQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getAllCustomGoals, updateOrgProgram } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { OrgContentQueryParams, OrgProgramsQueryParams } from "lib-fullstack/utils/queryParams";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { GetScenarioResponse } from "lib-fullstack/api/scenarioApiTypes";
import {
  ProgramResponse,
  ProgramScenarioStep,
  UpdateProgramRequest,
} from "lib-fullstack/api/programApiTypes";
import { ProgramState } from "lib-fullstack/utils/enums";
import { WebServerInternalPath } from "utils/paths";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { OrgSettingsTabs } from "lib-frontend/utils/orgUtils";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import { EMAIL_BRANDING_MESSAGE_MAX_LENGTH } from "lib-fullstack/utils/constants";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

type ProgramScenariosProps = {
  program: ProgramResponse;
  scenarios: GetScenarioResponse[];
  handleCopyPracticeLink: () => void;
};

enum ModificationModalType {
  Remove = "remove",
  Add = "add",
}

type ModificationModalState = {
  open: boolean;
  type: ModificationModalType;
  sendEmail: boolean;
  emailMessage: string;
  step: ProgramScenarioStep;
  scenarioTitle: string;
};

const StepModificationModalBody = ({
  modificationModalState,
  setModificationModalState,
}: {
  modificationModalState: ModificationModalState;
  setModificationModalState: (state: ModificationModalState) => void;
}) => {
  return (
    <Stack sx={{ gap: 2, mb: 2 }}>
      <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={modificationModalState.sendEmail}
              onChange={(e) =>
                setModificationModalState({
                  ...modificationModalState,
                  sendEmail: e.target.checked,
                })
              }
            />
          }
          label={
            <Stack direction="row" sx={{ gap: 1, alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: { xs: 12, md: 13 },
                  fontWeight: 600,
                  fontFamily: "poppins",
                  lineHeight: 1.4,
                  color: getDynamicColor("dark5"),
                  textAlign: "left",
                }}
              >
                Notify enrolled members via email
              </Typography>
            </Stack>
          }
        />
      </Stack>
      {modificationModalState.sendEmail && (
        <YoodliTextfield
          value={modificationModalState.emailMessage}
          onChange={(e) =>
            setModificationModalState({
              ...modificationModalState,
              emailMessage: e.target.value,
            })
          }
          multiline
          minRows={3}
          maxChars={EMAIL_BRANDING_MESSAGE_MAX_LENGTH}
        />
      )}
    </Stack>
  );
};
export const ProgramScenarios = ({
  program,
  scenarios,
  handleCopyPracticeLink,
}: ProgramScenariosProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const qp = new URLSearchParams(location.search);

  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);

  const [isAddingScenario, setIsAddingScenario] = React.useState<boolean>(
    qp.has(OrgProgramsQueryParams.SCENARIO_ID),
  );
  const [isEditingScenario, setIsEditingScenario] = React.useState<boolean>(false);
  const [routeLeavingModalVisible, setRouteLeavingModalVisible] = React.useState<boolean>(false);
  const [stepModificationModalState, setStepModificationModalState] =
    React.useState<ModificationModalState>({
      open: false,
      sendEmail: false,
      emailMessage: undefined,
      type: undefined,
      step: undefined,
      scenarioTitle: undefined,
    });

  const customGoalsQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.GoalList, defaultOrgId, curSpaceId],
    queryFn: async () => await getAllCustomGoals(defaultOrgId, { space_id: curSpaceId }),
    refetchOnWindowFocus: false,
  });

  const updateProgramStepsMutation = useMutation({
    mutationFn: ({
      steps,
      emailMessage,
    }: {
      steps: ProgramScenarioStep[];
      emailMessage?: string;
    }) => {
      const payload: UpdateProgramRequest = { plan_steps: steps };
      if (
        emailMessage &&
        stepModificationModalState.sendEmail &&
        program.state === ProgramState.Published
      ) {
        payload.notification_email_message = emailMessage;
      }
      return updateOrgProgram(defaultOrgId, program.id, payload);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [OrgProgramsQueryKeys.OrgPrograms, defaultOrgId],
      });
    },
  });

  const handleStepDrag = (result) => {
    // if the item was dragged outside of the droppable area, do nothing
    if (!result.destination) {
      return;
    }
    // get the source and destination indices
    const sourceIdx = result.source.index;
    const destinationIdx = result.destination.index;
    // create a new array to avoid mutating state
    const newSteps = [
      ...(updateProgramStepsMutation.isPending
        ? updateProgramStepsMutation.variables.steps
        : program.plan_steps),
    ];
    // remove the item from the source index
    const [removed] = newSteps.splice(sourceIdx, 1);
    // and insert it into the destination index
    newSteps.splice(destinationIdx, 0, removed);
    // update state
    updateProgramStepsMutation.mutate({ steps: newSteps });
  };

  const disableAction =
    isAddingScenario || isEditingScenario || program.state === ProgramState.Archived;
  return customGoalsQuery.isPending ? (
    <CircularProgress />
  ) : (
    <Stack>
      <RouteLeavingGuard
        when={
          (isAddingScenario || isEditingScenario) && !qp.has(OrgProgramsQueryParams.SCENARIO_ID)
        }
        shouldBlockNavigation={() => {
          if (!routeLeavingModalVisible && (isAddingScenario || isEditingScenario)) {
            return true;
          }
          return false;
        }}
        title="You are leaving this page"
        body="Heads up! If you leave before saving the program your changes will be lost. Are you sure you want to leave?"
        modalVisible={routeLeavingModalVisible}
        setModalVisible={setRouteLeavingModalVisible}
        okButtonSx={{ width: 90 }}
        cancelButtonSx={{ width: 90 }}
      />
      <Stack gap={2} direction="row" sx={{ alignItems: "center" }}>
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: 700,
          }}
        >
          Scenarios
        </Typography>
        {program.state === ProgramState.Archived && (
          <Stack direction="row" gap={0.5} sx={{ alignItems: "center" }}>
            <DoNotDisturbIcon sx={{ width: 12, height: 12 }} />
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontSize: "10px",
                fontFamily: "poppins",
                fontWeight: 600,
              }}
            >
              Programs steps are no longer editable
            </Typography>
          </Stack>
        )}
      </Stack>
      <Stack gap={1}>
        <DragDropContext onDragEnd={handleStepDrag}>
          <Droppable
            droppableId="droppable"
            style={{
              width: "100%",
            }}
          >
            {(provided) => (
              <Stack {...provided.droppableProps} ref={provided.innerRef}>
                {(updateProgramStepsMutation.isPending
                  ? updateProgramStepsMutation.variables.steps
                  : program.plan_steps
                ).map((step, index) => (
                  <Draggable
                    key={`${step.scenario_id}-${index}`}
                    draggableId={index.toString()} // According to docs, this needs to be a string
                    index={index}
                    isDragDisabled={disableAction}
                  >
                    {(provided) => (
                      <Box
                        component="div"
                        ref={provided.innerRef}
                        id={index}
                        {...provided.draggableProps}
                      >
                        <ProgramStep
                          step={step}
                          scenarios={scenarios}
                          program={program}
                          customGoals={customGoalsQuery.data.goals}
                          disableAction={disableAction}
                          programState={program.state as ProgramState}
                          handleRemoveStep={() => {
                            if (program.state === ProgramState.Published) {
                              const programScenario = scenarios.find(
                                (s) => s.id === step.scenario_id,
                              );
                              setStepModificationModalState({
                                open: true,
                                type: ModificationModalType.Remove,
                                sendEmail: false,
                                step: step,
                                scenarioTitle: programScenario.title,
                                emailMessage: `${programScenario.title} has been removed from ${program.name}. If you have already completed this roleplay, your progress is not affected.`,
                              });
                            } else {
                              updateProgramStepsMutation.mutate({
                                steps: program.plan_steps.filter((_, i) => i !== index),
                              });
                              setIsEditingScenario(false);
                            }
                          }}
                          handleEditStep={() => setIsEditingScenario(true)}
                          handleSaveEdits={(newStep) => {
                            const newSteps = [...program.plan_steps];
                            newSteps[index] = newStep;
                            updateProgramStepsMutation.mutate({
                              steps: newSteps,
                            });
                            setIsEditingScenario(false);
                          }}
                          handleCopyPracticeLink={handleCopyPracticeLink}
                          showLine={
                            index !== program.plan_steps.length - 1 ||
                            program.state !== ProgramState.Archived
                          }
                          dragHandleProps={provided.dragHandleProps}
                          programId={program.id}
                          stepIndex={index}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>

        {isAddingScenario && (
          <EditProgramStep
            isAddingScenario={isAddingScenario}
            programState={program.state}
            scenarios={scenarios}
            customGoals={customGoalsQuery.data.goals}
            handleRemoveScenario={() => setIsAddingScenario(false)}
            handleSaveScenario={(step) => {
              if (program.state === ProgramState.Published) {
                const programScenario = scenarios.find((s) => s.id === step.scenario_id);
                setStepModificationModalState({
                  open: true,
                  type: ModificationModalType.Add,
                  sendEmail: false,
                  step: step,
                  scenarioTitle: programScenario.title,
                  emailMessage: `${programScenario.title} has been added to ${program.name}. Please review your status.`,
                });
              } else {
                updateProgramStepsMutation.mutate({
                  steps: [...program.plan_steps, step],
                });
                Instrumentation.logProgramScenarioAdded(defaultOrgId, program.id);
                setIsAddingScenario(false);
              }
            }}
          />
        )}
        {program.state !== ProgramState.Archived && (
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              justifyContent: "space-evenly",
              alignItems: "center",
              borderRadius: "3px",
              border: `1px solid ${getDynamicColor("dark3")}`,
              height: { xs: "200px", md: "85px" },
              py: 1,
            }}
          >
            <YoodliTooltip title={scenarios.length === 0 ? "No scenarios currently created" : ""}>
              <Button
                onClick={() => setIsAddingScenario(true)}
                disabled={disableAction || scenarios.length === 0}
                startIcon={<AddCircleIcon />}
                sx={{ fontSize: "14px" }}
              >
                Add existing scenario
              </Button>
            </YoodliTooltip>
            <Divider
              orientation={isSmallScreen ? "horizontal" : "vertical"}
              flexItem
              sx={{ borderColor: getDynamicColor("dark3") }}
            />
            <Button
              onClick={() => {
                const queryParams = new URLSearchParams({
                  [OrgContentQueryParams.TAB]: OrgSettingsTabs.CUSTOMIZE_PRACTICE,
                  [OrgContentQueryParams.PROGRAM_ID]: program.id,
                  [OrgContentQueryParams.SHOW_SCENARIO_TEMPLATES]: "true",
                });

                navigate({
                  pathname: WebServerInternalPath.ORG_CONTENT,
                  search: queryParams.toString(),
                });
              }}
              disabled={disableAction}
              startIcon={<AddCircleIcon />}
              sx={{ fontSize: "14px" }}
            >
              Create a scenario
            </Button>
          </Stack>
        )}
      </Stack>
      <YoodliCtaModal
        wrapperSx={{ gap: 2 }}
        theme={
          stepModificationModalState.type === ModificationModalType.Remove
            ? YoodliCtaModalTheme.Danger
            : YoodliCtaModalTheme.Primary
        }
        hideCloseButton
        loading={updateProgramStepsMutation.isPending}
        open={stepModificationModalState.open}
        close={() => setStepModificationModalState((prev) => ({ ...prev, open: false }))}
        ctaBody={{
          title: `Are you sure you want to ${stepModificationModalState.type === ModificationModalType.Remove ? "remove" : "add"} ${stepModificationModalState.scenarioTitle} from this Program?`,
          subtitle:
            stepModificationModalState.type === ModificationModalType.Remove
              ? "This will not delete the roleplay from your library."
              : "This may affect members’ Program progress.",
        }}
        bodyComponent={
          <StepModificationModalBody
            modificationModalState={stepModificationModalState}
            setModificationModalState={setStepModificationModalState}
          />
        }
        buttons={
          {
            primary: {
              text: `${stepModificationModalState.type === ModificationModalType.Remove ? "Remove" : "Add"} roleplay ${stepModificationModalState.sendEmail ? "& send email" : ""}`,
              handler: async () => {
                if (stepModificationModalState.type === ModificationModalType.Remove) {
                  const index = program.plan_steps.findIndex(
                    (s) => s.id === stepModificationModalState.step.id,
                  );
                  updateProgramStepsMutation.mutate({
                    steps: program.plan_steps.filter((_, i) => i !== index),
                    emailMessage: stepModificationModalState.sendEmail
                      ? stepModificationModalState.emailMessage
                      : undefined,
                  });
                  setIsEditingScenario(false);
                } else {
                  await updateProgramStepsMutation.mutateAsync({
                    steps: [...program.plan_steps, stepModificationModalState.step],
                    emailMessage: stepModificationModalState.sendEmail
                      ? stepModificationModalState.emailMessage
                      : undefined,
                  });
                  setIsAddingScenario(false);
                  Instrumentation.logProgramScenarioAdded(defaultOrgId, program.id);
                }
                setStepModificationModalState((prev) => ({ ...prev, open: false }));
              },
            },
            secondary: {
              text: "Cancel",
              handler: () => setStepModificationModalState((prev) => ({ ...prev, open: false })),
            },
          } as CtaButtonHandlers
        }
      />
    </Stack>
  );
};
