export const SPEECH_TYPES = {
  UPLOADED: "Uploaded",
  LIVE: "No Notes",
  CHROME_EXTENSION: "Google Chrome Extension",
  ZOOM_RECALL: "Meetings (Team)",
  POODLI: "Meetings (Private)",
} as const;

export enum SPEECH_SUBTYPES {
  IMPROMPTU_PROMPT = "Fun Topic",
  JOB_INTERVIEW = "Interview",
  CONVERSATION = "Roleplay",
  SPEECH = "Presentation",
}

export enum SpeechDisplayType {
  LIVE = "Live",
  PRACTICE = "Practice",
  UPLOADED = "Uploaded",
  OTHER = "-",
}

/** Note: Maintained on the back-end too. If a case is added here, it also needs to be added to the back-end (except for time-related alerts) */
export const REAL_TIME_ALERTS = {
  FIFTY_PERCENT_COMPLETE: {
    name: "FIFTY_PERCENT_COMPLETE",
    message: "Halfway there!",
  },
  ONE_HUNDRED_PERCENT_COMPLETE: {
    name: "ONE_HUNDRED_PERCENT_COMPLETE",
    message: "Time's up! Finish strong.",
  },
  ANALYTICS_REMINDER: {
    name: "ANALYTICS_REMINDER",
    message: "Speak for at least 30 seconds to get the most from our analytics",
  },
};

export enum HubModalStatus {
  CREATE_HUB = "create_hub",
  INVITE_MEMBERS = "invite_members",
  TRANSFER_ORG_OWNER = "transfer_org_owner",
  DELETE_HUB = "delete_hub",
  REMOVE_HUB_MEMBER = "remove_hub_member",
  REMOVE_ORG_MEMBER = "remove_org_member",
  LEAVE_HUB = "leave_member",
  RENAME_HUB = "rename_hub",
  ACCEPT_INVITE = "accept_invite",
  JOINED_HUB = "joined_hub",
  MANAGE_ADMINS = "manage_admins",
  MANAGE_DATA = "manage_data",
  UPLOAD_DEMO_VIDEO = "upload_demo_video",
  CREATE_COURSE = "create_course",
  EDIT_COURSE_DETAILS = "edit_course_details",
  EDIT_DEMO_VIDEOS = "edit_demo_videos",
  UPLOAD_COURSE_VIDEO = "upload_course_video",
  EDIT_COURSE_VIDEOS = "edit_course_videos",
  DELETE_COURSE = "delete_course",
  CLOSED = "closed",
  EXISTING_MEMBER = "existing_member",
  UPLOAD_ORG_LOGO = "upload_org_logo",
  CREATE_INTERVIEW_BANK = "create_interview_bank",
  DELETE_INTERVIEW_BANK = "delete_interview_bank",
  EDIT_INTERVIEW_BANK_NAME = "edit_interview_bank_name",
  EDIT_INTERVIEW_BANK_QUESTIONS = "edit_interview_bank_questions",
  EDIT_INTERVIEW_BANK_AVAILABLE_HUBS = "edit_interview_bank_available_hubs",
  UPDATE_ORG_SEATS = "update_org_seats",
  DECREASE_ORG_SEATS_CONFIRM = "decrease_org_seats_confirm",
  ORG_FULL = "org_full",
  ORG_CREATED = "org_created",
  DELETE_SIGN_UP_NOTICE = "delete_sign_up_notice",
}

export enum OrgSettingsSectionStatus {
  DEFAULT = "default",
  CREATE_ORG = "create_org",
  INVITE_ADMIN = "invite_admin",
  EDIT_ORG_EMAIL_BRANDING = "edit_org_email_branding",
  INVITE_MEMBERS = "invite_members",
  EDIT_ORG_SIGN_UP_NOTICE = "edit_org_sign_up_notice",
  MANAGE_FEATURE_ACCESS = "manage_feature_access",
  MANAGE_INTEGRATIONS = "manage_integrations",
  MANAGE_HELP_PAGE = "manage_help_page",
}

export enum ZIndex {
  NAV_DRAWER = 1700,
  MODAL = 2000,
}

export enum DeleteModalType {
  HUB = "hub",
  COURSE = "course",
  INTERVIEW_BANK = "interview bank",
}

export enum OrgSubscriptionTypeLabel {
  PAYG = "Pay as you go",
  FLEXIBLE = "Flexible",
  PREPAID = "Prepaid",
}

export enum OrgCustomerTypeLabel {
  COACH = "Communication Coaching",
  EDUCATION = "Education",
  ENTERPRISE = "Enterprise",
  OTHER = "Other",
}
