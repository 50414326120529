import { db } from "lib-fullstack";

// Components
import { Box, Checkbox } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import {
  AnalyticProcessingState,
  HumanEvaluationState,
  OrgFileState,
} from "lib-fullstack/utils/enums";
import { TeamMemberRoleEnum } from "lib-fullstack/utils/enums/teamMemberRole";

export interface VideoListingItem {
  dbSpeech: db.Doc<db.Speech>;
  dbShare?: db.Doc<db.Share>;
  thumbnailUrl?: string | null;
}

export type VideoJournalTableRow = {
  // This type is comprised of fields and metadata
  // Each field is a table column, and is used for filtering/sorting (can be overridden though)
  // Each field must non-null because tan table will log a warning if it is null
  // Any sub-data of metaData can be null.
  id: string;
  thumbnail: string;
  title: string;
  created: string;
  sharedBy: string;
  recordedByName: string;
  isViewed: boolean;
  type: string;
  totalTime: number;
  fillerWordPercentage: number;
  pacing: number;
  humanEvaluationState: HumanEvaluationState;
  scenario: string;
  metaData: {
    videoListingItem: VideoListingItem;
  };
};

export type SpeechTableRow = {
  // This type is comprised of fields and metadata
  // Each field is a table column, and is used for filtering/sorting (can be overridden though)
  // Each field must non-null because tan table will log a warning if it is null
  // Any sub-data of metaData can be null.
  id: string;
  thumbnail: string;
  title: string;
  created: string;
  sharedBy: string;
  recordedByName: string;
  recordedByEmail: string;
  isViewed: boolean;
  type: string;
  totalTime: number;
  fillerWordPercentage: number;
  pacing: number;
  score: string | number;
  humanEvaluationState: HumanEvaluationState;
  scenario: string;
  metaData: {
    videoListingItem: VideoListingItem;
  };
};

export type FileLibraryTableRow = {
  id: string;
  name: string;
  updated_date: string;
  type: string;
  signed_url: string;
  state: OrgFileState;
  upload_pct: number;
};

export type CoachBotFileTableRow = {
  id: string;
  file_id: string;
  name: string;
  type: string;
  last_updated: string;
  signed_url: string;
  processing_status: AnalyticProcessingState;
  file_processing_status: OrgFileState;
  upload_state?: OrgFileState;
  upload_pct?: number;
};

export type TeamMemberTableRow = {
  id: string;
  name: string;
  email: string;
  role: TeamMemberRoleEnum;
  lastActive: string;
};

export type TeamTableRow = {
  id: string;
  name: string;
  lastUpdated: string;
  numMembers: number;
};

export type TableRow =
  | VideoJournalTableRow
  | SpeechTableRow
  | FileLibraryTableRow
  | CoachBotFileTableRow
  | TeamMemberTableRow
  | TeamTableRow;

export const headerRowStyles = {
  width: "100%",
  borderBottom: `1px solid ${getDynamicColor("dark2")}`,
  backgroundColor: getDynamicColor("light1"),
  position: "sticky",
  top: 0,
  zIndex: 1,
};

export const defaultTableDataStyles = {
  fontFamily: "poppins",
  fontWeight: 400,
  fontSize: 12,
  color: getDynamicColor("dark5"),
};

export const tableHeaderStyles = {
  ...defaultTableDataStyles,
  fontWeight: 600,
  mr: 1,
};

export const dataRowStyles = {
  width: "max-content",
  borderBottom: `1px solid ${getDynamicColor("dark2")}`,
  "&:last-of-type": {
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    borderBottom: "unset",
  },
};

export const CheckboxTableElement = ({
  component,
  checked,
  indeterminate,
  onChange,
}: {
  component: "th" | "td";
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  indeterminate?: boolean;
}): JSX.Element => {
  return (
    <Box
      sx={{
        width: 50,
        px: 0.5,
      }}
      component={component}
    >
      <Box sx={{ display: "flex" }}>
        <Checkbox checked={checked} indeterminate={indeterminate} onChange={onChange} />
      </Box>
    </Box>
  );
};
