import firebase from "firebase/app";
import React from "react";

// Components
import { CheckCircleRounded as SuccessIcon } from "@mui/icons-material";
import { Box, Button, Checkbox, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { CustomizePracticeQueryKey } from "components/ConvoScenarios/convoScenarioUtils";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { useQuery as useApiQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { listScenarios } from "lib-frontend/modules/AxiosInstance";
import {
  createOrgIntegrationContextAssociation,
  deleteOrgIntegrationContextAssociation,
  listOrgIntegrationContextAssociations,
} from "lib-frontend/modules/axiosOrgIntegrationContextAssociation";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { LtiIntegrations, OrgIntegrationQueryKeys } from "lib-frontend/utils/orgUtils";
import {
  CreateOrgIntegrationContextToScenarioAssociationRequest,
  ListOrgIntegrationContextAssociationsResponseItem,
} from "lib-fullstack/api/integrationContextAssociationApiTypes";
import { ListOrgIntegrationResponseItem } from "lib-fullstack/api/integrationsApiTypes";
import { IntegrationContextToScenarioAssociation } from "lib-fullstack/db/db_runtypes_integration_context_association";
import { asyncMap } from "lib-fullstack/utils/asyncMap";
import { IntegrationContextAssociationTypeEnum } from "lib-fullstack/utils/enums/integrationContextAssociationType";

type ManageIntegrationModalProps = {
  scenarioId: string;
  integrations: ListOrgIntegrationResponseItem[];
};

export const ManageLtiIntegrationModalContent = ({
  scenarioId,
  integrations,
}: ManageIntegrationModalProps): JSX.Element => {
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);
  const queryClient = useQueryClient();
  const [selectedIntegration, setSelectedIntegration] =
    React.useState<ListOrgIntegrationResponseItem>(integrations?.[0]);
  const [currCourseIds, setCurrCourseIds] = React.useState<string>("");
  const [selectedCourseIdsForDeletion, setSelectedCourseIdsForDeletion] = React.useState<string[]>(
    [],
  );
  const [showingSuccessMessage, setShowingSuccessMessage] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const scenariosQuery = useApiQuery({
    queryKey: [CustomizePracticeQueryKey.Scenarios, defaultOrgId],
    queryFn: async () => await listScenarios(defaultOrgId, { space_id: curSpaceId }),
    enabled: !!firebase.auth().currentUser?.uid && !!defaultOrgId && !!curSpaceId,
    refetchOnWindowFocus: false,
  });
  const integrationContextsQuery = useApiQuery({
    queryKey: [
      OrgIntegrationQueryKeys.ORG_INTEGRATION_CONTEXTS,
      defaultOrgId,
      selectedIntegration?.id,
    ],
    queryFn: () => listOrgIntegrationContextAssociations(defaultOrgId, selectedIntegration?.id),
    refetchOnWindowFocus: false,
    enabled: !!selectedIntegration,
  });
  const integrationContexts =
    (integrationContextsQuery.data?.associations as IntegrationContextToScenarioAssociation[]) ??
    [];
  const contexts = React.useMemo(() => {
    // TODO filter to only the contexts associated to this scenario
    return integrationContexts.filter((association) => {
      return association.scenarioId === scenarioId;
    });
  }, [integrationContexts]);

  const createIntegrationContextMutation = useMutation({
    mutationFn: async () => {
      const courseIds = parseCourseIds();
      await asyncMap(
        courseIds,
        async (courseId) => {
          const body: CreateOrgIntegrationContextToScenarioAssociationRequest = {
            contextId: courseId,
            type: IntegrationContextAssociationTypeEnum.Scenario,
            scenarioId: scenarioId,
          };
          try {
            await createOrgIntegrationContextAssociation(
              defaultOrgId,
              selectedIntegration?.id,
              body,
            );
          } catch (error) {
            const existingAssociation = integrationContexts.find(
              (context) => context.contextId === courseId,
            );
            if (existingAssociation) {
              const scenario = scenariosQuery.data?.contentArray.find(
                (scenario) => scenario.id === existingAssociation.scenarioId,
              );
              setError(`Course already associated with scenario "${scenario?.title}"`);
            } else {
              setError(error.message);
            }
            throw error;
          }
        },
        5,
        1,
      );
    },
    onSuccess: () => {
      setCurrCourseIds("");
      showSuccessMessage();
      void queryClient.invalidateQueries({
        queryKey: [
          OrgIntegrationQueryKeys.ORG_INTEGRATION_CONTEXTS,
          defaultOrgId,
          selectedIntegration?.id,
        ],
      });
    },
    onError: (error) => {
      console.error("Error creating org integration context", error);
    },
  });

  const deleteIntegrationContextMutation = useMutation({
    mutationFn: async () => {
      await asyncMap(
        selectedCourseIdsForDeletion,
        async (courseId) => {
          await deleteOrgIntegrationContextAssociation(
            defaultOrgId,
            selectedIntegration?.id,
            courseId,
          );
        },
        5,
        1,
      );
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [
          OrgIntegrationQueryKeys.ORG_INTEGRATION_CONTEXTS,
          defaultOrgId,
          selectedIntegration?.id,
        ],
      });
    },
    onError: (error) => {
      console.error("Error creating org integration context", error);
    },
  });

  const parseCourseIds = () => {
    return currCourseIds.split(",").map((id) => id.trim());
  };

  const showSuccessMessage = (): void => {
    setShowingSuccessMessage(true);
    setTimeout(() => {
      setShowingSuccessMessage(false);
    }, 3000);
  };

  const integrationOptions = React.useMemo(() => {
    return integrations
      .filter((integration) => LtiIntegrations.includes(integration.type))
      .map((integration) => {
        return {
          value: integration.id,
          label: integration.name,
        };
      });
  }, [integrations]);

  const renderContentAssociations = () => {
    if (!contexts || contexts.length === 0) {
      return (
        <Stack
          direction="row"
          sx={{
            py: 2,
            px: 1,
            fontSize: 14,
            fontWeight: 500,
            color: getDynamicColor("dark3"),
          }}
        >
          <Typography>None yet</Typography>
        </Stack>
      );
    }
    return (
      <>
        {contexts.map(
          (context: ListOrgIntegrationContextAssociationsResponseItem, index: number) => {
            return (
              <Stack
                key={context.contextId}
                direction="row"
                sx={{
                  gap: 1,
                  alignItems: "center",
                  borderBottom:
                    index < contexts.length - 1 && `1px solid ${getDynamicColor("dark3")}`,
                }}
              >
                <Checkbox
                  sx={{ fontSize: 12 }}
                  value={selectedCourseIdsForDeletion.includes(context.contextId)}
                  onChange={() => {
                    if (selectedCourseIdsForDeletion.includes(context.contextId)) {
                      setSelectedCourseIdsForDeletion((prev) =>
                        prev.filter((id) => id !== context.contextId),
                      );
                    } else {
                      setSelectedCourseIdsForDeletion((prev) => [...prev, context.contextId]);
                    }
                  }}
                />
                <Typography>{context.contextId}</Typography>
              </Stack>
            );
          },
        )}
      </>
    );
  };

  const renderIntegrationSelector = (): JSX.Element => {
    if (!selectedIntegration) {
      return;
    }
    if (integrations?.length === 1) {
      return (
        <Stack
          sx={{
            p: 2,
            fontSize: 14,
            fontWeight: 500,
            color: getDynamicColor("purple3"),
            border: `1px solid ${getDynamicColor("dark3")}`,
            borderRadius: "4px",
          }}
        >
          <Typography sx={{ textAlign: "left" }}>{selectedIntegration?.name}</Typography>
        </Stack>
      );
    }
    return (
      <YoodliSelect
        value={selectedIntegration?.id}
        onChange={(e) => {
          const integration = integrations.find((integration) => integration.id === e.target.value);
          setSelectedIntegration(integration);
        }}
        options={integrationOptions}
        sx={{
          background: getDynamicColor("light1"),
          minWidth: { xs: "fit-content", md: 200 },
        }}
        inputProps={{
          sx: {
            ".selected": {
              p: 0,
              fontWeight: 700,
              color: getDynamicColor("purple3"),
              ".label": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                textAlign: "left",
              },
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
            p: 1.5,
          },
        }}
      />
    );
  };

  return (
    <Stack
      sx={{
        gap: 3,
        fontSize: 12,
        fontFamily: "poppins",
        fontWeight: 700,
        color: getDynamicColor("purple3"),
      }}
    >
      {renderIntegrationSelector()}
      <Divider sx={{ mx: -5 }} />
      <Stack
        direction="column"
        sx={{
          gap: 0.5,
          position: "relative",
        }}
      >
        <Typography sx={{ textAlign: "left" }}>Add new</Typography>
        <YoodliTextfield
          value={currCourseIds}
          placeholder="Course ID"
          InputProps={{
            endAdornment: (
              <>
                {createIntegrationContextMutation.isPending ? (
                  <Box sx={{ width: 16, height: 16 }}>
                    <CircularProgress size={16} />
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ ml: 1 }}
                    onClick={() => createIntegrationContextMutation.mutate()}
                    disabled={currCourseIds.length === 0}
                  >
                    Add
                  </Button>
                )}
              </>
            ),
          }}
          onChange={(e) => {
            setError(null);
            setCurrCourseIds(e.target.value);
          }}
        />
        {error && (
          <Typography
            sx={{
              color: getDynamicColor("redError"),
              fontSize: 12,
              fontWeight: 600,
              fontFamily: "poppins",
              textAlign: "start",
            }}
          >
            {error}
          </Typography>
        )}
        {showingSuccessMessage && (
          <Stack
            direction="row"
            sx={{
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 0.5,
              position: "absolute",
              color: getDynamicColor("greenSuccess"),
              fontSize: 12,
              fontWeight: 600,
              bottom: "-18px",
            }}
          >
            <SuccessIcon sx={{ width: "12px", height: "12px" }} />
            <Typography>Scenario mapped to courses</Typography>
          </Stack>
        )}
      </Stack>
      <Stack
        direction="column"
        sx={{
          gap: 0.5,
        }}
      >
        <Stack
          direction="row"
          sx={{ gap: 2, justifyContent: "space-between", alignItems: "center", height: 24 }}
        >
          <Typography sx={{ textAlign: "left" }}>Active in</Typography>
          {selectedCourseIdsForDeletion.length > 0 && (
            <Button
              variant="text_small"
              sx={{ fontSize: 12, color: getDynamicColor("redError") }}
              onClick={() => {
                deleteIntegrationContextMutation.mutate();
              }}
              disabled={selectedCourseIdsForDeletion.length === 0}
              endIcon={deleteIntegrationContextMutation.isPending && <CircularProgress size={14} />}
            >
              Delete selected
            </Button>
          )}
        </Stack>
        <Stack
          direction="column"
          sx={{
            px: 1,
            borderRadius: "4px",
            border: `1px solid ${getDynamicColor("dark3")}`,
            maxHeight: "180px",
            overflow: "auto",
          }}
        >
          {renderContentAssociations()}
        </Stack>
      </Stack>
    </Stack>
  );
};
