import retry from "async-retry";
import firebase from "firebase/app";
import { initializeFirebase } from "lib-frontend/modules/FirebaseModule";
import React from "react";
import { useBeforeunload } from "react-beforeunload";
import {
  fullBrowserVersion,
  isChromium,
  isChrome,
  isOpera,
  isEdge,
  isFirefox,
  isMobile,
  isSafari,
} from "react-device-detect";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { IntercomProvider as ReactUseIntercomProvider } from "react-use-intercom";

// Components
import { HomePageEls } from "./components/Home/Home";
import IncompatibleBrowser from "./components/IncompatibleBrowser";
import Layout from "./components/Layout";
import { MyLearningTabs } from "./components/Orgs/MyLearning";
import AnalyticsProcessingModal from "./ui/AnalyticsProcessingModal";
import { RootStylesWrapper } from "./ui/Styles";
import v5ToastmastersTheme from "./ui/ToastmastersTheme";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import AcceptInvite from "components/AcceptInvite/AcceptInvite";
import { AdminConfigOrgDetails } from "components/Admin/AdminConfigOrgDetails";
import { AdminConfigOrgs } from "components/Admin/AdminConfigOrgs";
import { CreateHubWizard } from "components/Orgs/CreateHubWizard/CreateHubWizard";
import { AffiliateProgram } from "components/Settings/AffiliateProgram/AffiliateProgram";
import { FullscreenLoadingAnimation } from "lib-frontend/components/Animations/LoadingAnimation";
import { WIGGLE_ANIMATION as _wiggle } from "lib-frontend/ui/Theme";
import v5theme from "lib-frontend/ui/Theme";

// Utils
import "./App.css";
import AuthFlows from "./auth/";
import { envConfig } from "./utils/Constants";
import { lazyWithRefresh } from "./utils/LazyWithRefresh";
import { setDifference } from "./utils/Utilities";
import YoodliErrorBoundary from "./utils/YoodliErrorBoundary";
import * as amplitude from "@amplitude/analytics-browser";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { setupAuthChangeHandler } from "auth";
import EmailVerificationProvider from "auth/providers/EmailVerificationProvider";
import { CurrentlyUploadingFilesContext } from "contexts/CurrentlyUploadingFilesContext";
import { FeatureUpdatesProvider } from "contexts/FeatureUpdatesContext";
import { CustomIntercomProvider, INTERCOM_APP_ID } from "contexts/IntercomContext";
import { PracticeRecorderUploadContext } from "contexts/PracticeRecorderUploadContext";
import { UIStateProvider } from "contexts/UIStateContext";
import { UploadedRecordingIdsContext } from "contexts/UploadedRecordingIdsContext";
import { VideoBlobContext } from "contexts/VideoBlobContext";
import Cookies from "js-cookie";
import { ContentSpacesProvider } from "lib-frontend/contexts/ContentSpacesContext";
import { NotificationProvider } from "lib-frontend/contexts/NotificationContext";
import { TeamProvider } from "lib-frontend/contexts/TeamContext";
import { UserOrgDataProvider } from "lib-frontend/contexts/UserOrgContext";
import { YoodliActivityDataProvider } from "lib-frontend/contexts/YoodliActivityContext";
import { InitiateAsyncTranscription, updateSpeechStates } from "lib-frontend/modules/AxiosInstance";
import { AmplitudeYoodliLogger } from "lib-frontend/utils/AmplitudeYoodliLogger";
import {
  getStaticFullSiteConf,
  liveSiteDocsAreResolved,
  pLiveSiteDocsReady,
} from "lib-frontend/utils/LiveSiteDocs";
import { userDocsService } from "lib-frontend/utils/LiveUserDocs";
import { SettingsTab } from "lib-frontend/utils/PricingData";
import { syncPricingExperimentWebclient } from "lib-frontend/utils/PricingUtils";
import { ProgressiveSpeechUploader, SpeechUploader } from "lib-frontend/utils/SpeechUploader";
import {
  getProgressiveSpeechUploader,
  uploadRecording,
} from "lib-frontend/utils/speechUploaderWrappers";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { useIsBraveBrowser } from "lib-frontend/utils/themeUtils";
import { getEnabledFlag, startUnleash } from "lib-frontend/utils/unleash";
import { injectSeekMetadata } from "lib-frontend/utils/VideoUtils";
import { getAppVersion, getClientEnvConfig } from "lib-fullstack/client_env";
import { UploadState } from "lib-fullstack/db";
import { KFA_PROVIDER, SIGN_IN_CUSTOM_TOKEN_QUERY_PARAM } from "lib-fullstack/utils/auth";
import {
  logRocketNetworkCaptureConfig,
  logrocketBrowserConfig,
  logrocketDomConfig,
} from "lib-fullstack/utils/configs";
import {
  AMPLITUDE_DEVICE_ID,
  AMPLITUDE_REVERSE_PROXY,
  ON_PAID_PLAN_COOKIE,
} from "lib-fullstack/utils/constants";
import {
  LandingPageExternalPath,
  WebServerExternalPath,
  getLandingPageExternalUrl,
} from "lib-fullstack/utils/paths";
import { GlobalQueryParams, MyLearningQueryParams } from "lib-fullstack/utils/queryParams";
import * as LogRocket from "logrocket";
import { PageTitleProvider } from "utils/PageTitleProvider";
import { WebServerInternalPath } from "utils/paths";
import { useHideUnimportantErrors } from "hooks/useHideUnimportantErrors";

initializeFirebase();

// Lazy Loaded Components
const SpinAYarn = lazyWithRefresh(() => import("./components/Games/YarnGame"));
const MetaphorMania = lazyWithRefresh(() => import("./components/Games/MetaphorMania"));
const NoFiller = lazyWithRefresh(() => import("./components/Games/NoFiller"));
const Storyteller = lazyWithRefresh(() => import("./components/Games/Storyteller"));

// NOTE: we do not use lazyWithRefresh for the Speech Summary page as it can be loaded from a live speech.
// In this case, it is actually better to error, as it provides a lower chance of interrupting the upload
// (and if it does interrupt the upload, it's clearer that something went wrong)
const SpeechSummaryWrapper = React.lazy(
  () => import("./components/VideoSummary/SpeechSummary/SpeechSummaryWrapper"),
);
const Home = lazyWithRefresh(() => import("./components/Home/Home"));
const CourseContent = lazyWithRefresh(() => import("./components/Courses/CourseContent"));
const Onboarding = lazyWithRefresh(() => import("./components/Onboarding/Onboarding"));
const Account = lazyWithRefresh(() => import("./components/Account"));
const OrgSettings = lazyWithRefresh(() => import("./components/Orgs/OrgSettings/OrgSettings"));
const OrgOverview = lazyWithRefresh(() => import("./components/Orgs/Overview/OrgOverview"));
const OrgMembers = lazyWithRefresh(() => import("./components/Orgs/Members/OrgMembers"));
const OrgManageContent = lazyWithRefresh(
  () => import("./components/Orgs/ManageContent/OrgManageContent"),
);
const ReportingTeamsRoutes = lazyWithRefresh(
  () => import("./components/Orgs/ReportingTeams/ReportingTeamsRoutes"),
);

const MyLearning = lazyWithRefresh(() => import("./components/Orgs/MyLearning"));
const Builder = lazyWithRefresh(() => import("./components/Builder/Builder"));
const OrgHubs = lazyWithRefresh(() => import("./components/Orgs/Hubs/OrgHubs"));
const CreateOrg = lazyWithRefresh(() => import("./components/Orgs/CreateOrg/CreateOrg"));
const VideoJournal = lazyWithRefresh(() => import("./components/VideoJournal/VideoJournal"));
const SupportPage = lazyWithRefresh(() => import("./components/Support/Support"));

const DashboardRoutes = lazyWithRefresh(() => {
  return import("./components/Dashboard/DashboardRoutes");
});

const NotFound = lazyWithRefresh(() => import("./components/NotFound"));
const Restricted = lazyWithRefresh(() => import("./components/Restricted"));
const FinishSettingUpPoodli = lazyWithRefresh(() => import("./components/FinishSettingUpPoodli"));
const CheckoutComplete = lazyWithRefresh(() => import("./components/CheckoutComplete"));

const PracticeRecorder = lazyWithRefresh(
  () => import("./components/PracticeRecorder/PracticeRecorder"),
);
const EmailVerified = lazyWithRefresh(() => import("./components/EmailVerified"));
const IntegrationErrorPage = lazyWithRefresh(() => import("./components/IntegrationErrorPage"));

const reactQueryClient = new QueryClient();

let handlerHasBeenSetup = false;
const setupPricingExpSync = () => {
  if (handlerHasBeenSetup) {
    return;
  }
  void userDocsService.registerUserDocsChangeListener(() => {
    if (firebase.auth().currentUser) {
      syncPricingExperimentWebclient().catch((err) =>
        console.error("Error getting pricing plan info", err),
      );
    }
  });
  handlerHasBeenSetup = true;
};

export const parseConsentString = (consentString: string): Record<string, string> => {
  const pairs = consentString.split(",");
  const result: Record<string, string> = {};

  for (const pair of pairs) {
    const [key, value] = pair.split(":");
    result[key] = value;
  }

  return result;
};

export const isCookiePreferenceCategoryEnabled = (category: string): boolean | undefined => {
  const consentCookie = Cookies.get("cookieyes-consent");
  if (consentCookie) {
    const consent = parseConsentString(consentCookie);
    if (!consent[category]) {
      return undefined;
    }
    return consent[category] === "yes";
  }
  return undefined;
};

const versionIsGreaterOrEqual = (a: string, b: string): boolean => {
  return a.localeCompare(b, undefined, { numeric: true }) >= 0;
};

const initializeLogRocket = () => {
  if (envConfig.logRocketAppId && Cookies.get("trackingOptOut") !== "true") {
    LogRocket.init(envConfig.logRocketAppId, {
      serverURL: envConfig.logRocketServerUrl,
      release: getAppVersion(),
      rootHostname: envConfig.logRocketRootHostname,
      uploadTimeInterval: 5000,
      ...logrocketBrowserConfig,

      ...logrocketDomConfig,
      ...logRocketNetworkCaptureConfig,
    });
  }
};

const MainApp = (): JSX.Element => {
  /**** Nothing below this line executes until
   * the Firebase Auth user is resolved. *****/
  setupPricingExpSync();

  const navigate = useNavigate();

  setupAuthChangeHandler(navigate);

  const [uploadedRecordingIds, setUploadedRecordingIds] = React.useState(null);
  const value = { uploadedRecordingIds, setUploadedRecordingIds };

  const [currentlyUploadingFiles, setCurrentlyUploadingFiles] = React.useState(null);
  const currentlyUploadingFilesValue = {
    currentlyUploadingFiles,
    setCurrentlyUploadingFiles,
  };

  const videoBlobObjectMapRef = React.useRef<Record<string, Blob>>({});
  const videoUploadObjectMapRef = React.useRef<Record<string, SpeechUploader>>({});
  const recordedSpeechIdRef = React.useRef<string>(null);
  const recordedSpeechDurationRef = React.useRef<number>(0); // ms
  const videoBlobValue = {
    videoBlobObjectMapRef,
    recordedSpeechIdRef,
    recordedSpeechDurationRef,
  };
  const discardableBlobRef = React.useRef<Array<string>>([]);
  const progressiveUploaderMapRef = React.useRef<Record<string, ProgressiveSpeechUploader>>({});
  const urlParams = new URLSearchParams(window.location.search);

  React.useEffect(() => {
    if (urlParams.has(GlobalQueryParams.TIMESTAMP)) {
      urlParams.delete(GlobalQueryParams.TIMESTAMP);
      const newSearch = urlParams.toString();
      const newPath = `${location.pathname}${newSearch ? `?${newSearch}` : ""}`;
      navigate(newPath, { replace: true });
      return; // Exit early since we're updating URL
    }
  }, [navigate, urlParams]);

  useBeforeunload((event: Event) => {
    // we are holding a blob in memory
    if (Object.keys(videoBlobObjectMapRef.current).length > 0) {
      // and that blob is not ready to be deleted (because it finished uploading)
      if (
        setDifference(
          new Set(Object.keys(videoBlobObjectMapRef.current)),
          new Set(discardableBlobRef.current),
        ).size > 0
      ) {
        console.log("Attempted to stop user from leaving the page during live speech upload");
        event.preventDefault();
      }
    } else if (currentlyUploadingFiles?.length > 0) {
      console.log("Attempted to stop user from leaving the page during speech file upload");
      event.preventDefault();
    }
  });

  const videoBlobUpload = (speechId: string, videoBlob: Blob, lastVideoBlob: boolean) => {
    const speechDuration = recordedSpeechDurationRef.current;
    if (videoUploadObjectMapRef.current[speechId]) {
      throw new Error("Attempted to start video upload when one was already in progress");
    }

    if (!lastVideoBlob) {
      if (!progressiveUploaderMapRef.current[speechId]) {
        console.log("Creating new progressive uploader for speechId", speechId);
        getProgressiveSpeechUploader(speechId)
          .then(async (uploader) => {
            progressiveUploaderMapRef.current[speechId] = uploader;
            const seekable = await injectSeekMetadata(videoBlob, 1000 * 60 * 60 * 2); // set the duration of the first blob to 2 hours, so that the blob is basically useable before we fix it
            progressiveUploaderMapRef.current[speechId].queueUpload(seekable, null);
          })
          .catch((err) => {
            console.error(`Failed to create progressive uploader: ${err}`);
          });
      } else {
        progressiveUploaderMapRef.current[speechId].queueUpload(videoBlob, null);
      }
      return;
    }

    let uploadCompletePromise: Promise<string>;

    if (progressiveUploaderMapRef.current[speechId]) {
      progressiveUploaderMapRef.current[speechId].queueUpload(videoBlob, null, true);
      uploadCompletePromise = progressiveUploaderMapRef.current[speechId].finalize();
    } else {
      // this is the case if there's no progressive uploader, probably because the flag is off (but also if the video is very short)
      uploadCompletePromise = uploadRecording(
        videoBlobObjectMapRef.current[speechId] ?? videoBlob,
        speechDuration,
        speechId,
      );
    }

    // Next if() checks if uploadCompletePromise is defined, which looks valid usage.
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (uploadCompletePromise) {
      uploadCompletePromise.then(
        (gsUrl) => {
          console.log(`\tFile was uploaded at: ${gsUrl}`);

          const makeBlobDiscardable = () => {
            discardableBlobRef.current.push(speechId);
            // if file has uploaded, and is no longer the active blob, we can discard it to free up memory
            discardableBlobRef.current = discardableBlobRef.current.filter((speechId) => {
              if (speechId !== recordedSpeechIdRef.current) {
                delete videoBlobObjectMapRef.current[speechId];
                return false;
              }
              return true;
            });
          };

          retry(
            async () => {
              await updateSpeechStates(speechId, {
                upload_state: UploadState.UPLOADED,
                gcs_uploaded: gsUrl,
                total_time_sec: Math.round(speechDuration / 1000),
              });
            },
            {
              retries: 3,
              // This parameter means waiting for 1 sec before the first retry, not actually a timeout of 1 sec
              minTimeout: 1000,
              onRetry: async (error) => {
                console.warn(`updateSpeechStates(uploadState=UPLOADED) failed, retrying`, error);
              },
            },
          )
            .then(() => {
              InitiateAsyncTranscription(speechId)
                .catch((err) => {
                  console.error(err);
                })
                .finally(() => {
                  makeBlobDiscardable();
                });
            })
            .catch(makeBlobDiscardable);
        },
        (error) => {
          console.log(`Error with uploading video to Google Cloud Storage: ${error}`);
          console.error(error);
        },
      );
    }
  };

  const speechRecorderUploadValue = {
    videoBlobUpload,
  };

  const isBrave = useIsBraveBrowser();

  function renderContent() {
    if (envConfig.envName === "production" || envConfig.envName === "staging") {
      const isOldSafari = isSafari && !versionIsGreaterOrEqual(fullBrowserVersion, "14.1");
      const isIncompatibleBrowser =
        isOldSafari ||
        !(isChromium || isChrome || isOpera || isFirefox || isEdge || isSafari || isBrave);
      if (isIncompatibleBrowser && !isMobile) {
        return (
          <IncompatibleBrowser
            header={
              <span>
                Public speaking can be hard.
                <br />
                Building for all browsers is also hard. 🥶
              </span>
            }
            copy="Please visit us at https://www.yoodli.ai/ on Google Chrome or Firefox for the best experience!"
          />
        );
      }
    }

    return (
      <RootStylesWrapper>
        <YoodliErrorBoundary>
          <QueryClientProvider client={reactQueryClient}>
            <UserOrgDataProvider>
              <ReactUseIntercomProvider appId={INTERCOM_APP_ID}>
                {/* Custom Intercom Provider handle things like initializing the launcher, hiding on disallowed paths, etc */}
                <CustomIntercomProvider>
                  <NotificationProvider>
                    <ContentSpacesProvider>
                      <TeamProvider>
                        <PageTitleProvider>
                          <UIStateProvider>
                            <YoodliActivityDataProvider>
                              <UploadedRecordingIdsContext.Provider value={value}>
                                <CurrentlyUploadingFilesContext.Provider
                                  value={currentlyUploadingFilesValue}
                                >
                                  <VideoBlobContext.Provider value={videoBlobValue}>
                                    <PracticeRecorderUploadContext.Provider
                                      value={speechRecorderUploadValue}
                                    >
                                      <CssBaseline />
                                      <React.Suspense
                                        fallback={<FullscreenLoadingAnimation showPrompt={true} />}
                                      >
                                        {/* Establishing all the routes, paths, and custom components */}
                                        <EmailVerificationProvider>
                                          <FeatureUpdatesProvider>
                                            <Layout>
                                              <Routes>
                                                <Route
                                                  path={WebServerExternalPath.SIGN_IN}
                                                  element={AuthFlows(WebServerExternalPath.SIGN_IN)}
                                                />
                                                {/*
                                                 * Redirect from login to signin
                                                 * Note that TMI login *must* redirect to login first
                                                 * and that we must preserve query params during these redirects
                                                 */}
                                                <Route
                                                  path={WebServerExternalPath.LOG_IN}
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname: `${WebServerExternalPath.SIGN_IN}`,
                                                        search: `${window.location.search}`,
                                                      }}
                                                    />
                                                  }
                                                />
                                                <Route
                                                  path={WebServerExternalPath.SIGN_UP}
                                                  element={AuthFlows(WebServerExternalPath.SIGN_UP)}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.FORGOT_PASSWORD}
                                                  element={AuthFlows(
                                                    WebServerInternalPath.FORGOT_PASSWORD,
                                                  )}
                                                />
                                                <Route
                                                  path={
                                                    WebServerExternalPath.DOWNLOAD_POODLI_DIRECT
                                                  }
                                                  element={AuthFlows(
                                                    WebServerExternalPath.DOWNLOAD_POODLI_DIRECT,
                                                  )}
                                                />
                                                <Route
                                                  path={WebServerExternalPath.RESET_PASSWORD}
                                                  element={AuthFlows(
                                                    WebServerExternalPath.RESET_PASSWORD,
                                                  )}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.VERIFY_EMAIL}
                                                  element={AuthFlows(
                                                    WebServerInternalPath.VERIFY_EMAIL,
                                                  )}
                                                />
                                                <Route
                                                  path={WebServerExternalPath.ACCOUNT}
                                                  element={<Account />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.AFFILIATE_PROGRAM}
                                                  element={<AffiliateProgram />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.ONBOARDING}
                                                  element={<Onboarding />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.LIBRARY}
                                                  element={<VideoJournal />}
                                                />
                                                {/* Routes for Recordings */}
                                                {/* DEPRECATED PRACTICE RECORDER ROUTES */}
                                                <Route
                                                  path={
                                                    WebServerInternalPath.RECORD_SPEECH_DEPRECATED
                                                  }
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname:
                                                          WebServerExternalPath.PRACTICE_SPEECH,
                                                      }}
                                                    />
                                                  }
                                                />
                                                <Route
                                                  path={
                                                    WebServerInternalPath.IMPROMPTU_PROMPT_DEPRECATED
                                                  }
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname:
                                                          WebServerExternalPath.PRACTICE_SPEECH,
                                                        search: "?prompt=true",
                                                      }}
                                                    />
                                                  }
                                                />
                                                <Route
                                                  path={
                                                    WebServerInternalPath.PRACTICE_INTERVIEW_DEPRECATED
                                                  }
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname:
                                                          WebServerExternalPath.PRACTICE_INTERVIEW,
                                                      }}
                                                    />
                                                  }
                                                />
                                                <Route
                                                  path={WebServerInternalPath.INTERVIEW_DEPRECATED}
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname:
                                                          WebServerExternalPath.PRACTICE_INTERVIEW,
                                                      }}
                                                    />
                                                  }
                                                />
                                                {/* END DEPRECATED PRACTICE RECORDER ROUTES */}
                                                <Route
                                                  path={
                                                    WebServerInternalPath.PRACTICE_RECORDER_DEPRECATED
                                                  }
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname:
                                                          WebServerExternalPath.PRACTICE_SPEECH,
                                                      }}
                                                    />
                                                  }
                                                />
                                                <Route
                                                  path={WebServerExternalPath.PRACTICE_CONVERSATION}
                                                  element={
                                                    <PracticeRecorder
                                                      key={
                                                        window.location.pathname +
                                                        window.location.search
                                                      }
                                                    />
                                                  }
                                                />
                                                <Route
                                                  path={WebServerExternalPath.PRACTICE_SPEECH}
                                                  element={
                                                    <PracticeRecorder
                                                      key={
                                                        window.location.pathname +
                                                        window.location.search
                                                      }
                                                    />
                                                  }
                                                />
                                                <Route
                                                  path={WebServerExternalPath.PRACTICE_INTERVIEW}
                                                  element={
                                                    <PracticeRecorder
                                                      key={
                                                        window.location.pathname +
                                                        window.location.search
                                                      }
                                                    />
                                                  }
                                                />
                                                {/* END Routes for Recordings */}
                                                <Route
                                                  path={WebServerExternalPath.SUPPORT}
                                                  element={<SupportPage />}
                                                />
                                                {/* Routes for Speech Summary */}
                                                <Route
                                                  path={WebServerInternalPath.SHARE_SLUG}
                                                  element={<SpeechSummaryWrapper />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.SHARE_SLUG_NAME}
                                                  element={<SpeechSummaryWrapper />}
                                                />
                                                {/* END Routes for Speech Summary */}
                                                {/* Routes for Games */}
                                                <Route
                                                  path={WebServerInternalPath.EXERCISES_DEPRECATED}
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname: WebServerExternalPath.MY_LEARNING,
                                                        search: `?${MyLearningQueryParams.TAB}=${MyLearningTabs.Exercises}`,
                                                      }}
                                                    />
                                                  }
                                                />
                                                <Route
                                                  path={WebServerInternalPath.EXERCISES_SPIN_A_YARN}
                                                  element={<SpinAYarn />}
                                                />
                                                <Route
                                                  path={
                                                    WebServerInternalPath.EXERCISES_METAPHOR_MANIA
                                                  }
                                                  element={<MetaphorMania />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.EXERCISES_NO_FILLER}
                                                  element={<NoFiller />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.EXERCISES_STORYTELLER}
                                                  element={<Storyteller />}
                                                />
                                                <Route
                                                  path={
                                                    WebServerInternalPath.GAMES_SPIN_A_YARN_DEPRECATED
                                                  }
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname:
                                                          WebServerInternalPath.EXERCISES_SPIN_A_YARN,
                                                      }}
                                                    />
                                                  }
                                                />
                                                <Route
                                                  path={
                                                    WebServerInternalPath.GAMES_METAPHOR_MANIA_DEPRECATED
                                                  }
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname:
                                                          WebServerInternalPath.EXERCISES_METAPHOR_MANIA,
                                                      }}
                                                    />
                                                  }
                                                />
                                                <Route
                                                  path={
                                                    WebServerInternalPath.GAMES_NO_FILLER_DEPRECATED
                                                  }
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname:
                                                          WebServerInternalPath.EXERCISES_NO_FILLER,
                                                      }}
                                                    />
                                                  }
                                                />
                                                <Route
                                                  path={
                                                    WebServerInternalPath.GAMES_STORYTELLER_DEPRECATED
                                                  }
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname:
                                                          WebServerInternalPath.EXERCISES_STORYTELLER,
                                                      }}
                                                    />
                                                  }
                                                />
                                                {/* END Routes for Games */}
                                                <Route
                                                  path={`${WebServerExternalPath.DASHBOARD}/*`}
                                                  element={<DashboardRoutes />}
                                                />
                                                {/* Routes for Discover */}
                                                <Route
                                                  path={WebServerExternalPath.HOME_LOGGED_IN}
                                                  element={<Home />}
                                                />
                                                <Route
                                                  path={getLandingPageExternalUrl(
                                                    getClientEnvConfig(),
                                                    LandingPageExternalPath.COURSES,
                                                  )}
                                                  element={<CourseContent />}
                                                />
                                                {/* END Routes for Discover */}
                                                <Route
                                                  path={WebServerInternalPath.RESTRICTED}
                                                  element={<Restricted />}
                                                />
                                                {/* Z vs. P */}
                                                <Route
                                                  path={
                                                    WebServerInternalPath.FOR_MEETINGS_DEPRECATED
                                                  }
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname:
                                                          WebServerExternalPath.HOME_LOGGED_IN,
                                                        hash: `#${HomePageEls.ZOODLI_CARD}`,
                                                      }}
                                                    />
                                                  }
                                                />
                                                {/* Include redirects for the old zoodli/poodli pages so links dont die on us */}
                                                <Route
                                                  path={
                                                    WebServerInternalPath.PRIVATE_YOODLI_DEPRECATED
                                                  }
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname:
                                                          WebServerExternalPath.HOME_LOGGED_IN,
                                                        hash: `#${HomePageEls.ZOODLI_CARD}`,
                                                      }}
                                                    />
                                                  }
                                                />
                                                <Route
                                                  path={WebServerInternalPath.ZOODLIBOT_DEPRECATED}
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname:
                                                          WebServerExternalPath.HOME_LOGGED_IN,
                                                        hash: `#${HomePageEls.ZOODLI_CARD}`,
                                                      }}
                                                    />
                                                  }
                                                />
                                                <Route
                                                  path={
                                                    WebServerInternalPath.DOWNLOAD_POODLI_DIRECT_DEPRECATED
                                                  }
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname:
                                                          WebServerExternalPath.DOWNLOAD_POODLI_DIRECT,
                                                      }}
                                                    />
                                                  }
                                                />
                                                {/* END Routes for Zoodli / Poodli */}
                                                {/* START Deprecated routes so old URLs don't 404 */}
                                                <Route
                                                  path={WebServerInternalPath.LEARNING_DEPRECATED}
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname:
                                                          WebServerExternalPath.HOME_LOGGED_IN,
                                                      }}
                                                    />
                                                  }
                                                />
                                                <Route
                                                  path={WebServerInternalPath.SHARED_DEPRECATED}
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname: WebServerInternalPath.LIBRARY,
                                                      }}
                                                    />
                                                  }
                                                />
                                                {/* END Deprecated routes so old URLs don't 404 */}
                                                <Route
                                                  path={
                                                    WebServerExternalPath.POODLI_CALENDAR_CONNECT
                                                  }
                                                  element={<FinishSettingUpPoodli />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.SETUP_DESKTOP_APP}
                                                  element={<FinishSettingUpPoodli />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.CHECKOUT_COMPLETE}
                                                  element={<CheckoutComplete />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.CHECKOUT}
                                                  element={
                                                    <Navigate
                                                      to={{
                                                        pathname: WebServerExternalPath.ACCOUNT,
                                                        search: `tab=${SettingsTab.BILLING}`,
                                                      }}
                                                    />
                                                  }
                                                />
                                                {/* START Yoodli Product Administration */}
                                                <Route
                                                  path={WebServerInternalPath.ADMIN_CONFIG}
                                                  element={<AdminConfigOrgs />}
                                                />
                                                <Route
                                                  path={
                                                    WebServerInternalPath.ADMIN_CONFIG_ORG_DETAILS
                                                  }
                                                  element={<AdminConfigOrgDetails />}
                                                />{" "}
                                                {/* END Yoodli Product Administration */}
                                                <Route
                                                  path={WebServerInternalPath.ACCEPT_INVITE}
                                                  element={<AcceptInvite />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.ORG_OVERVIEW}
                                                  element={<OrgOverview />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.ORG_SETTINGS}
                                                  element={<OrgSettings />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.ORG_MEMBERS}
                                                  element={<OrgMembers />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.ORG_CONTENT}
                                                  element={<OrgManageContent />}
                                                />
                                                <Route
                                                  path={WebServerExternalPath.MY_LEARNING}
                                                  element={<MyLearning />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.ORG_GROUPS}
                                                  element={<OrgHubs />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.CREATE_ORG}
                                                  element={<CreateOrg />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.CREATE_GROUP}
                                                  element={<CreateHubWizard />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.BUILDER}
                                                  element={<Builder />}
                                                />
                                                <Route
                                                  path={WebServerInternalPath.EMAIL_VERIFIED}
                                                  element={<EmailVerified />}
                                                />
                                                <Route
                                                  path={WebServerExternalPath.INTEGRATION_ERROR}
                                                  element={<IntegrationErrorPage />}
                                                />
                                                {/* Add `/*` to these routes because they have sub-routes */}
                                                <Route
                                                  path={`${WebServerInternalPath.ORG_REPORTING_TEAMS}/*`}
                                                  element={<ReportingTeamsRoutes />}
                                                />
                                                {/* Must be the final route */}
                                                <Route path="*" element={<NotFound />} />
                                              </Routes>
                                            </Layout>
                                          </FeatureUpdatesProvider>
                                        </EmailVerificationProvider>
                                        <AnalyticsProcessingModal />
                                      </React.Suspense>
                                    </PracticeRecorderUploadContext.Provider>
                                  </VideoBlobContext.Provider>
                                </CurrentlyUploadingFilesContext.Provider>
                              </UploadedRecordingIdsContext.Provider>
                            </YoodliActivityDataProvider>
                          </UIStateProvider>
                        </PageTitleProvider>
                      </TeamProvider>
                    </ContentSpacesProvider>
                  </NotificationProvider>
                </CustomIntercomProvider>
              </ReactUseIntercomProvider>
            </UserOrgDataProvider>
          </QueryClientProvider>
        </YoodliErrorBoundary>
      </RootStylesWrapper>
    );
  }

  return renderContent();
};

/**
 * App() blocks the loading of MainApp() until we complete initialization
 *   actions, such as resolving the current user (if any), loading the user doc(s),
 *   or (eventually) getting Remote Config values. These actions are needed to display the
 *   webpage without stuttering/re-rendering.
 * This helps prevent bugs, but it takes ~0.2s extra time for un-logged-in
 *   users, so we may need to re-think if we want to eventually optimize
 *   first-page loading time.
 */
const App = (): JSX.Element => {
  // Has Firebase Auth resolved the current user (if any), or are we still
  //  waiting for it to finish its (async) auth process?
  const clientEnv = getClientEnvConfig();
  const [userIsResolved, setUserIsResolved] = React.useState(!!firebase.auth().currentUser);
  const [_userIsSignedIn, setUserIsSignedIn] = React.useState(false);
  const [userDocsAreFetched, setUserDocsAreFetched] = React.useState(
    userDocsService.liveUserDocsAreResolved(),
  );
  const [unleashTogglesResolved, setUnleashTogglesResolved] = React.useState(false);
  const [siteDocsAreFetched, setSiteDocsAreFetched] = React.useState(liveSiteDocsAreResolved());
  const [enableTracking, setEnableTracking] = React.useState<boolean>(
    isCookiePreferenceCategoryEnabled("analytics"),
  );
  const [amplitudeInitialized, setAmplitudeInitialized] = React.useState<boolean>(false);
  const [signInTokenChecked, setSignInTokenChecked] = React.useState<boolean>(false);

  const urlParams = new URLSearchParams(window.location.search);
  const signInAuthToken = urlParams.get(SIGN_IN_CUSTOM_TOKEN_QUERY_PARAM);

  // Hook to hide the ResizeObserver loop completed with undelivered notifications error, with room to add others if needed
  useHideUnimportantErrors();

  const initializeAmplitude = () => {
    // Amplitude (https://github.com/amplitude/Amplitude-TypeScript)
    let legacyAmptlitudeEnabled = false;

    if (urlParams?.get("legacy_amplitude")?.toLowerCase() === "true") {
      legacyAmptlitudeEnabled = true;
    }
    if (Cookies.get("trackingOptOut") !== "true") {
      void (async () => {
        amplitude.init(envConfig.amplitudeId, null, {
          deviceId: Cookies.get(AMPLITUDE_DEVICE_ID, { domain: envConfig.domain }),
          loggerProvider: new AmplitudeYoodliLogger(),
          appVersion: getAppVersion(),
          serverUrl: legacyAmptlitudeEnabled ? null : AMPLITUDE_REVERSE_PROXY,
        });
      })();
      setAmplitudeInitialized(true);
    }
  };

  React.useEffect(() => {
    if (!enableTracking) {
      return;
    }
    initializeAmplitude();
  }, [enableTracking]);

  React.useEffect(() => {
    const handlePermissionsUpdate = (eventData: CustomEvent) => {
      const data = eventData.detail;
      // This includes amplitude so set a do not track cookie
      if (data.rejected.includes("analytics")) {
        Cookies.set("trackingOptOut", "true", { domain: clientEnv.domain, expires: 365 });
        if (amplitudeInitialized) {
          amplitude.setOptOut(true);
        }

        if (enableTracking !== undefined && amplitudeInitialized) {
          console.log("Reloading due to tracking consent changing to disabled");
          window.location.reload();
        }
        setEnableTracking(false);
      }

      if (data.accepted.includes("analytics")) {
        Cookies.remove("trackingOptOut", { domain: clientEnv.domain });
        if (amplitudeInitialized) {
          amplitude.setOptOut(false);
        }
        // only reload if we have gone from disabled tracking to enabled tracking
        if (enableTracking === false) {
          console.log("Reloading due to tracking consent changing to enable");
          window.location.reload();
        }

        setEnableTracking(true);
      }
    };
    document.addEventListener("cookieyes_consent_update", handlePermissionsUpdate);
    return () => {
      document.removeEventListener("cookieyes_consent_update", handlePermissionsUpdate);
    };
  }, [amplitudeInitialized]);

  React.useEffect(() => {
    console.log("Signin token checker: signInTokenChecked %s", signInTokenChecked);

    // If we have already checked for the sign-in token, we should not
    // check it again.
    if (signInTokenChecked) {
      return;
    }

    // If we have no auth token, we don't need to sign out.
    if (!signInAuthToken) {
      console.log("No sign-in token. No need to sign out.");
      setSignInTokenChecked(true);
      return;
    }

    // Wait for Firebase to trigger an auth state change. If no user
    // is signed in, the user object will be undefined. If a user
    // is signed in, the user object will be defined.

    // Track the unsubscribe function; we only want to run this handler
    // once.
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("User %s is signed in. Signing out.", user.uid);
        firebase
          .auth()
          .signOut()
          .then(() => {
            console.log("Successfully signed out.");
          })
          .catch((error) => {
            console.error("Error signing out:", error);
          })
          .finally(() => {
            setSignInTokenChecked(true);
            unsubscribe();
          });
      } else {
        console.log("No user signed in. No need to sign out.");
        setSignInTokenChecked(true);
        unsubscribe();
      }
    });
  }, [signInTokenChecked]);

  React.useEffect(() => {
    console.log("User resolution: signInTokenChecked %s", signInTokenChecked);

    // If we have not yet checked for the sign-in token, we should not
    // proceed with user resolution.
    if (!signInTokenChecked) {
      return;
    }

    if (!userIsResolved) {
      const t0 = performance.now();
      console.log(`First load. User not resolved initially.`);
      firebase.auth().onAuthStateChanged(() => {
        setUserIsResolved(true);
        const t1 = performance.now();
        console.log(`User now resolved: ${firebase.auth().currentUser?.uid}`);
        console.log(`User resolution took ${t1 - t0} milliseconds.`);
        if (firebase.auth().currentUser?.uid) {
          setUserIsSignedIn(true);
          startUnleash({
            promiseToAwait: pLiveSiteDocsReady,
            userId: firebase.auth().currentUser.uid,
            userEmail: firebase.auth().currentUser.email,
          })
            .then(() => {
              if (
                Cookies.get(ON_PAID_PLAN_COOKIE, { domain: clientEnv.domain }) === "true" ||
                firebase
                  .auth()
                  .currentUser?.providerData?.filter((d) => d.providerId === KFA_PROVIDER)?.length >
                  0 ||
                getEnabledFlag("logrocket_tracing")
              ) {
                initializeLogRocket();
              }
              setUnleashTogglesResolved(true);
            })
            .catch(console.error);
        } else {
          setUnleashTogglesResolved(true);
        }
      });
    } else {
      // user is already resolved, so set signed in state to be true
      setUserIsSignedIn(true);

      console.log(`User resolved on first load: ${firebase.auth().currentUser}`);
      startUnleash({
        promiseToAwait: pLiveSiteDocsReady,
        userId: firebase.auth().currentUser.uid,
        userEmail: firebase.auth().currentUser.email,
      })
        .then(() => {
          if (
            Cookies.get(ON_PAID_PLAN_COOKIE, { domain: clientEnv.domain }) === "true" ||
            getEnabledFlag("logrocket_tracing")
          ) {
            initializeLogRocket();
          }
          setUnleashTogglesResolved(true);
        })
        .catch(console.error);
    }
    const resolutions: Promise<void>[] = [];
    if (!userDocsAreFetched) {
      console.log("Waiting for user docs to be fetched.");
      resolutions.push(userDocsService.awaitUserDocsResolved());
    }
    if (!siteDocsAreFetched) {
      console.log("Waiting for site docs to be fetched.");
      resolutions.push(pLiveSiteDocsReady);
    }
    Promise.all(resolutions)
      .then(() => {
        setUserDocsAreFetched(true);
        setSiteDocsAreFetched(true);
        console.log("Site and user docs now ready.");
      })
      .catch(console.error);
  }, [signInTokenChecked]);

  const router = React.useMemo(
    () => createBrowserRouter(createRoutesFromElements(<Route path="*" element={<MainApp />} />)),
    [MainApp],
  );

  if (!(userIsResolved && userDocsAreFetched && unleashTogglesResolved && siteDocsAreFetched)) {
    return <></>;
  } else {
    const siteTheme = isToastmasters(getStaticFullSiteConf()) ? v5ToastmastersTheme : v5theme;

    return (
      <>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={siteTheme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </StyledEngineProvider>
      </>
    );
  }
};

export default App;
