import { Stack } from "@mui/material";
import { SelectHub } from "../BulkInviteByCSVWizard/SelectHub";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import React from "react";
import { getDynamicColor } from "lib-frontend/utils/Colors";

type UpdateGroupModalBodyProps = {
  selectedGroupIds: string[];
  setSelectedGroupIds: (groupIds: string[]) => void;
};

export const UpdateGroupModalBody = ({
  selectedGroupIds,
  setSelectedGroupIds,
}: UpdateGroupModalBodyProps): JSX.Element => {
  const {
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);
  return (
    <Stack sx={{ gap: 2, mb: 2 }}>
      <SelectHub
        showSearchBar
        hideTitle
        hubSelectorSx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: "100%",
          border: `1px solid ${getDynamicColor("dark3")}`,
        }}
        hubListSx={{
          maxHeight: 300,
        }}
        allHubs={defaultOrg?.hubs}
        selectedHubIds={selectedGroupIds}
        setSelectedHubIds={(hubIds) => {
          setSelectedGroupIds(hubIds);
        }}
      />
    </Stack>
  );
};
