import { IconButton, Stack, Typography } from "@mui/material";
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
} from "@mui/icons-material";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import Slider from "react-slick";

const buttonStyles = {
  color: getDynamicColor("purple3"),
  padding: 0.5,
};

type ProgramCarouselControlsProps = {
  totalSteps: number;
  currentStepIndex: number;
  sliderRef: React.RefObject<Slider>;
};

export const ProgramCarouselControls = ({
  totalSteps,
  currentStepIndex,
  sliderRef,
}: ProgramCarouselControlsProps): JSX.Element => {
  return (
    <Stack
      direction="row"
      gap={1}
      sx={{
        position: "absolute",
        top: "16px",
        right: "16px",
        backgroundColor: getDynamicColor("light1"),
        borderRadius: "100px",
        boxShadow: Y_SHADOWS.box_shadow_1,
        alignItems: "center",
      }}
    >
      <IconButton sx={buttonStyles} onClick={() => sliderRef.current?.slickPrev()}>
        <ChevronLeftIcon />
      </IconButton>
      <Typography sx={{ fontSize: 12, fontWeight: 700, color: getDynamicColor("purple3") }}>
        {currentStepIndex + 1} / {totalSteps}
      </Typography>
      <IconButton sx={buttonStyles} onClick={() => sliderRef.current?.slickNext()}>
        <ChevronRightIcon />
      </IconButton>
    </Stack>
  );
};
