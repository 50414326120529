import { PersonaMemberViewResponse } from "lib-fullstack/api/scenarioApiTypes";
import { Box, Divider, IconButton, Stack } from "@mui/material";
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Check as CheckIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import React from "react";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

type PersonaListProps = {
  allPersonas: PersonaMemberViewResponse[];
  selectedPersonaIds: string[];
  handlePersonaSelectionChange: (personaId: string, action: "add" | "remove") => void;
  renderPersonaValue: (persona?: PersonaMemberViewResponse) => JSX.Element;
  showSearchBar?: boolean;
  handleEditPersona: (persona: PersonaMemberViewResponse) => void;
  maxSelectable?: number;
};

export const PersonaList = ({
  allPersonas,
  selectedPersonaIds,
  handlePersonaSelectionChange,
  showSearchBar,
  renderPersonaValue,
  handleEditPersona,
  maxSelectable,
}: PersonaListProps): JSX.Element => {
  const [hoveringIconId, setHoveringIconId] = React.useState<string | null>(null);
  const [blockIconHover, setBlockIconHover] = React.useState<Record<string, boolean>>({});
  const [searchText, setSearchText] = React.useState<string>("");

  // Check if maximum number of selections has been reached
  const maxSelectionsReached = React.useMemo(() => {
    return maxSelectable !== undefined && selectedPersonaIds.length >= maxSelectable;
  }, [maxSelectable, selectedPersonaIds]);

  const getIconBackgroundColor = (personaId: string, isSelected: boolean) => {
    if (hoveringIconId === personaId && isSelected && !blockIconHover[personaId]) {
      return getDynamicColor("dark4");
    }
    if (isSelected) {
      return getDynamicColor("greenSuccess");
    }
    if (!isSelected && maxSelectionsReached) {
      return getDynamicColor("dark3"); // Disabled state
    }
    return getDynamicColor("primary");
  };

  const renderAddRemoveIcons = (personaId: string, isSelected: boolean) => {
    if (hoveringIconId === personaId && isSelected && !blockIconHover[personaId]) {
      return <RemoveIcon />;
    }
    if (isSelected) {
      return <CheckIcon />;
    }
    return <AddIcon />;
  };

  const searchFilteredPersonas = React.useMemo(() => {
    return allPersonas.filter((persona) => {
      return persona.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [allPersonas, searchText]);

  // Handle clicking the persona or its add/remove button
  const handlePersonaClick = (personaId: string, isSelected: boolean, isDisabled: boolean) => {
    if (isDisabled) return;
    return isSelected
      ? handlePersonaSelectionChange(personaId, "remove")
      : handlePersonaSelectionChange(personaId, "add");
  };

  return (
    <Stack
      sx={{
        borderRadius: "12px",
        pt: 1,
        gap: 2,
        width: "800px",
        maxWidth: "100%",
      }}
    >
      {showSearchBar && (
        <YoodliSearchBar
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          clearSearch={() => setSearchText("")}
          placeholder="Search and select from your library"
          InputProps={{
            sx: {
              height: "40px",
            },
          }}
          sx={{
            width: "100%",
            maxWidth: "unset",
            px: 1,
          }}
        />
      )}
      <Stack
        sx={{
          gap: 1.5,
          overflow: "auto",
          maxHeight: "500px",
          transform: "max-height 0.3s ease-out",
          pb: 2,
        }}
      >
        {searchFilteredPersonas.map((persona, index) => {
          const isSelected = selectedPersonaIds?.includes(persona.id);
          const isDisabled = !isSelected && maxSelectionsReached;

          const personaItem = (
            <React.Fragment key={persona.id}>
              <YoodliTooltip
                title={isDisabled ? `Maximum of ${maxSelectable} personas can be selected` : ""}
              >
                <Stack direction="column" sx={{ width: "100%", px: 2 }}>
                  <Stack
                    direction="row"
                    sx={{
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: isDisabled ? "default" : "pointer",
                      px: 2,
                      opacity: isDisabled ? 0.7 : 1,
                    }}
                    onClick={() => handlePersonaClick(persona.id, isSelected, isDisabled)}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        width: "100%",
                        gap: 2,
                        alignItems: "center",
                        overflow: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          height: 28,
                          width: 28,
                          cursor: isDisabled ? "default" : "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onMouseEnter={() => {
                          if (!isDisabled) setHoveringIconId(persona.id);
                        }}
                        onMouseLeave={() => {
                          setHoveringIconId(null);
                          setBlockIconHover({ ...blockIconHover, [persona.id]: false });
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePersonaClick(persona.id, isSelected, isDisabled);

                          if (!isDisabled) {
                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                            isSelected
                              ? setBlockIconHover({ ...blockIconHover, [persona.id]: false })
                              : setBlockIconHover({ ...blockIconHover, [persona.id]: true });
                          }
                        }}
                      >
                        <Box
                          sx={{
                            height: 20,
                            width: 20,
                            borderRadius: "50%",
                            position: "relative",
                            backgroundColor: getIconBackgroundColor(persona.id, isSelected),
                            color: getDynamicColor("light1"),
                            transition: "background-color 0.2s ease-out",
                            svg: {
                              position: "absolute",
                              display: "block",
                              top: 1,
                              left: 1,
                              height: 18,
                              width: 18,
                              strokeWidth: 0.5,
                              stroke: getDynamicColor("light1"),
                            },
                          }}
                        >
                          {renderAddRemoveIcons(persona.id, isSelected)}
                        </Box>
                      </Box>
                      {renderPersonaValue(persona)}
                    </Stack>
                    <IconButton
                      sx={{
                        color: getDynamicColor("primary"),
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleEditPersona(persona);
                      }}
                    >
                      <EditIcon sx={{ width: 24, height: 24 }} />
                    </IconButton>
                  </Stack>
                </Stack>
              </YoodliTooltip>
              {index < searchFilteredPersonas.length - 1 && (
                <Divider sx={{ color: getDynamicColor("dark3"), mx: 4 }} />
              )}
            </React.Fragment>
          );

          return personaItem;
        })}
      </Stack>
      {maxSelectable && (
        <Box sx={{ px: 2, pb: 1, color: getDynamicColor("dark4") }}>
          {selectedPersonaIds.length} of {maxSelectable} personas selected
        </Box>
      )}
    </Stack>
  );
};
