// Components
import { styled } from "@mui/material/styles";

const StyledSkipLink = styled("a")(({ theme }) => ({
  position: "absolute",
  top: "-100px",
  left: theme.spacing(2), // 16px from left edge
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1, 2),
  zIndex: 10001,
  textDecoration: "none",
  color: theme.palette.text.primary,
  boxShadow: theme.shadows[2],
  borderRadius: theme.shape.borderRadius,
  transition: "top 0.2s ease-in-out",
  "&:focus": {
    top: theme.spacing(1), // 8px from top when focused
    outline: `2px solid ${theme.palette.primary.main}`,
  },
}));

const SkipToMainContent = (): JSX.Element => {
  return <StyledSkipLink href="#main-content">Skip to main content</StyledSkipLink>;
};

export default SkipToMainContent;
