import React from "react";

// Components
import { Box, CircularProgress, Stack, SxProps, Typography } from "@mui/material";

// Utils
import { LogDashboardAmplitudeEventType, NoDataType } from "../Dashboard";
import { FOCUS_ANALYTICS, HoistedAnalytic } from "../DashboardTypes";
import AnalyticChart from "./AnalyticChart";
import AnalyticDetail from "./AnalyticDetail/AnalyticDetail";
import FocusArea from "./AnalyticDetail/FocusArea";
import NoData, { NoDataCopy } from "./NoData";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";

const KEEP_USING_YOODLI_COPY = "Practice to see your speaking trends over time";

type ReportSnapshotProps = {
  activeAnalytic: HoistedAnalytic;
  numDays: number;
  loadingData: boolean;
  noData: NoDataType;
  customNoDataCopy?: NoDataCopy;
  noHoistedAnalytics: boolean;
  handleSetShowAnalyticsSelector?: (showAnalyticsSelector: boolean) => void;
  logDashboardAmplitudeEvent?: LogDashboardAmplitudeEventType;
  wrapperSx?: SxProps;
  hideTitleRow?: boolean;
};
const ReportSnapshot = ({
  activeAnalytic,
  numDays,
  loadingData,
  noData,
  customNoDataCopy,
  noHoistedAnalytics,
  handleSetShowAnalyticsSelector,
  logDashboardAmplitudeEvent,
  wrapperSx,
  hideTitleRow,
}: ReportSnapshotProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const [hoveringThresholdLegend, setHoveringThresholdLegend] = React.useState(false);
  const showNoData =
    (activeAnalytic &&
      (noData?.curr ||
        (activeAnalytic?.data && !activeAnalytic?.data?.length) ||
        noHoistedAnalytics)) ||
    !activeAnalytic;

  const renderKeepYoodlingMessage = () => {
    return (
      <Typography
        sx={{
          fontSize: { xs: 12, lg: 14 },
          textAlign: "center",
          lineHeight: "17px",
          fontWeight: 500,
          color: getDynamicColor("greenSuccess"),
          backgroundColor: getDynamicColor("greenSuccessLight"),
          px: 2,
          py: 1,
          width: "fit-content",
          borderRadius: "100px",
          mr: { xs: "auto", md: 2.55 },
          ml: "auto",
          mt: { xs: 2, sm: 1, md: 0 },
          mb: { xs: 0, md: 2 },
        }}
      >
        {KEEP_USING_YOODLI_COPY}
      </Typography>
    );
  };

  const isGoal = !FOCUS_ANALYTICS[activeAnalytic?.identifier];

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        borderRadius: "8px",
        boxShadow: Y_SHADOWS.box_shadow_3,
        backgroundColor: getDynamicColor(!loadingData && showNoData ? "dark1" : "light1"),
        ...wrapperSx,
      }}
    >
      <ChartLoader loadingData={loadingData} />
      <Stack
        direction={{ xs: "column-reverse", md: "row" }}
        sx={{
          px: { xs: 3, md: 5 },
          py: 3,

          ...(loadingData
            ? {
                WebkitFilter: "blur(2px)",
                filter: "blur(2px)",
              }
            : {}),
        }}
      >
        {showNoData ? (
          <NoData
            noHoistedAnalytics={noHoistedAnalytics}
            activeAnalytic={activeAnalytic}
            customNoDataCopy={customNoDataCopy}
            handleSetShowAnalyticsSelector={handleSetShowAnalyticsSelector}
            logDashboardAmplitudeEvent={logDashboardAmplitudeEvent}
          />
        ) : activeAnalytic?.data ? (
          <>
            {activeAnalytic && (
              <Box
                sx={{
                  display: "inline-block",
                  verticalAlign: "top",
                  width: { xs: "100%", md: "max(30%, 240px)" },
                }}
              >
                {isSmallScreen && noData.prev && renderKeepYoodlingMessage()}
                <AnalyticDetail
                  activeAnalytic={activeAnalytic}
                  numDays={numDays}
                  logDashboardAmplitudeEvent={logDashboardAmplitudeEvent}
                  hideTitleRow={hideTitleRow}
                />
              </Box>
            )}
            <Stack
              gap={1}
              sx={{
                display: "inline-flex",
                verticalAlign: "top",
                width: { xs: "calc(100% + 24px)", md: "min(70%, calc(100% - 200px))" },
                position: "relative",
                left: { xs: -24, md: 0 },
                height: { xs: 320, md: 300 },
                fontSize: 12,
                ".recharts-legend-item": { fontSize: 12 },
              }}
            >
              {(activeAnalytic?.threshold || activeAnalytic?.threshold === 0) && (
                <Box
                  sx={{
                    backgroundColor: getDynamicColor("light1"),
                    p: 2.5,
                    border: `1px solid ${getDynamicColor("chartGreenLight")}`,
                    borderRadius: 3, // 3 is '12px' because borderRadius is half the base 8px
                    boxShadow: Y_SHADOWS.box_shadow_1,
                    position: "absolute",
                    zIndex: 2,
                    top: { xs: "calc(100% - 30px)", md: 0 },
                    left: { xs: "calc(50% - 10px)", md: "calc(100% - 150px)" },
                    opacity: hoveringThresholdLegend ? 1 : 0,
                    transition: "opacity 0.2s ease-in-out",
                    pointerEvents: hoveringThresholdLegend ? "auto" : "none",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: 10,
                      textTransform: "uppercase",
                      lineHeight: 2.6,
                      mb: 1,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Target Average
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {typeof activeAnalytic.threshold !== "object"
                      ? activeAnalytic.threshold
                      : `${activeAnalytic.threshold[0]} – ${activeAnalytic.threshold[1]}`}{" "}
                    {activeAnalytic.label}
                  </Typography>
                </Box>
              )}
              <AnalyticChart
                activeAnalytic={activeAnalytic}
                setHoveringThresholdLegend={setHoveringThresholdLegend}
              />
            </Stack>

            {isSmallScreen && !hideTitleRow && (
              <Stack
                direction="row"
                gap={1.5}
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  mb: 2,
                  position: "relative",
                }}
              >
                <Typography
                  component="h2"
                  variant="poppins"
                  sx={{
                    fontWeight: 600,
                    fontSize: 20,
                  }}
                >
                  {activeAnalytic?.label}
                </Typography>
                <FocusArea
                  focusArea={activeAnalytic?.focusArea}
                  isGoal={isGoal}
                  logDashboardAmplitudeEvent={logDashboardAmplitudeEvent}
                />
              </Stack>
            )}
          </>
        ) : (
          <Box sx={{ minHeight: 300 }} />
        )}
      </Stack>
      {!isSmallScreen && noData.prev && !showNoData && renderKeepYoodlingMessage()}
    </Box>
  );
};

const ChartLoader = ({ loadingData }: { loadingData?: boolean }) => {
  return (
    <Box
      sx={{
        pointerEvents: loadingData ? "auto" : "none",
        opacity: loadingData ? 1 : 0,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.01)",
        transition: "opacity 0.5s ease-in-out",
        zIndex: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        color="primary"
        size={80}
        thickness={4}
        sx={{
          svg: {
            filter: `drop-shadow(${Y_SHADOWS.box_shadow_4})`,
          },
        }}
        aria-label="Progress bar for loading chart data"
      />
    </Box>
  );
};

export default ReportSnapshot;
