import React from "react";
import firebase from "firebase/app";
import { useIntercom } from "react-use-intercom";
import { getIntercomHmacHash } from "lib-frontend/modules/AxiosInstance";
import { useLocation } from "react-router-dom";
import { IntercomContextType } from "contexts/IntercomContext";
import { isToastmasters } from "lib-frontend/utils/subdomain";

/**
 * Intercom launcher is the little floating widget in the corner of the screen
 * Intercom messenger is the actual popup window used for messenging
 */

/**
 * Cache to store HMAC hashes by user ID to prevent repeated API calls
 * This is kept outside the component to persist across renders
 */
const hmacCache: Record<string, string> = {};

interface IntercomLauncherProps {
  /**
   * Whether or not to initialize intercom
   */
  shouldInitialize: boolean;
  /**
   * Array of paths where Intercom is allowed to be displayed
   */
  allowedPaths: string[];
  bottom?: number;
  right?: number;
}
export const useIntercomLauncher = ({
  shouldInitialize,
  allowedPaths,
  bottom = 40,
  right = 40,
}: IntercomLauncherProps): IntercomContextType => {
  const { boot, shutdown, hide, show, isOpen, update } = useIntercom();
  const [isInitialized, setIsInitialized] = React.useState(false);
  const location = useLocation();

  // Track previous shouldInitialize value to detect changes
  const prevShouldInitializeRef = React.useRef(shouldInitialize);

  // Track the current user ID to detect changes
  const [currentUserId, setCurrentUserId] = React.useState<string | undefined>(
    firebase.auth().currentUser?.uid,
  );

  // hides the intercom messenger and launcher widget
  const hideIntercomLauncher = React.useCallback(() => {
    // ensure messenger is hidden when closing the launcher
    hide();
    update({ hideDefaultLauncher: true });
  }, [hide, update]);

  const showIntercomLauncher = React.useCallback(() => {
    update({ hideDefaultLauncher: false });
  }, [update]);

  // Track if we're currently fetching to prevent duplicate requests
  const isInitializingRef = React.useRef(false);

  // Store location for path change comparison
  const prevPathRef = React.useRef(location.pathname);

  // Check if current path is in the allowed paths list
  const isOnAllowedPath = allowedPaths.includes(location.pathname);

  // Handle changes to shouldInitialize prop
  React.useEffect(() => {
    // If shouldInitialize changed from true to false
    if (prevShouldInitializeRef.current && !shouldInitialize && isInitialized) {
      hideIntercomLauncher();
      shutdown();
      setIsInitialized(false);
    }

    // Update the ref
    prevShouldInitializeRef.current = shouldInitialize;
  }, [shouldInitialize, hideIntercomLauncher, shutdown, isInitialized]);

  // Initialize Intercom with user data and HMAC hash
  React.useEffect(() => {
    const userId = firebase.auth().currentUser?.uid;

    // Skip if no user is logged in
    if (!userId) {
      return;
    }

    // Skip if we're already fetching
    if (isInitializingRef.current) {
      return;
    }

    // Skip if already initialized with the same user and shouldInitialize hasn't changed
    if (
      isInitialized &&
      userId === currentUserId &&
      shouldInitialize === prevShouldInitializeRef.current
    ) {
      return;
    }

    // If shouldInitialize is false, make sure Intercom is shut down
    if (!shouldInitialize && isInitialized) {
      hideIntercomLauncher();
      shutdown();
      setIsInitialized(false);
      return;
    }

    const initializeIntercom = async () => {
      console.log("Initializing Intercom...");
      // Use the ref to track fetching status
      isInitializingRef.current = true;
      try {
        // Check if we already have a cached HMAC hash for this user
        let hmacHash = hmacCache[userId];

        // If not cached, fetch it from the backend
        if (!hmacHash) {
          hmacHash = await getIntercomHmacHash(userId);

          // Cache the result if valid
          if (hmacHash) {
            hmacCache[userId] = hmacHash;
          }
        }

        // Boot Intercom with user data and HMAC hash
        boot({
          userId: userId,
          email: firebase.auth().currentUser?.email,
          userHash: hmacHash,
          // styling options
          verticalPadding: bottom,
          horizontalPadding: right,
          // Hide the messenger and launcher on boot
          hideDefaultLauncher: !isOnAllowedPath,
          alignment: "right",
        });

        // Update state to reflect initialization
        setIsInitialized(true);
        setCurrentUserId(userId);
        console.log("Intercom initialized successfully");

        // Make sure it's hidden if we're not on an allowed path
        if (!isOnAllowedPath) {
          hideIntercomLauncher();
        }
      } catch (error) {
        console.error("Error initializing Intercom:", error);
      } finally {
        // Update the ref to indicate initialization is complete
        isInitializingRef.current = false;
      }
    };

    // only initialize intercom when needed
    if (shouldInitialize && isOnAllowedPath && !isToastmasters()) {
      // Call the async function and handle the promise properly
      void initializeIntercom();
    } else {
      // If shouldInitialize is false, make sure we're not in initializing state
      isInitializingRef.current = false;
    }

    // Cleanup on unmount or when dependencies change
    return () => {
      if (isInitialized) {
        hideIntercomLauncher();
        shutdown();
        setIsInitialized(false);
      }
    };
  }, [
    currentUserId,
    shouldInitialize,
    boot,
    isOnAllowedPath,
    hide,
    shutdown,
    hideIntercomLauncher,
    bottom,
    right,
    isInitialized,
  ]);

  // REVISED PATH CHANGE DETECTION: Run a separate effect that only depends on location
  // This guarantees it will run whenever the location changes
  React.useEffect(() => {
    if (!isInitialized || !shouldInitialize) return;

    // Check if we've navigated from an allowed path to a disallowed path
    const wasOnAllowedPath = allowedPaths.includes(prevPathRef.current);
    const pathChanged = prevPathRef.current !== location.pathname;

    if (pathChanged) {
      if (wasOnAllowedPath && !isOnAllowedPath) {
        hideIntercomLauncher();
      } else if (!wasOnAllowedPath && isOnAllowedPath) {
        update({ hideDefaultLauncher: false });
      }

      // Update prev path reference at the end of the effect
      prevPathRef.current = location.pathname;
    }
  }, [
    location.pathname,
    isInitialized,
    isOnAllowedPath,
    allowedPaths,
    update,
    shouldInitialize,
    hideIntercomLauncher,
  ]);

  // Always keep Intercom hidden when not on an allowed path
  React.useEffect(() => {
    if (!isInitialized || !shouldInitialize) return;

    if (!isOnAllowedPath) {
      hideIntercomLauncher();
    }
  }, [isOnAllowedPath, isInitialized, hideIntercomLauncher, shouldInitialize]);

  return {
    isIntercomOpen: isOpen,
    showIntercomMessenger: show,
    hideIntercomMessenger: hide,
    hideIntercomLauncher,
    showIntercomLauncher,
  };
};
