import React from "react";

// Components
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { Button, Link, FormControlLabel, Stack, Typography } from "@mui/material";
import { AddContentModal } from "components/Orgs/ManageContent/ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotUploadContent/AddContentModal";
import { UploadedFiles } from "components/Orgs/ManageContent/ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotUploadContent/UploadedFiles";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";
import { YoodliSwitch } from "lib-frontend/components/YoodliSwitch";

// Utils
import { useContentUpload } from "hooks/useContentUpload";
import { useOrgFilesQuery } from "hooks/useOrgFilesQuery";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { SupportPages } from "lib-frontend/utils/paths";

const MAX_FILES = 5;

type EditPrereadFilesProps = {
  prereadFiles: string[];
  updatePrereadFiles: (prereadFileIds: string[]) => void;
  showStrictSwitch: boolean;
  strictPreread: boolean;
  setStrictPreread: (strictPreread: boolean) => void;
};
export const EditPrereadFiles = ({
  prereadFiles,
  updatePrereadFiles,
  showStrictSwitch,
  strictPreread,
  setStrictPreread,
}: EditPrereadFilesProps): JSX.Element => {
  const [showAddContentModal, setShowAddContentModal] = React.useState<boolean>(false);

  const handleAddNewContent = (filesIds: string[]) => {
    const updatedPrereadFiles = [...prereadFiles, ...filesIds];
    updatePrereadFiles(updatedPrereadFiles);
  };

  const { uploadingFiles, handleUploadContent, handleCancelFileUpload, handleClearUploadedFiles } =
    useContentUpload({ handleAddNewContent: handleAddNewContent });
  const { orgFilesQuery } = useOrgFilesQuery();

  const content = React.useMemo(() => {
    if (!orgFilesQuery?.data?.files) return [];

    return (prereadFiles ?? []).map((fileId) => ({
      id: fileId,
      name: orgFilesQuery?.data?.files?.find((file) => file.id === fileId)?.name ?? "",
    }));
  }, [orgFilesQuery?.data?.files, prereadFiles]);

  const cancelFileUpload = async (fileId: string) => {
    const updatedPrereadFiles = prereadFiles.filter((id) => id !== fileId);
    updatePrereadFiles(updatedPrereadFiles);
    handleCancelFileUpload(fileId);
  };

  const handleClearAllFiles = () => {
    updatePrereadFiles([]);
    handleClearUploadedFiles();
  };

  return (
    <>
      <Stack gap={1}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={2}
          sx={{
            alignItems: { xs: "flex-start", md: "center" },
            justifyContent: "space-between",
          }}
        >
          <Stack gap={1}>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: 12,
                fontWeight: 600,
                color: getDynamicColor("purple3"),
              }}
            >
              (Optional) Add up to {MAX_FILES} files to give the AI more context.
            </Typography>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: 10,
                fontWeight: 600,
                color: getDynamicColor("purple3"),
              }}
            >
              These documents will only inform the conversation, not the scoring / feedback. Learn
              more about files{" "}
              <Link href={SupportPages.AI_PREREAD} target="_blank">
                here
              </Link>
            </Typography>
          </Stack>
          {showStrictSwitch && (
            <FormControlLabel
              label={
                <Stack direction="row" gap={1} sx={{ width: "100%", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: getDynamicColor("purple3"),
                      fontWeight: 600,
                      fontFamily: "poppins",
                    }}
                  >
                    Strict Mode
                  </Typography>
                  <YoodliTooltip
                    placement="bottom-start"
                    title="Turn this on to ensure the AI strictly adheres to provided context and content from attached files"
                  >
                    <InfoIcon
                      sx={{
                        height: 20,
                        width: 20,
                        color: getDynamicColor("purple3"),
                      }}
                    />
                  </YoodliTooltip>
                </Stack>
              }
              labelPlacement="start"
              sx={{
                justifyContent: "start",
                gap: { xs: 1, md: 1 },
                mx: 0,
              }}
              control={
                <YoodliSwitch
                  checked={strictPreread}
                  onClick={(e) => setStrictPreread(e.target.checked)}
                  color={getDynamicColor("primary")}
                />
              }
            />
          )}
        </Stack>
        <Stack
          direction="row"
          gap={1}
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Button
            onClick={() => setShowAddContentModal(true)}
            disableRipple
            sx={{ fontSize: 14, px: 0 }}
          >
            Add files
          </Button>
          {prereadFiles.length > 0 && (
            <Button
              onClick={handleClearAllFiles}
              sx={{
                fontSize: 12,
                color: getDynamicColor("purple3"),
              }}
            >
              Clear all
            </Button>
          )}
        </Stack>
        <UploadedFiles files={content} handleCancelFileUpload={cancelFileUpload} />
      </Stack>
      <AddContentModal
        uploadingFiles={uploadingFiles}
        handleUploadContent={handleUploadContent}
        amtOfFilesLeft={MAX_FILES - prereadFiles.length}
        handleCancelFileUpload={cancelFileUpload}
        showAddContentModal={showAddContentModal}
        setShowAddContentModal={setShowAddContentModal}
        content={content}
        handleAddNewContent={handleAddNewContent}
      />
    </>
  );
};
