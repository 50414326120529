import { DateTime } from "luxon"; // This is the only Luxon import we need
import {
  Button,
  CalendarCell,
  CalendarGrid,
  DateRangePicker,
  Dialog,
  Group,
  Heading,
  Popover,
  RangeCalendar,
} from "react-aria-components";
import type { DateRangePickerProps, DateValue, ValidationResult } from "react-aria-components";
import { FieldError, Text } from "react-aria-components";

// Components
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  ExpandCircleDownRounded as ExpandCircleDownRoundedIcon,
} from "@mui/icons-material";
import { Box, Stack, Typography, Button as MuiButton } from "@mui/material";

// Utils
import { getDynamicColor, Y_SHADOWS } from "../../utils/Colors";
import { useIsSmallScreen } from "../../utils/themeUtils";
import {
  DEFAULT_DATE_PICKER_WRAPPER_STYLES,
  DEFAULT_CALENDAR_STYLES,
} from "./YoodliDatePickerStyles";
import { parseDate } from "@internationalized/date";

const getDateRange = (numDays: number) => {
  const today = DateTime.now();
  const endDate = today.toFormat("yyyy-MM-dd");
  const startDate = today.minus({ days: numDays }).toFormat("yyyy-MM-dd");
  return { startDate, endDate };
};

interface MyDateRangePickerProps<T extends DateValue> extends DateRangePickerProps<T> {
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  dateRangeBank?: number[];
  onDateRangeSelected?: (startDate: string, endDate: string) => void;
}

function MyDateRangePicker<T extends DateValue>({
  description,
  errorMessage,
  dateRangeBank,
  onDateRangeSelected,
  ...props
}: MyDateRangePickerProps<T>) {
  const formatDate = (date: DateValue) => {
    const luxonDate = DateTime.fromISO(date.toString());
    return luxonDate.toFormat("dd MMM yy");
  };

  const isSmallScreen = useIsSmallScreen();

  return (
    <DateRangePicker {...props} aria-label="Date Range Picker">
      <Box
        sx={{
          color: getDynamicColor("purple3"),
          "& .control-button": {
            minWidth: "200px",
            border: `1px solid ${getDynamicColor("dark4")}`,
            borderRadius: "4px",
            px: 2,
            py: 1.5,
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            gap: "8px",
            cursor: "pointer",
            background: "white",
          },
        }}
      >
        <Group>
          <Button className="control-button" slot="trigger">
            <Stack
              direction="row"
              gap={1}
              sx={{
                display: "flex",
                width: "100%",
                alignContent: "center",
                justifyContent: "space-between",
              }}
            >
              <Stack direction="row" gap={1}>
                {props.value?.start && (
                  <Typography noWrap sx={{ alignContent: "center" }}>
                    {formatDate(props.value.start)}
                  </Typography>
                )}
                <Typography noWrap sx={{ alignContent: "center" }} aria-hidden="true">
                  –
                </Typography>
                {props.value?.end && (
                  <Typography noWrap sx={{ alignContent: "center" }}>
                    {formatDate(props.value.end)}
                  </Typography>
                )}
              </Stack>
              <ExpandCircleDownRoundedIcon
                sx={{
                  color: getDynamicColor("primary"),
                }}
              />
            </Stack>
          </Button>
        </Group>
      </Box>
      {description && <Text slot="description">{description}</Text>}
      <FieldError>{errorMessage}</FieldError>
      <Popover
        offset={8}
        placement="bottom end"
        style={{
          backgroundColor: getDynamicColor("light1"),
          borderRadius: "8px",
          boxShadow: Y_SHADOWS.date_picker_popover,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          padding: "16px",
          gap: "16px",
          height: "auto",
          minHeight: 260,
        }}
      >
        {dateRangeBank?.length > 0 && (
          <Stack
            direction="column"
            gap={1}
            alignItems="flex-start"
            justifyContent="space-between"
            sx={{
              height: "100%",
            }}
          >
            <Stack direction="column" gap={1} alignItems="flex-start" justifyContent="flex-start">
              {dateRangeBank?.map((date) => {
                const startDate = DateTime.fromObject({
                  year: props.value?.start?.year,
                  month: props.value?.start?.month,
                  day: props.value?.start?.day,
                });
                const endDate = DateTime.fromObject({
                  year: props.value?.end?.year,
                  month: props.value?.end?.month,
                  day: props.value?.end?.day,
                });

                const isSelected =
                  props.value?.start &&
                  props.value?.end &&
                  startDate.plus({ days: date }).equals(endDate);

                return (
                  <MuiButton
                    key={date}
                    onClick={() => {
                      const { startDate: newStartDate, endDate: newEndDate } = getDateRange(date);

                      const currentStart = props.value?.start ? props.value.start.toString() : "";
                      const currentEnd = props.value?.end ? props.value.end.toString() : "";

                      if (currentStart === newStartDate && currentEnd === newEndDate) {
                        return;
                      }

                      onDateRangeSelected?.(newStartDate, newEndDate);
                      props.onChange?.({
                        start: newStartDate,
                        end: newEndDate,
                      });
                    }}
                    sx={{
                      fontSize: 12,
                      fontWeight: 400,
                      backgroundColor: getDynamicColor("dark2"),
                      color: getDynamicColor("purple3"),
                      ...(isSelected && {
                        backgroundColor: getDynamicColor("secondaryLight"),
                        color: getDynamicColor("secondaryHover"),
                      }),
                      "&:hover": {
                        backgroundColor: getDynamicColor("dark3"),
                        ...(isSelected && {
                          backgroundColor: getDynamicColor("secondaryLight"),
                          color: getDynamicColor("secondaryHover"),
                        }),
                      },
                    }}
                  >
                    {isSmallScreen ? "" : "Last "}
                    {date} days
                  </MuiButton>
                );
              })}
            </Stack>
            <MuiButton
              variant="text"
              sx={{
                fontSize: 12,
              }}
              onClick={() => {
                const { startDate, endDate } = getDateRange(7);
                onDateRangeSelected?.(startDate, endDate);
                props.onChange?.({
                  start: startDate,
                  end: endDate,
                });
              }}
            >
              Reset
            </MuiButton>
          </Stack>
        )}
        <Dialog>
          <Box sx={DEFAULT_CALENDAR_STYLES}>
            <RangeCalendar
              className="range-calendar"
              maxValue={parseDate(DateTime.now().toFormat("yyyy-MM-dd"))}
              defaultFocusedValue={props.value?.end}
            >
              <header className="month-header">
                <Button slot="previous" className="month-button">
                  <ChevronLeftIcon />
                </Button>
                <Heading />
                <Button slot="next" className="month-button">
                  <ChevronRightIcon />
                </Button>
              </header>
              <CalendarGrid>
                {(date) => {
                  // TODO: currently come bugs with this but it looks fine as is, just needs some zhuzhing
                  // let isStart = false;
                  // let isEnd = false;
                  let isToday = false;
                  // Check if this is a selected date
                  // if (props.value?.start && props.value?.end) {
                  //   isStart = date.compare(props.value.start) === 0;
                  //   isEnd = date.compare(props.value.end) === 0;
                  // }
                  if (
                    date.day === DateTime.now().day &&
                    date.month === DateTime.now().month &&
                    date.year === DateTime.now().year
                  ) {
                    isToday = true;
                  }
                  return (
                    <CalendarCell
                      date={date}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        ...(isToday && {
                          color: getDynamicColor("secondary"),
                        }),
                      }}
                    />
                  );
                }}
              </CalendarGrid>
            </RangeCalendar>
          </Box>
        </Dialog>
      </Popover>
    </DateRangePicker>
  );
}

type YoodliDatePickerProps = {
  startDate: string;
  endDate: string;
  onChange: (startDate: string, endDate: string) => void;
  dateRangeBank?: number[];
  onDateRangeSelected?: (startDate: string, endDate: string) => void;
};

export const YoodliDatePicker = ({
  startDate,
  endDate,
  onChange,
  dateRangeBank,
  onDateRangeSelected,
}: YoodliDatePickerProps): JSX.Element => {
  return (
    <Box sx={DEFAULT_DATE_PICKER_WRAPPER_STYLES}>
      <MyDateRangePicker
        value={{
          start: parseDate(startDate),
          end: parseDate(endDate),
        }}
        onChange={(value) => {
          onChange(value.start.toString(), value.end.toString());
        }}
        dateRangeBank={dateRangeBank}
        onDateRangeSelected={(startDate, endDate) => {
          onDateRangeSelected?.(startDate, endDate);
        }}
      />
    </Box>
  );
};
