import { DateTime, Info } from "luxon";

// #region Date formatting
/**
 * Returns the displayable time string (minutes and seconds) given the raw seconds value
 * @param {*} seconds
 * @returns displayable time string
 */
export const getDisplayableTime = (seconds: number): string => {
  const displayTime = Math.round(seconds);

  if (displayTime >= 0) {
    return `${getMinutes(displayTime)}:${getSeconds(displayTime)}`;
  } else {
    return `- ${getMinutes(displayTime)}:${getSeconds(displayTime)}`;
  }
};

/**
 * Returns true if the given string is a valid displayable time string (MM:SS)
 * @param value - string to check
 * @returns true if the string is a valid displayable time string
 */
export const isValidDisplayableTimeString = (value: string): boolean => {
  // First check if string contains only numbers and colons
  if (/[^\d:]/.test(value)) return false;

  // Check MM:SS format
  const timePattern = /^(\d{1,2}):(\d{1,2})$/;
  const match = value.match(timePattern);

  if (!match) return false;

  const minutes = parseInt(match[1], 10);
  const seconds = parseInt(match[2], 10);

  return minutes >= 0 && minutes <= 60 && seconds >= 0 && seconds <= 59;
};

/**
 * Returns string format time XXXm XXs
 * @param seconds - seconds to convert to
 * @returns XXXm XXs formatted string
 * @example getDisplayableTime(3965)
 * > "1m 6s"
 */
export const getDisplayableTimeAlt = (seconds: number, trimLeadingZero = false): string => {
  const displayTime = Math.round(seconds);

  if (displayTime >= 0) {
    return `${
      getMinutes(displayTime, trimLeadingZero)
        ? `${getMinutes(displayTime, trimLeadingZero)}m `
        : ""
    }${getSeconds(displayTime, trimLeadingZero)}s`;
  } else {
    return `- ${getMinutes(displayTime, trimLeadingZero)}m ${getSeconds(
      displayTime,
      trimLeadingZero,
    )}s`;
  }
};

/**
 * Returns the displayable date in human readable format
 * @param {*} date
 * @param {*} option
 * @returns displayable date string (ex. December 12th, 2022)
 */
export const getHumanReadableDate = (
  date: string,
  options?: {
    year?: "numeric" | "2-digit";
    month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
    day?: "numeric" | "2-digit";
    hour?: "numeric" | "2-digit";
    minute?: "numeric" | "2-digit";
    hour12?: boolean;
  },
): string => {
  if (!date) {
    return "";
  }

  const d = new Date(date);
  if (d.getTime() === 0) {
    return "Never";
  }

  return d.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    ...options,
  });
};

/**
 * Formats the String date into a shorter human readable string, with MM, DD
 * @param dateString - Date to format
 * @returns MM, DD formatted string
 * @example formatDateShort("2020-01-01T00:00:00.000Z")
 * > "Jan 1"
 */
export const formatDateShort = (dateString: string): string => {
  const options = { month: "short", day: "numeric" } as const;
  return new Date(dateString).toLocaleDateString(undefined, options);
};

/**
 * Formats the Date into a human readable 12-hour string of format: HH:MM AM/PM
 * @param date - Date to format
 * @returns MM, DD formatted string
 * > "2:34 PM"
 */
export const formatTimeOfDay = (date: Date): string => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const meridiem = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Convert to 12-hour format
  const formattedMinutes = minutes.toString().padStart(2, "0"); // Add leading zero if needed
  return `${formattedHours}:${formattedMinutes} ${meridiem}`;
};

/**
 * Return "now" shifted by plus_seconds as a Date object.
 */
export function nowInDateFormat(plus_seconds?: number): Date {
  return new Date(
    new Date().getTime() + (plus_seconds ?? 0) * 1000, // lock for 5 minutes.
  );
}

/**
 * Return "now" shifted by plus_seconds as a ISO string.
 */
export function nowInIsoFormat(plus_seconds?: number): string {
  return nowInDateFormat(plus_seconds).toISOString();
}

export const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

/**
 * Examine if passed parameter is a valid UTC date and time string which
 * matches exactly the format of Javascript Date.toISOString().
 * e.g. 2020-01-01T00:00:00.000Z
 * @param input Any type, including null or undefined
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isCanonicalUtcDateTimeString(input): boolean {
  if (typeof input !== "string") {
    return false;
  }
  if (!input.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/)) {
    return false;
  }
  // Note: fromISO may generate valid objects with wide variety of strings.
  // Therefore, format check needs to be done separately above.
  return DateTime.fromISO(input).isValid;
}

/**
 * Formats the String date into a human readable string, with MM, DD, YYYY, HH:MM
 * @param dateString - Date to format
 * @returns MM, DD, YYYY, HH:MM formatted string
 * @example formatDate("2020-01-01T00:00:00.000Z")
 * > "January 1, 2020, 12:00 AM"
 */
export const formatDate = (dateString: string, includeTime = true): string => {
  let options = {
    month: "long",
    day: "numeric",
    year: "numeric",
  } as Intl.DateTimeFormatOptions;
  if (includeTime) {
    options = {
      ...options,
      hour: "numeric",
      minute: "numeric",
    };
  }
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const daysToMs = (days: number): number => {
  return days * 24 * 60 * 60 * 1000;
};

// #endregion

// #region Day and Day of Week Helpers
/**
 * Determine whether the given `date` is yesterday.
 * @param date - Date to check
 * @returns true if the date is yesterday
 */
export const isYesterday = (date: Date): boolean => {
  if (!(date instanceof Date)) {
    throw new Error('Invalid argument: you must provide a "date" instance');
  }
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  );
};

/**
 * Returns true if the date is today
 * @param someDate - Date to check
 * @returns true if the date is today
 */
export const isToday = (date: Date): boolean => {
  if (!(date instanceof Date)) {
    throw new Error('Invalid argument: you must provide a "date" instance');
  }
  const today = new Date();
  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  );
};

/**
 * Returns true if the date is tomorrow
 * @param someDate - Date to check
 * @returns true if the date is tomorrow
 */
export const isTomorrow = (date: Date): boolean => {
  if (!(date instanceof Date)) {
    throw new Error('Invalid argument: you must provide a "date" instance');
  }
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return (
    date.getDate() == tomorrow.getDate() &&
    date.getMonth() == tomorrow.getMonth() &&
    date.getFullYear() == tomorrow.getFullYear()
  );
};

export const getDayOfWeek = (timestamp: string, timezone: string): string => {
  const date = new Date(timestamp);
  const dayOfWeek = date.toLocaleDateString("en-US", {
    timeZone: timezone,
    weekday: "long",
  });
  return dayOfWeek;
};

export const getDayInTimezone = (timezone: string, date: Date): string => {
  return date.toLocaleString("en-US", { timeZone: timezone }).split(",")[0];
};

// #endregion

// #region Other helpers
/**
 * Returns the displayable minutes value gives the raw seconds value
 * @param {*} seconds
 * @returns displayable minutes number
 */
export const getMinutes = (seconds: number, trimLeadingZero = false): string => {
  let num = Math.floor((seconds * -1) / 60);
  if (seconds >= 0) {
    num = Math.floor(seconds / 60);
  }
  let returnVal = num.toString();
  if (trimLeadingZero) {
    returnVal = returnVal.replace(/^0+/, "");
  }
  return returnVal;
};

/**
 * Returns the displayable seconds value gives the raw seconds value
 * @param {*} seconds
 * @returns displayable seconds string
 */
export const getSeconds = (seconds: number, trimLeadingZero = false): string => {
  if (seconds >= 0) {
    return ((trimLeadingZero ? "" : "0") + (seconds % 60)).slice(-2);
  } else {
    return ((trimLeadingZero ? "" : "0") + ((seconds * -1) % 60)).slice(-2);
  }
};

/**
 * @param seconds - seconds to convert to
 * @returns HH:MM:SS formatted string with the last trailing zero removed
 * @example getMMSS(3965)
 * > "1:06:05"
 * getMMSS(360)
 * > "6:00"
 */
export const getMMSS = (seconds = 0): string => {
  if (!seconds || seconds < 1) {
    return "0:00";
  }
  const dateISO = new Date(seconds * 1000).toISOString();
  let temp;
  if (seconds >= 3600) {
    temp = dateISO.substring(11, 19);
  } else {
    temp = dateISO.substring(14, 19);
  }
  return temp[0] === "0" ? temp.substring(1) : temp;
};

function isWithinNDays(dateString: string, n: number): boolean {
  const inputDate = new Date(dateString);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate.getTime() - inputDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays <= n;
}

export const isWithinWeek = (dateString: string): boolean => {
  return isWithinNDays(dateString, 7);
};

/**
 * Calculates the difference in days between now and the target date.
 * For example, today at 9am and yesterday at 10pm would be 1 day apart.
 * Considers both forward and backward comparisons and returns a positive value.
 * @param targetDate - Date to compare to
 * @returns number of days
 */
export const getDaysBetweenNowAndTargetDate = (targetDate: Date): number => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const targetCopy = new Date(targetDate);
  targetCopy.setHours(0, 0, 0, 0);
  const timeDifference = targetCopy.getTime() - now.getTime();
  const daysDifference = Math.abs(Math.floor(timeDifference / (1000 * 60 * 60 * 24)));
  return daysDifference;
};

/**
 * Returns if a time is in "classic" business hours - M-F 8am-5:59pm
 * This doesn't hold true for all users (e.g. holidays, some Islamic countries, folks with odd schedules)
 * so consider if these edge cases are important when choosing to use this logic
 */
export const inBusinessHours = (day: number, hour: number): boolean => {
  return day !== 6 && day !== 7 && hour >= 8 && hour < 18;
};

/**
 * Returns the day of the week (Monday=1 thru Sunday=7) and hour of the day (0-23) in the
 * local timezone of the iso string.
 */
export const getLocalDayAndHourFromIso = (isoString: string): [number, number] => {
  const localTime = DateTime.fromISO(isoString, { setZone: true });
  return [localTime.weekday, localTime.hour];
};

export const timestampToPeriodOfDay = (timestamp: string, timezone: string): string => {
  const date = new Date(timestamp);
  // format: 12:00
  const time = date.toLocaleTimeString("en-US", {
    timeZone: timezone,
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  });
  // if time is between 4am and 12pm, return morning. if time is between 12pm and 6pm, return afternoon. if time is between 6pm and 4am, return evening.
  const hour = parseInt(time.split(":")[0]);
  if (hour >= 4 && hour < 12) {
    return "morning";
  }
  if (hour >= 12 && hour < 18) {
    return "afternoon";
  }
  return "evening";
};

/**
 * Returns the input as-is if passed timezone is valid.
 * Otherwise returns UTC.
 */
const sanitizeTimezone = (timezone: string): string => {
  if (Info.isValidIANAZone(timezone)) {
    return timezone;
  }
  return "UTC";
};

/**
 * Create Date that represents the midnight on the same day of the input in the given timezone.
 * @param date Specific date and time.
 * Please note that Date object does not have a concept of timezone.
 * It represents a point in time since Unix epoch, and there is
 * no notion of Date obejct in a specific timezone.
 * @param timezone Timezone string (e.g. America/Los_Angele)
 * For convenience of the caller, this function internally checks
 * the validity of this input and assumes UTC if its invalid.
 * @returns A new Date object at 00:00 in the given timezone
 * on the same day as the input date and time.
 */
export const getMidnightLocalDay = (date: Date, timezone: string): Date => {
  const localTime = DateTime.fromJSDate(date).setZone(sanitizeTimezone(timezone));
  return localTime.startOf("day").toJSDate();
};

/**
 * Create Date that represents the midnight of next Saturday to Sunday switch in the given timezone.
 * @param date Specific date and time.
 * Please note that Date object does not have a concept of timezone.
 * It represents a point in time since Unix epoch, and there is
 * no notion of Date obejct in a specific timezone.
 * @param timezone Timezone string (e.g. America/Los_Angele)
 * For convenience of the caller, this function internally checks
 * the validity of this input and assumes UTC if its invalid.
 * @returns A new Date object at 00:00 of next Sunday
 * from the input date and time in the given timezone.
 */
export const getNextMidnightLocalSunday = (date: Date, timezone: string): Date => {
  const localTime = DateTime.fromJSDate(date).setZone(sanitizeTimezone(timezone));

  let daysToNextSunday = 7 - localTime.weekday;
  if (daysToNextSunday == 0) {
    // If it is already Sunday, then next Sunday is 7 days ahead.
    daysToNextSunday = 7;
  }
  return localTime.plus({ days: daysToNextSunday }).startOf("day").toJSDate();
};

/**
 * Create Date that represents the midnight in the given timezone.
 * This is intended to adjusting plus/minus one hour depending on DST.
 */
export const getMidnightNearestLocalTime = (date: Date, timezone: string): Date => {
  const localTime = DateTime.fromJSDate(date).setZone(sanitizeTimezone(timezone));
  const localHour = localTime.hour;
  if (localHour > 12) {
    return localTime.plus({ days: 1 }).startOf("day").toJSDate();
  } else {
    return localTime.startOf("day").toJSDate();
  }
};

/**
 * Substracts the given number of seconds from the given ISO date string
 * If either param is falsy, returns an empty string or the original ISO date string if it exists
 * @param isoDate - ISO date string to subtract seconds from
 * @param s - number of seconds to subtract
 * @returns ISO date string with the given number of seconds subtracted
 * */
export const subtractSecondsFromISO = (
  isoDate: string | undefined,
  s: number | undefined,
): string => {
  if (!isoDate || !s) {
    return isoDate || "";
  }
  // Parse the ISO date string to get a Date object
  const date = new Date(isoDate);

  // Subtract the desired number of seconds
  date.setSeconds(date.getSeconds() - s);

  // Convert the modified Date object back to an ISO string and return
  return date.toISOString();
};

/**
 * Seconds (with sub-second as fractional part) since Unix epoch (1970-01-01T00:00:00Z)
 */
export const getEpochS = (): number => {
  return Date.now() / 1000;
};

export const getHoursToDate = (expirationDate: string): number => {
  const expiryDate = new Date(expirationDate);
  const now = new Date();
  const diff = Math.abs(expiryDate.getTime() - now.getTime());
  return Math.ceil(diff / (1000 * 60 * 60));
};

/**
 * Validates if a date range is valid, checking:
 * 1. Both start and end dates are valid dates
 * 2. Start date is not after end date
 *
 * @param startDate - Start date string (any format parsable by Date constructor)
 * @param endDate - End date string (any format parsable by Date constructor)
 * @returns true if the date range is valid
 */
export const isValidDateRange = (startDate: string, endDate: string): boolean => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return !isNaN(start.getTime()) && !isNaN(end.getTime()) && start <= end;
};

// #endregion
