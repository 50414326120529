// Components
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { Stack, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { PlanStepState, ProgramRecordState } from "lib-fullstack/utils/enums";

type ProgramStateIndicatorProps = {
  state: ProgramRecordState | PlanStepState;
};

export const ProgramStateIndicator = ({ state }: ProgramStateIndicatorProps): JSX.Element => {
  const stateConfig = {
    [ProgramRecordState.NotStarted]: {
      icon: (
        <RemoveCircleIcon sx={{ width: 14, height: 14, color: getDynamicColor("chartBlue") }} />
      ),
      text: "NOT STARTED",
    },
    [ProgramRecordState.InProgress]: {
      icon: (
        <WatchLaterIcon sx={{ width: 14, height: 14, color: getDynamicColor("chartPurple") }} />
      ),
      text: "IN PROGRESS",
    },
    [ProgramRecordState.Completed]: {
      icon: (
        <CheckCircleIcon sx={{ width: 14, height: 14, color: getDynamicColor("chartGreen") }} />
      ),
      text: "COMPLETED",
    },
  };

  const selectedConfig = stateConfig[state];

  return (
    <Stack direction="row" gap={0.5} sx={{ alignItems: "center" }}>
      {selectedConfig.icon}
      <Typography
        sx={{
          color: getDynamicColor("purple3"),
          fontSize: "8px",
          letterSpacing: "1px",
          textWrap: "nowrap",
        }}
      >
        {selectedConfig.text}
      </Typography>
    </Stack>
  );
};
