import React from "react";
import { useIntercomLauncher } from "hooks/useIntercomLauncher";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { envConfig } from "utils/Constants";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";

export const INTERCOM_APP_ID = envConfig.envName === "production" ? "pc2chqwy" : "p97ss0fs";

export interface IntercomContextType {
  isIntercomOpen: boolean;
  showIntercomMessenger: () => void;
  hideIntercomMessenger: () => void;
  hideIntercomLauncher: () => void;
  showIntercomLauncher: () => void;
}

/**
 * Default paths where Intercom is allowed to be shown
 */
const ALLOWED_INTERCOM_PATHS = [WebServerExternalPath.SUPPORT];

// Create the context with a default value
export const IntercomContext = React.createContext<IntercomContextType | null>(null);

interface IntercomProviderProps {
  children: React.ReactNode;
}

/**
 * Provider component that wraps your app and makes Intercom available globally
 */
export function CustomIntercomProvider({ children }: IntercomProviderProps): React.ReactElement {
  const {
    loading: userOrgContextLoading,
    defaultOrgAllowYoodliSupport,
    defaultOrgUseCustomHelpPage,
  } = React.useContext(UserOrgContext);

  // Define shouldInitialize with a detailed comment about its purpose
  const shouldInitialize = React.useMemo(() => {
    // Only initialize Intercom when:
    // 1. Context is loaded (not loading)
    // 2. Either:
    //    a. Organization allows Yoodli support (defaultOrgAllowYoodliSupport is true), OR
    //    b. Organization doesn't use a custom help page (defaultOrgUseCustomHelpPage is false)
    return !userOrgContextLoading && (defaultOrgAllowYoodliSupport || !defaultOrgUseCustomHelpPage);
  }, [userOrgContextLoading, defaultOrgAllowYoodliSupport, defaultOrgUseCustomHelpPage]);

  // We use React.useEffect to log when the state changes for debugging purposes
  React.useEffect(() => {
    console.log(
      `Intercom state: shouldInitialize=${shouldInitialize}, allowYoodliSupport=${defaultOrgAllowYoodliSupport}, useCustomHelpPage=${defaultOrgUseCustomHelpPage}`,
    );
  }, [shouldInitialize, defaultOrgAllowYoodliSupport, defaultOrgUseCustomHelpPage]);

  const intercomState = useIntercomLauncher({
    shouldInitialize,
    allowedPaths: ALLOWED_INTERCOM_PATHS,
    bottom: 40,
    right: 40,
  });

  return <IntercomContext.Provider value={intercomState}>{children}</IntercomContext.Provider>;
}

/**
 * Hook that lets any component access the Intercom functions
 */
export function useIntercomFunctions(): IntercomContextType {
  const context = React.useContext(IntercomContext);
  if (!context) {
    throw new Error("useIntercom must be used within an IntercomProvider");
  }
  return context;
}
