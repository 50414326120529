import React from "react";

// Components
import { Stack, Typography, Button, Divider, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// Utils
import { OrgProgramsQueryKeys } from "./OrgPrograms";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { updateOrgProgram } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { HubData } from "lib-frontend/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { ProgramResponse } from "lib-fullstack/api/programApiTypes";
import { OverviewSection } from "./ProgramOverview";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";
import { InfoOutlined } from "@mui/icons-material";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import { UpdateGroupModalBody } from "./UpdateGroupModalBody";
import { AddEvaluatorModal } from "./AddEvaluatorModal";
import { useProgramMutations } from "./hooks/useProgramMutations";

type ProgramSettingsPeopleProps = {
  program: ProgramResponse;
  hubs: HubData[];
};

export const ProgramSettingsPeople = ({
  program,
  hubs,
}: ProgramSettingsPeopleProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { updateProgramEvaluatorsMutation } = useProgramMutations();

  const [groupsModalOpen, setGroupsModalOpen] = React.useState<boolean>(false);
  const [evaluatorsModalOpen, setEvaluatorsModalOpen] = React.useState<boolean>(false);

  const { defaultOrgId } = React.useContext(UserOrgContext);

  const [selectedGroups, setSelectedGroups] = React.useState<string[]>(program.hub_ids ?? []);

  const updateProgramGroupsMutation = useMutation({
    mutationFn: () => updateOrgProgram(defaultOrgId, program.id, { hub_ids: selectedGroups }),
    onSuccess: async () => {
      if (selectedGroups.some((group) => !program.hub_ids.includes(group))) {
        Instrumentation.logProgramGroupAdded(defaultOrgId, program.id);
      }
      await queryClient.invalidateQueries({
        queryKey: [OrgProgramsQueryKeys.OrgPrograms, defaultOrgId],
      });
    },
  });

  const renderGroupsContent = () => {
    if (program.hub_ids.length) {
      const hubsToShow = program.hub_ids
        .map((hubId) => hubs.find((hub) => hub.id === hubId))
        // be sure to filter out any undefined values, i.e. if a hub was deleted
        .filter(Boolean);
      return (
        <Stack
          sx={{
            gap: 2,
            maxHeight: "144px",
            overflowY: "auto",
            pr: 2,
            mr: -2,
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          {hubsToShow.map((hub, index) => {
            return (
              <React.Fragment key={hub.id}>
                <Stack
                  direction="row"
                  sx={{ alignItems: "center", justifyContent: "space-between" }}
                >
                  <Typography
                    sx={{
                      color: getDynamicColor("purple3"),
                      fontFamily: "poppins",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    {hub.name}
                  </Typography>
                  <Typography
                    sx={{
                      color: getDynamicColor("dark5"),
                      fontFamily: "poppins",
                      fontSize: "12px",
                    }}
                  >
                    {hub.numMembers} member{hub.numMembers !== 1 ? "s" : ""}
                  </Typography>
                </Stack>
                {index !== hubsToShow.length - 1 && <Divider />}
              </React.Fragment>
            );
          })}
        </Stack>
      );
    } else {
      return (
        <Button
          onClick={() => setGroupsModalOpen(true)}
          startIcon={<AddIcon />}
          sx={{ m: "auto", fontSize: "14px" }}
        >
          Enroll groups
        </Button>
      );
    }
  };

  const renderEvaluatorsContent = () => {
    if (program.leaders.length) {
      return (
        <Stack
          sx={{
            gap: 2,
            maxHeight: "144px",
            overflowY: "auto",
            pr: 2,
            mr: -2,
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          {program.leaders.map((leader, index) => {
            return (
              <Stack key={leader.id} sx={{ gap: 2 }}>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: getDynamicColor("purple3"),
                    fontFamily: "poppins",
                    fontSize: "12px",
                  }}
                >
                  <Grid item>
                    <Typography
                      sx={{
                        fontWeight: 600,
                      }}
                    >
                      {leader.display_name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        fontWeight: 400,
                      }}
                    >
                      {leader.email}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: getDynamicColor("primary"),
                        fontSize: "12px",
                        cursor: "pointer",
                        fontWeight: 600,
                      }}
                      onClick={() => {
                        const newEmails = program.leaders
                          .filter((programLeader) => programLeader.id !== leader.id)
                          .map((programLeader) => programLeader.email);
                        console.log(newEmails);
                        updateProgramEvaluatorsMutation.mutate(
                          {
                            programId: program.id,
                            leaderEmails: newEmails,
                          },
                          {
                            onSuccess: async () => {
                              await queryClient.invalidateQueries({
                                queryKey: [OrgProgramsQueryKeys.OrgPrograms, defaultOrgId],
                              });
                            },
                          },
                        );
                      }}
                    >
                      Remove
                    </Typography>
                  </Grid>
                </Grid>
                {index !== program.leaders.length - 1 && <Divider />}
              </Stack>
            );
          })}
        </Stack>
      );
    } else {
      return (
        <Typography
          onClick={() => setEvaluatorsModalOpen(true)}
          sx={{ color: getDynamicColor("dark4"), fontSize: "12px" }}
        >
          No evaluators yet
        </Typography>
      );
    }
  };

  return (
    <Stack gap={2}>
      <Stack
        direction="row"
        gap={1}
        sx={{ justifyContent: "space-between", alignItems: "center", height: "37px" }}
      >
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontSize: "16px",
            fontWeight: 700,
            fontFamily: "poppins",
          }}
        >
          Enrolled groups
        </Typography>
      </Stack>
      <Stack direction={{ sm: "column", md: "row" }} gap={4}>
        <OverviewSection
          title={`Groups enrolled (${program.hub_ids.length})`}
          content={renderGroupsContent()}
          cta={
            program.hub_ids.length ? (
              <Button
                variant="outlined"
                onClick={() => setGroupsModalOpen(true)}
                sx={{ fontSize: "14px", whiteSpace: "nowrap", px: { xs: 1, md: 2, lg: 3 } }}
              >
                Modify
              </Button>
            ) : undefined
          }
        />
        <OverviewSection
          title={
            <Stack direction="row" sx={{ gap: { xs: 1, md: 2 }, alignItems: "center" }}>
              <Typography>
                Evaluators {program.leaders?.length > 0 && `(${program.leaders.length})`}
              </Typography>
              <YoodliTooltip title="These are human reviewers who can adjust AI scores on member attempts.">
                <InfoOutlined sx={{ width: 16, height: 16, cursor: "pointer" }} />
              </YoodliTooltip>
            </Stack>
          }
          content={renderEvaluatorsContent()}
          cta={
            <Button
              variant="outlined"
              onClick={() => setEvaluatorsModalOpen(true)}
              sx={{ fontSize: "14px", whiteSpace: "nowrap", px: { xs: 1, md: 2, lg: 3 } }}
            >
              Add new
            </Button>
          }
        />
      </Stack>
      <YoodliCtaModal
        wrapperSx={{ gap: 2 }}
        theme={YoodliCtaModalTheme.Primary}
        hideCloseButton
        loading={updateProgramGroupsMutation.isPending}
        open={groupsModalOpen}
        close={() => setGroupsModalOpen(false)}
        ctaBody={{
          title: "Add or remove groups from this program",
          subtitle: "This may affect members’ Program progress.",
        }}
        bodyComponent={
          <UpdateGroupModalBody
            selectedGroupIds={selectedGroups}
            setSelectedGroupIds={setSelectedGroups}
          />
        }
        buttons={
          {
            primary: {
              text: "Save changes",
              handler: async () => {
                console.log(selectedGroups);
                updateProgramGroupsMutation.mutate();
              },
            },
            secondary: {
              text: "Cancel",
              handler: () => setGroupsModalOpen(false),
            },
          } as CtaButtonHandlers
        }
      />
      <AddEvaluatorModal
        program={program}
        evaluatorsModalOpen={evaluatorsModalOpen}
        setEvaluatorsModalOpen={setEvaluatorsModalOpen}
      />
    </Stack>
  );
};
