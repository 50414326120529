import { db } from "lib-fullstack";
import React from "react";

// Components
import { Box, Stack, Typography } from "@mui/material";
import VideoListing, { JournalView } from "components/VideoJournal/VideoListing";

// Utils
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { PaginationState } from "@tanstack/react-table";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  getProgramMemberRecordings,
  listOrgProgramUsers,
  sendOrgProgramEvaluationReminder,
} from "lib-frontend/modules/AxiosInstance";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import { SharedWithOrgSpeech } from "lib-fullstack/api/orgApiTypes";
import {
  DEFAULT_PROGRAM_MEMBER_RECORDING_FETCH_COUNT,
  ORG_MEMBER_FETCH_LIMIT,
} from "lib-fullstack/utils/constants";
import { queryThumbnails, getThumbnail } from "utils/thumbnailHandler";
import {
  GetProgramMemberRecordingsQueryParams,
  GetProgramMemberRecordingsSortOption,
  ListProgramUsersSortOption,
  ProgramResponse,
} from "lib-fullstack/api/programApiTypes";
import { HumanEvaluationState } from "lib-fullstack/utils/enums";
import { GetScenarioResponse } from "lib-fullstack/api/scenarioApiTypes";
import { LabelledValue } from "lib-frontend/components/YoodliComponents/TableComponents/TableFilter";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { OrgProgramsQueryKeys } from "./OrgPrograms";
import useDebounce from "hooks/useDebounce";
import { YoodliMultiSelect } from "lib-frontend/components/YoodliComponents/YoodliMultiSelect";
import { YoodliSelectOption } from "lib-frontend/components/YoodliComponents/YoodliSelect";

type MemberRecordingsTableProps = {
  program: ProgramResponse;
  scenarios: GetScenarioResponse[];
};

export type TypeFilters = {
  humanEvaluationState: HumanEvaluationState | undefined;
  scenario: string | undefined;
};

const SHARED_WITH_ORG_SPEECHES_QUERY_KEY = "sharedWithOrgSpeeches";

const mapSpeechesToDoc = (speeches: SharedWithOrgSpeech[]): db.Doc<db.Speech>[] => {
  return speeches.map(
    (speech) =>
      ({
        ref: {
          id: speech.id,
          collection: { path: `sites/yoodli/users/${speech.recordedBy}/speeches` },
        },
        data: speech as db.Speech,
      }) as db.Doc<db.Speech>,
  );
};

export const MemberRecordingsTable = ({
  program,
  scenarios,
}: MemberRecordingsTableProps): JSX.Element => {
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { showNotificationBanner } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [pendingMemberEmails, setPendingMemberEmails] = React.useState<string[]>([]);
  const [memberEmailSearchText, setMemberEmailSearchText] = React.useState<string>("");

  const debouncedEmailSearchText = useDebounce(memberEmailSearchText, 250);
  const memberSearchValue = React.useMemo(() => {
    return pendingMemberEmails.map((email) => ({ label: email, value: email }));
  }, [pendingMemberEmails]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columnContent: Record<string, (variables: any) => void> = {
    humanEvaluationState: (variables) => {
      sendOrgProgramEvaluationReminder(defaultOrgId, program.id, variables.query)
        .then(() => {
          showNotificationBanner("Reminder email sent to evaluators");
        })
        .catch((err) => {
          console.error(`Error sending program evaluation reminder: ${err}`);
        });
    },
  };

  // Map UI column IDs to server sort options
  const columnToSortOption: Record<string, GetProgramMemberRecordingsSortOption> = React.useMemo(
    () => ({
      created: GetProgramMemberRecordingsSortOption.RECORDED_DATE_DESC,
      score: GetProgramMemberRecordingsSortOption.SCORE_DESC,
      "-created": GetProgramMemberRecordingsSortOption.RECORDED_DATE_ASC,
      "-score": GetProgramMemberRecordingsSortOption.SCORE_ASC,
    }),
    [],
  );

  const sortOptionToColumn = React.useMemo(
    () => Object.fromEntries(Object.entries(columnToSortOption).map(([k, v]) => [v, k])),
    [columnToSortOption],
  );

  const programUsersQuery = useApiQuery({
    queryKey: [
      OrgProgramsQueryKeys.ProgramUsers,
      defaultOrgId,
      program.id,
      {
        limit: ORG_MEMBER_FETCH_LIMIT.toString(),
        sort: ListProgramUsersSortOption.EmailAsc,
        prefix: debouncedEmailSearchText,
      },
    ],
    queryFn: () =>
      listOrgProgramUsers(defaultOrgId, program.id, {
        sort: ListProgramUsersSortOption.EmailAsc,
        limit: ORG_MEMBER_FETCH_LIMIT.toString(),
        prefix: debouncedEmailSearchText,
      }),
  });

  const [forceUpdateVal, forceUpdate] = React.useState(0);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PROGRAM_MEMBER_RECORDING_FETCH_COUNT,
  });
  const [pagesRequested, setPagesRequested] = React.useState([]);
  const paginationStartInd = (pagination.pageIndex * pagination.pageSize).toString();

  const [sortFilter, setSortFilter] = React.useState<GetProgramMemberRecordingsSortOption>(
    GetProgramMemberRecordingsSortOption.RECORDED_DATE_DESC,
  );

  const [typeFilters, setTypeFilters] = React.useState<LabelledValue>(undefined);

  const speechesQuery = useApiQuery({
    queryKey: [
      SHARED_WITH_ORG_SPEECHES_QUERY_KEY,
      getSiteId(),
      program.id,
      {
        start: paginationStartInd,
        limit: Math.min(pagination.pageSize).toString(),
        sort: sortFilter ?? GetProgramMemberRecordingsSortOption.RECORDED_DATE_DESC,
        filter: typeFilters?.label ? `${typeFilters.label}-${typeFilters.value}` : undefined,
        search: pendingMemberEmails.length > 0 ? pendingMemberEmails.join(",") : undefined,
      },
    ],
    queryFn: async () => {
      try {
        setLoading(true);
        const params: GetProgramMemberRecordingsQueryParams = {
          sort: sortFilter ?? GetProgramMemberRecordingsSortOption.RECORDED_DATE_DESC,
          start: paginationStartInd,
          limit: Math.min(pagination.pageSize).toString(),
        };
        if (typeFilters?.label === "humanEvaluationState") {
          params.humanEvaluationState = typeFilters.value as HumanEvaluationState;
        }
        if (typeFilters?.label === "scenario") {
          params.scenarioId = typeFilters.value as string;
        }
        if (pendingMemberEmails.length > 0) {
          const userIds = [];
          pendingMemberEmails.forEach((email) => {
            const user = (programUsersQuery.data?.users ?? []).find((user) =>
              email.includes(user.email),
            );
            if (user) {
              userIds.push(user.user_id);
            }
          });
          if (userIds.length > 0) {
            params.userId = userIds.join(",");
          }
        }
        const response = await getProgramMemberRecordings(defaultOrgId, program.id, params);

        // easily keep track of which pages have been requested, so i know whether to show the loader or not
        // if the page has already been requested, it will load from cache, so we dont want to to pop in and out the loader
        if (
          !pagesRequested.includes(JSON.stringify({ page: pagination.pageIndex, sort: sortFilter }))
        ) {
          setPagesRequested((prev) => [
            ...prev,
            JSON.stringify({ page: pagination.pageIndex, sort: sortFilter }),
          ]);
        }

        return response;
      } catch (error) {
        console.error("Error fetching speeches shared with org", error);
        return { speeches: [], total: 0 };
      }
    },

    enabled: !!defaultOrgId && !!program.id,
    placeholderData: { speeches: [], total: 0 },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const speechDocs = React.useMemo(() => {
    const speechDocuments = mapSpeechesToDoc(speechesQuery.data?.speeches ?? []);
    queryThumbnails(speechDocuments)
      .then(() => {
        forceUpdate((prev) => prev + 1);
      })
      .catch((error) => {
        console.error(`Failed to fetch thumbnails: ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
    return speechDocuments;
  }, [speechesQuery.data?.speeches]);

  const memberRecordingsItems = React.useMemo(() => {
    return speechDocs.map((speech) => ({
      dbSpeech: speech,
      thumbnailUrl: getThumbnail(speech.ref.id),
    }));
    // must include the forceUpdateVal here so when thumbnails are ready, they re applied to the speeches
  }, [speechDocs, forceUpdateVal]);

  const handlePageChange = (page: number) => {
    setPagination((oldPagination) => {
      return { ...oldPagination, pageIndex: Math.max(page, 0) };
    });
  };

  const programScenarios = React.useMemo(() => {
    return scenarios.filter((scenario) =>
      program.plan_steps.some((step) => step.scenario_id === scenario.id),
    );
  }, [scenarios, program.plan_steps]);

  // must convert VideoListing/Speech sort options to GetSharedWithOrgSortOption
  const handleSetSortFilter = (newSortFilter: string) => {
    setSortFilter(columnToSortOption[newSortFilter]);
    setTypeFilters(undefined);
    setPendingMemberEmails([]);
    setPagination((oldPagination) => {
      return { ...oldPagination, pageIndex: 0 };
    });
  };

  const handleTypeFilterChange = (newTypeFilter: LabelledValue) => {
    setTypeFilters(newTypeFilter);
    setSortFilter(GetProgramMemberRecordingsSortOption.RECORDED_DATE_DESC);
    setPendingMemberEmails([]);
    setPagination((oldPagination) => {
      return { ...oldPagination, pageIndex: 0 };
    });
  };

  const filterOptions = React.useMemo(() => {
    return {
      scenario: programScenarios.map((scenario) => ({
        value: scenario.id,
        label: scenario.title,
      })),
      humanEvaluationState: [
        { value: HumanEvaluationState.Incomplete, label: "Needs grading" },
        { value: HumanEvaluationState.Completed, label: "Graded" },
      ],
    };
  }, [programScenarios]);

  const renderMemberEmailSearch = () => {
    return (
      <Box sx={{ width: { xs: "100%", sm: "500px" } }}>
        <YoodliMultiSelect
          isCheckboxSelect={false}
          componentsProps={{
            popper: {
              placement: "top-start",
              modifiers: [
                {
                  name: "flip",
                  enabled: false,
                },
              ],
            },
          }}
          placeholder="Search member emails"
          value={memberSearchValue}
          loading={programUsersQuery.isFetching}
          noOptionsText={`${
            memberEmailSearchText.length > 0 ? "No results found" : "Type to search member emails"
          }`}
          onChange={(_, vals: YoodliSelectOption[], _reason, detail) => {
            if (vals.length === 0) {
              setPendingMemberEmails([]);
              return;
            }
            if (pendingMemberEmails.includes(detail?.option?.value)) {
              setPendingMemberEmails(
                pendingMemberEmails.filter((email) => email !== detail?.option?.value),
              );
            } else {
              setPendingMemberEmails([...pendingMemberEmails, detail?.option?.value]);
            }
            setTypeFilters(undefined);
            setSortFilter(GetProgramMemberRecordingsSortOption.RECORDED_DATE_DESC);
            setPagination((oldPagination) => {
              return { ...oldPagination, pageIndex: 0 };
            });
          }}
          onInputChange={(e, value) => {
            if (value) {
              setMemberEmailSearchText(value);
            }
          }}
          filterOptions={(x) => x}
          options={
            programUsersQuery.data?.users?.map((user) => ({
              label: user.email,
              value: user.email,
            })) ?? []
          }
        />
      </Box>
    );
  };

  return (
    <Stack direction="column" gap={2}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{ justifyContent: "space-between", alignItems: "center", gap: 2 }}
      >
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontSize: "16px",
            fontWeight: 700,
            fontFamily: "poppins",
          }}
        >
          Shared from this program
        </Typography>
        {renderMemberEmailSearch()}
      </Stack>
      <VideoListing
        simpleTable
        maxSpeechesPerPage={20}
        loadingLibrarySpeeches={
          loading ||
          !pagesRequested.includes(JSON.stringify({ page: pagination.pageIndex, sort: sortFilter }))
        }
        paginationProps={{
          count: speechesQuery.data?.total,
          ...pagination,
        }}
        columnExtraContent={columnContent}
        journalView={JournalView.ProgramMemberRecordings}
        programMemberRecordingsItems={memberRecordingsItems}
        handlePageChangeProp={handlePageChange}
        serverSortHeaders={["created", "score"]}
        sortFilter={sortOptionToColumn[sortFilter]}
        setSortFilter={handleSetSortFilter}
        setTypeFilters={handleTypeFilterChange}
        filterOptions={filterOptions}
        typeFilters={typeFilters}
      />
    </Stack>
  );
};
