import { noop } from "lodash";

// Components
import { Close as CloseIcon } from "@mui/icons-material";
import { CheckCircleRounded as SuccessIcon } from "@mui/icons-material";
import { HighlightOffRounded as ErrorIcon } from "@mui/icons-material";
import { IconButton, SxProps, Stack, Button, Typography, CircularProgress } from "@mui/material";
import { DialogDrawer } from "lib-frontend/components/DialogDrawer";
import { UITestId } from "lib-fullstack/utils/enums";

// Utils
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";

export type ModalButton = {
  text: string | boolean;
  handler: (() => Promise<void>) | (() => void);
  variant?: "contained" | "outlined" | "text";
  buttonSx?: SxProps;
  theme?: YoodliCtaModalTheme;
};

export type CtaButtonHandlers = {
  primary: ModalButton;
  secondary?: ModalButton;
};

export enum YoodliCtaModalTheme {
  Danger = "danger",
  Warning = "warning",
  Primary = "primary",
}

export enum YoodliButtonLayout {
  Single = "single",
  TwoButton = "two_button",
}

export enum YoodliCtaModalState {
  Cta = "cta",
  Success = "success",
  Error = "error",
}

type ModalBody = {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
};

type YoodliCtaModalText = {
  [YoodliCtaModalState.Cta]: ModalBody;
  [YoodliCtaModalState.Success]?: ModalBody;
  [YoodliCtaModalState.Error]?: ModalBody;
};

const YoodliCtaModalTextColor = {
  [YoodliCtaModalState.Cta]: `${getDynamicColor("purple3")} !important`,
  [YoodliCtaModalState.Success]: `${getDynamicColor("greenSuccess")} !important`,
  [YoodliCtaModalState.Error]: `${getDynamicColor("redError")} !important`,
};

type YoodliCtaModalProps = {
  open: boolean;
  loading?: boolean;
  error?: string;
  hideCloseButton?: boolean;
  close?: () => void;
  buttons?: CtaButtonHandlers;
  ctaBody?: ModalBody;
  successBody?: ModalBody;
  errorBody?: ModalBody;
  theme?: YoodliCtaModalTheme;
  wrapperSx?: SxProps;
  state?: YoodliCtaModalState;
  setState?: (state: YoodliCtaModalState) => void;
  bodySx?: SxProps;
  buttonsSx?: SxProps;
  bodyComponent?: JSX.Element;
  buttonsComponent?: JSX.Element;
  breakpointPx?: number;
};

type YoodliCtaModalSxProps = {
  title?: SxProps;
  subtitle?: SxProps;
  primaryButton?: SxProps;
  secondaryButton?: SxProps;
  body?: SxProps;
  wrapper?: SxProps;
  buttonsWrapper?: SxProps;
};

const YoodliCtaModalThemes: Record<YoodliCtaModalTheme, YoodliCtaModalSxProps> = {
  [YoodliCtaModalTheme.Primary]: {
    primaryButton: {
      backgroundColor: getDynamicColor("primary"),
      color: getDynamicColor("light1"),
    },
  },
  [YoodliCtaModalTheme.Danger]: {
    primaryButton: {
      backgroundColor: getDynamicColor("redError"),
      color: getDynamicColor("light1"),
      "&:hover": {
        backgroundColor: getDynamicColor("redErrorDark"),
      },
    },
  },
  // stubbed out possible themes?
  [YoodliCtaModalTheme.Warning]: {},
};

export const YoodliCtaModal = ({
  ctaBody,
  loading,
  error,
  open,
  hideCloseButton,
  close,
  wrapperSx,
  buttons,
  successBody,
  errorBody,
  theme = YoodliCtaModalTheme.Primary,
  state,
  setState,
  bodySx,
  bodyComponent,
  buttonsComponent,
  breakpointPx,
}: YoodliCtaModalProps): JSX.Element => {
  // generating a modal text body
  const body = {
    [YoodliCtaModalState.Cta]: ctaBody,
    [YoodliCtaModalState.Success]: successBody,
    [YoodliCtaModalState.Error]: errorBody,
  } as YoodliCtaModalText;
  const modalState = state ?? YoodliCtaModalState.Cta;
  const { modalStyles, navDrawerOffset } = useNavDrawerOffset();

  const layoutStyles = {
    [YoodliButtonLayout.Single]: {} as YoodliCtaModalSxProps,
    [YoodliButtonLayout.TwoButton]: {} as YoodliCtaModalSxProps,
  };

  const currLayoutStyle = buttons?.secondary
    ? YoodliButtonLayout.TwoButton
    : YoodliButtonLayout.Single;

  const mergedBodySx = { ...layoutStyles[currLayoutStyle].body, ...bodySx } as SxProps;
  const mergedWrapperSx = { ...layoutStyles[currLayoutStyle].wrapper, ...wrapperSx } as SxProps;
  const mergedButtonsSx = {
    ...layoutStyles[currLayoutStyle].buttonsWrapper,
    ...wrapperSx,
  } as SxProps;
  const mergedPrimaryButtonSx = {
    ...YoodliCtaModalThemes[theme]?.primaryButton,
    ...buttons?.primary?.buttonSx,
  } as SxProps;

  const mergedSecondaryButtonSx = {
    ...YoodliCtaModalThemes[theme]?.secondaryButton,
    ...buttons?.secondary?.buttonSx,
  } as SxProps;

  const handleSubmitButton = async () => {
    await Promise.resolve(buttons?.primary.handler());
    // if no state was passed in, close on completion of primary handler.
    // otherwise, stay open for manual dismiss
    if (!state) {
      close();
    }
  };

  const handleSecondaryButton = async () => {
    await Promise.resolve(buttons?.secondary.handler());
  };

  const renderIcon = () => {
    switch (modalState) {
      case YoodliCtaModalState.Success:
        return <SuccessIcon />;
      case YoodliCtaModalState.Error:
        return <ErrorIcon />;
      case YoodliCtaModalState.Cta:
        return <></>;
    }
  };

  const renderContent = () => (
    <>
      {!hideCloseButton && (
        <IconButton
          onClick={close}
          sx={{
            position: "absolute",
            top: 12,
            right: 12,
          }}
        >
          <CloseIcon sx={{ width: "24px", height: "24px" }} />
        </IconButton>
      )}
      {body[modalState] && (
        <Stack
          width="100%"
          fontFamily="poppins"
          fontSize="16px"
          fontWeight={500}
          gap={2}
          color={getDynamicColor("purple3")}
          sx={{
            ...mergedBodySx,
          }}
        >
          <Stack sx={{ color: YoodliCtaModalTextColor[modalState] }} gap={1} direction="row">
            {renderIcon()}
            <Typography
              component="h2"
              sx={{ fontWeight: 700, fontFamily: "Poppins", textAlign: "left" }}
            >
              {body[modalState]?.title}
            </Typography>
          </Stack>

          {body[modalState]?.subtitle && (
            <Typography
              component="div"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "left",
                color: YoodliCtaModalTextColor[modalState],
                fontSize: "14px",
              }}
            >
              {body[modalState].subtitle}
            </Typography>
          )}
        </Stack>
      )}
      {bodyComponent && <Stack sx={{ width: "100%" }}>{bodyComponent}</Stack>}
      {modalState === YoodliCtaModalState.Cta && (buttons || buttonsComponent) && (
        <Stack width="100%" gap={2}>
          {buttonsComponent ? (
            <>{buttonsComponent}</>
          ) : (
            <Stack
              direction="row"
              width="100%"
              gap={1}
              justifyContent={buttons?.secondary ? "space-between" : "flex-end"}
              sx={{ flexWrap: "wrap", ...mergedButtonsSx }}
            >
              {buttons?.secondary && (
                <Button
                  data-testid={UITestId.ModalSecondaryButton}
                  onClick={handleSecondaryButton}
                  disabled={loading}
                  variant={buttons?.secondary?.variant ?? "outlined"}
                  sx={{
                    fontSize: "16px",
                    whiteSpace: "nowrap",
                    fontFamily: "poppins",
                    fontWeight: 700,
                    px: 4,
                    ...mergedSecondaryButtonSx,
                  }}
                >
                  {buttons?.secondary.text}
                </Button>
              )}
              <Button
                startIcon={
                  loading && (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: getDynamicColor("dark1"),
                      }}
                    />
                  )
                }
                data-testid={UITestId.ModalPrimaryButton}
                onClick={handleSubmitButton}
                disabled={loading}
                variant={buttons?.primary?.variant ?? "contained"}
                sx={{
                  fontSize: "16px",
                  whiteSpace: "nowrap",
                  fontFamily: "poppins",
                  fontWeight: 700,
                  px: 4,
                  ...mergedPrimaryButtonSx,
                }}
              >
                {buttons?.primary.text}
              </Button>
            </Stack>
          )}
          {error && (
            <Typography
              fontFamily="poppins"
              fontSize="14px"
              color={getDynamicColor("redError")}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {error}
            </Typography>
          )}
        </Stack>
      )}
    </>
  );

  const sharedStyles = {
    borderRadius: "16px",
    boxShadow: Y_SHADOWS.box_shadow_7,
    background: getDynamicColor("light1"),
    p: { xs: 3, md: 5 },
    pt: { xs: 4, md: 6 },
    zIndex: 12,
  };

  return (
    <DialogDrawer
      open={open}
      breakpointPx={breakpointPx}
      onClose={() => {
        close();
        // timeout to prevent flicker as modal/drawer closes with a transition
        if (setState) {
          setTimeout(() => {
            setState(YoodliCtaModalState.Cta);
          }, 1000);
        }
      }}
      onOpen={noop}
      dialogProps={{
        sx: {
          cursor: "default",
          width: `calc(100% - ${navDrawerOffset}px)`,
        },
        PaperProps: {
          sx: {
            ...sharedStyles,
            px: { xs: 3, md: 5 },
            py: { xs: 2, md: 4 },
            width: {
              xs: "100%",
              sm: `min(500px, 100%)`,
              lg: `min(600px, 100%)`,
            },
            maxHeight: "min(700px, 100%)",
            maxWidth: "unset",
            boxShadow: Y_SHADOWS.box_shadow_1,
            alignItems: "center",
            textAlign: "center",
            justifyContent: "space-between",
            position: "relative",
            border: `2px solid ${getDynamicColor("dark2")}`,
            overflowY: "auto",
            gap: 4,
            ...mergedWrapperSx,
            ...modalStyles,
          },
        },
      }}
      drawerProps={{
        // come up from bottom
        anchor: "bottom",
        sx: {
          zIndex: "1203 !important",
        },
        PaperProps: {
          sx: {
            ...sharedStyles,
            width: "100%",
            gap: 2,
            mt: 4,
            p: { xs: 4 },
            pt: { xs: 6 },
            textAlign: "center",
            pb: `24px !important`,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        },
      }}
    >
      {renderContent()}
    </DialogDrawer>
  );
};
