import React from "react";
import { isSafari, isIOS } from "react-device-detect";
import { useNavigate, useLocation } from "react-router-dom";

// Components
import CloseDrawerIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, Button, Drawer, List, Stack, IconButton, Typography, ListItem } from "@mui/material";
import HamburgerButton from "components/HamburgerButton";
import { AccountNavItem } from "components/Nav/NavigationDrawer/AccountNavItem";
import SelectPracticeTypeDropdown from "components/Nav/SelectPracticeType/SelectPracticeTypeDropdown";
import PricingUsageNavDisplay from "components/Pricing/PricingUsageNavDisplay";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";

// Assets
import { ReactComponent as PoweredByYoodliGraphicSmall } from "images/graphics/graphic-powered-by-yoodli-small.svg";
import { ReactComponent as PoweredByYoodliGraphic } from "images/graphics/graphic-powered-by-yoodli.svg";
import { ReactComponent as YoodliTextLogo } from "images/logos/yoodli_logo.svg";
import { ReactComponent as YoodliIconLogo } from "lib-frontend/assets/YoodliIcon.svg";

// Utils
import SelectPracticeTypeDrawer from "../SelectPracticeType/SelectPracticeTypeDrawer";
import { NavItem } from "./NavItem";
import { NavItemGroup } from "./NavItemGroup";
import { UserOrgContext, UserOrgProfile } from "lib-frontend/contexts/UserOrgContext";
import { YoodliActivityContext } from "lib-frontend/contexts/YoodliActivityContext";
import { CustomEventEnum, publish, subscribe, unsubscribe } from "lib-frontend/events";
import { useShouldRenderPricingGate } from "lib-frontend/hooks";
import { DRAWER_MENU_TRANSITION_DURATION } from "lib-frontend/hooks/useNavDrawerOffset";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { getLiveUserDocMain, updateThisUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { isOrgTrialEnded } from "lib-frontend/utils/orgUtils";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { EffectiveRole, UITestId } from "lib-fullstack/utils/enums";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { DRAWER_MENU_WIDTH } from "utils/Constants";
import {
  hideTmiPaths,
  getNavItems,
  NavRole,
  NavigationItem,
  NavigationItemGroup,
} from "utils/navUtils";
import { WebServerInternalPath } from "utils/paths";

const Z_INDEX = 10000;

const MAX_NAV_LOGO_HEIGHT = 42;

type NavigationDrawerProps = {
  open: boolean;
  setOpen?: (open: boolean) => void; // omit to disable toggling
  handleUploadSpeechClick?: () => void;
  openPricingModal?: () => void;
  accountNavExpanded: boolean;
  handleToggleAccountNavExpanded: (expanded?: boolean) => void;
};
export default function NavigationDrawer({
  open,
  setOpen,
  handleUploadSpeechClick,
  openPricingModal,
  accountNavExpanded,
  handleToggleAccountNavExpanded,
}: NavigationDrawerProps): React.ReactElement {
  const orgContext = React.useContext(UserOrgContext);
  const { isFocusedActivity } = React.useContext(YoodliActivityContext);

  const isSmallScreen = useIsSmallScreen();
  const shouldRenderPricingGate = useShouldRenderPricingGate();

  const siteConf = getStaticFullSiteConf();
  const navOpenLogo =
    orgContext.defaultOrgPrimaryLogo ?? orgContext.defaultOrgSecondaryLogo ?? siteConf?.logo?.url;
  const navClosedLogo =
    orgContext.defaultOrgSecondaryLogo ?? orgContext.defaultOrgPrimaryLogo ?? siteConf?.logo?.url;

  const [hoveringNavToggle, setHoveringNavToggle] = React.useState(false);

  const [practiceTypeDropdownOpen, setPracticeTypeDropdownOpen] = React.useState(false);

  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const location = useLocation();
  // auto expand the account nav when opening the nav drawer on desktop
  React.useLayoutEffect(() => {
    const pathname = location.pathname;
    if (
      !isSmallScreen &&
      open &&
      (pathname === WebServerExternalPath.ACCOUNT ||
        pathname === WebServerInternalPath.ORG_SETTINGS)
    ) {
      handleToggleAccountNavExpanded(true);
    }
    if (!open) {
      handleToggleAccountNavExpanded(false);
    }
  }, [open]);

  // when open changes, fire custom open/close events
  // that we can subscribe to elsewhere in the app
  React.useEffect(() => {
    if (open) {
      publish(CustomEventEnum.NAV_DRAWER_OPEN);
    } else {
      publish(CustomEventEnum.NAV_DRAWER_CLOSE);
    }
  }, [open]);

  // also listen for custom events being fired elsewhere, and close/open the drawer accordingly
  React.useEffect(() => {
    const handleNavDrawerOpen = () => setOpen?.(true);
    const handleNavDrawerClose = () => setOpen?.(false);
    subscribe(CustomEventEnum.NAV_DRAWER_OPEN, handleNavDrawerOpen);
    subscribe(CustomEventEnum.NAV_DRAWER_CLOSE, handleNavDrawerClose);
    return () => {
      unsubscribe(CustomEventEnum.NAV_DRAWER_OPEN, handleNavDrawerOpen);
      unsubscribe(CustomEventEnum.NAV_DRAWER_CLOSE, handleNavDrawerClose);
    };
  }, []);

  const navigate = useNavigate();
  const userOrgRole = React.useMemo(() => {
    const lastRole = getLiveUserDocMain()?.lastUserOrgRole as EffectiveRole;
    let role: NavRole = (lastRole as NavRole) || "none";
    if (lastRole === EffectiveRole.ORG_OWNER) {
      role = EffectiveRole.ORG_ADMIN;
    }
    return role;
  }, [getLiveUserDocMain()?.lastUserOrgRole]);

  // org admin and owner behave the same, so set role to "org_admin" if owner, just for the nav items lookup
  const navigationItems = React.useMemo(() => {
    let items = getNavItems(
      orgContext?.userOrgProfile === UserOrgProfile.ORGANIZATION
        ? userOrgRole
        : EffectiveRole.HUB_MEMBER,
      !!orgContext?.adminInfo?.defaultOrg?.coach_bot_enabled,
    );

    if (
      !orgContext?.userInOrg ||
      (!orgContext?.defaultOrgId && !orgContext.adminInfo.defaultOrgLoading) ||
      (userOrgRole === EffectiveRole.HUB_MEMBER &&
        isOrgTrialEnded(orgContext?.adminInfo.defaultOrg))
    ) {
      items = getNavItems("none", false);
    }

    return items.filter((item) => {
      if ("items" in item) {
        return true;
      }

      if (!orgContext?.orgModuleAccess?.memberBuilderEnabled) {
        return item.path !== WebServerInternalPath.BUILDER;
      }
      if (isToastmasters()) {
        return !hideTmiPaths.includes(item.path as WebServerInternalPath);
      }
      return true;
    });
  }, [
    orgContext?.userOrgProfile,
    orgContext?.adminInfo.defaultOrg,
    orgContext?.adminInfo.defaultOrgLoading,
    orgContext.userInOrg,
    orgContext.orgModuleAccess,
    userOrgRole,
  ]);

  const showOrgSwitcher = React.useMemo(() => {
    return orgContext?.fullOrgList?.length > 1;
  }, [orgContext?.fullOrgList]);

  React.useEffect(() => {
    const coachBotEnabled = !!orgContext?.adminInfo?.defaultOrg?.coach_bot_enabled;

    const hasMatchingPath = (items: (NavigationItem | NavigationItemGroup)[]) =>
      items
        .filter((item): item is NavigationItem => !("items" in item))
        .some((item) => {
          const fullPath = item.path + (item.search || "");
          return fullPath === location.pathname + location.search;
        });

    // if the user is on a route that is not in their profile, switch their profile
    if (
      (hasMatchingPath(getNavItems(EffectiveRole.HUB_MEMBER, coachBotEnabled)) ||
        hasMatchingPath(getNavItems("none", coachBotEnabled))) &&
      orgContext?.userOrgProfile !== UserOrgProfile.PERSONAL
    ) {
      orgContext.setUserOrgProfile(UserOrgProfile.PERSONAL);
    } else if (
      (hasMatchingPath(getNavItems(EffectiveRole.ORG_ADMIN, coachBotEnabled)) ||
        hasMatchingPath(getNavItems(EffectiveRole.HUB_ADMIN, coachBotEnabled)) ||
        hasMatchingPath(getNavItems(EffectiveRole.SPACE_ADMIN, coachBotEnabled))) &&
      orgContext?.userOrgProfile !== UserOrgProfile.ORGANIZATION
    ) {
      orgContext.setUserOrgProfile(UserOrgProfile.ORGANIZATION);
    }
  }, [location.pathname, location.search]);

  const navBarBackground = getDynamicColor(
    isSmallScreen ? "navBackgroundDark" : "navBackgroundLight",
  );
  const navBarTextColor = getDynamicColor("navTextColor");

  // #region Handlers

  const handleDrawerOpen = () => {
    updateThisUserDocMain({
      navigationBarDefaultExpanded: true,
    }).catch(console.error);
    setOpen?.(true);
  };

  const handleDrawerClose = () => {
    setOpen?.(false);
    if (accountNavExpanded) {
      handleToggleAccountNavExpanded(false);
    }
    updateThisUserDocMain({
      navigationBarDefaultExpanded: false,
    }).catch(console.error);
  };

  const handleLogoClick = () => {
    navigate(`${WebServerExternalPath.HOME_LOGGED_IN}`);
  };

  const handleOpenPricingModal = () => {
    setOpen?.(false);
    openPricingModal();
  };

  // #endregion Handlers

  // #region Renderers

  const renderBrandingFooter = () => {
    if (navOpenLogo) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            minHeight: 63,
            pl: open ? 3 : 2,
          }}
        >
          {open ? <PoweredByYoodliGraphic /> : <PoweredByYoodliGraphicSmall />}
        </Box>
      );
    }
  };

  const renderNavOpenLogo = () => {
    // default to the basic "Y Yoodli" text logo
    let content = (
      <YoodliTextLogo
        style={{
          height: "100%",
          maxHeight: MAX_NAV_LOGO_HEIGHT,
          width: "100%",
        }}
      />
    );

    // branding (org) logo and TMI logo take priority over Yoodli logo
    if (navOpenLogo) {
      content = (
        <img
          src={navOpenLogo}
          style={{
            maxHeight: MAX_NAV_LOGO_HEIGHT,
            maxWidth: "100%",
          }}
        />
      );
    }
    return (
      <Box
        onClick={
          location.pathname !== WebServerExternalPath.HOME_LOGGED_IN ? handleLogoClick : undefined
        }
        sx={{
          cursor:
            location.pathname !== WebServerExternalPath.HOME_LOGGED_IN ? "pointer" : "default",
          "img, svg": {
            display: "block",
          },
          width: open ? "100%" : "0px !important",
          overflow: "hidden",
          position: "relative",
        }}
      >
        {content}
      </Box>
    );
  };

  const renderDesktopToggleNavButton = () => {
    // if the nav is closed, and the user is not hovering over the nav toggle, show the icon/smaller brand logo
    // otherwise, if the nav is open, or the user is hovering the toggle, show the Open/CloseDrawerIcon button
    if (!open && !hoveringNavToggle) {
      let content = (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: MAX_NAV_LOGO_HEIGHT,
            height: MAX_NAV_LOGO_HEIGHT,
          }}
        >
          <YoodliIconLogo />
        </Box>
      );
      // branding (org) logo and TMI logo take priority over Yoodli logo
      if (navClosedLogo || (isToastmasters() && siteConf?.logo?.url)) {
        let src = navClosedLogo;
        // TMI takes priority over branding logo, since we don't want to show org stuff info on TMI site
        if (isToastmasters() && siteConf?.logo?.url) {
          src = siteConf?.logo?.url;
        }
        content = (
          <img
            src={src}
            style={{
              display: "block",
              maxHeight: MAX_NAV_LOGO_HEIGHT,
              maxWidth: MAX_NAV_LOGO_HEIGHT,
            }}
          />
        );
      }
      return (
        <Stack
          alignItems="center"
          justifyContent="center"
          style={{
            height: MAX_NAV_LOGO_HEIGHT,
          }}
        >
          {content}
        </Stack>
      );
    }
    return (
      <IconButton
        sx={{
          height: MAX_NAV_LOGO_HEIGHT,
          width: MAX_NAV_LOGO_HEIGHT,
          position: "relative",
          color: navBarTextColor,
          borderRadius: "6px",
          svg: {
            height: 16,
            width: 16,
            display: "block",
          },
          "& .MuiTouchRipple-root .MuiTouchRipple-child": {
            borderRadius: "6px",
          },
        }}
        onClick={open ? handleDrawerClose : handleDrawerOpen}
        aria-label={`${open ? "close" : "open"} navigation drawer`}
      >
        <CloseDrawerIcon
          sx={{
            transform: open ? "none" : "rotate(-180deg)",
            transition: "transform 0.3s",
          }}
        />
      </IconButton>
    );
  };

  const renderDesktopHeaderLogo = () => {
    return (
      <Stack
        direction="row"
        justifyContent={open ? "space-between" : "center"}
        alignItems="center"
        gap={1}
        sx={{
          width: "100%",
          p: 2,
          pb: 0,
        }}
      >
        {renderNavOpenLogo()}

        {/* Open/close toggle button if we've got a valid callback */}
        <Box
          sx={{
            // hide the toggle button via visibility so that the layout remains consistent.
            visibility: setOpen ? "visible" : "hidden",
          }}
          onMouseEnter={() => setHoveringNavToggle(true)}
          onMouseLeave={() => setHoveringNavToggle(false)}
        >
          {renderDesktopToggleNavButton()}
        </Box>
      </Stack>
    );
  };

  const renderPracticeTypeDropdown = () => {
    // hide dropdown on only if the user is in an org, it's not personal profile view, and the user is not a hub member
    // (in these instances, the practice dropdown should always be shown on desktop layout)
    if (
      // userInOrg is undefined until it completes fetching on mount, so check actual value not just falseyness
      orgContext.userInOrg !== false &&
      orgContext.userOrgProfile !== UserOrgProfile.PERSONAL &&
      userOrgRole !== EffectiveRole.HUB_MEMBER
    ) {
      return null;
    }
    return (
      <SelectPracticeTypeDropdown
        drawerOpen={open}
        practiceTypeDropdownOpen={practiceTypeDropdownOpen}
        setPracticeTypeDropdownOpen={setPracticeTypeDropdownOpen}
        handleUploadSpeechClick={handleUploadSpeechClick}
      />
    );
  };

  const handleUserOrgProfileSelectorChange = (newProfile: UserOrgProfile) => {
    if (newProfile === UserOrgProfile.ORGANIZATION) {
      const pathMap = {
        [EffectiveRole.ORG_ADMIN]: WebServerInternalPath.ORG_OVERVIEW,
        [EffectiveRole.SPACE_ADMIN]: WebServerInternalPath.ORG_CONTENT,
        [EffectiveRole.HUB_ADMIN]: WebServerInternalPath.ORG_GROUPS,
      };
      navigate(pathMap[userOrgRole] ?? WebServerInternalPath.ORG_GROUPS);
    } else {
      navigate(WebServerExternalPath.HOME_LOGGED_IN);
    }
    orgContext.setUserOrgProfile(newProfile);
  };

  const renderUserOrgProfileSelector = () => {
    if (
      orgContext.userInOrg === false ||
      userOrgRole === EffectiveRole.HUB_MEMBER ||
      isToastmasters(siteConf)
    ) {
      return null;
    }
    const options = [
      {
        value: UserOrgProfile.ORGANIZATION,
        label: "Switch to admin view",
        optionSx: {
          display: orgContext?.userOrgProfile === UserOrgProfile.ORGANIZATION ? "none" : "block",
        },
      },
      {
        value: UserOrgProfile.PERSONAL,
        label: "Switch to member view",
        optionSx: {
          display: orgContext?.userOrgProfile === UserOrgProfile.PERSONAL ? "none" : "block",
        },
      },
    ];

    return (
      <YoodliSelect
        aria-label="Profile mode selector"
        options={options}
        value={orgContext?.userOrgProfile}
        onOpen={() => {
          // open the drawer when the select is opened on safari, due to overflowX issues
          if (isSafari || isIOS) {
            setOpen?.(true);
          }
        }}
        renderValue={(value: string) => {
          if (!open) {
            return "";
          }
          return (
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: { xs: 14, md: 16 },
              }}
            >
              {value}
            </Typography>
          );
        }}
        {...(isSafari || isIOS
          ? {
              MenuProps: {
                sx: {
                  maxWidth: 148,
                },
              },
            }
          : {})}
        onChange={(e) => handleUserOrgProfileSelectorChange(e.target.value as UserOrgProfile)}
        sx={{
          minWidth: "unset",
          height: 48,
          mx: { xs: 0, md: 1.5 },
          borderRadius: open ? "4px" : 100,
          ".MuiMenuItem-root": {
            fontSize: 14,
            ...(isSafari
              ? {
                  ".MuiBox-root": {
                    overflow: "initial",
                    textOverflow: "unset",
                    whiteSpace: "break-spaces",
                  },
                }
              : {}),
          },
          width: open ? "unset" : 48,
          position: "relative",
          zIndex: 9999999,
        }}
        tooltip={
          !open
            ? {
                onOpen: () => setTooltipOpen(true),
                onClose: () => setTooltipOpen(false),
                // remove the title if tooltip not open, to prevent it from showing on the select when it shouldn't /hack
                title: tooltipOpen ? (
                  <>
                    Current profile: <b>{orgContext?.userOrgProfile}</b>
                  </>
                ) : undefined,
                placement: "right",
                sx: {
                  zIndex: 10000,
                },
                enterDelay: 500,
              }
            : undefined
        }
        hideTooltipWhenOpen
      />
    );
  };

  // #endregion Renderers

  // #region Layouts

  const renderDesktopLayout = () => (
    <>
      {renderDesktopHeaderLogo()}
      {renderUserOrgProfileSelector()}
      {renderPracticeTypeDropdown()}

      <Stack justifyContent="space-between" height="100%">
        <List
          sx={{
            py: 0,
            display: open ? "block" : "flex",
            alignItems: "stretch",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {navigationItems.map((item) => (
            <ListItem
              key={item.text}
              disablePadding
              sx={{
                width: "100%",
                display: open ? "block" : "flex",
                alignItems: "center",
                justifyContent: open ? "flex-start" : "center",
              }}
            >
              {"items" in item ? ( // Differentiate between NavigationItemGroup and NavigationItem
                <NavItemGroup
                  key={item.text}
                  group={item}
                  navOpen={open}
                  accountNavExpanded={accountNavExpanded}
                  handleToggleAccountNavExpanded={handleToggleAccountNavExpanded}
                  handleDrawerClose={handleDrawerClose}
                />
              ) : (
                <NavItem item={item} navOpen={open} handleDrawerClose={handleDrawerClose} />
              )}
            </ListItem>
          ))}
        </List>
        <Stack gap={2}>
          {shouldRenderPricingGate && (
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              >
                <PricingUsageNavDisplay
                  open={open}
                  openPricingModal={openPricingModal}
                  handleDrawerOpen={handleDrawerOpen}
                />
              </Box>
            </Box>
          )}
          <List
            sx={{
              // if showing branding logo, use 24px
              // otherwise, if it's not expanded, use 16px, otherwise use just 8px
              pb: navOpenLogo ? 2 : 1,
              borderTop: `1px solid ${getDynamicColor("muiGrey")}`,
            }}
          >
            <AccountNavItem
              navOpen={open}
              handleNavOpen={handleDrawerOpen}
              expanded={accountNavExpanded}
              handleToggleExpanded={() => handleToggleAccountNavExpanded()}
              showOrgSwitcher={showOrgSwitcher}
            />
            {renderBrandingFooter()}
          </List>
        </Stack>
      </Stack>
    </>
  );

  // Ideally move into separate components since mobile/desktop layout are way too different
  const renderMobileLayout = () => (
    <>
      <div>
        <Box
          sx={{
            height: WEBCLIENT_TOP_NAVBAR_HEIGHT,
            px: 2,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            height="100%"
            gap={2}
          >
            <HamburgerButton isOpen={open} onClick={handleDrawerClose} />
            <Box sx={{ flexGrow: 1 }}>{renderUserOrgProfileSelector()}</Box>
          </Stack>
        </Box>

        <Stack direction="column" gap={1}>
          {location.pathname.includes(WebServerExternalPath.PRACTICE) && (
            <>
              <Button
                variant="gradient"
                onClick={() => {
                  setOpen?.(!open);
                  setPracticeTypeDropdownOpen(!practiceTypeDropdownOpen);
                }}
                sx={{
                  width: "calc(100% - 32px)",
                  borderRadius: "6px",
                  mx: 2,
                }}
                data-testid={UITestId.PracticeButton}
              >
                Practice
              </Button>
            </>
          )}

          {navigationItems.map((item) => (
            <ListItem key={item.text} disablePadding>
              {"items" in item ? ( // Differentiate between NavigationItemGroup and NavigationItem
                <NavItemGroup
                  key={item.text}
                  group={item}
                  navOpen={open}
                  accountNavExpanded={accountNavExpanded}
                  handleToggleAccountNavExpanded={handleToggleAccountNavExpanded}
                  handleDrawerClose={handleDrawerClose}
                />
              ) : (
                <NavItem
                  key={item.text}
                  item={item}
                  navOpen={open}
                  handleDrawerClose={handleDrawerClose}
                />
              )}
            </ListItem>
          ))}
        </Stack>
      </div>

      <List
        sx={{
          backgroundColor: !isToastmasters(siteConf) && getDynamicColor("light2"),
          pt: shouldRenderPricingGate ? "initial" : 0,
        }}
      >
        {shouldRenderPricingGate && (
          <ListItem sx={{ height: 0, width: "100%", position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                bottom: 8,
                left: 0,
                width: "100%",
              }}
            >
              <PricingUsageNavDisplay
                open={open}
                openPricingModal={handleOpenPricingModal}
                isMobileDrawer
              />
            </Box>
          </ListItem>
        )}

        <AccountNavItem
          navOpen={open}
          handleNavOpen={handleDrawerOpen}
          expanded={accountNavExpanded}
          handleToggleExpanded={handleToggleAccountNavExpanded}
          showOrgSwitcher={showOrgSwitcher}
        />

        <ListItem sx={{ mt: { xs: 1 } }}>
          {navOpenLogo && (
            <Box sx={{ mx: 3, mb: 2 }}>
              <PoweredByYoodliGraphic />
            </Box>
          )}
        </ListItem>
      </List>
    </>
  );

  // #endregion Layouts

  const width = `${
    isSmallScreen ? DRAWER_MENU_WIDTH.OPEN_MOBILE : DRAWER_MENU_WIDTH[open ? "OPEN" : "CLOSED"]
  } !important`;

  if (isFocusedActivity) {
    return null;
  }

  return (
    <>
      <Drawer
        id={`${open ? CustomEventEnum.NAV_DRAWER_OPEN : ""}`}
        data-testid={UITestId.NavigationDrawer}
        className="navigation-drawer"
        variant={isSmallScreen ? "temporary" : "permanent"}
        onClose={handleDrawerClose}
        open={open}
        sx={{
          zIndex: Z_INDEX,
          pointerEvents: { xs: open ? "auto" : "none", md: "auto" },
          height: "100svh",
          transition: `width ${DRAWER_MENU_TRANSITION_DURATION}`,
          width,
          "& .MuiDrawer-paper": {
            transition: `width ${DRAWER_MENU_TRANSITION_DURATION}`,
            width,
          },
        }}
        PaperProps={{
          id: "nav-drawer-paper",
          sx: {
            zIndex: Z_INDEX,
            maxHeight: "100vh",
            justifyContent: "space-between",
            background: navBarBackground,
            color: navBarTextColor,
            overflowX: "hidden",
            overflowY: "auto",
            borderRight: `1px solid ${getDynamicColor("dark2")}`,
            backgroundColor: getDynamicColor("light1"),
            gap: 2,
          },
        }}
      >
        {isSmallScreen ? renderMobileLayout() : renderDesktopLayout()}
      </Drawer>
      {isSmallScreen && (
        <SelectPracticeTypeDrawer
          open={practiceTypeDropdownOpen}
          setOpen={setPracticeTypeDropdownOpen}
          paddingTop={WEBCLIENT_TOP_NAVBAR_HEIGHT}
        />
      )}
    </>
  );
}
