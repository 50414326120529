import React from "react";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { listTeams } from "lib-frontend/modules/axiosOrgTeams";
import { createReport, listReports } from "lib-frontend/modules/AxiosInstance";
import { currentUserEmail } from "lib-frontend/utils/AccountUtils";
import { GetTeamListResponseItem } from "lib-fullstack/api/orgTeamApiTypes";
import {
  ListReportsResponse,
  CreateReportResponse,
  ListReportsResponseItem,
} from "lib-fullstack/api/reportApiTypes";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  InteractiveDashboardType,
  ReportState,
  HumanEvaluationState,
} from "lib-fullstack/utils/enums";
import { db } from "lib-fullstack";
import { VideoListingItem } from "lib-frontend/utils/sharedTableUtils";
import { getThumbnail } from "webclient/src/utils/thumbnailHandler";
import firebase from "firebase/app";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import { LifecycleState } from "lib-fullstack/db";

// Maximum number of shares to query from the database
const MAX_SHARES_TO_QUERY = 500;
export const DEFAULT_DATE_RANGE_DAYS = 30;

export type DateRange = {
  startDate: string;
  endDate: string;
};

export const videoIsDisplayable = (data: db.Speech): boolean => {
  return (
    data.lifecycleState === LifecycleState.CREATED ||
    data.lifecycleState === LifecycleState.REDACTED
  );
};

const isVideoListingShareAccessible = (item: VideoListingItem): boolean => {
  return (
    item.dbSpeech?.data &&
    item.dbSpeech.data.lifecycleState === LifecycleState.CREATED &&
    ((item.dbSpeech.data.linkSharing && !item.dbShare?.data?.collabStatusRequired) ||
      Object.values(item.dbSpeech.data.collabs ?? {})
        .map((e) => e?.email?.toLowerCase())
        .includes(currentUserEmail()))
  );
};

export enum TeamQueryKeys {
  LIST_TEAMS = "orgTeams",
  LIST_SCENARIOS = "orgScenarios",
  TEAM_PROGRAM_SUMMARY = "teamProgramSummary",
  TEAM_MEMBERS_DASHBOARD = "teamMembersProgramDashboard",
  TEAM_SCENARIO_SUMMARY = "teamScenarioSummary",
  TEAM_MEMBERS_SCENARIO_DASHBOARD = "teamMembersScenarioDashboard",
  GRADE_NEEDED_ITEMS = "gradeNeededItems",
}

export interface ProgramData {
  orgId: string;
  programId: string;
  programName: string;
  teamId: string;
  notStartedCount: number;
  inProgressCount: number;
  completedCount: number;
}

export interface ProgramDashboardUser {
  orgId: string;
  programId: string;
  teamId: string;
  userId: string;
  displayName: string;
  email: string;
  state: string;
  numAttemptStarted: number;
  bestGoalId: string;
  bestGoalName: string;
  worstGoalId: string;
  worstGoalName: string;
}

export interface ScenarioSummaryData {
  scenarioId: string;
  scenarioTypeId: string;
  scenarioName: string;
  numAttemptStarted: number;
  numCompletedSpeeches: number;
  numMinutesRecorded: number;
  priorNumAttemptStarted: number;
  priorNumCompletedSpeeches: number;
  priorNumMinutesRecorded: number;
}

export interface ScenarioInsightsData {
  userId: string;
  email: string;
  displayName: string;
  priorAvgScenarioScore?: number | null;
  currentAvgScenarioScore?: number | null;
  currentNumCompletedSpeeches: number;
  currentNumMinutesRecorded: number;
  currentNumAttemptStarted: number;
  bestGoalId?: string | null;
  bestGoalName?: string | null;
  worstGoalId?: string | null;
  worstGoalName?: string | null;
}

export interface ITeamContext {
  teams: { isLoading: boolean; isSuccess: boolean; data: GetTeamListResponseItem[] };
  programs: { isLoading: boolean; isSuccess: boolean; data: ProgramData[] };
  dashboard: { isLoading: boolean; data: ProgramDashboardUser[] };
  gradeNeededItems: { isLoading: boolean; data: VideoListingItem[] };
  scenarios: { isLoading: boolean; isSuccess: boolean; data: ScenarioSummaryData[] };
  scenarioInsights: { isLoading: boolean; data: ScenarioInsightsData[] };
  requestTeamPrograms: (
    teamId: string,
    dateParams?: { startDate?: string; endDate?: string },
  ) => void;
  requestScenarioData: (teamId: string, dateParams: { startDate: string; endDate: string }) => void;
  requestScenarioDashboard: (scenarioId: string) => void;
  requestDashboard: (teamId: string, programId: string) => void;
}

export const TeamContext = React.createContext<ITeamContext>({
  teams: { isLoading: false, isSuccess: false, data: [] },
  programs: { isLoading: false, isSuccess: false, data: [] },
  dashboard: { isLoading: false, data: [] },
  gradeNeededItems: { isLoading: false, data: [] },
  scenarios: { isLoading: false, isSuccess: false, data: [] },
  scenarioInsights: { isLoading: false, data: [] },
  requestTeamPrograms: () => {},
  requestScenarioData: () => {},
  requestScenarioDashboard: () => {},
  requestDashboard: () => {},
});

export const TeamProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const [currentTeamId, setCurrentTeamId] = React.useState<string | null>(null);
  const [currentProgramId, setCurrentProgramId] = React.useState<string | null>(null);
  const [scenarioDateParams, setScenarioDateParams] = React.useState<DateRange>(() => {
    const today = new Date();
    const previousDate = new Date(today);
    previousDate.setDate(today.getDate() - DEFAULT_DATE_RANGE_DAYS);
    return {
      startDate: previousDate.toISOString().split("T")[0],
      endDate: today.toISOString().split("T")[0],
    };
  });
  const [scenarioQueryHasData, setScenarioQueryHasData] = React.useState(false);
  const [currentScenarioId, setCurrentScenarioId] = React.useState<string | null>(null);

  const {
    data: teamsData,
    isLoading: isTeamsLoading,
    isSuccess: isTeamsSuccess,
  } = useApiQuery({
    queryKey: [TeamQueryKeys.LIST_TEAMS, defaultOrgId],
    queryFn: async () => {
      try {
        return await listTeams(defaultOrgId, {});
      } catch (error) {
        // Handle and log to console, but proceed as if there are zero teams available
        console.log(error);
        setCurrentTeamId(null);
        return { teams: [] };
      }
    },
    enabled: !!defaultOrgId,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    data: programsData,
    isLoading: isProgramsLoading,
    isSuccess: isProgramsSuccess,
  } = useApiQuery({
    queryKey: [TeamQueryKeys.TEAM_PROGRAM_SUMMARY, defaultOrgId, currentTeamId],
    queryFn: async () => {
      let report: ListReportsResponseItem | CreateReportResponse = null;
      try {
        const reportResponse: ListReportsResponse = await listReports(defaultOrgId, {
          reportType: InteractiveDashboardType.TeamProgramSummary,
          states: [ReportState.Rendered],
          parameters: JSON.stringify({ teamId: currentTeamId, orgId: defaultOrgId }),
        });

        report = reportResponse.reports.find(
          (r: ListReportsResponseItem) => r.state === ReportState.Rendered,
        );

        if (!report) {
          report = await createReport(defaultOrgId, {
            type: InteractiveDashboardType.TeamProgramSummary,
            parameters: JSON.stringify({ teamId: currentTeamId, orgId: defaultOrgId }),
          });
        }
      } catch (e) {
        console.error(e);
      }

      return report?.data ? JSON.parse(report.data) : [];
    },
    enabled: !!defaultOrgId && !!currentTeamId && teamsData?.teams?.length > 0,
    refetchOnWindowFocus: false,
  });

  const { data: dashboardData, isLoading: isDashboardLoading } = useApiQuery({
    queryKey: [TeamQueryKeys.TEAM_MEMBERS_DASHBOARD, defaultOrgId, currentProgramId, currentTeamId],
    queryFn: async () => {
      let report: ListReportsResponseItem | CreateReportResponse = null;
      try {
        const reportResponse: ListReportsResponse = await listReports(defaultOrgId, {
          reportType: InteractiveDashboardType.TeamMembersProgramDashboard,
          states: [ReportState.Rendered],
          parameters: JSON.stringify({
            programId: currentProgramId,
            orgId: defaultOrgId,
            teamId: currentTeamId,
          }),
        });

        report = reportResponse.reports.find(
          (r: ListReportsResponseItem) => r.state === ReportState.Rendered,
        );

        if (!report) {
          report = await createReport(defaultOrgId, {
            type: InteractiveDashboardType.TeamMembersProgramDashboard,
            parameters: JSON.stringify({
              programId: currentProgramId,
              orgId: defaultOrgId,
              teamId: currentTeamId,
            }),
          });
        }
      } catch (e) {
        console.error(e);
      }

      return report?.data ? JSON.parse(report.data) : null;
    },
    enabled:
      !!defaultOrgId && !!currentProgramId && !!currentTeamId && teamsData?.teams?.length > 0,
    refetchOnWindowFocus: false,
  });

  const { data: sharesData, isLoading: isSharesLoading } = useApiQuery({
    queryKey: [TeamQueryKeys.GRADE_NEEDED_ITEMS, defaultOrgId, currentProgramId],
    queryFn: async () => {
      const shares = await db.query(
        db.userShares([getSiteId(defaultOrgId), firebase.auth()?.currentUser?.uid]),
        [
          // @ts-ignore
          db.order("shareTimestamp", "desc") as db.WhereQuery<db.Share>,
          // @ts-ignore
          db.limit(MAX_SHARES_TO_QUERY) as db.WhereQuery<db.Share>,
        ] as db.WhereQuery<db.Share>[],
      );

      if (!shares.length) {
        return [];
      }

      const sharesLookup = Object.fromEntries(
        shares.map((share) => [
          share.data.path.substring(share.data.path.lastIndexOf("/") + 1),
          share,
        ]),
      );

      const sharedSpeechPromises = shares.map((dbShare) => {
        const speechRef = db.pathToRef(dbShare.data.path);
        return db.get<db.Speech>(speechRef);
      });

      const fetchedSpeeches = await Promise.allSettled(sharedSpeechPromises);

      const processedSharedSpeeches = fetchedSpeeches
        .filter((sharedSpeech): sharedSpeech is PromiseFulfilledResult<db.Doc<db.Speech>> => {
          if (sharedSpeech.status !== "fulfilled") return false;

          const speech = sharedSpeech.value;
          const share = sharesLookup[speech.ref.id];

          return (
            speech.data.programId === currentProgramId &&
            speech.data.humanEvaluationState === HumanEvaluationState.Incomplete &&
            videoIsDisplayable(speech.data) &&
            isVideoListingShareAccessible({ dbSpeech: speech, dbShare: share })
          );
        })
        .map((sharedSpeech) => {
          const speech = sharedSpeech.value;
          const share = sharesLookup[speech.ref.id];
          return {
            dbSpeech: speech,
            dbShare: share,
          };
        });

      const thumbnailPromises = processedSharedSpeeches.map(({ dbSpeech }) => {
        return getThumbnail(dbSpeech.data.recordedBy);
      });

      // use Promise.allSettled to tolerate some promises failing.
      const thumbnailResults = await Promise.allSettled(thumbnailPromises);
      const items = processedSharedSpeeches.map(({ dbSpeech, dbShare }, index) => {
        const thumbnailResult = thumbnailResults[index];
        const thumbnailUrl = thumbnailResult.status === "fulfilled" ? thumbnailResult.value : null;

        return {
          dbSpeech,
          dbShare,
          thumbnailUrl,
        };
      });

      return items;
    },
    enabled: !!defaultOrgId && !!currentProgramId,
    refetchOnWindowFocus: false,
  });

  const {
    data: scenarioSummaryData,
    isLoading: isScenarioSummaryLoading,
    isSuccess: isScenarioSummarySuccess,
  } = useApiQuery({
    queryKey: [
      TeamQueryKeys.TEAM_SCENARIO_SUMMARY,
      defaultOrgId,
      scenarioDateParams?.startDate,
      scenarioDateParams?.endDate,
      currentTeamId,
    ],
    queryFn: async () => {
      let report: ListReportsResponseItem | CreateReportResponse = null;
      try {
        const reportResponse: ListReportsResponse = await listReports(defaultOrgId, {
          reportType: InteractiveDashboardType.TeamScenarioSummary,
          states: [ReportState.Rendered],
          parameters: JSON.stringify({
            orgId: defaultOrgId,
            teamId: currentTeamId,
            teamLeadUserId: firebase.auth()?.currentUser?.uid,
            startDate: scenarioDateParams?.startDate,
            endDate: scenarioDateParams?.endDate,
          }),
        });

        report = reportResponse.reports.find(
          (r: ListReportsResponseItem) => r.state === ReportState.Rendered,
        );

        if (!report) {
          report = await createReport(defaultOrgId, {
            type: InteractiveDashboardType.TeamScenarioSummary,
            parameters: JSON.stringify({
              orgId: defaultOrgId,
              teamId: currentTeamId,
              teamLeadUserId: firebase.auth()?.currentUser?.uid,
              startDate: scenarioDateParams?.startDate,
              endDate: scenarioDateParams?.endDate,
            }),
          });
        }

        if (!report?.data) {
          return [];
        }

        const reportData = JSON.parse(report.data);

        const data: ScenarioSummaryData[] = reportData?.map((scenario) => {
          return {
            scenarioId: scenario.scenario_id,
            scenarioTypeId: scenario.scenario_type_id,
            scenarioName: scenario.scenario_name,
            numAttemptStarted: scenario.current_num_attempt_started,
            numCompletedSpeeches: scenario.current_num_completed_speeches,
            numMinutesRecorded: scenario.current_num_minutes_recorded,
            priorNumAttemptStarted: scenario.prior_num_attempt_started,
            priorNumCompletedSpeeches: scenario.prior_num_completed_speeches,
            priorNumMinutesRecorded: scenario.prior_num_minutes_recorded,
          };
        });
        return data;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    enabled:
      !!defaultOrgId &&
      !!scenarioDateParams &&
      !!scenarioDateParams.startDate &&
      !!scenarioDateParams.endDate,
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    if (
      Array.isArray(scenarioSummaryData) &&
      scenarioSummaryData.length > 0 &&
      !scenarioQueryHasData
    ) {
      setScenarioQueryHasData(true);
    }
  }, [scenarioSummaryData, scenarioQueryHasData]);

  const { data: scenarioInsightsData, isLoading: isScenarioInsightsLoading } = useApiQuery({
    queryKey: [
      TeamQueryKeys.TEAM_MEMBERS_SCENARIO_DASHBOARD,
      defaultOrgId,
      currentTeamId,
      currentScenarioId,
      scenarioDateParams?.startDate,
      scenarioDateParams?.endDate,
    ],
    queryFn: async () => {
      const currentUserId = firebase.auth()?.currentUser?.uid || "";
      let report: ListReportsResponseItem | CreateReportResponse = null;
      try {
        const listParams = {
          teamId: currentTeamId,
          orgId: defaultOrgId,
          scenarioId: currentScenarioId,
          startDate: scenarioDateParams?.startDate,
          endDate: scenarioDateParams?.endDate,
          teamLeadUserId: currentUserId,
        };
        const reportResponse: ListReportsResponse = await listReports(defaultOrgId, {
          reportType: InteractiveDashboardType.TeamMembersScenarioDashboard,
          states: [ReportState.Rendered],
          parameters: JSON.stringify(listParams),
        });

        report = reportResponse.reports.find((r) => r.state === ReportState.Rendered);

        if (!report) {
          const createParams = {
            teamId: currentTeamId,
            orgId: defaultOrgId,
            scenarioId: currentScenarioId,
            startDate: scenarioDateParams?.startDate,
            endDate: scenarioDateParams?.endDate,
            teamLeadUserId: currentUserId,
          };
          report = await createReport(defaultOrgId, {
            type: InteractiveDashboardType.TeamMembersScenarioDashboard,
            parameters: JSON.stringify(createParams),
          });
        }
      } catch (e) {
        console.error(e);
      }

      if (report?.data) {
        try {
          const parsedData = JSON.parse(report.data);
          return parsedData.map((row) => {
            return {
              userId: row.user_id,
              email: row.email,
              displayName: row.displayname,
              priorAvgScenarioScore: row.prior_avg_scenario_score,
              currentAvgScenarioScore: row.current_avg_scenario_score,
              currentNumCompletedSpeeches: row.current_num_completed_speeches,
              currentNumMinutesRecorded: row.current_num_minutes_recorded,
              currentNumMinutesStarted: row.current_num_attempt_started,
              bestGoalId: row.best_goal_id,
              bestGoalName: row.best_goal_name,
              worstGoalId: row.worst_goal_id,
              worstGoalName: row.worst_goal_name,
            };
          });
        } catch (e) {
          console.error(e);
          return [];
        }
      }

      return [];
    },
    enabled:
      !!defaultOrgId &&
      !!currentTeamId &&
      !!currentScenarioId &&
      !!scenarioDateParams?.startDate &&
      !!scenarioDateParams?.endDate,
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    if (
      Array.isArray(scenarioSummaryData) &&
      scenarioSummaryData.length > 0 &&
      !scenarioQueryHasData
    ) {
      setScenarioQueryHasData(true);
    }
  }, [scenarioSummaryData, scenarioQueryHasData]);

  const requestTeamPrograms = (teamId: string) => {
    setCurrentTeamId(teamId);
  };

  const requestScenarioDashboard = (scenarioId: string) => {
    if (scenarioId !== currentScenarioId) setCurrentScenarioId(scenarioId);
  };

  const requestScenarioData = (
    teamId: string,
    dateParams: { startDate: string; endDate: string },
  ) => {
    if (teamId !== currentTeamId) setCurrentTeamId(teamId);
    if (
      dateParams?.startDate !== scenarioDateParams?.startDate ||
      dateParams?.endDate !== scenarioDateParams?.endDate
    ) {
      setScenarioDateParams(dateParams);
    }
  };

  const requestDashboard = (teamId: string, programId: string) => {
    if (teamId !== currentTeamId) setCurrentTeamId(teamId);
    if (programId !== currentProgramId) setCurrentProgramId(programId);
  };

  return (
    <TeamContext.Provider
      value={{
        teams: {
          isLoading: isTeamsLoading,
          isSuccess: isTeamsSuccess,
          data: teamsData?.teams ?? [],
        },
        scenarios: {
          isLoading: isScenarioSummaryLoading,
          isSuccess: isScenarioSummarySuccess,
          data: scenarioSummaryData ?? [],
        },
        programs: {
          isLoading: isProgramsLoading,
          isSuccess: isProgramsSuccess,
          data: programsData ?? [],
        },
        dashboard: { isLoading: isDashboardLoading, data: dashboardData ?? [] },
        gradeNeededItems: { isLoading: isSharesLoading, data: sharesData ?? [] },
        scenarioInsights: {
          isLoading: isScenarioInsightsLoading,
          data: scenarioInsightsData ?? [],
        },
        requestTeamPrograms,
        requestScenarioData,
        requestScenarioDashboard,
        requestDashboard,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};
