import React from "react";
import { useNavigate, useLocation } from "react-router";

// Components
import VideoCameraIcon from "@mui/icons-material/VideoCameraFrontOutlined";
import {
  Box,
  Button,
  Grow,
  MenuItem,
  MenuList,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

// Assets
import { ReactComponent as ConversationIcon } from "images/icons/icon-conversation.svg";
import { ReactComponent as InterviewIcon } from "images/icons/icon-interview.svg";
import { ReactComponent as SpeechIcon } from "images/icons/icon-presentation.svg";
import { ReactComponent as UploadIcon } from "images/icons/icon-upload.svg";

// Utils
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { isWhiteLabel } from "lib-frontend/utils/Utilities";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { EventsRecordWheres } from "lib-fullstack/utils/productAnalyticEvents";
import { WebServerInternalPath } from "utils/paths";
import { getDropDownButtonOptions } from "utils/Utilities";
import { UITestId } from "lib-fullstack/utils/enums";
import { ChevronRight } from "@mui/icons-material";
import { handleEnterOrSpaceKey } from "lib-frontend/utils/keyboard";

export const iconMap = {
  speech: (): JSX.Element => <SpeechIcon />,
  interview: (): JSX.Element => <InterviewIcon />,
  conversation: (): JSX.Element => <ConversationIcon />,
  upload: (): JSX.Element => <UploadIcon />,
};
export let mouseExitTimeout: NodeJS.Timeout;
export default function SelectPracticeTypeDropdown({
  drawerOpen,
  handleUploadSpeechClick,
  setPracticeTypeDropdownOpen,
  practiceTypeDropdownOpen,
}: {
  drawerOpen: boolean;
  handleUploadSpeechClick: () => void;
  setPracticeTypeDropdownOpen: (open: boolean) => void;
  practiceTypeDropdownOpen: boolean;
}): JSX.Element {
  const { orgModuleAccess } = React.useContext(UserOrgContext);
  const siteConf = getStaticFullSiteConf();
  const navigate = useNavigate();
  const location = useLocation();
  const uploadDisabled = (
    [
      WebServerExternalPath.PRACTICE_CONVERSATION,
      WebServerExternalPath.PRACTICE_INTERVIEW,
      WebServerExternalPath.PRACTICE_SPEECH,
      WebServerExternalPath.PRACTICE,
      WebServerInternalPath.RECORD_SPEECH_DEPRECATED,
      WebServerInternalPath.PRACTICE_INTERVIEW_DEPRECATED,
      WebServerInternalPath.INTERVIEW_DEPRECATED,
      WebServerInternalPath.IMPROMPTU_PROMPT_DEPRECATED,
    ] as string[]
  ).includes(location.pathname);
  const options = getDropDownButtonOptions(isToastmasters(siteConf), orgModuleAccess);
  if (!isToastmasters(siteConf) && orgModuleAccess.videoUploadEnabled) {
    options.push({
      value: "Upload",
      iconId: "upload",
      tooltip: uploadDisabled ? "Can't upload while practicing!" : undefined,
      disabled: uploadDisabled,
      onClick: () => {
        setPracticeTypeDropdownOpen(false);
        handleUploadSpeechClick();
      },
    });
  }

  const [open, setOpen] = React.useState(practiceTypeDropdownOpen);
  React.useEffect(() => {
    setOpen(practiceTypeDropdownOpen);
  }, [practiceTypeDropdownOpen]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (path?: string) => {
    setOpen(false);
    if (!path) return;
    handleDelayClose();
    switch (path) {
      case WebServerExternalPath.PRACTICE_INTERVIEW:
        Instrumentation.logRecordInterviewCheckpoint(EventsRecordWheres.SIDENAV);
        break;
      case WebServerExternalPath.PRACTICE_CONVERSATION:
        Instrumentation.logRecordConversationCheckpoint(EventsRecordWheres.SIDENAV);
        break;
      case WebServerExternalPath.PRACTICE_SPEECH:
        Instrumentation.logRecordSpeechCheckpoint(EventsRecordWheres.SIDENAV);
        break;
    }
    navigate(path);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    if (mouseExitTimeout) clearTimeout(mouseExitTimeout);
    setAnchorEl(event.currentTarget);
    setPracticeTypeDropdownOpen(true);
  };

  const handleDelayClose = () => {
    mouseExitTimeout = setTimeout(() => {
      setPracticeTypeDropdownOpen(false);
    }, 100);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  return (
    <div onMouseLeave={handleDelayClose} onMouseEnter={handleOpen}>
      <Button
        disableElevation
        onKeyDown={(e) => {
          if (handleEnterOrSpaceKey(e) && !open) {
            handleOpen(e);
          }
        }}
        startIcon={
          <VideoCameraIcon
            fontSize="large"
            sx={{
              ml: "0px !important",
            }}
          />
        }
        endIcon={
          <ChevronRight
            fontSize="small"
            sx={{
              ml: "auto",
              position: "relative",
              right: !drawerOpen ? 20 : 0,
            }}
          />
        }
        variant={isWhiteLabel() ? "contained" : "gradient"}
        sx={{
          justifyContent: "flex-start",
          backgroundColor: isWhiteLabel() ? getDynamicColor("primary") : "unset",
          borderRadius: "8px",
          fontWeight: 700,
          fontSize: 16,
          height: 48,
          mx: "12px",
          textAlign: "left",
          width: "calc(100% - 24px)",
          ".MuiButton-startIcon": {
            // ml: "0px !important",
          },
          ...(!drawerOpen && {
            ".MuiButton-startIcon": {
              ml: "-4px !important",
            },
          }),
        }}
        aria-label="Choose practice type"
        data-testid={UITestId.PracticeButton}
        aria-haspopup="menu"
        aria-expanded={open}
        aria-controls={id}
      >
        <Typography
          sx={{
            flexGrow: 1,
          }}
        >
          {drawerOpen && "Practice"}
        </Typography>
      </Button>
      <Popper
        sx={{ zIndex: 10001, "*": { outline: "transparent !important" } }}
        placement="right-start"
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={250}>
            <MenuList
              autoFocus
              onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget as Node)) {
                  setOpen(false);
                  setPracticeTypeDropdownOpen(false);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  setOpen(false);
                }
                if (e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "Tab") {
                  e.preventDefault();
                  const items = Array.from(
                    e.currentTarget.querySelectorAll('[role="menuitem"]:not([disabled])'),
                  ) as HTMLElement[];

                  if (!items.length) return;

                  // Use the event target as the starting point
                  const currentIndex = items.indexOf(e.target as HTMLElement);
                  let nextIndex = 0;

                  if (e.key === "ArrowDown" || e.key === "Tab") {
                    // If currentIndex is -1 (nothing focused) or at the end, wrap to 0; otherwise, go to the next index.
                    nextIndex =
                      currentIndex === -1 || currentIndex === items.length - 1
                        ? 0
                        : currentIndex + 1;
                  } else if (e.key === "ArrowUp") {
                    // If currentIndex is -1 or at 0, wrap to the last; otherwise, decrement.
                    nextIndex =
                      currentIndex === -1 || currentIndex === 0
                        ? items.length - 1
                        : currentIndex - 1;
                  }
                  items[nextIndex].focus();
                }
              }}
              sx={{
                backgroundColor: getDynamicColor("light1"),
                color: getDynamicColor("purple3"),
                border: `1px solid ${getDynamicColor("dark2")}`,
                boxShadow: Y_SHADOWS.box_shadow_1,
                borderRadius: "12px",
                position: "relative",
                bottom: 24,
                right: 12,
                minWidth: 200,
                py: "0 !important",
              }}
            >
              {options.map((option, i) => (
                <Tooltip title={option.tooltip} key={option.value}>
                  <div>
                    <MenuItem
                      key={`recording-option-${i}`}
                      aria-label={`Record ${option.value}`}
                      disabled={option.disabled}
                      onClick={() => {
                        if (!option.disabled) {
                          if (option.onClick) option.onClick();
                          handleClick(option.path);
                        }
                      }}
                      role="menuitem"
                      onKeyDown={(e) => {
                        if (handleEnterOrSpaceKey(e) && !option.disabled) {
                          if (option.onClick) option.onClick();
                          handleClick(option.path);
                        }
                      }}
                      title={option.tooltip}
                      sx={{
                        py: 1.5,
                        pl: 2.5,
                        fontFamily: "poppins",
                        fontWeight: 400,
                        lineHeight: 1.3,
                        "&:focus, &.Mui-focusVisible": {
                          backgroundColor: getDynamicColor("dark2"),
                        },
                        "&.Mui-selected, &.Mui-selected:focus": {
                          backgroundColor: getDynamicColor("dark2"),
                        },
                      }}
                    >
                      <Stack direction="row" gap={1}>
                        <Box
                          sx={{
                            position: "relative",
                            svg: {
                              height: "100%",
                              width: "100%",
                              display: "block",
                            },
                          }}
                        >
                          {iconMap[option.iconId]()}
                        </Box>
                        {option.value}
                      </Stack>
                    </MenuItem>
                  </div>
                </Tooltip>
              ))}
            </MenuList>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
