// Components
import { Button, Stack } from "@mui/material";
import { WizardSubTitle, WizardTitle } from "components/Wizard/WizardTitles";
import { CSVUploadDropzone } from "../CSVUploadDropzone";

// Utils
import { WIZARD_FOOTER_HEIGHT } from "utils/Constants";
import { downloadSampleUserCSV } from "lib-frontend/utils/orgUtils";

type CSVUploadProps = {
  subtitleOverride?: string;
  setEmails: (emails: string[]) => void;
  error: string;
  setError: (error: string) => void;
  onNext: () => void;
};

export const CSVUpload = ({
  setEmails,
  error,
  setError,
  onNext,
  subtitleOverride,
}: CSVUploadProps): JSX.Element => {
  const handleEmailsAdded = (emails: string[]) => {
    setEmails(emails);
    onNext();
  };

  return (
    <Stack
      gap={{ xs: 2, md: 5 }}
      sx={{
        pb: WIZARD_FOOTER_HEIGHT,
      }}
    >
      <Stack
        direction="column"
        sx={{
          fontFamily: "Poppins",
        }}
        gap={1}
      >
        <WizardTitle>Upload a CSV</WizardTitle>
        <WizardSubTitle
          overrideSx={{
            maxWidth: 540,
          }}
        >
          {subtitleOverride ??
            "Add a CSV file with the email addresses of all the members you would like to invite. Download a sample CSV file below to get started."}
        </WizardSubTitle>
        <Button
          variant="text"
          onClick={downloadSampleUserCSV}
          disableRipple
          sx={{
            fontSize: 14,
            p: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          Download Sample CSV
        </Button>
      </Stack>
      <CSVUploadDropzone handleEmailsAdded={handleEmailsAdded} error={error} setError={setError} />
    </Stack>
  );
};
