import React from "react";
import { useLocation, useNavigate } from "react-router";

// Components
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Stack, Typography, Button, CircularProgress, IconButton } from "@mui/material";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Assets
import { ReactComponent as DownloadIcon } from "images/icons/icon-download.svg";

// Utils
import { OrgProgramsQueryKeys, OrgProgramsSectionStatus } from "./OrgPrograms";
import { ProgramUserTable } from "./ProgramUserTable";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { stringify as csvStringify } from "csv-stringify/sync";
import { saveAs } from "file-saver";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { listOrgProgramUsers } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { parseProgramProgressDate } from "lib-frontend/utils/programUtils";
import { useIsMediumScreen, useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { ProgramResponse } from "lib-fullstack/api/programApiTypes";
import { CSV_STRINGIFY_DEFAULT_OPTIONS } from "lib-fullstack/utils/constants";
import { ProgramState } from "lib-fullstack/utils/enums";
import { OrgProgramsQueryParams } from "lib-fullstack/utils/queryParams";

type ProgramMembersProps = {
  program: ProgramResponse;
};

export const ProgramMembers = ({ program }: ProgramMembersProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const isSmallScreen = useIsSmallScreen();
  const isMediumScreen = useIsMediumScreen();

  const qp = new URLSearchParams(location.search);

  const { defaultOrgId } = React.useContext(UserOrgContext);

  const [searchText, setSearchText] = React.useState<string>("");

  const programMembersTotalQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.ProgramUsers, defaultOrgId, program.id],
    queryFn: () => listOrgProgramUsers(defaultOrgId, program.id, { limit: "1" }),
  });

  const exportToCSV = async () => {
    const data = (await listOrgProgramUsers(defaultOrgId, program.id, { limit: "10000" })).users;

    const rows = data.map((item) => ({
      Name: item.name,
      Email: item.email,
      "Last practice": parseProgramProgressDate(item),
      State: item.state,
      "Scenarios completed": `${item.num_steps_completed}/${program.plan_steps.length}`,
      "Minutes recorded": `${Math.round(item.total_seconds_attempts / 60)}`,
      "Overall attempts": `${item.num_attempt_started}`,
    }));

    const csv = csvStringify(rows, CSV_STRINGIFY_DEFAULT_OPTIONS);
    saveAs(new Blob([csv], { type: "text/csv;charset=utf-8" }), "program_users.csv");
  };

  const memberControls = [
    {
      title: "Download CSV",
      icon: <DownloadIcon style={{ width: "18px", height: "18px" }} />,
      onClick: exportToCSV,
      variant: "text",
    },
    {
      title: program.state === ProgramState.Published ? "Send a reminder" : "Edit reminder",
      icon: <EmailOutlinedIcon style={{ width: "18px", height: "18px" }} />,
      onClick: () => {
        qp.set(OrgProgramsQueryParams.SECTION, OrgProgramsSectionStatus.SendProgramReminder);
        navigate({ search: qp.toString() });
      },
      variant: "outlined",
    },
  ];

  if (programMembersTotalQuery.isLoading) {
    return <CircularProgress />;
  } else if (programMembersTotalQuery?.data?.total === 0) {
    if (program?.state === ProgramState.Draft) {
      return null;
    }
    return (
      <Typography
        sx={{
          color: getDynamicColor("dark4"),
          fontSize: "14px",
          fontWeight: 600,
          fontFamily: "poppins",
        }}
      >
        No users found
      </Typography>
    );
  }

  const renderMemberControls = () => {
    if (isSmallScreen) {
      return (
        <YoodliMenu
          type={YoodliMenuButtonType.Icon}
          menuItems={memberControls.map((control) => ({
            title: control.title,
            onClick: control.onClick,
            type: YoodliMenuItemType.Default,
          }))}
        />
      );
    } else if (isMediumScreen) {
      return memberControls.map((control) => (
        <YoodliTooltip key={control.title} title={control.title}>
          <IconButton
            sx={{ width: "24px", height: "24px", p: 0, color: getDynamicColor("primary") }}
          >
            {control.icon}
          </IconButton>
        </YoodliTooltip>
      ));
    } else {
      return memberControls.map((control) => (
        <Button
          key={control.title}
          variant={control.variant as "text" | "outlined"}
          onClick={control.onClick}
          startIcon={control.icon}
          sx={{
            whiteSpace: "nowrap",
            fontSize: "14px",
          }}
        >
          {control.title}
        </Button>
      ));
    }
  };

  return (
    <Stack gap={4}>
      <Stack direction="row" gap={1} sx={{ alignItems: "center", justifyContent: "space-between" }}>
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          All members
        </Typography>
        <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
          {renderMemberControls()}
          <YoodliSearchBar
            value={searchText}
            label="Search by email"
            onChange={(e) => setSearchText(e.target.value)}
            clearSearch={() => setSearchText("")}
            sx={{ minWidth: { xs: "150px", md: "250px" } }}
          />
        </Stack>
      </Stack>
      <ProgramUserTable program={program} searchText={searchText} />
    </Stack>
  );
};
