import parse from "html-react-parser";

// Components
import { Stack, Typography, Box, Button, Avatar } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

// Assets
import { ReactComponent as LearningPathIcon } from "../../images/icons/learning-path.svg";
import { ReactComponent as MicrophoneTrophyIcon } from "../../images/icons/microphone-trophy.svg";
import { ReactComponent as TrophyIcon } from "images/icons/icon-star-trophy.svg";

// Utils
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { ProgramMemberViewItem } from "lib-fullstack/api/programApiTypes";
import { PlanStepState, ProgramRecordState, UITestId } from "lib-fullstack/utils/enums";
import React from "react";
import { AnimatedGradientText } from "lib-frontend/components/AnimatedGradientText";
import { pluralize } from "lib-fullstack/utils/generalUtils";
import {
  navigateToProgramPractice,
  navigateToProgramDetailsMemberView,
} from "lib-frontend/utils/programUtils";
import {
  GetScenarioMemberViewResponse,
  PersonaMemberViewResponse,
} from "lib-fullstack/api/scenarioApiTypes";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { useNavigate } from "react-router";
import { MyLearningQueryParams } from "lib-fullstack/utils/queryParams";
import { MyLearningSection } from "lib-frontend/utils/orgUtils";
import { getDemeanorColor } from "components/ConvoScenarios/convoScenarioUtils";
import { lineClampStyles } from "lib-frontend/utils/Utilities";
import { PersonaGroupAvatar } from "components/PracticeRecorder/SessionHelper/PersonaGroupAvatar";
import { UIPersonaGroup } from "components/PracticeRecorder/PracticeRecorderTypes";
import { ProgramDetails } from "components/Orgs/Programs/ProgramDetails";

type MemberProgramItemProps = {
  program: ProgramMemberViewItem;
  scenarios: GetScenarioMemberViewResponse[];
};

const ProgramStatusChip = ({
  uncompletedStepCount,
  totalStepCount,
}: {
  uncompletedStepCount: number;
  totalStepCount: number;
}): JSX.Element => {
  let chipText = "Now";
  if (uncompletedStepCount === totalStepCount) {
    chipText = "Begin program";
  } else if (uncompletedStepCount === 0) {
    chipText = "Last step";
  }

  return (
    <Box
      sx={{
        backgroundColor: getDynamicColor("light2"),
        borderRadius: "4px",
        px: 1,
        py: 0.5,
        width: "fit-content",
      }}
    >
      <AnimatedGradientText
        sx={{
          fontFamily: "poppins",
          fontSize: "14px",
          fontWeight: 600,
          verticalAlign: "bottom",
          lineHeight: 1.5,
        }}
      >
        {chipText}
      </AnimatedGradientText>
    </Box>
  );
};

export const MemberProgramCarouselItem = ({
  program,
  scenarios,
}: MemberProgramItemProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const navigate = useNavigate();
  const currentStep = React.useMemo(() => {
    return program.plan_step_results.find((step) => step.state !== PlanStepState.Completed);
  }, [program.plan_step_results]);

  const currentScenario = React.useMemo(() => {
    if (!currentStep) {
      return undefined;
    }
    return scenarios.find((scenario) => scenario.id === currentStep.scenario_id);
  }, [currentStep, scenarios]);

  const stepsCompletedCount = React.useMemo(
    () => program.plan_step_results.filter((step) => step.state === PlanStepState.Completed).length,
    [program.plan_step_results],
  );

  // stepsToGo is number of steps to complete INCLUDING the current step
  const stepsToGo = program.plan_step_results.length - stepsCompletedCount;

  const stepDetails = React.useMemo(() => {
    if (!currentStep) {
      return undefined;
    }
    return [
      {
        icon: (
          <Box
            sx={{
              svg: {
                "path, rect": {
                  stroke: getDynamicColor("purple3"),
                },
                width: 16,
                height: 16,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <TrophyIcon />
          </Box>
        ),
        text: `${currentStep.completion_score ? `${currentStep.completion_score}%` : ""}${
          currentStep.completion_score && currentStep.completion_attempts ? " or " : ""
        }${
          currentStep.completion_attempts
            ? `${currentStep.completion_attempts} attempt${currentStep.completion_attempts !== 1 ? "s" : ""}`
            : ""
        }${currentStep.completion_human_evaluation ? ` and grading` : ""}`.trim(),
      },
    ];
  }, [currentStep]);

  const renderPersonaAvatar = (persona: PersonaMemberViewResponse): JSX.Element => {
    if (!persona) {
      return null;
    }
    return (
      <Stack direction="row" gap={0.5} alignItems="center">
        <Avatar
          alt={persona?.name}
          src={persona?.profile_picture_signed_url}
          key={persona?.profile_picture_signed_url}
          sx={{
            height: 75,
            width: 75,
          }}
        />
      </Stack>
    );
  };

  const renderPersonaInfo = (): JSX.Element => {
    const isMultiPersona =
      currentScenario?.multiPersona &&
      currentScenario?.defaultFullPersonaGroup?.length > 0 &&
      currentScenario?.personaGroups?.length > 0;
    let personaGroup: UIPersonaGroup = undefined;

    if (!currentScenario?.persona && !isMultiPersona) {
      return null;
    }

    let primaryPersonaText = currentScenario?.persona?.name;
    let secondaryPersonaText = currentScenario?.persona?.job_title;
    let personaDemeanor = currentScenario?.persona?.demeanor as string;
    if (isMultiPersona) {
      primaryPersonaText = currentScenario?.personaGroups[0]?.name;
      secondaryPersonaText = "Group conversation";
      personaDemeanor = currentScenario?.defaultFullPersonaGroup.every(
        (p) => p?.demeanor === currentScenario?.defaultFullPersonaGroup[0]?.demeanor,
      )
        ? currentScenario?.defaultFullPersonaGroup[0]?.demeanor
        : "Various";
      personaGroup = {
        name: currentScenario?.personaGroups[0]?.name,
        personas: currentScenario?.personaGroups[0]?.personaIds
          .map((id) => {
            // find the persona by id and map its data like propfile picture, name, all that
            const persona = currentScenario?.defaultFullPersonaGroup.find((p) => p?.id === id);
            return persona;
          })
          .filter(Boolean),
      };
    }

    const persona = currentScenario?.persona;

    return (
      <Stack gap={1}>
        <Typography sx={{ textTransform: "uppercase", fontSize: "10px", fontWeight: 400 }}>
          Roleplay Partner
        </Typography>
        <Stack direction="row" sx={{ gap: 1.5 }}>
          {isMultiPersona ? (
            <PersonaGroupAvatar personaGroup={personaGroup} size="medium" />
          ) : (
            renderPersonaAvatar(persona)
          )}
          <Stack sx={{ justifyContent: "space-between" }}>
            <Stack sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 700,
                  textOverflow: "ellipsis",
                  ...lineClampStyles(2),
                }}
              >
                {primaryPersonaText}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  textOverflow: "ellipsis",
                  ...lineClampStyles(2),
                }}
              >
                {secondaryPersonaText}
              </Typography>
            </Stack>
            <Box
              sx={{
                fontSize: 10,
                letterSpacing: "1px",
                color: getDynamicColor("purple3"),
                backgroundColor: getDemeanorColor(personaDemeanor),
                borderRadius: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                px: 1,
                py: 0.5,
                width: "fit-content",
              }}
            >
              {personaDemeanor.toUpperCase()}
            </Box>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const renderCtas = (): JSX.Element => {
    return (
      <Stack direction="row" gap={1} sx={{ flexWrap: "wrap" }}>
        <Button
          data-testid={`${UITestId.ProgramCarouselCardPracticeButton}-${program.id}`}
          variant="gradient"
          onClick={() => navigateToProgramPractice(defaultOrgId, program, scenarios, navigate)}
          sx={{ fontSize: "14px", p: "0.75em 1.25em", textWrap: "nowrap" }}
        >
          Start now
        </Button>
        <Button
          onClick={() => navigateToProgramDetailsMemberView(program, navigate)}
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            textWrap: "nowrap",
          }}
        >
          View program
        </Button>
      </Stack>
    );
  };

  const renderStepsCompleted = (): JSX.Element => {
    if (stepsCompletedCount === 0 || program.state === ProgramRecordState.Completed) {
      return null;
    }
    return (
      <React.Fragment>
        <Stack direction="row" sx={{ ml: "10px", gap: 1, alignItems: "center" }}>
          <CheckIcon style={{ width: "20px", height: "20px", color: getDynamicColor("purple3") }} />
          <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
            {stepsCompletedCount} {pluralize("step", stepsCompletedCount)} complete
          </Typography>
        </Stack>
        <Box
          sx={{
            height: "20px",
            borderLeft: `3px solid ${getDynamicColor("dark3")}`,
            ml: 2,
            position: "relative",
            zIndex: 0,
            mt: 1,
            mb: "1px",
          }}
        />
      </React.Fragment>
    );
  };

  const renderStepsToGo = (): JSX.Element => {
    if (program.state === ProgramRecordState.Completed) {
      return null;
    }
    return (
      <React.Fragment>
        <Box
          sx={{
            height: "10px",
            borderLeft: `3px solid ${getDynamicColor("dark3")}`,
            ml: 2,
            mb: "2px",
            position: "relative",
            zIndex: 0,
          }}
        />
        <Stack direction="row" sx={{ ml: "13px", alignItems: "flex-end", gap: 2, pb: 1 }}>
          <LearningPathIcon style={{ width: "9px", height: "33px", marginBottom: "6px" }} />
          {stepsToGo > 1 ? (
            <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
              {stepsToGo} {pluralize("step", stepsToGo)} to go
            </Typography>
          ) : (
            <AnimatedGradientText
              sx={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
                verticalAlign: "bottom",
              }}
            >
              Complete
            </AnimatedGradientText>
          )}
        </Stack>
      </React.Fragment>
    );
  };

  const renderCurrentStepBody = (): JSX.Element => {
    if (program.state === ProgramRecordState.Completed) {
      return (
        <Stack
          data-testid={`${UITestId.ProgramCarouselCard}-${program.id}`}
          gap={1}
          direction="row"
          sx={{
            mx: 1,
            my: { xs: 2, md: 3 },
            p: { xs: 4 },
            borderRadius: "8px",
            border: `1px solid ${getDynamicColor("dark2")}`,
            borderTop: "none",
            boxShadow: Y_SHADOWS.box_shadow_1,
            position: "relative",
            backgroundColor: getDynamicColor("light1"),
            color: getDynamicColor("purple3"),
            justifyContent: "center",
          }}
        >
          <Stack sx={{ justifyContent: "center", alignItems: "center", gap: 2 }}>
            <MicrophoneTrophyIcon style={{ width: "50px", height: "50px" }} />
            <Stack sx={{ justifyContent: "center", alignItems: "center", gap: 1 }}>
              <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>Program complete</Typography>
              <Typography sx={{ fontSize: "14px" }}>
                Nice work! Check out how you did and keep up the good work
              </Typography>
            </Stack>
            <Button
              sx={{ fontWeight: 600, fontSize: "14px" }}
              onClick={() =>
                navigate(
                  `${WebServerExternalPath.MY_LEARNING}?${MyLearningQueryParams.SECTION}=${MyLearningSection.ProgramInfo}&${MyLearningQueryParams.PROGRAM_ID}=${program.id}`,
                )
              }
            >
              View your results
            </Button>
          </Stack>
        </Stack>
      );
    }
    return (
      <Stack
        data-testid={`${UITestId.ProgramCarouselCard}-${program.id}`}
        gap={1}
        direction={{ xs: "column", md: "row" }}
        sx={{
          p: { xs: 2 },
          px: { xs: 2, md: 3 },
          borderRadius: "8px",
          border: `1px solid ${getDynamicColor("dark2")}`,
          borderTop: "none",
          boxShadow: Y_SHADOWS.box_shadow_1,
          position: "relative",
          backgroundColor: getDynamicColor("light1"),
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: "-1px",
            right: "-1px",
            height: "8px",
            borderRadius: "8px 8px 0 0",
            background: getDynamicColor("gradient.default"),
          }}
        />
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={3}
          sx={{
            mt: 1,
            justifyContent: "space-between",
            display: "flex",
            mb: 1,
            height: "100%",
            width: "100%",
          }}
        >
          <Stack gap={2} sx={{ flex: 1 }}>
            <ProgramStatusChip
              uncompletedStepCount={stepsToGo}
              totalStepCount={program.plan_step_results.length}
            />
            <Stack>
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: { xs: "14px", md: "16px" },
                  fontWeight: 700,
                }}
              >
                {currentScenario?.title}
              </Typography>
              <ProgramDetails programDetails={stepDetails} />

              {currentScenario?.description && (
                <Typography
                  component="div"
                  sx={{
                    color: getDynamicColor("purple3"),
                    fontFamily: "poppins",
                    fontSize: "12px",
                    fontWeight: 500,
                    textAlign: "start",
                  }}
                >
                  {parse(currentScenario.description)}
                </Typography>
              )}
            </Stack>
            {renderCtas()}
          </Stack>
          {!isSmallScreen && (
            <Box
              sx={{
                pb: 1.5,
                mt: -1,
                mb: -1,
                borderLeft: `1px solid ${getDynamicColor("dark3")}`,
                pl: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                alignSelf: "stretch",
                height: "auto",
                minHeight: "100%",
                maxWidth: "40%",
                overflow: "hidden",
              }}
            >
              {renderPersonaInfo()}
            </Box>
          )}
          {isSmallScreen && (
            <Box
              sx={{
                pb: 1.5,
                pt: 3,
                borderTop: `1px solid ${getDynamicColor("dark3")}`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                alignSelf: "stretch",
                height: "auto",
                minHeight: "100%",
                overflow: "hidden",
              }}
            >
              {renderPersonaInfo()}
            </Box>
          )}
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack sx={{ gap: 2, p: 2, backgroundColor: getDynamicColor("light2"), borderRadius: "8px" }}>
      <Typography
        sx={{
          mr: { xs: 0, sm: "120px" }, // to avoid being overlapped by the carousel controls
          mt: { xs: "40px", sm: 0 },
          fontSize: "16px",
          fontWeight: 600,
          color: getDynamicColor("purple3"),
        }}
        data-testid={UITestId.ProgramCarouselCard}
      >
        {program.name}
      </Typography>
      <Stack>
        {renderStepsCompleted()}
        {renderCurrentStepBody()}
        {renderStepsToGo()}
      </Stack>
    </Stack>
  );
};
