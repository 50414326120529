import React from "react";

// Components
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";

// Utils
import { GoalImprovementCard } from "./GoalImprovement/GoalImprovementCard";
import { ProgramInfoTab } from "./ProgramInfo";
import { UserActivity } from "./UserActivity";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { HubData } from "lib-frontend/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { GetScenarioResponse } from "lib-fullstack/api/scenarioApiTypes";
import { ProgramResponse } from "lib-fullstack/api/programApiTypes";
import { ProgramState } from "lib-fullstack/utils/enums";
import { ProgramMembers } from "./ProgramMembers";

type OverviewSectionProps = {
  title: string | JSX.Element;
  subtitle?: string;
  content: JSX.Element;
  cta?: JSX.Element;
  tag?: JSX.Element;
  halfWidthTitle?: boolean;
};

export const OVERVIEW_SECTION_TITLE_SX = {
  color: getDynamicColor("purple3"),
  fontSize: "16px",
  fontFamily: "poppins",
  fontWeight: 600,
  width: { xs: "fit-content", lg: "100%" },
};

export const OVERVIEW_SECTION_SUBTITLE_SX = {
  color: getDynamicColor("purple3"),
  fontSize: "12px",
  fontFamily: "poppins",
};

export const OverviewSection = ({
  title,
  subtitle,
  content,
  cta,
  halfWidthTitle,
  tag,
}: OverviewSectionProps): JSX.Element => {
  return (
    <Stack
      gap={{ xs: 3, md: 5 }}
      direction={halfWidthTitle ? "row" : "column"}
      sx={{
        p: 3,
        flex: 1,
        borderRadius: "8px",
        border: `1px solid ${getDynamicColor("dark3")}`,
        justifyContent: "space-between",
        minHeight: { xs: "120px", md: "175px" },
        position: "relative",
      }}
    >
      <Stack
        sx={{
          width: halfWidthTitle ? "50%" : "100%",
        }}
      >
        <Stack
          direction="row"
          gap={{ xs: 1, md: 2 }}
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Typography component="div" sx={OVERVIEW_SECTION_TITLE_SX}>
            {title}
          </Typography>
          {cta}
        </Stack>
        <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
          {subtitle && <Typography sx={OVERVIEW_SECTION_SUBTITLE_SX}>{subtitle}</Typography>}
          {tag}
        </Stack>
      </Stack>
      {content}
    </Stack>
  );
};

type ProgramOverviewProps = {
  setActiveTab: (tab: ProgramInfoTab) => void;
  program: ProgramResponse;
  hubs: HubData[];
  scenarios: GetScenarioResponse[];
};

export const ProgramOverview = ({
  setActiveTab,
  program,
  hubs,
}: ProgramOverviewProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const { defaultOrgId } = React.useContext(UserOrgContext);

  React.useLayoutEffect(() => {
    Instrumentation.logProgramAdminOverviewViewed(defaultOrgId, program.id);
  }, []);

  const renderGroupsContent = () => {
    if (program.hub_ids.length) {
      const hubsToShow = program.hub_ids
        .map((hubId) => hubs.find((hub) => hub.id === hubId))
        .slice(0, 3)
        // be sure to filter out any undefined values, i.e. if a hub was deleted, or less than 3 exist
        .filter(Boolean);
      return (
        <Stack gap={2}>
          {hubsToShow.map((hub) => {
            return (
              <React.Fragment key={hub.id}>
                <Divider />
                <Stack
                  direction="row"
                  sx={{ alignItems: "center", justifyContent: "space-between" }}
                >
                  <Typography
                    sx={{
                      color: getDynamicColor("purple3"),
                      fontFamily: "poppins",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    {hub.name}
                  </Typography>
                  <Typography
                    sx={{
                      color: getDynamicColor("dark5"),
                      fontFamily: "poppins",
                      fontSize: "12px",
                    }}
                  >
                    {hub.numMembers} member{hub.numMembers !== 1 ? "s" : ""}
                  </Typography>
                </Stack>
              </React.Fragment>
            );
          })}
          {program.hub_ids.length > 3 && (
            <Typography
              sx={{
                color: getDynamicColor("dark4"),
                fontSize: "12px",
                fontFamily: "poppins",
                fontWeight: 700,
              }}
            >
              + {program.hub_ids.length - 3} more
            </Typography>
          )}
        </Stack>
      );
    } else {
      return (
        <Button
          onClick={() => setActiveTab(ProgramInfoTab.Settings)}
          startIcon={<AddIcon />}
          sx={{ m: "auto", fontSize: "14px" }}
        >
          Enroll groups
        </Button>
      );
    }
  };

  const renderUserStatsContent = () => {
    return program.user_activity.length ? (
      <Box sx={{ my: "auto" }}>
        <UserActivity userActivity={program.user_activity} />
      </Box>
    ) : (
      <Typography
        sx={{
          color: getDynamicColor("dark4"),
          fontFamily: "poppins",
          fontSize: "12px",
          fontWeight: 600,
          textAlign: "center",
          m: "auto",
        }}
      >
        You will see user stats here once enrolled group members start their programs
      </Typography>
    );
  };

  return (
    <Stack gap={{ xs: 2, md: 4 }}>
      <Stack direction="row" gap={4}>
        <OverviewSection
          title="User activity"
          subtitle={program.user_activity.length ? "Overview of overall usage" : undefined}
          content={renderUserStatsContent()}
          cta={
            program.hub_ids.length ? (
              <Button
                onClick={() => setActiveTab(ProgramInfoTab.Settings)}
                sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
              >
                View all
              </Button>
            ) : undefined
          }
        />
        {!isSmallScreen && (
          <OverviewSection
            title="Groups enrolled"
            subtitle={program.hub_ids.length ? "Enrolled in this program" : undefined}
            content={renderGroupsContent()}
            cta={
              program.hub_ids.length ? (
                <Button
                  onClick={() => setActiveTab(ProgramInfoTab.Settings)}
                  sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                >
                  View all
                </Button>
              ) : undefined
            }
          />
        )}
      </Stack>
      {program?.state !== ProgramState.Draft && <GoalImprovementCard programId={program.id} />}
      {isSmallScreen && (
        <OverviewSection
          title="User stats"
          subtitle={program.user_activity.length ? "Overview of overall usage" : undefined}
          content={renderUserStatsContent()}
        />
      )}
      <ProgramMembers program={program} />
    </Stack>
  );
};
