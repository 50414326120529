// Utilities/Functions
import { db } from "lib-fullstack";
import React from "react";

// Components
import { makeSimplePopper } from "../../ui/SimplePopper";
import {
  EditOutlined as EditOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  ShareOutlined as ShareIcon,
  MoreVert,
  GetAppOutlined as GetAppOutlinedIcon,
} from "@mui/icons-material";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import DeleteYoodliModal from "components/VideoSummary/DeleteYoodliModal";

// Utils
import "../../App.css";
import { handleDownloadFile } from "../../utils/EditVideoItemUtilities";
import { generateSpeechShareFullLink } from "../../utils/Utilities";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { copyToMyClipboard } from "lib-frontend/utils/Utilities";
import { SpeechSummaryAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";

/**
 * More options popper.
 */
export const MoreOptionsDropdown = (props: {
  dbSpeech: db.Doc<db.Speech>;
  dbShare?: db.Doc<db.Share>;
  complete: boolean;
  editable: boolean;
  handleEdit: () => void;
  handleShareRecordingClick: () => void;
}): JSX.Element => {
  const { dbSpeech, dbShare, complete, editable, handleEdit, handleShareRecordingClick } = props;

  let menuList = [];
  let popper;
  const isShare = !!dbShare;

  const [isOpen, setIsOpen] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  // Close the menu and call the handler passed in to perform some action on the speech.
  function closeMenuPassthroughHandler(handler: () => void) {
    setIsOpen(false);
    handler();
  }

  function handleDeleteSpeechClick() {
    setShowDeleteModal(true);
  }

  function handleDownloadFileClick() {
    Instrumentation.logAmplitudeEvent(
      SpeechSummaryAnalyticsEvents.SPEECH_SUMMARY_RECORDING_DOWNLOADED,
    );
    handleDownloadFile(dbSpeech).catch(console.error);
  }

  const linkSharePopper = makeSimplePopper("Link copied!");
  const handleCopyLinkButtonClicked = (event) => {
    const link = generateSpeechShareFullLink(dbSpeech.data);
    copyToMyClipboard(link);
    linkSharePopper.handleClick(event);
  };

  const getMenuItem = (clickHandler, listItemText, icon, menuItemKey) => (
    <MenuItem onClick={clickHandler} key={menuItemKey}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{listItemText}</ListItemText>
    </MenuItem>
  );

  if (isShare) {
    menuList = [getMenuItem(handleCopyLinkButtonClicked, "Copy link", <ShareIcon />, "share")];
    popper = linkSharePopper.el;
  } else if (editable) {
    menuList = [
      getMenuItem(
        () => closeMenuPassthroughHandler(handleShareRecordingClick),
        "Share",
        <ShareIcon />,
        "share",
      ),
      getMenuItem(
        () => closeMenuPassthroughHandler(handleEdit),
        "Edit title",
        <EditOutlinedIcon />,
        "edit",
      ),
      getMenuItem(
        () => closeMenuPassthroughHandler(handleDeleteSpeechClick),
        "Delete",
        <DeleteOutlinedIcon />,
        "del",
      ),
      getMenuItem(
        () => closeMenuPassthroughHandler(handleDownloadFileClick),
        "Download",
        <GetAppOutlinedIcon />,
        "dl",
      ),
    ];
  } else if (complete) {
    menuList = [
      getMenuItem(
        () => closeMenuPassthroughHandler(handleShareRecordingClick),
        "Share",
        <ShareIcon />,
        "share",
      ),
    ];
    popper = linkSharePopper.el;
  } else {
    menuList = [
      getMenuItem(
        () => closeMenuPassthroughHandler(handleDeleteSpeechClick),
        "Delete",
        <DeleteOutlinedIcon />,
        "del",
      ),
    ];
  }

  const [anchorEl, setAnchorEl] = React.useState(null); // anchorEl used to track where on the screen to open the dropdown
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsOpen((prevOpenState) => !prevOpenState);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <IconButton
        id="basic-button"
        aria-haspopup="true"
        size="small"
        style={{
          color: "inherit",
        }}
        aria-expanded={isOpen ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>

      {menuList && menuList.length > 0 && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={isOpen}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            zIndex: 3001,
          }}
        >
          {menuList.map((item) => item)}
        </Menu>
      )}
      {popper}
      <DeleteYoodliModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        dbSpeech={dbSpeech}
      />
    </>
  );
};
