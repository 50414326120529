import React from "react";
import { ReactMultiEmail, isEmail } from "react-multi-email";

// Components
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Chip, Stack, SxProps, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";

type YoodliMultiEmailProps = {
  pendingEmails: string[];
  setPendingEmails: (emails: string[]) => void;
  setErrorEmails?: (emails: string[]) => void;
  maxEmailCount?: number;
  disabled?: boolean;
  errorEmails?: string[];
  sxProps?: SxProps;
};

export const YoodliMultiEmail = ({
  pendingEmails,
  setPendingEmails,
  errorEmails,
  setErrorEmails,
  disabled,
  maxEmailCount = 20,
  sxProps,
}: YoodliMultiEmailProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();

  const [inputValue, setInputValue] = React.useState<string>("");
  const [error, setError] = React.useState<string>(undefined);

  const validateAddedEmail = (): boolean => {
    if (!isEmail(inputValue)) {
      setError("Invalid email");
      return false;
    }
    if (pendingEmails.includes(inputValue)) {
      setError("Email already added");
      return false;
    }
    if (pendingEmails.length === maxEmailCount) {
      setError(`You can only add up to ${maxEmailCount} emails`);
      return false;
    }
    if (error) {
      setError(undefined);
    }
    return true;
  };

  const handleKeyDown: React.KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case " ":
      case "Tab":
        addEmail();
    }
    if (error && !["Enter", " ", "Tab"].includes(event.key)) {
      setError(undefined);
    }
  };

  const addEmail = () => {
    if (validateAddedEmail()) {
      setPendingEmails([...pendingEmails, inputValue]);
      setInputValue("");
    }
  };

  const handleRemoveEmailClick = (removeEmail, index, emailToRemove) => {
    removeEmail(index);
    setInputValue("");
    if (error) {
      setError(undefined);
    }
    if (setErrorEmails && errorEmails) {
      setErrorEmails(errorEmails.filter((email) => emailToRemove !== email));
    }
  };
  return (
    <Stack>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          textAlign: "left",
          border: `1px solid ${getDynamicColor("dark5")}`,
          borderRadius: "4px",
          flexWrap: "nowrap",
          width: "100%",

          ".invite-email-input": {
            fontSize: "14px",
            fontFamily: "poppins",
            width: "100%",
            height: "100%",
            outline: "none",
            border: "none",
          },
          ".react-multi-email-input": {
            flexWrap: "wrap",
            width: "auto",
          },
          ...sxProps,
        }}
      >
        <ReactMultiEmail
          className="react-multi-email-input"
          inputClassName="invite-email-input"
          style={{
            borderRadius: "4px",
            border: "none",
            padding: "8px 16px",
            minHeight: 55,
            paddingRight: isSmallScreen ? "60px" : "140px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxHeight: "250px",
            overflowY: "auto",
          }}
          emails={pendingEmails}
          onChange={(_emails: string[]) => {
            setPendingEmails(_emails);
            setInputValue("");
          }}
          onChangeInput={(val: string) => {
            // if the most recently added char is a space, don't run the onChange callback
            // as it reset the inputValue and disabled the "Invite" button when called on spaces (which are invalid in emails)
            if (val[val.length - 1] !== " ") {
              setInputValue(val);
            }
          }}
          onKeyDown={handleKeyDown}
          getLabel={(email, index, removeEmail) => {
            return (
              <Chip
                key={index}
                label={email}
                onDelete={() => handleRemoveEmailClick(removeEmail, index, email)}
                variant="outlined"
                deleteIcon={<ClearIcon />}
                sx={{
                  mr: 1,
                  mb: 1,
                  backgroundColor: getDynamicColor("dark2"),
                  borderRadius: "5px",
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 600,
                  color: (errorEmails ?? []).includes(email)
                    ? getDynamicColor("redError")
                    : getDynamicColor("dark4"),
                }}
              />
            );
          }}
        />

        <Button
          disabled={disabled || !!error || !isEmail(inputValue)}
          variant="contained"
          sx={{
            position: "absolute",
            zIndex: 25,
            borderRadius: "50px",
            right: "20px",
            top: "13px",
            fontSize: "12px",
            fontWeight: 700,
            padding: { xs: 1, md: "8px 24px" },
          }}
          onClick={addEmail.bind(null, inputValue)}
        >
          {isSmallScreen ? <AddIcon fontSize="small" /> : "Add email"}
        </Button>
      </Box>
      {error && (
        <Typography
          sx={{
            color: getDynamicColor("redError"),
            fontFamily: "Poppins",
            fontSize: "12px",
            mx: 2,
            mt: 0.5,
          }}
        >
          {error}
        </Typography>
      )}
    </Stack>
  );
};
