// Components
import { Typography, TypographyProps } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

interface AnimatedTextButtonProps {
  animationSpeedS?: number;
}

export const getShineAnimationStyles = (
  textClip: boolean,
  animationSpeedS = 3,
): {
  [key: string]: string | object | number | undefined;
} => {
  return {
    backgroundSize: "200% auto",
    WebkitAnimation: `shine ${animationSpeedS}s linear infinite`,
    animation: `shine ${animationSpeedS}s linear infinite`,
    "@-webkit-keyframes shine": {
      to: {
        backgroundPosition: "200% center",
      },
    },
    "@keyframes shine": {
      to: {
        backgroundPosition: "200% center",
      },
    },
    ...(textClip && {
      color: "#000",
      backgroundClip: "text",
      textFillColor: "transparent",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    }),
  };
};

export const AnimatedGradientText = (
  props: AnimatedTextButtonProps & TypographyProps,
): JSX.Element => {
  const typographyProps = { ...props };
  delete typographyProps.animationSpeedS;

  return (
    <Typography
      {...typographyProps}
      sx={{
        background: getDynamicColor("gradient.slantedLoop"),
        ...getShineAnimationStyles(true, props.animationSpeedS),
        ...props.sx,
      }}
    >
      {props.children}
    </Typography>
  );
};
