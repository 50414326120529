import React, { useMemo } from "react";

// Components
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";

// Assets
import FilterIcon from "lib-frontend/assets/FilterIcon";

// Utils
import { Column } from "@tanstack/react-table";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { TableRow } from "lib-frontend/utils/sharedTableUtils.js";
import { handleEnterOrSpaceKey } from "lib-frontend/utils/keyboard";

type FilterItem = {
  label: string;
  value: string;
  isSelected: boolean;
};

export type LabelledValue = {
  label: string;
  value: string;
};

export const TableFilter = (props: {
  singleSelectOnly?: boolean;
  column?: Column<TableRow, unknown>;
  setFilterState?: (filterStr: string) => void;
  names: string[] | LabelledValue[];
  searchable: boolean;
  buttonSx?: SxProps;
  buttonText?: string;
  buttonProps?: ButtonProps;
  activeFilters?: string[];
}): JSX.Element => {
  const {
    column,
    setFilterState,
    names,
    searchable,
    buttonSx,
    buttonText,
    buttonProps,
    singleSelectOnly,
  } = props;
  const availableTypeFilters: FilterItem[] = React.useMemo(() => {
    return (names ?? []).map((name) => {
      const value = typeof name === "string" ? name : name.value;
      return {
        label: typeof name === "string" ? name : name.label,
        value: value,
        isSelected: props.activeFilters?.includes(value) ?? false,
      };
    });
  }, [names, props.activeFilters]);

  const [isOpen, setIsOpen] = React.useState(false);
  const [filterItems, setFilterItems] = React.useState<FilterItem[]>(availableTypeFilters);
  // We use a tempFilterItems to store the filter items that the user is currently selecting,
  // which are then only saved to the filterItems when the user clicks "Apply".
  const [tempFilterItems, setTempFilterItems] = React.useState([]);
  const dropDownRef = React.useRef();

  const [searchText, setSearchText] = React.useState("");

  const searchedFilterItems = useMemo(() => {
    if (searchText) {
      return tempFilterItems.filter((item) =>
        item.label.toLowerCase().includes(searchText.toLowerCase()),
      );
    }
    return tempFilterItems;
  }, [searchText, tempFilterItems]);

  React.useEffect(() => {
    const newFilterList = filterItems.filter((item) => item.isSelected).map((item) => item.label);
    if (column) {
      column.setFilterValue(newFilterList);
    }
    if (setFilterState) {
      const filterValues = filterItems.filter((item) => item.isSelected).map((item) => item.value);
      setFilterState(filterValues.join(","));
    }
  }, [filterItems]);

  React.useEffect(() => {
    setFilterItems(availableTypeFilters);
  }, [props.activeFilters]);

  const handleTempFilterValueChanged = (name: string) => {
    const newFilterItems = tempFilterItems.map((item) => {
      if (item.label === name) {
        return {
          label: name,
          value: item.value,
          isSelected: !item.isSelected,
        };
      } else if (singleSelectOnly) {
        return {
          ...item,
          isSelected: false,
        };
      }
      return item;
    });

    setTempFilterItems(newFilterItems);
    if (singleSelectOnly) {
      setFilterItems(newFilterItems);
      setIsOpen(false);
    }
  };

  const getMenuItem = (checked: boolean, name: string) => (
    <MenuItem
      key={name}
      onClick={() => handleTempFilterValueChanged(name)}
      role="menuitem"
      tabIndex={-1}
      aria-checked={checked}
      sx={{
        px: 1,
        backgroundColor:
          checked && singleSelectOnly ? getDynamicColor("primaryBackgroundHover") : "unset",
      }}
    >
      <Stack direction="row" alignItems="center">
        {singleSelectOnly ? (
          <Typography
            sx={{
              fontSize: "16px",
              fontFamily: "Poppins",
              fontWeight: 600,
              padding: "6px 16px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              minWidth: "12ch",
              maxWidth: "32ch",
              color: checked ? getDynamicColor("primary") : getDynamicColor("purple3"),
            }}
          >
            {name[0].toUpperCase() + name.slice(1)}
          </Typography>
        ) : (
          <>
            <Checkbox checked={checked} color={"primary"} sx={{ pl: 0 }} />
            <Typography
              style={{
                fontSize: "14px",
                fontFamily: "Poppins",
                fontWeight: 600,
                textOverflow: "ellipsis",
                overflow: "hidden",
                minWidth: "12ch",
                maxWidth: "32ch",
              }}
            >
              {" "}
              {name[0].toUpperCase() + name.slice(1)}
            </Typography>
          </>
        )}
      </Stack>
    </MenuItem>
  );

  const menuList = searchedFilterItems.map((item) => getMenuItem(item.isSelected, item.label));

  const getSearchBar = () => (
    <Stack sx={{ mx: 1, mt: 1 }}>
      <FormControl
        sx={{ minWidth: "16ch", backgroundColor: "white" }}
        variant="outlined"
        size="small"
        key="search"
      >
        <InputLabel htmlFor="filter-input-search" margin="dense">
          Search
        </InputLabel>
        <OutlinedInput
          id="filter-input-search"
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          // To prevent menu autofocus behavior
          onKeyDown={(event) => event.stopPropagation()}
          endAdornment={
            // search icon turns into 'X' for clearing text when
            // there is text to be cleared.
            // Search is instantaneous.
            <InputAdornment position="end">
              <IconButton aria-label="Search" onClick={() => setSearchText("")} edge="end">
                {searchText.length > 0 ? <ClearIcon /> : <SearchIcon />}
              </IconButton>
            </InputAdornment>
          }
          label="Search"
        />
      </FormControl>
    </Stack>
  );

  const handleClick = (event) => {
    event.stopPropagation();
    setTempFilterItems(filterItems);
    setIsOpen(true);
    setSearchText("");
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClearAllFilters = () => {
    const newFilterItems = filterItems.map((item) => {
      return { ...item, isSelected: false };
    });
    setSearchText("");
    setTempFilterItems(newFilterItems);
  };

  const handleApplyClicked = () => {
    setFilterItems(tempFilterItems);
    handleClose();
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        sx={{
          color: "inherit",
          maxWidth: buttonText ? "unset" : "3rem",
          minWidth: buttonText ? "unset" : "3rem",
          ...buttonSx,
        }}
        aria-label="Filter"
        aria-expanded={isOpen ? "true" : undefined}
        onClick={handleClick}
        ref={dropDownRef}
        {...buttonProps}
      >
        {buttonText ? (
          buttonText
        ) : (
          <Box style={{ width: "14px", height: "14px", marginBottom: "5px" }}>
            <FilterIcon
              stroke={
                filterItems.some((elem) => elem.isSelected)
                  ? getDynamicColor("primary")
                  : getDynamicColor("dark5")
              }
              aria-label="Filter icon"
            />
          </Box>
        )}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={dropDownRef.current}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          autoFocus: true,
          // Don't close menu on Tab, only on Escape
          autoFocusItem: true,
          variant: "menu",
          // Keep focus within menu when using Tab
          onKeyDown: (e) => {
            if (e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "Tab") {
              e.preventDefault();
              const items = Array.from(
                e.currentTarget.querySelectorAll('[role="menuitem"]'),
              ) as HTMLElement[];

              if (!items.length) return;

              // Use the event target as the starting point
              const currentIndex = items.indexOf(e.target as HTMLElement);
              let nextIndex = 0;

              if (e.key === "ArrowDown" || e.key === "Tab") {
                // If currentIndex is -1 (nothing focused) or at the end, wrap to 0; otherwise, go to the next index.
                nextIndex =
                  currentIndex === -1 || currentIndex === items.length - 1 ? 0 : currentIndex + 1;
              } else if (e.key === "ArrowUp") {
                // If currentIndex is -1 or at 0, wrap to the last; otherwise, decrement.
                nextIndex =
                  currentIndex === -1 || currentIndex === 0 ? items.length - 1 : currentIndex - 1;
              }
              items[nextIndex].focus();
            }
          },
        }}
        sx={{
          zIndex: 3001,
          borderRadius: "12px",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: "transparent",
              border: "hidden",
              boxShadow: "none",
              justifyContent: "center",
              zIndex: 1000,
            },
          },
        }}
      >
        <Stack
          minWidth="150px"
          py={2}
          pb={0}
          gap={1}
          borderRadius="12px"
          border={`1px solid ${getDynamicColor("dark3")}`}
          boxShadow={Y_SHADOWS.box_shadow_1}
          sx={{ background: getDynamicColor("light1") }}
        >
          {searchable && tempFilterItems.length >= 10 && getSearchBar()}
          <Stack sx={{ maxHeight: "min(400px, calc(100vh - 250px))", overflow: "auto" }}>
            {menuList}
          </Stack>
          {menuList.length == 0 && searchText.length > 0 && (
            <Typography sx={{ mt: 2, textAlign: "center" }}>No results found</Typography>
          )}
          {!singleSelectOnly && (
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ p: 1, borderTop: "1px solid lightgrey" }}
              gap={1}
            >
              <Button
                onClick={handleClearAllFilters}
                aria-label="Clear All Filters"
                role="menuitem"
                tabIndex={-1}
                onKeyDown={(e) => {
                  if (handleEnterOrSpaceKey(e)) {
                    handleClearAllFilters();
                  }
                }}
                sx={{
                  color: getDynamicColor("primary"),
                  fontWeight: 600,
                }}
              >
                Clear All
              </Button>
              <Button
                onClick={handleApplyClicked}
                aria-label="Apply Filters"
                role="menuitem"
                tabIndex={-1}
                onKeyDown={(e) => {
                  if (handleEnterOrSpaceKey(e)) {
                    handleApplyClicked();
                  }
                }}
                sx={{
                  backgroundColor: getDynamicColor("primary"),
                  color: getDynamicColor("light1"),
                  fontWeight: 600,
                  "&:hover": {
                    backgroundColor: getDynamicColor("primary"),
                  },
                }}
              >
                Apply
              </Button>
            </Stack>
          )}
        </Stack>
      </Menu>
    </>
  );
};
