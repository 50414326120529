import React from "react";

// Components
import { Box, Stack, Typography } from "@mui/material";
import GamesCatalog from "components/Games/GamesCatalog/GamesCatalog";
import { LearningMaterialsTab } from "components/Orgs/ManageContent/VideosAndCourses/LearningMaterialsTab";
import { OrgLoading } from "components/Orgs/OrgLoading";
import { ADMIN_HEADER_HEIGHT, defaultHeaderSx } from "lib-frontend/ui/Theme";

// Utils
import { MemberProgramInfo } from "./Programs/MemberProgramInfo";
import { MemberProgramLibrary } from "./Programs/MemberProgramLibrary";
import { OrgProgramsQueryKeys } from "./Programs/OrgPrograms";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { useQueryParamState } from "hooks/useQueryParamState";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  getOrgContentMemberViewV2,
  listProgramsMemberView,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { MyLearningQueryParams } from "lib-fullstack/utils/queryParams";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { UITestId } from "lib-fullstack/utils/enums";
import YoodliHeaderTabs from "lib-frontend/components/YoodliComponents/YoodliHeaderTabs";

export const VIDEOS_AND_COURSES_MEMBERS_QUERY_KEY = "videosAndCourses";

export enum MyLearningTabs {
  Programs = "Programs",
  Courses = "Courses",
  Exercises = "Exercises",
}

export const MyLearningTabLabels = {
  [MyLearningTabs.Programs]: "Programs",
  [MyLearningTabs.Courses]: "Courses",
  [MyLearningTabs.Exercises]: "Exercises",
};

export enum MyLearningSection {
  Default = "Default",
  ProgramInfo = "ProgramInfo", // NOTE (2024-08-19): This is used as a query param for CIO email template CTA (see sendReminder in programHandlers.ts)
}

export default function MyLearning(): JSX.Element {
  const { loading: orgContextLoading, defaultOrgId } = React.useContext(UserOrgContext);

  const [activeTab, setActiveTab] = useQueryParamState(MyLearningQueryParams.TAB, "");
  const [section, setSection] = useQueryParamState(
    MyLearningQueryParams.SECTION,
    MyLearningSection.Default,
  );
  const [programId, setProgramId] = useQueryParamState(MyLearningQueryParams.PROGRAM_ID, undefined);

  React.useEffect(() => {
    if (!programId && section === MyLearningSection.ProgramInfo) {
      setSection(MyLearningSection.Default);
    }
  }, [programId]);

  React.useEffect(() => {
    if (defaultOrgId) {
      Instrumentation.logLearningPageViewed(defaultOrgId);
    } else if ((!orgContextLoading || isToastmasters()) && !defaultOrgId) {
      setActiveTab(MyLearningTabs.Exercises);
    }
  }, [defaultOrgId, orgContextLoading]);

  const videosAndCoursesQueryResult = useApiQuery({
    queryKey: [VIDEOS_AND_COURSES_MEMBERS_QUERY_KEY, defaultOrgId],
    queryFn: async () => getOrgContentMemberViewV2(defaultOrgId),
    enabled: !!defaultOrgId,
  });
  const videosAndCourses = videosAndCoursesQueryResult.data;

  const programMemberViewQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.MemberView, defaultOrgId],
    queryFn: () => listProgramsMemberView(),
    enabled: !!defaultOrgId,
  });

  // Filter tabs based on available data
  const tabsToRender = React.useMemo(
    () =>
      [
        // Only show Programs tab if there are programs
        programMemberViewQuery.data?.length > 0 ? MyLearningTabs.Programs : null,
        // Only show Courses tab if there are videos or courses
        videosAndCourses?.demo_videos?.length > 0 || videosAndCourses?.courses?.length > 0
          ? MyLearningTabs.Courses
          : null,
        // Always show Exercises tab
        MyLearningTabs.Exercises,
      ].filter(Boolean) as MyLearningTabs[],
    [programMemberViewQuery.data, videosAndCourses],
  );

  React.useEffect(() => {
    if (
      !videosAndCoursesQueryResult.isLoading &&
      !programMemberViewQuery.isLoading &&
      !activeTab &&
      tabsToRender.length > 0
    ) {
      setActiveTab(tabsToRender[0]);
    }
  }, [
    tabsToRender,
    activeTab,
    videosAndCoursesQueryResult.isLoading,
    programMemberViewQuery.isLoading,
  ]);

  const renderTab = () => {
    switch (activeTab) {
      case MyLearningTabs.Programs:
        return (
          <MemberProgramLibrary
            programs={programMemberViewQuery.data}
            handleViewProgram={(programId) => {
              setSection(MyLearningSection.ProgramInfo);
              setProgramId(programId);
            }}
          />
        );
      case MyLearningTabs.Courses:
        return (
          <LearningMaterialsTab
            loading={videosAndCoursesQueryResult.isLoading}
            contentView={videosAndCourses}
            selectedOrgId={defaultOrgId}
            // This variable seems to have been just mysteriously not set but...compiled somehow??
            isHubAdmin={false}
          />
        );
      case MyLearningTabs.Exercises:
        return <GamesCatalog />;
    }
  };

  if (
    (!isToastmasters() && orgContextLoading) ||
    videosAndCoursesQueryResult.isLoading ||
    programMemberViewQuery.isLoading
  ) {
    return <OrgLoading prompts={["Loading resources..."]} />;
  }

  switch (section) {
    case MyLearningSection.ProgramInfo:
      return (
        <MemberProgramInfo
          program={programMemberViewQuery.data.find((program) => program.id === programId)}
          handleBack={() => {
            setSection(MyLearningSection.Default);
            setProgramId(undefined);
          }}
        />
      );
    case MyLearningSection.Default:
    default:
      return (
        <Stack sx={{ background: getDynamicColor("light1"), minHeight: "100dvh" }}>
          <Stack
            sx={{
              ...defaultHeaderSx,
              background: getDynamicColor("gradient.slantedMedium"),
            }}
          >
            <Box
              sx={{
                height: ADMIN_HEADER_HEIGHT,
                display: "flex",
                px: { xs: 2, md: 6 },
                alignItems: "center",
              }}
            >
              <Typography
                data-testid={UITestId.MyLearningTitle}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 20,
                  fontWeight: 600,
                  color: getDynamicColor("purple3"),
                }}
              >
                My Learning
              </Typography>
            </Box>
            {!!defaultOrgId && (
              <Box data-testid={UITestId.MyLearningTabs}>
                <YoodliHeaderTabs
                  selectedTab={activeTab}
                  tabLabels={MyLearningTabLabels}
                  handleTabChange={setActiveTab}
                  allTabs={tabsToRender}
                  containerSx={{
                    background: getDynamicColor("gradient.slantedMedium"),
                    color: getDynamicColor("purple3"),
                    px: { xs: 2, md: 6 },
                  }}
                  color={getDynamicColor("purple3")}
                />
              </Box>
            )}
          </Stack>
          {renderTab()}
        </Stack>
      );
  }
}
