import React from "react";

// Components
import { Stack } from "@mui/material";
import {
  CUSTOM_GOAL_NAME_MAX_CHARS,
  CUSTOM_GOAL_USER_DESC_MAX_CHARS,
} from "components/ConvoScenarios/convoScenarioUtils";
import { WYSIWYGEditor } from "components/WYSIWYGEditor";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { ConvoScenarioStepWrapper } from "./ConvoScenarioStepWrapper";
import { useWYSIWYG } from "hooks/useWYSIWYG";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { CreateScoreGoalRequest } from "lib-fullstack/api/scenarioApiTypes";
import { ScoreGoal } from "lib-fullstack/db";

export type ScoreGoalDescriptionStepProps = {
  scoreGoal: CreateScoreGoalRequest;
  setScoreGoal: React.Dispatch<React.SetStateAction<CreateScoreGoalRequest>>;
  title: string;
};

export const ScoreGoalDescriptionStep = ({
  scoreGoal,
  setScoreGoal,
  title,
}: ScoreGoalDescriptionStepProps): JSX.Element => {
  const handleUpdateScoreGoal = (key: keyof ScoreGoal, val: string | number) => {
    setScoreGoal((prev) => ({ ...prev, [key]: val }));
  };

  const { editor } = useWYSIWYG({
    content: scoreGoal.userDescription ?? "",
    placeholder: "e.g. Ask for constructive feedback during your conversation.",
    characterLimit: CUSTOM_GOAL_USER_DESC_MAX_CHARS,
    onUpdate: (update: string) => {
      handleUpdateScoreGoal("userDescription", update);
    },
  });

  React.useEffect(() => {
    if (scoreGoal?.userDescription !== editor?.getHTML()) {
      editor?.commands.setContent(scoreGoal?.userDescription);
    }
  }, [scoreGoal?.userDescription]);

  return (
    <ConvoScenarioStepWrapper title={title} subTitle="Name this goal and add a short description">
      <Stack
        gap={6}
        sx={{
          position: "relative",
        }}
      >
        <YoodliLabeledInput
          label="Name"
          labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
          inputEl={
            <YoodliTextfield
              autoFocus
              maxChars={CUSTOM_GOAL_NAME_MAX_CHARS}
              inputProps={{
                className: "blockEnterToNavigate",
              }}
              InputLabelProps={{ shrink: true }}
              placeholder="e.g. Seek feedback"
              value={scoreGoal.name}
              onChange={(e) => handleUpdateScoreGoal("name", e.target.value)}
            />
          }
        />
        <YoodliLabeledInput
          label="Add a member-facing description (optional)"
          labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
          inputEl={
            <WYSIWYGEditor
              editor={editor}
              excludeHeaderStyles
              excludeNumberedList
              excludeBulletedList
              characterLimit={CUSTOM_GOAL_USER_DESC_MAX_CHARS}
            />
          }
        />
      </Stack>
    </ConvoScenarioStepWrapper>
  );
};
