import React from "react";

// Components
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Stack, Box, Button } from "@mui/material";
import { CSVUpload } from "components/Orgs/BulkInviteByCSVWizard/CSVUpload";
import { RemoveImportComplete } from "components/Orgs/BulkInviteByCSVWizard/RemoveImportComplete";
import { Wizard } from "components/Wizard/Wizard";
import { WizardStep } from "components/Wizard/WizardTypes";
import { defaultHeaderSx } from "lib-frontend/ui/Theme";

// Utils
import { OrgMembersQueryKeys } from "../Members/OrgMembers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { useNavDrawerOffset, usePrevious } from "lib-frontend/hooks";
import {
  removeHubUsersAndInvitesByEmail,
  removeOrgUsersAndInvitesByEmail,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { HubV2Response } from "lib-fullstack/api/orgApiTypes";
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";
import { EffectiveRole, HubRole, OrgRole } from "lib-fullstack/utils/enums";
import { WIZARD_FOOTER_HEIGHT } from "utils/Constants";
import { OrgHubsQueryKeys } from "../Hubs/OrgHubs";
import { YoodliNotificationOptions } from "lib-frontend/contexts/NotificationContext";
import { YoodliNotificationBannerVariant } from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";

export const BULK_INVITE_ROLE_OPTIONS = [
  { value: HubRole.MEMBER, label: "Group member" },
  { value: HubRole.ADMIN, label: "Group admin" },
  { value: OrgRole.ADMIN, label: "Org admin" },
  { value: EffectiveRole.SPACE_ADMIN, label: "Content space admin" },
];

export enum BulkInviteRoleOptionEnum {
  HUB_MEMBER = HubRole.MEMBER,
  HUB_ADMIN = HubRole.ADMIN,
  ORG_ADMIN = OrgRole.ADMIN,
  CONTENT_SPACE_ADMIN = EffectiveRole.SPACE_ADMIN,
}

export type BulkInviteRoleOption = (typeof BULK_INVITE_ROLE_OPTIONS)[number];

type BulkRemoveByCSVWizardProps = {
  handleBack: () => void;
  handleShowNotificationBanner: (notificationData: {
    message: string | JSX.Element;
    options: YoodliNotificationOptions;
  }) => void;
  currentHub?: HubV2Response;
};

enum BulkInviteStepId {
  UPLOAD = "UPLOAD",
  IMPORT_COMPLETE = "IMPORT_COMPLETE",
  SELECT_ROLE = "SELECT_ROLE",
  SELECT_HUB = "SELECT_HUB",
  SEND_INVITES = "SEND_INVITES",
  INVITES_SENT = "INVITES_SENT",
}

export const BulkRemoveByCSVWizard = ({
  handleBack,
  handleShowNotificationBanner,
  currentHub,
}: BulkRemoveByCSVWizardProps): JSX.Element => {
  const {
    invalidateDefaultOrgQuery,
    defaultOrgId,
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);
  const { modalStyles, navDrawerOffset } = useNavDrawerOffset();
  const queryClient = useQueryClient();

  // State
  const [error, setError] = React.useState<string | null>(null);
  const [currStepIndex, setCurrStepIndex] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [fallbackHubId, setFallbackHubId] = React.useState<string>(
    currentHub?.id === defaultOrg.default_hub_id
      ? defaultOrg.hubs?.[0]?.id
      : defaultOrg.default_hub_id,
  );

  const [emails, setEmails] = React.useState<string[]>([]);

  const prevEmails = usePrevious(emails);
  React.useEffect(() => {
    if (!error) {
      // if i go from having emails to not, return to first slide to upload more emails
      if (prevEmails?.length && !emails?.length) {
        setCurrStepIndex(0);
      }
    }
  }, [emails, error]);

  const prevCurrStepIndex = usePrevious(currStepIndex);
  React.useEffect(() => {
    const shouldSkip = BULK_CSV_INVITE_STEPS[currStepIndex]?.shouldSkipStep?.();
    if (shouldSkip) {
      if (prevCurrStepIndex < currStepIndex) {
        setCurrStepIndex((prev) => prev + 1);
      } else if (prevCurrStepIndex > currStepIndex) {
        setCurrStepIndex((prev) => prev - 1);
      }
    }
  }, [currStepIndex]);

  const handleRemoveEmail = (email: string) => {
    setEmails((prevEmails) => prevEmails.filter((e) => e !== email));
  };

  const removeOrgUsersAndInvitesMutation = useMutation({
    mutationFn: async () => {
      // this is a group-level removal operation
      if (currentHub) {
        return await removeHubUsersAndInvitesByEmail(defaultOrgId, currentHub.id, {
          user_emails: emails,
          fallback_hub_id: fallbackHubId,
        });
      } else {
        return await removeOrgUsersAndInvitesByEmail(defaultOrgId, {
          user_emails: emails,
        });
      }
    },
    onSuccess: async (successCount) => {
      void invalidateDefaultOrgQuery();
      void queryClient.invalidateQueries({
        queryKey: [
          currentHub ? OrgMembersQueryKeys.HubUserCounts : OrgMembersQueryKeys.OrgUserCounts,
          defaultOrgId,
        ],
      });
      if (currentHub) {
        void queryClient.invalidateQueries({
          queryKey: [OrgHubsQueryKeys.HUB_USERS, defaultOrgId, currentHub.id],
        });
        void queryClient.invalidateQueries({
          queryKey: [OrgHubsQueryKeys.HUB_INVITES, defaultOrgId, currentHub.id],
        });
      } else {
        void queryClient.invalidateQueries({
          queryKey: [OrgMembersQueryKeys.OrgMembers, defaultOrgId],
        });
        void queryClient.invalidateQueries({
          queryKey: [OrgMembersQueryKeys.OrgInvites, defaultOrgId],
        });
      }
      handleBack();
      handleShowNotificationBanner({
        message: `${successCount} members have been removed ${currentHub ? "from this group" : "and their seats have been released"}`,
        options: { variant: YoodliNotificationBannerVariant.Success },
      });
    },
    onError: () => {
      setError("Something went wrong. Hit ‘Send’ to retry.");
    },
  });

  const BULK_CSV_INVITE_STEPS: Array<WizardStep> = React.useMemo(() => {
    return [
      {
        label: "Upload",
        id: BulkInviteStepId.UPLOAD,
        component: (
          <CSVUpload
            subtitleOverride={`Add a CSV file with the email addresses of all the members you would like to remove${currentHub ? " from this group" : ""}. Download a sample CSV file below to get started.`}
            onNext={() => {
              setCurrStepIndex(1);
            }}
            setEmails={setEmails}
            error={error}
            setError={setError}
          />
        ),
        validate: () => emails?.length > 0 && !error,
        enterToNavigate: true,
      },
      {
        label: "Import Complete",
        id: BulkInviteStepId.IMPORT_COMPLETE,
        component: (
          <RemoveImportComplete
            emails={emails}
            handleRemoveEmail={handleRemoveEmail}
            currentHub={currentHub}
            fallbackHubId={fallbackHubId}
            setFallbackHubId={setFallbackHubId}
          />
        ),
        validate: () => !error && (currentHub ? !!fallbackHubId : true),
        next: async () => {
          setError(null);
          await removeOrgUsersAndInvitesMutation.mutateAsync();
        },
        nextHelperText: `${emails.length} ${
          currentHub
            ? "members will be removed from this group"
            : "organization seats will be released"
        }`,
        nextCopy: "Remove members",
        nextButtonProps: {
          sx: {
            backgroundColor: getDynamicColor("redError"),
            "&:hover": { backgroundColor: getDynamicColor("redErrorDark") },
          },
        },
      },
    ].map((x, i) => ({ ...x, stepIdx: i, hidePrev: i === 0 }));
  }, [currStepIndex, currentHub, defaultOrgId, emails, error, fallbackHubId]);

  return (
    <Stack
      sx={{
        background: getDynamicColor("light1"),
        height: {
          xs: `calc(100vh - ${WEBCLIENT_TOP_NAVBAR_HEIGHT})`,
          md: `calc(100vh - ${WIZARD_FOOTER_HEIGHT})`,
        },
        position: "relative",
      }}
    >
      <Box
        sx={{
          ...defaultHeaderSx,
          display: "flex",
          alignItems: "center",
          pl: { xs: 0, md: 4 },
          position: "sticky",
          top: 0,
          left: 0,
          right: 0,
        }}
      >
        <Button
          variant="text"
          sx={{
            color: getDynamicColor("light1"),
          }}
          startIcon={<ChevronLeftIcon />}
          onClick={handleBack}
        >
          Members
        </Button>
      </Box>
      <Wizard
        routeLeavingGuardUIProps={{
          title: "Exit without sending",
          body: "Are you sure you want to leave this page? Your invites have not been sent yet!",
          when: emails?.length > 0 && currStepIndex < BULK_CSV_INVITE_STEPS.length - 1,
        }}
        controlsSx={{
          left: navDrawerOffset,
          transition: modalStyles.transition,
        }}
        containerSx={{
          py: { xs: 3, md: 8 },
          height: "100%",
        }}
        steps={BULK_CSV_INVITE_STEPS}
        currStepIndex={currStepIndex}
        setCurrStepIndex={setCurrStepIndex}
        loading={loading}
        setLoading={setLoading}
      />
    </Stack>
  );
};
