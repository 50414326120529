import React from "react";

// Components
import { Typography } from "@mui/material";
import { CreateConvoScenarioStepId, GoalHooks } from "components/ConvoScenarios/convoScenarioUtils";
import { WizardStep } from "components/Wizard/WizardTypes";

// Utils
import { ScoreGoalDescriptionStep } from "./ScoreGoalDescriptionStep";
import { ScoreGoalEvaluationStep } from "./ScoreGoalEvaluationStep";
import {
  CreateCustomGoalRequest,
  CreateScoreGoalRequest,
} from "lib-fullstack/api/scenarioApiTypes";

export const CreateRatedGoalSteps = (
  ratedGoal: CreateScoreGoalRequest,
  setRatedGoal: React.Dispatch<React.SetStateAction<CreateScoreGoalRequest>>,
  createCustomGoal: (customGoal: CreateCustomGoalRequest) => Promise<string>,
  goalEditId: string,
  setDiscardGoalModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  goalHooks: GoalHooks,
  closeWizard: () => void,
  inScenarioCreation?: boolean,
): WizardStep[] => {
  const title = goalEditId ? "Update Rated Goal" : "Create a Rated Goal";
  const descStep = {
    id: CreateConvoScenarioStepId.GOALS,
    label: inScenarioCreation ? "Customize" : "Start",
    component: (
      <ScoreGoalDescriptionStep scoreGoal={ratedGoal} setScoreGoal={setRatedGoal} title={title} />
    ),
    validate: () => ratedGoal.name.length > 0,
    prev: () => {
      setDiscardGoalModalOpen(true);
    },
    skipPrevIndexUpdate: inScenarioCreation,
    prevCopy: "Discard",
    enterToNavigate: true,
    showProgressTracker: true,
    ignoreStepInProgressTracker: inScenarioCreation,
  } as WizardStep;

  const evaluationStep = {
    id: CreateConvoScenarioStepId.GOALS,
    label: "Customize",
    component: (
      <ScoreGoalEvaluationStep
        scoreGoal={ratedGoal}
        setScoreGoal={setRatedGoal}
        goalHooks={goalHooks}
        title={title}
        subTitle={
          <>
            <Typography component="div">
              Explain the goal to the AI, then set and define the scoring scale.
            </Typography>
            <Typography component="div">All fields are required.</Typography>
          </>
        }
      />
    ),
    validate: () =>
      ratedGoal.aiDescription.length > 0 &&
      ratedGoal.highScoreDescription.length > 0 &&
      ratedGoal.lowScoreDescription.length > 0 &&
      ratedGoal.maxScore > 1,
    skipNextIndexUpdate: true,
    next: async () => {
      await createCustomGoal(ratedGoal);
      if (!inScenarioCreation) {
        closeWizard();
      }
    },
    nextCopy: "Save",
    enterToNavigate: true,
    showProgressTracker: true,
    ignoreStepInProgressTracker: inScenarioCreation,
  } as WizardStep;
  return [descStep, evaluationStep];
};
