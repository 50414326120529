// Components
import { Button, Typography } from "@mui/material";
import { CreateConvoScenarioStepId, GoalHooks } from "components/ConvoScenarios/convoScenarioUtils";
import { WizardStep } from "components/Wizard/WizardTypes";

// Utils
import { KnowledgeBasedGoalDescriptionStep } from "./KnowledgeBasedGoalDescriptionStep";
import { ScoreGoalEvaluationStep } from "./ScoreGoalEvaluationStep";
import { ContentUploadResponse } from "hooks/useContentUpload";
import {
  CreateCustomGoalRequest,
  CreateScoreGoalRequest,
} from "lib-fullstack/api/scenarioApiTypes";
import { GoalDocument } from "lib-fullstack/db";
import { OrgFileState } from "lib-fullstack/utils/enums";

const MAX_FILES = 5;

export const CreateKnowledgeBasedGoalSteps = (
  knowledgeBasedGoal: CreateScoreGoalRequest,
  setKnowledgeBasedGoal: React.Dispatch<React.SetStateAction<CreateScoreGoalRequest>>,
  goalEditId: string,
  createCustomGoal: (customGoal: CreateCustomGoalRequest) => Promise<string>,
  goalHooks: GoalHooks,
  closeWizard: () => void,
  setDiscardGoalModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  contentUploadResponse: ContentUploadResponse,
  handleAddNewContent: (filesIds: string[]) => void,
  inScenarioCreation?: boolean,
): WizardStep[] => {
  const getTitle = () => {
    const prefix = goalEditId ? "Update" : "Create";
    let documentType = "";
    if (knowledgeBasedGoal.documentBasisType === GoalDocument.ObjectionHandling) {
      documentType = "an objection handling goal";
    } else if (knowledgeBasedGoal.documentBasisType === GoalDocument.TechnicalSpecification) {
      documentType = "a technical specification goal";
    } else {
      documentType = "a knowledge-based goal";
    }
    return `${prefix} ${documentType}`;
  };

  const getSubTitle = () => {
    switch (knowledgeBasedGoal.documentBasisType) {
      case GoalDocument.ObjectionHandling:
        return `Add up to ${MAX_FILES} documents providing common objections and expected responses for a sales representative to adhere to`;
      case GoalDocument.TechnicalSpecification:
        return `Add up to ${MAX_FILES} documents that provide guidance on a specific list of features or technical details`;
      case GoalDocument.Generic:
      default:
        return (
          <>
            <Typography component="div">
              Explain the goal to the AI, then set and define the scoring scale.
            </Typography>
            <Typography component="div">All fields are required.</Typography>
          </>
        );
    }
  };

  const descStep = {
    id: CreateConvoScenarioStepId.GOALS,
    label: inScenarioCreation ? "Customize" : "Start",
    component: (
      <KnowledgeBasedGoalDescriptionStep
        knowledgeBasedGoal={knowledgeBasedGoal}
        setKnowledgeBasedGoal={setKnowledgeBasedGoal}
        title={getTitle()}
      />
    ),
    validate: () => knowledgeBasedGoal.documentBasisType && knowledgeBasedGoal.name.length > 0,
    prev: () => {
      setDiscardGoalModalOpen(true);
    },
    skipPrevIndexUpdate: inScenarioCreation,
    prevCopy: "Discard",
    enterToNavigate: true,
    showProgressTracker: true,
    ignoreStepInProgressTracker: inScenarioCreation,
    nextHelperText: !goalEditId &&
      !!knowledgeBasedGoal.documentBasisType &&
      !inScenarioCreation && (
        <Button
          onClick={() =>
            setKnowledgeBasedGoal({ ...knowledgeBasedGoal, documentBasisType: undefined })
          }
          sx={{ mr: "auto", textAlign: "center", ml: 1.5 }}
        >
          Back
        </Button>
      ), // NOTE: This is a hack to get the back button to work (the goal wizard is not very equipped to add a back button on the first step)
  } as WizardStep;

  const evaluationStep = {
    id: CreateConvoScenarioStepId.GOALS,
    label: "Customize",
    component: (
      <ScoreGoalEvaluationStep
        scoreGoal={knowledgeBasedGoal}
        setScoreGoal={setKnowledgeBasedGoal}
        goalHooks={goalHooks}
        title={getTitle()}
        subTitle={getSubTitle()}
        uploadingFiles={contentUploadResponse.uploadingFiles}
        handleUploadContent={contentUploadResponse.handleUploadContent}
        handleCancelFileUpload={contentUploadResponse.handleCancelFileUpload}
        handleClearUploadedFiles={contentUploadResponse.handleClearUploadedFiles}
        handleAddNewContent={handleAddNewContent}
        maxFiles={MAX_FILES}
      />
    ),
    validate: () =>
      knowledgeBasedGoal.aiDescription.length > 0 &&
      knowledgeBasedGoal.highScoreDescription.length > 0 &&
      knowledgeBasedGoal.lowScoreDescription.length > 0 &&
      knowledgeBasedGoal.maxScore > 1 &&
      knowledgeBasedGoal?.documentBasisIds.length > 0 &&
      contentUploadResponse.uploadingFiles.every((file) => file.state !== OrgFileState.Uploading),
    skipNextIndexUpdate: true,
    next: async () => {
      await createCustomGoal(knowledgeBasedGoal);
      if (!inScenarioCreation) {
        closeWizard();
      }
    },
    nextCopy: "Save",
    enterToNavigate: true,
    showProgressTracker: true,
    ignoreStepInProgressTracker: inScenarioCreation,
  } as WizardStep;
  return [descStep, evaluationStep];
};
