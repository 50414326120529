import React from "react";

// Components
import {
  CustomizePracticeQueryKey,
  GoalHooks,
  binaryGoalRequestBase,
  compoundGoalRequestBase,
  knowledgeBasedGoalRequestBase,
  ratedGoalRequestBase,
} from "components/ConvoScenarios/convoScenarioUtils";
import { Wizard } from "components/Wizard/Wizard";
import { WizardStep } from "components/Wizard/WizardTypes";

// Utils
import { CreateBinaryGoalSteps } from "../CustomizePractice/Scenarios/CreateConvoScenarioWizard/CreateBinaryGoalSteps";
import { CreateCompoundGoalSteps } from "../CustomizePractice/Scenarios/CreateConvoScenarioWizard/CreateCompoundGoalSteps";
import { CreateKnowledgeBasedGoalSteps } from "../CustomizePractice/Scenarios/CreateConvoScenarioWizard/CreateKnowledgeBasedGoalSteps";
import { CreateRatedGoalSteps } from "../CustomizePractice/Scenarios/CreateConvoScenarioWizard/CreateRatedGoalSteps";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContentUpload } from "hooks/useContentUpload";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { createCustomGoal, updateCustomGoal } from "lib-frontend/modules/AxiosInstance";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import {
  BinaryGoalResponse,
  CompoundGoalResponse,
  CreateBinaryGoalRequest,
  CreateCompoundGoalRequest,
  CreateNewCustomGoalRequest,
  CreateScoreGoalRequest,
  CustomGoalResponse,
  ScoreGoalResponse,
} from "lib-fullstack/api/scenarioApiTypes";
import { CreateContentRequestKind, GoalKind } from "lib-fullstack/db";

export const FOOTER_HEIGHT = 86;

export const HEADER_HEIGHT_SX = { xs: 116, md: 132 };
type CustomGoalWizardProps = {
  selectedGoal?: CustomGoalResponse;
  setSelectedGoal?: React.Dispatch<React.SetStateAction<CustomGoalResponse>>;
  closeWizard: () => void;
};

export const CustomGoalWizard = ({
  selectedGoal,
  setSelectedGoal,
  closeWizard,
}: CustomGoalWizardProps): JSX.Element => {
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);
  const queryClient = useQueryClient();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [currStepIndex, setCurrStepIndex] = React.useState<number>(0);
  const { navDrawerOffset, modalStyles } = useNavDrawerOffset();
  const [_, setDiscardGoalModalOpen] = React.useState<boolean>(false);

  const [binaryGoal, setBinaryGoal] =
    React.useState<CreateBinaryGoalRequest>(binaryGoalRequestBase);
  const [ratedGoal, setRatedGoal] = React.useState<CreateScoreGoalRequest>(ratedGoalRequestBase);
  const [compoundGoal, setCompoundGoal] =
    React.useState<CreateCompoundGoalRequest>(compoundGoalRequestBase);
  const [knowledgeBasedGoal, setKnowledgeBasedGoal] = React.useState<CreateScoreGoalRequest>(
    knowledgeBasedGoalRequestBase,
  );

  const [goalDescriptionLoading, setGoalDescriptionLoading] = React.useState(false);
  const [goalHighScoreLoading, setGoalHighScoreLoading] = React.useState(false);
  const [goalLowScoreLoading, setGoalLowScoreLoading] = React.useState(false);

  const handleAddNewContent = (filesIds: string[]) => {
    const updatedDocumentBasisIds = [...knowledgeBasedGoal.documentBasisIds, ...filesIds];
    setKnowledgeBasedGoal((prev) => ({ ...prev, documentBasisIds: updatedDocumentBasisIds }));
  };
  const uploadContentResponse = useContentUpload({ handleAddNewContent });

  React.useEffect(() => {
    setCustomGoalState(selectedGoal.goalKind, selectedGoal);
  }, []);

  const goalHooks: GoalHooks = {
    goalDescriptionLoading,
    setGoalDescriptionLoading,
    goalHighScoreLoading,
    setGoalHighScoreLoading,
    goalLowScoreLoading,
    setGoalLowScoreLoading,
  };

  // Mutations (for custom goals)
  const { mutateAsync: handleCustomGoalCreation } = useMutation({
    mutationFn: async (goal: CreateNewCustomGoalRequest) => {
      if (selectedGoal?.id) {
        const updatedGoalId = await updateCustomGoal(defaultOrgId, selectedGoal?.id, goal);
        return updatedGoalId;
      }
      goal.spaceId = curSpaceId;
      const createdGoalId = await createCustomGoal(defaultOrgId, goal);
      return createdGoalId;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CustomizePracticeQueryKey.CustomGoals, defaultOrgId],
      });
      if (selectedGoal?.id) {
        Instrumentation.logOrgCustomGoalUpdated(defaultOrgId);
      } else {
        Instrumentation.logOrgCustomGoalCreated(defaultOrgId);
      }
    },
    onError: (error) => {
      console.error("Error creating/updating custom goal: ", error);
    },
  });

  // Either set current state to selected goal to update or reset custom goal state to base state
  const setCustomGoalState = (goalKind: GoalKind, goalToUpdate?: CustomGoalResponse) => {
    switch (goalKind) {
      case GoalKind.BinaryGoal: {
        const goalRequest: CreateBinaryGoalRequest = goalToUpdate
          ? {
              ...(goalToUpdate as BinaryGoalResponse),
              spaceId: curSpaceId,
              requestType: CreateContentRequestKind.New,
            }
          : { ...binaryGoalRequestBase };
        setBinaryGoal(goalRequest);
        break;
      }
      case GoalKind.ScoreGoal: {
        if (!goalToUpdate && localStorage.getItem("customGoalMaxScore")) {
          ratedGoalRequestBase.maxScore = Number(localStorage.getItem("customGoalMaxScore"));
        }
        const goalRequest: CreateScoreGoalRequest = goalToUpdate
          ? {
              ...(goalToUpdate as ScoreGoalResponse),
              spaceId: curSpaceId,
              requestType: CreateContentRequestKind.New,
            }
          : { ...ratedGoalRequestBase };
        setRatedGoal(goalRequest);
        setKnowledgeBasedGoal(goalRequest);
        break;
      }
      case GoalKind.CompoundGoal: {
        if (!goalToUpdate && localStorage.getItem("customGoalMaxScore")) {
          compoundGoalRequestBase.maxScore = Number(localStorage.getItem("customGoalMaxScore"));
        }
        const goalRequest: CreateCompoundGoalRequest = goalToUpdate
          ? {
              ...(goalToUpdate as CompoundGoalResponse),
              spaceId: curSpaceId,
              requestType: CreateContentRequestKind.New,
            }
          : { ...compoundGoalRequestBase };
        setCompoundGoal(goalRequest);
        break;
      }
    }
  };

  const customGoalSteps = () => {
    switch (selectedGoal.goalKind) {
      case GoalKind.BinaryGoal:
        return CreateBinaryGoalSteps(
          binaryGoal,
          setBinaryGoal,
          handleCustomGoalCreation,
          selectedGoal?.id,
          setDiscardGoalModalOpen,
          goalHooks,
          closeWizard,
          false,
        );
      case GoalKind.ScoreGoal:
        if (selectedGoal.documentBasisIds) {
          return CreateKnowledgeBasedGoalSteps(
            knowledgeBasedGoal,
            setKnowledgeBasedGoal,
            selectedGoal?.id,
            handleCustomGoalCreation,
            goalHooks,
            closeWizard,
            setDiscardGoalModalOpen,
            uploadContentResponse,
            handleAddNewContent,
            false,
          );
        } else {
          return CreateRatedGoalSteps(
            ratedGoal,
            setRatedGoal,
            handleCustomGoalCreation,
            selectedGoal?.id,
            setDiscardGoalModalOpen,
            goalHooks,
            closeWizard,
            false,
          );
        }
      case GoalKind.CompoundGoal:
        return CreateCompoundGoalSteps(
          compoundGoal,
          setCompoundGoal,
          handleCustomGoalCreation,
          selectedGoal?.id,
          setDiscardGoalModalOpen,
          goalHooks,
          closeWizard,
          false,
        );
    }
  };

  const CUSTOM_GOAL_STEPS: Array<WizardStep> = [...customGoalSteps()]
    .filter(Boolean)
    .map((x, i) => ({ ...x, stepIdx: i, hidePrev: x.hidePrev === true || i === 0 }));

  return (
    <Wizard
      controlsSx={{
        left: navDrawerOffset,
        transition: modalStyles.transition,
      }}
      steps={CUSTOM_GOAL_STEPS}
      routeLeavingGuardUIProps={{
        title: "Exit custom goal creation",
        body: "Any progress you have made so far will not be saved. Are you sure you want to exit?",
        // When should shouldBlockNavigation be invoked, simply passing a boolean
        // (same as "when" prop of Prompt of React-Router)
        when: currStepIndex !== CUSTOM_GOAL_STEPS.length - 1,
        onNavigate: () => {
          setSelectedGoal(undefined);
        },
      }}
      loading={loading}
      setLoading={setLoading}
      currStepIndex={currStepIndex}
      setCurrStepIndex={setCurrStepIndex}
    />
  );
};
