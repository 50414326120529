import moment from "moment-timezone";
import React from "react";

// Components
import {
  Button,
  Grid,
  Stack,
  Typography,
  Select,
  MenuItem,
  Box,
  CircularProgress,
} from "@mui/material";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { USER_NAME_MAX_CHAR_LENGTH } from "lib-frontend/utils/AccountUtils";
import {
  currentUserEmail,
  currentUserName,
  updateCurrentUserName,
} from "lib-frontend/utils/AccountUtils";
import { handleDoLogout } from "lib-frontend/utils/AccountUtils";
import { getLiveUserDocMain, updateThisUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { useValidateDisplayString } from "auth/utils/validator";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";

export const AccountInfo = (): JSX.Element => {
  const userDocMain = getLiveUserDocMain();
  const tz = userDocMain?.homeTimezone;

  const { userInOrg, defaultOrgId, logOutEverywhereEnforced } = React.useContext(UserOrgContext);
  const [userName, setUserName] = React.useState<string>(currentUserName());
  const [editableUserName, setEditableUserName] = React.useState<string>(userName);
  const [isEditingUserName, setIsEditingUserName] = React.useState<boolean>(false);
  const [homeTimezone, setHomeTimezone] = React.useState<string>(tz ?? moment.tz.guess());
  const [tzChanging, setTzChanging] = React.useState<boolean>(false);
  const validateNameResult = useValidateDisplayString(editableUserName);

  const [timezones, setTimezones] = React.useState<string[]>([]);
  React.useEffect(() => {
    const getTimezones = async () => {
      const tzList = await moment.tz.names();
      setTimezones(tzList);
    };
    getTimezones().catch(console.error);
  }, []);

  const handleSaveUserNameClicked = () => {
    setUserName(editableUserName);
    updateCurrentUserName(editableUserName)
      .catch((er) => console.error(`Error updating display name: ${er}`))
      .finally(() => {
        // Set new userName or revert back to old name on error
        const newUserName = currentUserName();
        setUserName(newUserName);
        setEditableUserName(newUserName);
      });
    setIsEditingUserName(false);
  };

  const handleEditUserNameClicked = () => {
    // Allow edit textField
    setIsEditingUserName(true);
  };

  const handleUpdateTimezone = (e) => {
    try {
      const homeTimezone = e.target.value as string;
      setTzChanging(true);
      updateThisUserDocMain({
        homeTimezone,
      })
        .then(() => {
          setTzChanging(false);
          setHomeTimezone(homeTimezone);
        })
        .catch((er) => console.error(er));
    } catch (er) {
      setTzChanging(false);
      console.log("Error updating home timezone", er);
    }
  };

  return (
    <Stack style={{ zIndex: 2 }} gap={2}>
      <Typography variant="h2" sx={{ fontWeight: 700, fontFamily: "Poppins" }}>
        Account
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        gap={1.5}
        sx={{ height: "20px", mt: { xs: 3, md: 2 }, mb: 2 }}
      >
        <Typography
          data-logrocket-hidden
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: 2,
          }}
        >
          Name: {isEditingUserName ? "" : userName}
        </Typography>
        {isEditingUserName && (
          <YoodliTextfield
            data-logrocket-hidden
            value={editableUserName}
            onChange={(e) => setEditableUserName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                // Handle the Enter key press here
                handleSaveUserNameClicked();
              }
            }}
            placeholder="Your name"
            disabled={!isEditingUserName}
            maxChars={USER_NAME_MAX_CHAR_LENGTH}
            sx={{
              width: { xs: "230px", sm: "300px" },
            }}
            helperText={validateNameResult}
          />
        )}
        {isEditingUserName ? (
          <Button onClick={handleSaveUserNameClicked} disabled={validateNameResult?.length > 0}>
            Save
          </Button>
        ) : (
          <Button onClick={handleEditUserNameClicked}>Edit</Button>
        )}
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          mt: { xs: 2, md: 0 },
        }}
      >
        <Typography
          sx={{
            width: { xs: "unset", lg: "50%" },
          }}
        >
          Home Timezone
        </Typography>
        <Box
          sx={{
            width: { xs: "unset", lg: "50%" },
          }}
        >
          <Select
            value={tzChanging || timezones.length === 0 ? "loading" : homeTimezone}
            onChange={handleUpdateTimezone}
            MenuProps={{
              anchorOrigin: {
                horizontal: "right",
                vertical: "top",
              },
              sx: {
                ".MuiPaper-root": {
                  maxHeight: { xs: "50vh", md: "min(800px, 90vh)" },
                },
              },
            }}
            sx={{
              minWidth: { xs: 160, sm: 200 },
            }}
            inputProps={{
              "aria-label": "Home timezone selector",
            }}
          >
            <MenuItem
              value="loading"
              sx={{
                display: "none",
              }}
            >
              <Typography
                sx={{
                  mr: 2,
                  display: "inline-block",
                }}
              >
                Loading
              </Typography>
              <CircularProgress size={18} thickness={6} />
            </MenuItem>
            {timezones.length > 0 &&
              !tzChanging &&
              timezones.map((tz) => (
                <MenuItem key={tz} value={tz}>
                  {tz}
                </MenuItem>
              ))}
          </Select>
        </Box>
      </Stack>
      <Grid container justifyContent={"space-between"} sx={{ my: { xs: 4, md: 2 } }}>
        <Grid item flexWrap={"wrap"} xs={6} lg={6}>
          <Typography
            data-logrocket-hidden
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              lineHeight: 2,
            }}
          >
            {`Signed in as: ${currentUserEmail()}`}
          </Typography>
        </Grid>
        <Grid item lg={6}>
          <Button
            sx={{ height: "35px" }}
            variant={"outlined"}
            aria-label="Sign Out"
            onClick={() => handleDoLogout(userInOrg, defaultOrgId, logOutEverywhereEnforced)}
          >
            <Typography fontWeight={600}>Sign Out</Typography>
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};
