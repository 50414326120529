import React from "react";

// Components
import { Button, CircularProgress, Stack, SxProps, Typography } from "@mui/material";

// Utils
import { WizardStep } from "./WizardTypes";
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { WIZARD_FOOTER_HEIGHT } from "utils/Constants";

interface WizardControlsProps extends WizardStep {
  totalSteps: number;
  loading: boolean;
  position?: "bottom" | "top";
  controlsSx?: SxProps;
  shouldUseNavDrawerOffset?: boolean;
}

export const Controls = ({
  loading,
  validate,
  next,
  nextCopy = "Next",
  nextButtonProps,
  hidePrev,
  prev,
  prevCopy = "Back",
  prevButtonProps,
  position = "bottom",
  controlsSx,
  shouldUseNavDrawerOffset,
  nextHelperText,
}: WizardControlsProps): JSX.Element => {
  const { modalStyles, navDrawerOffset } = useNavDrawerOffset();
  const renderNextButton = () => {
    return (
      <Stack
        direction="row"
        sx={{
          width: "100%",
          justifyContent: "flex-end",
          gap: 2,
          alignItems: "center",
        }}
      >
        {nextHelperText && typeof nextHelperText === "string" ? (
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              color: getDynamicColor("redError"),
              textAlign: "right",
            }}
          >
            {nextHelperText}
          </Typography>
        ) : (
          nextHelperText
        )}
        <Button
          disabled={!validate?.() || loading}
          variant="contained"
          onClick={next}
          sx={{
            minWidth: 160,
          }}
          {...nextButtonProps}
        >
          {loading ? <CircularProgress size={20} /> : nextCopy}
        </Button>
      </Stack>
    );
  };
  const renderPrevButton = () => {
    if (hidePrev) {
      return null;
    }

    return (
      <Button
        variant="text"
        onClick={prev}
        sx={{
          minWidth: 95,
        }}
        {...prevButtonProps}
      >
        {prevCopy}
      </Button>
    );
  };
  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      sx={{
        backgroundColor: getDynamicColor("light1"),
        position: "fixed",
        bottom: position === "bottom" ? 0 : undefined,
        top: position === "top" ? 0 : undefined,
        left: 0,
        right: 0,
        zIndex: 100,
        p: 3,
        height: WIZARD_FOOTER_HEIGHT,
        boxShadow: Y_SHADOWS.upShadowLine,
        ...(shouldUseNavDrawerOffset && {
          left: navDrawerOffset,
          transition: modalStyles.transition,
        }),
        ...controlsSx,
      }}
    >
      {renderPrevButton()}
      {renderNextButton()}
    </Stack>
  );
};
