import React from "react";

// Components
import { CheckCircleRounded as CheckCircleRoundedIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { BulkInviteRoleOptionEnum } from "components/Orgs/BulkInviteByCSVWizard/BulkInviteByCSVWizard";
import { EmailBank } from "components/Orgs/BulkInviteByCSVWizard/EmailBank";
import { WizardSubTitle, WizardTitle } from "components/Wizard/WizardTitles";
import { YoodliMultiSelect } from "lib-frontend/components/YoodliComponents/YoodliMultiSelect";
import {
  YoodliSelect,
  YoodliSelectOption,
} from "lib-frontend/components/YoodliComponents/YoodliSelect";

// Utils
import { BULK_INVITE_ROLE_OPTIONS, BulkInviteRoleOption } from "./BulkInviteByCSVWizard";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { HubV2Response } from "lib-fullstack/api/orgApiTypes";
import { OrgSpaceItem } from "lib-fullstack/api/orgSpaceApiTypes";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { EffectiveRole } from "lib-fullstack/utils/enums";

type InviteImportCompleteProps = {
  emails: string[];
  handleRemoveEmail: (email: string) => void;

  selectedRole: BulkInviteRoleOption;
  setSelectedRole: (role: BulkInviteRoleOption) => void;

  selectedHubIds: string[];
  setSelectedHubIds: (hubIds: string[]) => void;

  selectedSpaceIds: string[];
  setSelectedSpaceIds: (spaceIds: string[]) => void;
};

type GroupOrSpaceSelectorProps = {
  allOptions: HubV2Response[] | OrgSpaceItem[];
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  label: string;
};

export const GroupOrSpaceSelector = ({
  allOptions,
  selectedIds,
  setSelectedIds,
  label,
}: GroupOrSpaceSelectorProps): JSX.Element => {
  return (
    <Stack gap={0.5} sx={{ width: { xs: "100%", lg: "60%" }, flexGrow: 1 }}>
      <Stack direction="row" gap={0.5} sx={{ alignItems: "center", flexGrow: 1 }}>
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: "12px",
            fontWeight: 600,
          }}
        >
          {label}
        </Typography>
      </Stack>
      <YoodliMultiSelect
        isCheckboxSelect
        value={
          selectedIds.map((id) => ({
            label: allOptions.find((h) => h.id === id)?.name ?? "",
            value: id,
          })) ?? []
        }
        onChange={(e, vals: YoodliSelectOption[], _reason) => {
          if (vals.length === 0) {
            setSelectedIds([]);
            return;
          }
          const newSelectedIds = vals.map((val) => val.value);
          setSelectedIds(newSelectedIds);
        }}
        placeholder={`Select ${label}`}
        options={allOptions.map((opt) => ({ label: opt.name, value: opt.id }))}
      />
    </Stack>
  );
};

export const InviteImportComplete = ({
  emails,
  handleRemoveEmail,

  selectedRole,
  setSelectedRole,

  selectedHubIds,
  setSelectedHubIds,

  selectedSpaceIds,
  setSelectedSpaceIds,
}: InviteImportCompleteProps): JSX.Element => {
  const {
    adminInfo: { defaultOrg },
    isDefaultOrgOwnerAdmin,
  } = React.useContext(UserOrgContext);
  const { spaces } = React.useContext(ContentSpacesContext);

  const isSmallScreen = useIsSmallScreen();
  const subTitle = `We found ${emails?.length} emails. Any invalid or existing/duplicate emails were ignored`;
  return (
    <Stack
      gap={{ xs: 2, md: 5 }}
      sx={{
        fontFamily: "poppins",
      }}
    >
      <Stack gap={1}>
        <WizardTitle
          icon={
            <CheckCircleRoundedIcon
              sx={{
                color: getDynamicColor("greenSuccess"),
              }}
            />
          }
        >
          Import Complete
        </WizardTitle>
        <WizardSubTitle
          overrideSx={{
            color: getDynamicColor("dark5"),
          }}
        >
          {subTitle}
        </WizardSubTitle>
      </Stack>
      <EmailBank emails={emails} title="Email addresses" handleRemoveEmail={handleRemoveEmail} />
      {isDefaultOrgOwnerAdmin && (
        <Stack
          direction={{ xs: "column", lg: "row" }}
          gap={2}
          sx={{
            width: "100%",
          }}
        >
          <Stack gap={0.5} sx={{ width: { xs: "100%", lg: "40%" } }}>
            <Stack direction="row" gap={0.5} sx={{ alignItems: "center" }}>
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                Role
              </Typography>
            </Stack>
            <YoodliSelect
              value={selectedRole.value}
              fullWidth={isSmallScreen}
              onChange={(e) => {
                setSelectedRole(BULK_INVITE_ROLE_OPTIONS.find((o) => o.value === e.target.value));
              }}
              options={[
                { label: "Group Member", value: BulkInviteRoleOptionEnum.HUB_MEMBER.toString() },
                { label: "Group Admin", value: BulkInviteRoleOptionEnum.HUB_ADMIN.toString() },
                {
                  label: "Content Space Admin",
                  value: BulkInviteRoleOptionEnum.CONTENT_SPACE_ADMIN.toString(),
                },
              ]}
            />
          </Stack>
          <GroupOrSpaceSelector
            allOptions={
              selectedRole.value === EffectiveRole.SPACE_ADMIN ? spaces : defaultOrg?.hubs
            }
            selectedIds={
              selectedRole.value === EffectiveRole.SPACE_ADMIN ? selectedSpaceIds : selectedHubIds
            }
            setSelectedIds={
              selectedRole.value === EffectiveRole.SPACE_ADMIN
                ? setSelectedSpaceIds
                : setSelectedHubIds
            }
            label={selectedRole.value === EffectiveRole.SPACE_ADMIN ? "Content Space" : "Group"}
          />
        </Stack>
      )}
    </Stack>
  );
};
