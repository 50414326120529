import { Language, RecordingSupportedLanguage } from "./enums";

export enum PrescriptiveType {
  GOOD = "GOOD",
  POOR = "POOR",
  DEFAULT = "DEFAULT",
}

// Note: these values are considered "good" [inclusive]. I.e. 4% is good filler word percent but 5% is bad.
export const WPM_LOW_THRESHOLD = 120;
export const WPM_HIGH_THRESHOLD = 170;
// these numbers are fuzzy for now--let's get some data and refine them
export const WPM_LOW_THRESHOLD_NON_ENGLISH = 105;
export const WPM_HIGH_THRESHOLD_NON_ENGLISH = 190;
export const DOUBLE_WORD_THRESHOLD = 4; // Percent
export const FILLER_WORD_THRESHOLD = 4; // Percent
export const HEDGING_THRESHOLD = 4; // Percent
export const NON_INCLUSIVE_THRESHOLD = 0;
export const NUM_MONOLOGUES_THRESHOLD = 0;
export const TALK_TIME_THRESHOLD = 50; // Percent
export const NUM_PAUSES_THRESHOLD = 1;
export const NUM_QUESTIONS_THRESHOLD = 5;
export const CONCISENESS_THRESHOLD = 0.7;
export const SENTENCE_STARTERS_THRESHOLD = 1;
export const MIN_MONOLOGUE_LENGTH_S = 60;

export const WPM_THRESHOLDS: Record<RecordingSupportedLanguage, [number, number]> = {
  [Language.English]: [WPM_LOW_THRESHOLD, WPM_HIGH_THRESHOLD],
  [Language.Spanish]: [WPM_LOW_THRESHOLD_NON_ENGLISH, WPM_HIGH_THRESHOLD_NON_ENGLISH],
  [Language.French]: [WPM_LOW_THRESHOLD_NON_ENGLISH, WPM_HIGH_THRESHOLD_NON_ENGLISH],
  [Language.Portuguese]: [WPM_LOW_THRESHOLD_NON_ENGLISH, WPM_HIGH_THRESHOLD_NON_ENGLISH],
  [Language.Italian]: [WPM_LOW_THRESHOLD_NON_ENGLISH, WPM_HIGH_THRESHOLD_NON_ENGLISH],
  [Language.ChineseSimplified]: [200, 350],
  [Language.Japanese]: [240, 360],
  [Language.German]: [WPM_LOW_THRESHOLD_NON_ENGLISH, WPM_HIGH_THRESHOLD_NON_ENGLISH],
  [Language.Hindi]: [WPM_LOW_THRESHOLD_NON_ENGLISH, WPM_HIGH_THRESHOLD_NON_ENGLISH],
  [Language.Korean]: [WPM_LOW_THRESHOLD_NON_ENGLISH, WPM_HIGH_THRESHOLD_NON_ENGLISH],
  [Language.Dutch]: [WPM_LOW_THRESHOLD_NON_ENGLISH, WPM_HIGH_THRESHOLD_NON_ENGLISH],
  [Language.Polish]: [WPM_LOW_THRESHOLD_NON_ENGLISH, WPM_HIGH_THRESHOLD_NON_ENGLISH],
};
