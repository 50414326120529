// Components
import { Stack, Typography } from "@mui/material";

// Utils
import { FOCUS_ANALYTICS, HoistedAnalytic } from "../DashboardTypes";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { DAYS } from "lib-fullstack/utils/dateUtils";
import { formatDateShort } from "lib-fullstack/utils/dateUtils";
// Recharts
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
} from "recharts";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import { formatAggAnalyticValue } from "utils/DashboardUtils";

const DEFAULT_AXIS_PROPS = {
  tickLine: false,
  axisLine: { stroke: getDynamicColor("dark2"), strokeWidth: 1 },
};
const DEFAULT_CHART_PROPS = {
  isAnimationActive: false,
};

type ChartProps = {
  data: { x: number; y: number | number[] }[];
  dataKey: string;
  xAxisId: string;
  name: string;
  stroke: string;
  strokeWidth: number;
  fill: string;
  dot: { r: number; fill: string };
  activeDot: { r: number; stroke: string };
};

type AnalyticChartProps = {
  activeAnalytic: HoistedAnalytic;
  setHoveringThresholdLegend: (hoveringThresholdLegend: boolean) => void;
};
const AnalyticChart = ({
  activeAnalytic,
  setHoveringThresholdLegend,
}: AnalyticChartProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();

  const isGoal = !FOCUS_ANALYTICS[activeAnalytic?.identifier];
  // Renderers
  const renderTooltip = (props: TooltipProps<ValueType, string | number>) => {
    const payload = props?.payload?.find((x) => x.name === "Daily averages") ?? {};
    const { value } = payload;
    if (value === undefined || value === null || isNaN(value as number)) return;
    const totalYoodlis = payload?.payload?.slugs?.length ?? 0;
    const displayValue = isGoal
      ? `${(Number(value) * 100).toFixed(0)}%`
      : formatAggAnalyticValue(value as number, activeAnalytic);
    let displayThreshold: string | number | number[] = activeAnalytic.threshold;
    if (typeof activeAnalytic.threshold === "object") {
      displayThreshold = `${activeAnalytic.threshold[0]} – ${activeAnalytic.threshold[1]}`;
    } else if (activeAnalytic.customThresholdTransformer) {
      displayThreshold = activeAnalytic.customThresholdTransformer(activeAnalytic.threshold);
    }

    return (
      <Stack
        gap={1}
        sx={{
          backgroundColor: getDynamicColor("light1"),
          p: 2.5,
          border: `1px solid ${getDynamicColor("chartPurpleLight")}`,
          borderRadius: 3, // 3 is '12px' because borderRadius is half the base 8px
          boxShadow: Y_SHADOWS.box_shadow_1,
        }}
      >
        <Stack
          direction="row"
          gap={0.5}
          sx={{
            fontWeight: 400,
            fontSize: 10,
            textTransform: "uppercase",
            lineHeight: 2.6,
          }}
        >
          <Typography>
            {DAYS[new Date(props.label).getDay()]}, {formatDateShort(props.label)}
          </Typography>
          {activeAnalytic.counted !== "yoodlis" && activeAnalytic?.counted !== "comments" && (
            <>
              <Typography>•</Typography>
              <Typography>
                {totalYoodlis} Yoodli{totalYoodlis > 1 || totalYoodlis === 0 ? "s" : ""}
              </Typography>
            </>
          )}
        </Stack>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          {displayValue}
        </Typography>
        {activeAnalytic.threshold !== undefined && (
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 10,
              verticalAlign: "baseline",
              color: getDynamicColor("chartGreen"),
              position: "relative",
              textTransform: "uppercase",
            }}
          >
            Target: {displayThreshold}
          </Typography>
        )}
      </Stack>
    );
  };
  const renderThreshold = () => {
    if (!activeAnalytic.threshold && activeAnalytic.threshold !== 0) return;
    const thresholdData = activeAnalytic?.data.map((x, i) => ({
      x: i + 1,
      y: activeAnalytic.threshold,
    }));
    if (thresholdData.length === 1) {
      thresholdData.push({
        x: 2,
        y: activeAnalytic.threshold,
      });
    }

    const DEFAULT_THRESHOLD_PROPS: ChartProps = {
      ...DEFAULT_CHART_PROPS,
      data: thresholdData,
      dataKey: "y",
      xAxisId: "threshold",
      name: "Target average",
      stroke: getDynamicColor("chartGreen"),
      strokeWidth: 2,
      fill: getDynamicColor("chartGreen"),
      dot: { r: 0, fill: getDynamicColor("chartGreen") },
      activeDot: { r: 0, stroke: getDynamicColor("chartGreen") },
    };

    if (typeof activeAnalytic.threshold === "number") {
      return <Line {...DEFAULT_THRESHOLD_PROPS} />;
    }
    return <Area {...DEFAULT_THRESHOLD_PROPS} />;
  };

  if (!activeAnalytic?.data) return;

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <Tooltip content={renderTooltip} />

          <CartesianGrid stroke={getDynamicColor("dark2")} />
          <XAxis
            {...DEFAULT_AXIS_PROPS}
            dataKey="x"
            xAxisId="data"
            minTickGap={isSmallScreen ? 10 : 60}
            tickFormatter={formatDateShort}
            interval={"preserveStartEnd"}
          />
          {/* threshold x-axis (hidden) */}
          <XAxis
            {...DEFAULT_AXIS_PROPS}
            hide
            dataKey="x"
            xAxisId="threshold"
            minTickGap={isSmallScreen ? 10 : 60}
            interval={"preserveStartEnd"}
          />
          <YAxis
            {...DEFAULT_AXIS_PROPS}
            tickFormatter={(value) => {
              if (isGoal) {
                return `${(Number(value) * 100).toFixed(0)}%`;
              }
              return formatAggAnalyticValue(value as number, activeAnalytic);
            }}
          />
          <Legend
            onMouseEnter={(item) => {
              const props = item?.payload as unknown as ChartProps;
              if (props?.xAxisId === "threshold") {
                setHoveringThresholdLegend(true);
              }
            }}
            onMouseLeave={(item) => {
              const props = item?.payload as unknown as ChartProps;
              if (props?.xAxisId === "threshold") {
                setHoveringThresholdLegend(false);
              }
            }}
            verticalAlign={isSmallScreen ? "bottom" : "top"}
            align={isSmallScreen ? "center" : "right"}
            iconSize={20}
            height={isSmallScreen ? 20 : 40}
            iconType="plainline"
          />

          {renderThreshold()}
          <Line
            {...DEFAULT_CHART_PROPS}
            data={activeAnalytic?.data}
            type="natural"
            name="Daily averages"
            dataKey="y"
            xAxisId="data"
            stroke={getDynamicColor("chartPurple")}
            strokeWidth={2}
            dot={{ r: 3, fill: getDynamicColor("chartPurple") }}
            activeDot={{
              r: 5,
              stroke: getDynamicColor("chartPurple"),
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export default AnalyticChart;
