import React from "react";

// Components
import { Button, Stack, SxProps, Typography } from "@mui/material";

// Utils
import { OrgCtaBanner } from "./OrgCtaBanner";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { DAY_IN_MS, WEEK_IN_MS } from "lib-fullstack/utils/constants";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { OrgSubscriptionType } from "lib-fullstack/utils/enums";
import { getLandingPageExternalUrl, LandingPageExternalPath } from "lib-fullstack/utils/paths";

type OrgContractExpirationBannerProps = {
  sx?: SxProps;
};
export const OrgContractExpirationBanner = ({
  sx,
}: OrgContractExpirationBannerProps): JSX.Element => {
  const {
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);
  const [bannerShowing, setBannerShowing] = React.useState(
    localStorage
      .getItem("orgContractExpiryWarningDismissedArr")
      ?.split(",")
      .includes(defaultOrg?.id)
      ? false
      : true,
  );

  const updateBannerShowing = () => {
    const orgContractExpiryWarningDismissedArr =
      localStorage.getItem("orgContractExpiryWarningDismissedArr")?.split(",") ?? [];
    orgContractExpiryWarningDismissedArr.push(defaultOrg?.id);
    setBannerShowing(false);
    localStorage.setItem(
      "orgContractExpiryWarningDismissedArr",
      orgContractExpiryWarningDismissedArr.join(","),
    );
  };

  const renderBanner = () => {
    const showExpired =
      defaultOrg.cancellation_date && new Date(defaultOrg.cancellation_date).getTime() < Date.now();
    const showPaygExpirationWarning =
      !showExpired &&
      defaultOrg.cancellation_date &&
      defaultOrg?.subscription_type === OrgSubscriptionType.PAYG &&
      new Date(defaultOrg.cancellation_date).getTime() - Date.now() < DAY_IN_MS;
    const showPrepaidFlexibleExpirationWarning =
      !showExpired &&
      defaultOrg.cancellation_date &&
      [OrgSubscriptionType.PREPAID, OrgSubscriptionType.FLEXIBLE].includes(
        defaultOrg?.subscription_type,
      ) &&
      new Date(defaultOrg.cancellation_date).getTime() - Date.now() < WEEK_IN_MS;
    // don't show the banner if it was a dismissable banner (i.e. not the fully expired one)
    if (!bannerShowing && !showExpired) {
      return null;
    }
    if (showExpired || showPaygExpirationWarning || showPrepaidFlexibleExpirationWarning) {
      return (
        <OrgCtaBanner
          sx={{
            width: "unset",
            justifyContent: "space-between",
            backgroundColor: showExpired
              ? getDynamicColor("redErrorLight")
              : getDynamicColor("orangeWarning"),
            ...sx,
          }}
          title={
            <Stack
              direction="row"
              gap={0.5}
              sx={{
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                fontFamily: "poppins",
                color: showExpired
                  ? getDynamicColor("redError")
                  : getDynamicColor("orangeWarningDark"),
                textAlign: "left",
              }}
            >
              {showExpired && (
                <Typography sx={{ fontWeight: 600 }}>Your contract has expired!</Typography>
              )}
              {(showPaygExpirationWarning || showPrepaidFlexibleExpirationWarning) && (
                <Typography sx={{ fontWeight: 600 }}>
                  Your contract will expire on {getHumanReadableDate(defaultOrg.cancellation_date)}.
                </Typography>
              )}
              <Typography>
                Talk to sales to renew your contract and keep Yoodli for you and your members
              </Typography>
            </Stack>
          }
          cta={
            <Stack direction="row" sx={{ gap: { xs: 1, md: 2 } }}>
              {!showExpired && (
                <Button
                  variant="text"
                  onClick={() => {
                    updateBannerShowing();
                  }}
                  sx={{ fontSize: "12px", whiteSpace: "nowrap" }}
                >
                  Dismiss
                </Button>
              )}
              <Button
                variant="gradient"
                target="_blank"
                href={getLandingPageExternalUrl(
                  getClientEnvConfig(),
                  LandingPageExternalPath.TALK_TO_SALES,
                )}
                sx={{ fontSize: "12px", whiteSpace: "nowrap", px: "16px" }}
              >
                Talk to Sales
              </Button>
            </Stack>
          }
        />
      );
    }
  };

  return renderBanner();
};
