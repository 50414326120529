import React from "react";
import { Stack, SxProps, Button, Box, Theme } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

type YoodliTab = {
  label: string;
  content: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
  tabSxOverride?: SxProps<Theme>;
};

type YoodliTabsProps = {
  tabs: YoodliTab[];
  tabSx?: SxProps<Theme>;
  tabWrapperSx?: SxProps<Theme>;
};

export const YoodliTabs = ({ tabs, tabSx, tabWrapperSx }: YoodliTabsProps): JSX.Element => {
  return (
    <>
      <Stack direction="row" sx={tabWrapperSx}>
        {tabs.map((tab) => (
          <Button
            key={tab.label}
            onClick={tab.onClick}
            sx={
              {
                flexBasis: `${100 / tabs.length}%`,
                borderTopRightRadius: "12px",
                borderTopLeftRadius: "12px",
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                py: 2,
                px: 1,
                fontSize: 14,
                fontFamily: "poppins",
                color: getDynamicColor("dark4"),
                fontWeight: 500,
                border: `1px solid ${getDynamicColor("light2")}`,
                position: "relative",
                // active tab specific styles
                ...(tab.isActive && {
                  color: getDynamicColor("primary"),
                  fontWeight: 700,
                  backgroundColor: getDynamicColor("light2"),
                }),
                // inactive tab specific styles
                ...(!tab.isActive && {
                  "&:hover": {
                    backgroundColor: getDynamicColor("light2", 0.25),
                  },
                }),
                ...(tabSx || {}),
                ...(tab.tabSxOverride || {}),
              } as SxProps<Theme>
            }
          >
            {/* Visible text */}
            <Box sx={{ position: "relative", zIndex: 1 }}>{tab.label}</Box>

            {/* Hidden bold version to reserve space - will not be visible but maintains layout */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: 700,
                visibility: "hidden",
                zIndex: 0,
                pointerEvents: "none",
              }}
            >
              {tab.label}
            </Box>
          </Button>
        ))}
      </Stack>
      {tabs.find((tab) => tab.isActive)?.content}
    </>
  );
};
