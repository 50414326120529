import React from "react";

// Components
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Box, Button, Stack, Typography } from "@mui/material";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Utils
import { ChecklistDataElement } from "./tasks";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { OnboardingChecklistTask } from "lib-fullstack/db";
import { UITestId } from "lib-fullstack/utils/enums";

type ChecklistTasksProps = {
  allTasks: OnboardingChecklistTask[];
  data: ChecklistDataElement[];
  checkIfCompleted: (taskId: OnboardingChecklistTask) => boolean;
  checkIfDismissed: (taskId: OnboardingChecklistTask) => boolean;
  activeTaskIdx: number | null;
  setActiveTaskIdx: (idx: number | null) => void;
  allCompleted: boolean;
};

export const ChecklistTasks = ({
  allTasks,
  data,
  checkIfCompleted,
  checkIfDismissed,
  activeTaskIdx,
  setActiveTaskIdx,
  allCompleted,
}: ChecklistTasksProps): JSX.Element => {
  return (
    <Stack direction="row">
      <Stack
        sx={{
          width: "100%",
          backgroundColor: getDynamicColor("light1"),
          p: 2.5,
          borderRadius: "12px",
        }}
        gap={0.5}
      >
        {activeTaskIdx !== null &&
          data.map((task, idx) => (
            <ChecklistTaskRow
              key={task.taskId}
              task={task}
              isActive={activeTaskIdx === idx}
              allCompleted={allCompleted}
              allTasks={allTasks}
              checkIfCompleted={checkIfCompleted}
              checkIfDismissed={checkIfDismissed}
              setActiveTaskIdx={setActiveTaskIdx}
              idx={idx}
            />
          ))}
      </Stack>
    </Stack>
  );
};

const ChecklistTaskRow = ({
  task,
  isActive,
  checkIfCompleted,
  checkIfDismissed,
  allCompleted,
  allTasks,
  setActiveTaskIdx,
  idx,
}: {
  task: ChecklistDataElement;
  isActive: boolean;
  checkIfCompleted: (taskId: OnboardingChecklistTask) => boolean;
  checkIfDismissed: (taskId: OnboardingChecklistTask) => boolean;
  allCompleted: boolean;
  allTasks: OnboardingChecklistTask[];
  setActiveTaskIdx: (idx: number) => void;
  idx: number;
}): JSX.Element => {
  const isCompleted = checkIfCompleted(task.taskId);
  const isDismissed = checkIfDismissed(task.taskId);
  if (isDismissed) {
    return <></>;
  }
  const onRowClicked = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    text: string,
    idx: number,
    isCompleted: boolean,
  ) => {
    e.preventDefault();
    if (!allCompleted && !isActive) {
      setActiveTaskIdx(idx);
      Instrumentation.logYoodliOnboardingChecklistItemSelected(
        text,
        allTasks[idx],
        idx,
        isCompleted,
      );
    }
  };
  return (
    <Stack
      direction={"row"}
      sx={{
        py: 2,
        pl: 1.5,
        pr: 3,
        fontFamily: "Poppins",
        backgroundColor: isActive && getDynamicColor("light2"),
        cursor: !allCompleted && !isActive ? "pointer" : "default",
        borderRadius: "12px",
        "&:hover": {
          boxShadow: Y_SHADOWS.box_shadow_1,
        },
        transition: "box-shadow 0.3s ease-in-out",
        width: "100%",
      }}
      onClick={(e) => onRowClicked(e, task.name, idx, isCompleted)}
      gap={2}
      key={task.taskId}
    >
      <div
        style={{
          width: "32px",
          height: "32px",
        }}
      >
        <Box
          sx={{
            height: "32px",
            width: "32px",
            borderRadius: "50%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            backgroundColor: isCompleted && getDynamicColor("greenSuccess"),
            border: !isCompleted && `2px solid ${getDynamicColor("dark3")}`,
          }}
        >
          {isCompleted && (
            <CheckRoundedIcon
              sx={{
                color: getDynamicColor("light1"),
                width: "100%",
              }}
            />
          )}
        </Box>
      </div>
      <Stack
        direction="row"
        sx={{
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        gap={1.5}
      >
        <Stack sx={{ maxWidth: 450, pl: 1 }} gap={1}>
          <Typography
            style={{
              color:
                task.isDisabled || !isActive
                  ? getDynamicColor("purple3")
                  : getDynamicColor("primary"),
              fontFamily: "poppins",
              fontWeight: isActive ? 700 : "unset",
              fontSize: "14px",
            }}
            data-testid={`${UITestId.FTUXCTAButton}-${task.taskId}`}
          >
            {task.name}
          </Typography>
          {isActive && (
            <Typography
              component="div"
              style={{
                color: getDynamicColor("purple3"),
                fontFamily: "Poppins",
                fontSize: "12px",
              }}
            >
              {task?.description}
            </Typography>
          )}
        </Stack>

        {isActive && (
          <Stack direction={{ xs: "column", sm: "row" }} gap={1}>
            {task?.buttonText && (
              <YoodliTooltip title={task.disabledTipText}>
                <Button
                  variant="gradient"
                  sx={{
                    fontSize: "14px",
                    height: "fit-content",
                    background: task.isDisabled && getDynamicColor("dark2"),
                    color: task.isDisabled && `${getDynamicColor("dark4")} !important`,
                  }}
                  onClick={task?.onClick}
                  disabled={task?.isDisabled}
                >
                  {task?.buttonText}
                </Button>
              </YoodliTooltip>
            )}
            {task?.onDismiss && !isCompleted && (
              <Button variant="text" onClick={task?.onDismiss} sx={{ fontSize: "14px" }}>
                Dismiss
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
