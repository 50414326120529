import { useState } from "react";

// Components
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Stack,
  Typography,
  Button,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";

// Utils
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { InterviewBankResponse } from "lib-fullstack/api/hubApiTypes";
import { HubModalStatus } from "utils/Enums";

type InterviewBankQuestionProps = {
  index: number;
  interviewBank: InterviewBankResponse;
  canUpdateBank: boolean;
  showBankHubsDetails: boolean;
  handleBankUpdateModalSelection?: (val: HubModalStatus) => void;
  isSmallScreen: boolean;
};

export default function InterviewBank({
  index,
  interviewBank,
  canUpdateBank,
  showBankHubsDetails,
  handleBankUpdateModalSelection,
  isSmallScreen,
}: InterviewBankQuestionProps): JSX.Element {
  const { id, interview_questions, name, available_hubs } = interviewBank;
  const questionCount = interview_questions.length;

  const [expanded, setExpanded] = useState<boolean>(false);

  const handleMenuSelect = (modalStatus: HubModalStatus) => {
    handleBankUpdateModalSelection(modalStatus);
  };

  return (
    <Accordion
      key={id}
      defaultExpanded={index === 0}
      expanded={expanded}
      aria-expanded={expanded}
      sx={{
        fontSize: "16px",
        border: `1px solid ${getDynamicColor("dark3")}`,
        borderRadius: "4px",
        boxShadow: Y_SHADOWS.dark_elevation,
        width: "100%",
        "&.mui-expanded": {
          maxHeight: "380px",
        },
      }}
      disableGutters
      elevation={2}
    >
      <AccordionSummary
        id={`${id}-panel-header`}
        aria-controls={`${id}-panel-content`}
        sx={{
          cursor: "default !important",
          margin: "5px 0 3px",
          p: isSmallScreen ? "0px 8px 0px 16px" : "0px 16px",
        }}
      >
        {/** Header with bank name & menu of options */}
        <Stack direction="row" justifyContent="space-between" flexWrap="nowrap" flexGrow={1}>
          <Stack direction="column">
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "16px",
                fontWeight: 600,
                flexGrow: 1,
                alignContent: "flex-start",
                overflowWrap: "break-word",
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{ color: getDynamicColor("purple3"), fontSize: "12px", fontWeight: 600 }}
            >
              {`${questionCount} question${questionCount > 1 ? "s" : ""}`}
            </Typography>
          </Stack>
          {/* Update Interview Bank Content Hub availability */}
          <Stack
            direction="row"
            justifyContent="flex-end"
            flexGrow={0}
            alignItems="center"
            flexWrap="nowrap"
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={isSmallScreen ? 1 : 0.5}
              justifyContent="space-between"
            >
              {canUpdateBank && (
                <>
                  {/** If on org page & desktop, show hub count directly on header*/}
                  {!isSmallScreen && showBankHubsDetails && (
                    <Button
                      onClick={() => {
                        handleBankUpdateModalSelection(
                          HubModalStatus.EDIT_INTERVIEW_BANK_AVAILABLE_HUBS,
                        );
                      }}
                      variant="outlined"
                      color="inherit"
                      sx={{
                        color: getDynamicColor("purple3"),
                        fontFamily: "poppins",
                        fontSize: "12px",
                        fontWeight: "Medium",
                        borderColor: getDynamicColor("purple3"),
                      }}
                    >
                      Used by {available_hubs.length} Group
                      {available_hubs.length !== 1 && "s"}
                    </Button>
                  )}
                  <YoodliMenu
                    type={YoodliMenuButtonType.Icon}
                    menuItems={[
                      {
                        title: "Edit Name",
                        onClick: () => handleMenuSelect(HubModalStatus.EDIT_INTERVIEW_BANK_NAME),
                        type: YoodliMenuItemType.Default,
                      },
                      {
                        title: "Edit Questions",
                        onClick: () =>
                          handleMenuSelect(HubModalStatus.EDIT_INTERVIEW_BANK_QUESTIONS),
                        type: YoodliMenuItemType.Default,
                      },
                      {
                        title: "Delete Interview Bank",
                        onClick: () => handleMenuSelect(HubModalStatus.DELETE_INTERVIEW_BANK),
                        type: YoodliMenuItemType.Warning,
                      },
                      isSmallScreen &&
                        showBankHubsDetails && {
                          title: `Used by ${available_hubs.length} Group${
                            available_hubs.length !== 1 && "s"
                          }`,
                          onClick: () =>
                            handleMenuSelect(HubModalStatus.EDIT_INTERVIEW_BANK_AVAILABLE_HUBS),
                          type: YoodliMenuItemType.Default,
                        },
                    ].filter(Boolean)}
                  />
                </>
              )}
              <IconButton
                onClick={() => setExpanded(!expanded)}
                aria-controls={expanded ? `${id}-panel-content` : undefined}
                aria-expanded={expanded}
                size={isSmallScreen ? "small" : "medium"}
                sx={{
                  cursor: "pointer !important",
                  color: getDynamicColor("purple3"),
                }}
              >
                {!expanded ? <ExpandMore /> : <ExpandLess />}
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        id={`${id}-panel-content`}
        sx={{
          maxHeight: "330px",
          height: "min-content",
          overflowY: "auto",
        }}
      >
        {/* Interview Box questions list (all) */}
        <Stack
          direction="column"
          sx={{
            maxHeight: "",
            boxSizing: "content-box",
            p: "auto 0.5",
          }}
          justifyContent="space-evenly"
          gap={1.5}
        >
          {interview_questions.map((question, questionIndex) => (
            <Typography
              key={`bank-${id}-question-${questionIndex}`}
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 500,
                overflowWrap: "break-word",
              }}
            >
              {question}
            </Typography>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
