import React from "react";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Collapse, Stack, ListItemButton } from "@mui/material";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";
import { NavItem } from "./NavItem";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { NavigationItemGroup } from "utils/navUtils";
import { useLocation, createPath } from "react-router-dom";
import { publish, CustomEventEnum } from "lib-frontend/events";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";

type NavItemGroupProps = {
  group: NavigationItemGroup;
  navOpen: boolean;
  accountNavExpanded: boolean;
  handleToggleAccountNavExpanded: (expanded?: boolean) => void;
  handleDrawerClose?: () => void;
};

export const NavItemGroup = ({
  group,
  navOpen,
  accountNavExpanded,
  handleToggleAccountNavExpanded,
  handleDrawerClose,
}: NavItemGroupProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  // Check if any child item's path matches current location
  const isAnyChildActive = React.useMemo(() => {
    return group.items.some((item) => {
      const [itemPath, itemQuery] = item.path.split("?");
      const [currentPath, currentQuery] = (location.pathname + location.search).split("?");
      return currentPath === itemPath && (!itemQuery || currentQuery === itemQuery);
    });
  }, [group.items, location]);

  React.useEffect(() => {
    if (isAnyChildActive && navOpen) {
      setOpen(true);
    }
  }, [isAnyChildActive, navOpen]);

  React.useEffect(() => {
    if (open) {
      // auto-close account nav item if we open any nav group
      handleToggleAccountNavExpanded(false);
    }
  }, [open]);

  React.useEffect(() => {
    if (accountNavExpanded) {
      // auto-close nav groups if the account nav item opens.
      setOpen(false);
    }
  }, [accountNavExpanded]);

  React.useEffect(() => {
    if (!navOpen) {
      setOpen(false);
    }
  }, [navOpen]);

  const handleGroupClick = () => {
    if (!navOpen) {
      publish(CustomEventEnum.NAV_DRAWER_OPEN);
      setOpen(true);
    } else {
      setOpen(!open);
    }
  };

  return (
    <YoodliTooltip title={!navOpen && group.text} placement="right" enterDelay={500}>
      <Stack sx={{ width: "100%" }}>
        <ListItemButton
          onClick={handleGroupClick}
          aria-label={group.text}
          sx={{
            display: "flex",
            py: "auto",
            height: 40,
            color: isToastmasters() ? getDynamicColor("light1") : getDynamicColor("purple3"),
            minWidth: "100%",
            "&:hover": {
              backgroundColor: getDynamicColor("dark2"),
            },
            transition: "background-color 0.2s",
            ...(isAnyChildActive && {
              color: getDynamicColor("primary"),
              "& .MuiListItemIcon-root": {
                color: getDynamicColor("primary"),
                fill: getDynamicColor("primary"),
              },
            }),
          }}
        >
          <Stack direction="row" gap={2} alignItems="center" sx={{ height: "100%", width: "100%" }}>
            <ListItemIcon
              sx={{
                alignItems: "center",
                color: "currentColor",
                fill: "currentColor",
                minWidth: "unset",
                svg: {
                  display: "block",
                  fill: "currentColor",
                },
                transition: "color 0.2s",
                width: 24,
                height: 24,
                flexGrow: 0,
              }}
            >
              {group.icon}
            </ListItemIcon>
            {navOpen && (
              <ListItemText
                primary={group.text}
                data-testid={group.dataTestId}
                sx={{
                  display: "block",
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: "poppins",
                    whiteSpace: "nowrap",
                    lineHeight: isSmallScreen ? "32px" : "24px",
                  },
                }}
              />
            )}
            {navOpen && (
              <ExpandMoreIcon
                sx={{
                  transform: open ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.2s ease-in-out",
                  display: "block",
                }}
              />
            )}
          </Stack>
        </ListItemButton>
        <Collapse in={open && navOpen} timeout="auto" unmountOnExit>
          <Stack>
            {group.items.map((item) => (
              <NavItem
                key={createPath({ pathname: item.path, search: item.search })}
                item={item}
                navOpen={navOpen}
                handleDrawerClose={handleDrawerClose}
                isGroupItem
              />
            ))}
          </Stack>
        </Collapse>
      </Stack>
    </YoodliTooltip>
  );
};
