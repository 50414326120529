import React from "react";

enum ErrorStrings {
  ResizeObserverLoopCompleted = "ResizeObserver loop completed with undelivered notifications.",
}

export const useHideUnimportantErrors = (): void => {
  React.useEffect(() => {
    function hideError(e: ErrorEvent) {
      if (e.message === ErrorStrings.ResizeObserverLoopCompleted) {
        console.log("Hiding ResizeObserver error overlay:", e);

        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div",
        );
        const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    }

    window.addEventListener("error", hideError);
    return () => {
      window.addEventListener("error", hideError);
    };
  }, []);
};
