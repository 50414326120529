export enum YoodliExternalPath {
  STATUS_PAGE = "https://yoodli.statuspage.io/",
  TRUST_CENTER = "https://trust.yoodli.ai/",
  SUPPORT_PAGE = "https://support.yoodli.ai/",
  VIDEO_TUTORIALS = "https://www.youtube.com/@yoodli/playlists",
  CAREERS = "https://yoodli-careers.notion.site/yoodli-careers/Careers-at-Yoodli-36d8559419ca48808300b742a9d5efdf",
  SLACK_COMMUNITY = "https://yoodli.ai/community",
}

export const SupportPages = {
  ORG_OVERVIEW_ROLES: `${YoodliExternalPath.SUPPORT_PAGE}en/articles/9048697-yoodli-organizations-overview#h_0e51ef986d`,
  ORG_OVERVIEW_OWNERSHIP: `${YoodliExternalPath.SUPPORT_PAGE}en/articles/9048697-yoodli-organizations-overview#h_8108721857`,
  SPEECH_HUMAN_GRADING: `${YoodliExternalPath.SUPPORT_PAGE}en/articles/9719148-programs#h_f5369d9a13`,
  SCENARIOS: `${YoodliExternalPath.SUPPORT_PAGE}/en/articles/9628260-customizing-practice`,
  BUILDER: `${YoodliExternalPath.SUPPORT_PAGE}en/articles/10055659-builder`,
  AI_PREREAD: `${YoodliExternalPath.SUPPORT_PAGE}en/articles/10546865-ai-pre-read`,
  CONTENT_SPACES: `${YoodliExternalPath.SUPPORT_PAGE}en/articles/10549843-content-spaces`,
  LMS_INTEGRATIONS: `${YoodliExternalPath.SUPPORT_PAGE}en/articles/10046727-lms-integrations`,
  REPORTING_TEAMS: `${YoodliExternalPath.SUPPORT_PAGE}en/articles/10874726-team-dashboard`,
} as const;
