// Utils
/**
 * @file Website paths shared among all backend services. These must be used when
 * computing URLs across different backend services.
 */
import { IEnvSpec, ReactAppEnvironment } from "lib-fullstack/client_env";

/**
 * Paths for the web server site. These may be accessed outside of the
 * web server site. If so, please use the getWebServerExternalUrl function to
 * build the URL to the path.
 * https://docs.google.com/document/d/1hqaC4xpEbCI1S9DbgMCc70pK-fmSCoiKLioPVFpW8Dg/edit#heading=h.xxudglj7to2z
 */
export enum WebServerExternalPath {
  ACCENTURE_1_0_LAUNCH = "/api/accenture/1.0/launch",
  ACCENTURE_1_0_LAUNCH_SPEECH_SUMMARY = "/api/accenture/1.0/launchSpeechSummary",
  ACCOUNT = "/account",
  DASHBOARD = "/dashboard",
  /**
   * Direct link to download Poodli. Must not change as this is used in email campaigns.
   */
  DOWNLOAD_POODLI_DIRECT = "/download",
  HOME_LOGGED_IN = "/home",
  INTEGRATION_ERROR = "/integration-error",
  LOG_IN = "/login",
  MEMBERS = "/org/members",
  MY_LEARNING = "/org/resources",
  POODLI_CALENDAR_CONNECT = "/connect-calendar-to-desktop-app",
  PRACTICE = "/practice",
  PRACTICE_CONVERSATION = "/practice/conversation",
  PRACTICE_SALES = "/practice/conversation?scenario=outbound_customer_discovery",
  PRACTICE_INTERVIEW = "/practice/interview",
  PRACTICE_SPEECH = "/practice/speech",
  RESET_PASSWORD = "/reset-password",
  SCORM_1_2_LAUNCH = "/api/scorm/1.2/launch",
  SHARE = "/share/",
  SIGN_IN = "/signin",
  SIGN_UP = "/signup",
  SUPPORT = "/support",
}

/**
 * Gets the external URL to a path on the web server.
 *
 * @param clientEnv The client environment configuration.
 * @param path The path to get the URL for.
 * @returns The external URL to the path.
 */
export function getWebServerExternalUrl(
  clientEnv: IEnvSpec<ReactAppEnvironment>,
  path: WebServerExternalPath,
): string {
  const url = new URL(clientEnv.url.WEB_SERVER);
  url.pathname = path.toString();
  return url.toString();
}

/**
 * Paths for the landing page site. These may be accessed outside of the
 * landing page site. If so, please use the getLandingPageExternalUrl function to
 * build the URL to the path.
 * https://docs.google.com/document/d/1hqaC4xpEbCI1S9DbgMCc70pK-fmSCoiKLioPVFpW8Dg/edit#heading=h.xxudglj7to2z
 */
export enum LandingPageExternalPath {
  ABOUT = "/about",
  BLOG = "/blog",
  TALK_TO_SALES = "/talk-to-sales",
  COPYRIGHT = "/copyright",
  COURSES = "/courses",
  PRICING = "/pricing",
  PRIVACY = "/privacy",
  PRIVACY_POLICY = "/privacy-policy",
  TERMS_OF_SERVICE = "/tos",
}

/**
 * Gets the external URL to a path on the landing page.
 *
 * @param clientEnv The client environment configuration.
 * @param path The path to get the URL for.
 * @returns The external URL to the path.
 */
export function getLandingPageExternalUrl(
  clientEnv: IEnvSpec<ReactAppEnvironment>,
  path: LandingPageExternalPath,
): string {
  const url = new URL(clientEnv.url.LANDING_PAGE);
  url.pathname = path;
  return url.toString();
}
