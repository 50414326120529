import parse from "html-react-parser";
import React from "react";
import { isMobile } from "react-device-detect";

// Components
import {
  VideoCameraFrontOutlined as VideoCameraIcon,
  LinkOutlined as LinkIcon,
  Edit as EditIcon,
  CheckRounded as CheckRoundedIcon,
  MoreVert as MoreVertIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { ReactComponent as MultiPersonaIcon } from "images/icons/icon-multi-persona.svg";
import { ReactComponent as PersonaIcon } from "images/icons/icon-persona.svg";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Stack,
  Typography,
  Fade,
  IconButton,
  Box,
} from "@mui/material";
import {
  CustomizePracticeQueryKey,
  MAX_SCENARIO_DESCRIPTION_LENGTH,
  TEMPLATE_SUB_TYPE_DATA,
} from "components/ConvoScenarios/convoScenarioUtils";
import { scenarioTypeMap } from "lib-fullstack/utils/orgUtils";
import { getDownloadableReport, getReportProcessing } from "components/Orgs/Reports/reportsUtils";
import { WYSIWYGEditor } from "components/WYSIWYGEditor";
import IconMenu from "lib-frontend/components/IconMenu";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { GroupAssignmentMenu } from "./GroupAssignmentMenu";
import { ManageLtiIntegrationModalContent } from "./ManageLtiIntegrationModal";
import { useQuery as useApiQuery, useMutation } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { useWYSIWYG } from "hooks/useWYSIWYG";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { usePrevious } from "lib-frontend/hooks";
import {
  createReport,
  createScenario,
  deleteScenario,
  listReports,
  patchScenario,
} from "lib-frontend/modules/AxiosInstance";
import { generatePackage } from "lib-frontend/modules/axiosScorm12";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getScenarioPracticePath } from "lib-frontend/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { useIsExtraSmallScreen } from "lib-frontend/utils/themeUtils";
import { copyToMyClipboard } from "lib-frontend/utils/Utilities";
import { GetScenarioResponse } from "lib-fullstack/api/scenarioApiTypes";
import { ListOrgIntegrationResponseItem } from "lib-fullstack/api/integrationsApiTypes";
import { OrgScenario, ScenarioTypeIdEnum } from "lib-fullstack/db";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { ReportParameter, ReportState, ReportType } from "lib-fullstack/utils/enums";
import { IntegrationTypeEnum } from "lib-fullstack/utils/enums/integrationType";
import { OrgReportWheres } from "lib-fullstack/utils/productAnalyticEvents";
import { useNavigate } from "react-router";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

type CustomScenarioCardProps = {
  scenario: GetScenarioResponse;
  selectedOrgId: string;
  handleSelectScenario: (scenario: GetScenarioResponse, action: "edit" | "duplicate") => void;
  personaName?: string;
  personaNameLoading?: boolean;
  integrations: ListOrgIntegrationResponseItem[];
  activeHubIds: string[];
};

const SCENARIO_REPORT_QUERY_KEY = "scenarioReportQueryKey";

const SHORT_DESC_MAX_LENGTH = 160;

type EditableField = "title" | "description";

export const validateUrlRegex =
  "^(http(s)://.)[-a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$";

export const CustomScenarioCard = ({
  scenario,
  selectedOrgId,
  handleSelectScenario,
  personaName,
  personaNameLoading,
  integrations,
  activeHubIds,
}: CustomScenarioCardProps): JSX.Element => {
  const { editor } = useWYSIWYG({
    content: scenario?.description,
    characterLimit: MAX_SCENARIO_DESCRIPTION_LENGTH,
  });

  React.useEffect(() => {
    if (scenario?.description !== editor?.getHTML()) {
      editor?.commands.setContent(scenario.description);
    }
  }, [scenario?.description]);

  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId, copyContentInfo } = React.useContext(ContentSpacesContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isExtraSmallScreen = useIsExtraSmallScreen();
  const [scenarioHubIds, setScenarioHubIds] = React.useState<string[]>(activeHubIds ?? []);
  const [practiceLinkCopied, setPracticeLinkCopied] = React.useState(false);
  const [descriptionOpen, setDescriptionOpen] = React.useState(false);

  const [hovering, setHovering] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const [editingField, setEditingField] = React.useState<EditableField | null>(null);

  const [newTitle, setNewTitle] = React.useState<string>(scenario.title);

  const [reportProcessing, setReportProcessing] = React.useState<boolean>(false);

  const [openManageIntegrationModal, setOpenManageIntegrationModal] =
    React.useState<boolean>(false);
  const scenarioReportQuery = useApiQuery({
    queryKey: [`${SCENARIO_REPORT_QUERY_KEY}-${scenario?.id}`, defaultOrgId],
    queryFn: async () => {
      const reports = await listReports(defaultOrgId, {
        reportType: ReportType.OrgScenarioEngagement,
        states: [ReportState.Rendered, ReportState.Created],
        parameters: JSON.stringify({
          [ReportParameter.ScenarioId]: scenario?.id,
        }),
      });
      return reports;
    },
    refetchInterval: reportProcessing ? 2000 : 0,
    enabled: !!defaultOrgId && !!scenario?.id,
  });
  const downloadableReport = React.useMemo(() => {
    return getDownloadableReport(scenarioReportQuery.data);
  }, [scenarioReportQuery.data]);

  const prevProcessing = usePrevious(reportProcessing);
  React.useEffect(() => {
    const processing = !!getReportProcessing(scenarioReportQuery.data);
    setReportProcessing(processing);

    // if i move from processing ot not processing, a report must have just come back, so download it
    if (prevProcessing && !processing && downloadableReport?.downloadUrl) {
      void handleDownloadReport(downloadableReport.downloadUrl);
    }
  }, [scenarioReportQuery.data, downloadableReport]);

  React.useEffect(() => {
    if (activeHubIds.sort().join() !== scenarioHubIds.sort().join()) {
      setScenarioHubIds(activeHubIds);
    }
  }, [activeHubIds]);

  const orgHasScormIntegration = integrations.some((integration) => {
    return integration.type === IntegrationTypeEnum.SCORM_1_2;
  });
  const orgHasLtiIntegration = integrations.some((integration) => {
    return (
      integration.type === IntegrationTypeEnum.LTI_1_1 ||
      integration.type === IntegrationTypeEnum.LTI_1_3
    );
  });

  const deleteScenarioMutation = useMutation({
    mutationFn: () => deleteScenario(selectedOrgId, scenario.id),
    onSuccess: async () => {
      void queryClient.invalidateQueries({
        queryKey: [CustomizePracticeQueryKey.Personas, defaultOrgId],
      });
      void queryClient.invalidateQueries({
        queryKey: [CustomizePracticeQueryKey.CustomGoals, defaultOrgId],
      });
      if (
        [ScenarioTypeIdEnum.BEHAVIORAL_INTERVIEW, ScenarioTypeIdEnum.TECHNICAL_INTERVIEW].includes(
          scenario.scenarioTypeId,
        )
      ) {
        void queryClient.invalidateQueries({
          queryKey: [CustomizePracticeQueryKey.InterviewBanks, defaultOrgId],
        });
      }
      await queryClient.refetchQueries({
        queryKey: [CustomizePracticeQueryKey.Scenarios, defaultOrgId],
      });
      Instrumentation.logOrgScenarioDeleted(defaultOrgId);
    },
    onError: (error) => {
      console.error(`Error deleting scenario with id ${scenario.id}: ${error}`);
    },
  });

  const handleUpdateGroupAssignment = async (groupIds: string[]) => {
    let error = null;
    try {
      // save the scenarioHubIds to the scenario in db
      await patchScenario(selectedOrgId, scenario.id, { activeHubs: groupIds });
    } catch (e) {
      console.error(`Error updating scenario activeHubs with id ${scenario.id}: ${e}`);
      error = e;
    } finally {
      void queryClient.refetchQueries({
        queryKey: [CustomizePracticeQueryKey.Scenarios, defaultOrgId],
      });
    }
    return error;
  };

  const handleDownloadReport = async (downloadUrl?: string) => {
    if (downloadUrl) {
      // download report
      window.location.assign(downloadUrl);
    } else {
      const params = {
        [ReportParameter.ScenarioId]: scenario?.id,
        [ReportParameter.OrgId]: defaultOrgId,
      };
      setReportProcessing(true);
      await createReport(defaultOrgId, {
        type: ReportType.OrgScenarioEngagement,
        parameters: JSON.stringify(params),
      });
      Instrumentation.logOrgReportGenerated(defaultOrgId, OrgReportWheres.SCENARIO);
      await queryClient.invalidateQueries({
        queryKey: [`${SCENARIO_REPORT_QUERY_KEY}-${scenario?.id}`],
      });
    }
  };

  const practiceLinkCopiedTimeout = React.useRef<NodeJS.Timeout>(null);
  const renderScenarioOptionsMenu = () => {
    const menuItems = [
      {
        title: "Edit",
        onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.stopPropagation();
          handleSelectScenario(scenario, "edit");
        },
      },
      {
        title: "Duplicate",
        onClick: async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.stopPropagation();
          await createScenario(
            selectedOrgId,
            curSpaceId,
            `${scenario.title} (copy)`,
            scenario.id,
            true,
            curSpaceId,
          );
          void queryClient.invalidateQueries({
            queryKey: [CustomizePracticeQueryKey.Scenarios, selectedOrgId, curSpaceId],
          });
        },
      },
      {
        title: "Copy to",
        onClick: async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.stopPropagation();
          copyContentInfo.handleIsCopyDrawerOpen(true);
          copyContentInfo.handleSetCopyContentRequest({
            copyHandler: async (destinationSpaceId: string) => {
              await createScenario(
                selectedOrgId,
                curSpaceId,
                `${scenario.title} (copy)`,
                scenario.id,
                true,
                destinationSpaceId,
              );
              if (curSpaceId === destinationSpaceId) {
                void queryClient.invalidateQueries({
                  queryKey: [CustomizePracticeQueryKey.Scenarios, selectedOrgId, curSpaceId],
                });
              }
            },
            content: {
              name: scenario.title,
              type: "Scenario",
            },
          });
        },
      },
      {
        title: reportProcessing ? (
          <Stack direction="row" alignItems="center" gap={1}>
            <CircularProgress size={16} />
            <Typography
              sx={{
                color: getDynamicColor("dark4"),
              }}
            >
              Report processing
            </Typography>
          </Stack>
        ) : (
          "Download report"
        ),
        onClick: async () => {
          await handleDownloadReport(downloadableReport?.downloadUrl);
        },
      },
      orgHasLtiIntegration && {
        title: "Manage integration",
        onClick: async () => {
          setOpenManageIntegrationModal(true);
        },
      },
      orgHasLtiIntegration && {
        title: "Copy scenario ID",
        onClick: async () => {
          copyToMyClipboard(scenario.id);
        },
      },
      orgHasScormIntegration && {
        title: "Download SCORM package",
        onClick: async () => {
          await generatePackage({
            orgId: defaultOrgId,
            spaceId: curSpaceId,
            scenarioId: scenario.id,
          });
        },
      },
      {
        title: "Delete scenario",
        onClick: async () => {
          setDeleteModalOpen(true);
        },
        disabled: deleteScenarioMutation.isPending || scenario.usedByPrograms,
        disabledTooltip: "This scenario is in use by a program and cannot be deleted at this time",
      },
    ].filter(Boolean);
    return (
      <IconMenu
        title="Manage File"
        hideCaret
        hideTooltip
        disableDrag
        openOverride={!!reportProcessing}
        menuItems={menuItems}
        iconButtonSx={{
          height: 24,
          width: 24,
          svg: {
            color: getDynamicColor(deleteScenarioMutation.isPending ? "dark3" : "primary"),
          },
        }}
        menuItemSx={{
          fontSize: 14,
          fontWeight: 600,
          fontFamily: "poppins",
          px: 2,
          py: 1.5,
          color: getDynamicColor("primary"),
        }}
        minWidth={"140px"}
        paperSx={{
          borderRadius: "12px",
          minWidth: 190,
        }}
        icon={<MoreVertIcon />}
      />
    );
  };
  const renderPersona = () => {
    if (personaNameLoading) {
      return <CircularProgress size={16} />;
    } else if (personaName) {
      return (
        <Stack direction="row" gap={0.5} alignItems="center">
          {scenario.multiPersona ? (
            <MultiPersonaIcon
              style={{
                width: 28,
                height: "auto",
              }}
            />
          ) : (
            <PersonaIcon />
          )}
          <Typography
            sx={{
              fontSize: 11,
              fontWeight: 500,
              fontFamily: "poppins",
            }}
          >
            {personaName}
          </Typography>
        </Stack>
      );
    }
    return null;
  };
  const handleSave = async (fieldToEdit: "title" | "description", leaveOpen?: boolean) => {
    try {
      if (newTitle !== scenario.title || editor?.getHTML() !== scenario.description) {
        if (fieldToEdit === "title") {
          await patchScenario(selectedOrgId, scenario.id, { title: newTitle });
        } else if (fieldToEdit === "description") {
          await patchScenario(selectedOrgId, scenario.id, {
            description: editor?.getHTML(),
          });
        }
        await queryClient.refetchQueries({
          queryKey: [CustomizePracticeQueryKey.Scenarios, defaultOrgId],
        });
      }
    } catch (e) {
      console.error(`Error updating scenario with id ${scenario.id}: ${e}`);
    } finally {
      if (!leaveOpen) {
        setEditingField(null);
      }
    }
  };

  const renderSaveEdit = (fieldToEdit: "title" | "description") => {
    const saveDisabled =
      fieldToEdit === "title" ? !newTitle : editor?.storage.characterCount.characters() === 0;
    if (editingField === fieldToEdit) {
      return (
        <IconButton
          disabled={saveDisabled}
          sx={{
            p: 0.5,
            color: getDynamicColor("primary"),
            cursor: "pointer",
          }}
          onClick={() => handleSave(fieldToEdit)}
        >
          <CheckRoundedIcon
            sx={{
              height: 22,
              width: 22,
            }}
          />
        </IconButton>
      );
    }
    return (
      <Fade in={hovering || isMobile}>
        <EditIcon
          onClick={() => {
            if (editingField === fieldToEdit) {
              setEditingField(null);
            } else {
              setEditingField(fieldToEdit);
            }
          }}
          sx={{
            color: getDynamicColor("primary"),
            height: 16,
            width: 16,
            cursor: "pointer",
          }}
        />
      </Fade>
    );
  };

  const renderLtiIntegrationModal = () => {
    if (!integrations || integrations.length === 0) {
      return null;
    }
    return (
      <YoodliCtaModal
        ctaBody={{
          title: `Manage integration for "${scenario.title}"`,
          subtitle:
            "Paste the course ID from your LMS. To map this scenario to multiple courses, list them " +
            "with commas (e.g. Course1, Course2, Course3). " +
            `For LMSs which can pass custom parameters to Yoodli, you may use "scenarioId=${scenario.id}" ` +
            "to pass the scenario ID from the LMS to Yoodli.",
        }}
        bodyComponent={
          <ManageLtiIntegrationModalContent scenarioId={scenario.id} integrations={integrations} />
        }
        open={openManageIntegrationModal}
        theme={YoodliCtaModalTheme.Primary}
        close={() => {
          setOpenManageIntegrationModal(false);
        }}
      />
    );
  };

  const renderDescriptionText = () => {
    if (editingField === "description") {
      return (
        <Stack direction="row" gap={1} alignItems="center">
          <WYSIWYGEditor
            editor={editor}
            characterLimit={MAX_SCENARIO_DESCRIPTION_LENGTH}
            mini
            excludeHeaderStyles
          />
          {renderSaveEdit("description")}
        </Stack>
      );
    }
    return (
      <Stack
        direction="row"
        gap={1}
        alignItems="flex-end"
        sx={{
          fontSize: 12,
          fontWeight: 400,
          lineHeight: 1.4,
        }}
      >
        <Box
          sx={{
            "> *": {
              margin: "0px !important",
              width: "fit-content",
              wordBreak: { xs: "break-word", md: "unset" },
            },
          }}
        >
          {parse(scenario.description)}
        </Box>
        {renderSaveEdit("description")}
      </Stack>
    );
  };

  const renderPracticeLinks = () => {
    return (
      <Stack
        direction="row"
        sx={{
          gap: 1,
          justifyContent: { xs: "flex-start", sm: "flex-end" },
          alignItems: "center",
          button: { height: 26, width: 26 },
        }}
      >
        <YoodliTooltip title="Practice now">
          <IconButton
            sx={{ border: `1px solid ${getDynamicColor("primary")}`, p: 0.5 }}
            onClick={() => {
              navigate(
                getScenarioPracticePath(
                  scenario.id,
                  selectedOrgId,
                  false,
                  scenarioTypeMap[scenario.scenarioTypeId],
                ),
                { replace: true },
              );
            }}
          >
            <VideoCameraIcon
              sx={{
                width: 16,
                height: 16,
                color: getDynamicColor("primary"),
              }}
            />
          </IconButton>
        </YoodliTooltip>
        <YoodliTooltip title={practiceLinkCopied ? "Link copied" : "Copy practice link"}>
          <IconButton
            sx={{
              border: `1px solid ${practiceLinkCopied ? getDynamicColor("greenSuccess") : getDynamicColor("primary")}`,
              p: 0.5,
            }}
            onClick={() => {
              if (practiceLinkCopiedTimeout.current) {
                clearTimeout(practiceLinkCopiedTimeout.current);
              }

              copyToMyClipboard(
                getScenarioPracticePath(
                  scenario.id,
                  selectedOrgId,
                  true,
                  scenarioTypeMap[scenario.scenarioTypeId],
                ),
              );
              setPracticeLinkCopied(true);
              practiceLinkCopiedTimeout.current = setTimeout(() => {
                setPracticeLinkCopied(false);
              }, 3000);
            }}
          >
            {practiceLinkCopied ? (
              <CheckRoundedIcon
                sx={{
                  width: 16,
                  height: 16,
                  color: getDynamicColor("greenSuccess"),
                }}
              />
            ) : (
              <LinkIcon
                sx={{
                  width: 16,
                  height: 16,
                  color: getDynamicColor("primary"),
                }}
              />
            )}
          </IconButton>
        </YoodliTooltip>
      </Stack>
    );
  };
  const renderDescription = () => {
    if (!scenario.description) {
      return null;
    }
    if (scenario.description.length > SHORT_DESC_MAX_LENGTH) {
      return (
        <Accordion
          sx={{
            boxShadow: "none",
            fontSize: 12,
            my: "0px !important",
            color: getDynamicColor("purple3"),
            "::before": {
              display: "none !important",
              position: "relative",
            },
          }}
          onChange={(e, expanded) => {
            setDescriptionOpen(expanded);
          }}
        >
          <AccordionSummary
            sx={{
              my: 0,
              px: 0,
              width: "fit-content",
              minHeight: "unset !important",
              ".MuiAccordionSummary-content, .Mui-expanded": {
                my: "0px !important",
                mr: 0.5,
              },
              "&::before": {
                display: "none",
              },
            }}
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: getDynamicColor("primary"),
                  height: 20,
                  width: 20,
                }}
              />
            }
          >
            {descriptionOpen ? "Hide" : "Show"} description
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontSize: 12,
              fontWeight: 400,
              lineHeight: 1.4,
              pb: "8px !important",
            }}
          >
            {renderDescriptionText()}
          </AccordionDetails>
        </Accordion>
      );
    }
    return renderDescriptionText();
  };
  return (
    <Stack
      alignItems="stretch"
      justifyContent="space-between"
      direction={{ xs: "column", sm: "row" }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      gap={1}
      sx={{
        fontFamily: "poppins",
        p: { xs: 1.5, md: 2.5 },
        border: `1px solid ${getDynamicColor("dark3")}`,
        borderRadius: "4px",
        height: "100%",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        className="left-container"
        gap={1}
        sx={{
          flex: "1 1 auto",
        }}
      >
        <Stack
          gap={1}
          sx={{
            flex: "1 1 auto",
            width: "calc(100% - 36px)",
          }}
        >
          <Stack
            gap={1}
            sx={{
              flex: "1 1 auto",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              sx={{
                maxWidth: "100%",
              }}
            >
              {editingField === "title" ? (
                <YoodliTextfield
                  maxChars={200}
                  value={newTitle}
                  onChange={(e) => {
                    setNewTitle(e.target.value);
                  }}
                  InputProps={{
                    sx: { fontSize: 14, fontWeight: 600, p: 1 },
                  }}
                  inputProps={{
                    sx: { p: 0 },
                  }}
                  sx={{
                    lineHeight: 1.3,
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                />
              ) : (
                <Typography
                  onClick={() => handleSelectScenario(scenario, "edit")}
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: 1.3,
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  {scenario.title}
                </Typography>
              )}
              {renderSaveEdit("title")}
            </Stack>
            {renderDescription()}
          </Stack>
          <Stack
            direction="row"
            columnGap={2}
            rowGap={1}
            alignItems="center"
            sx={{
              flexWrap: "wrap",
            }}
          >
            <Stack direction="row" gap={0.5} alignItems="center">
              <Box
                sx={{
                  height: 17,
                  width: 17,
                  position: "relative",
                  svg: {
                    height: "100% !important",
                    width: "100% !important",
                    minWidth: "unset !important",
                    minHeight: "unset !important",
                  },
                }}
              >
                {TEMPLATE_SUB_TYPE_DATA[scenario.templateSubType]?.icon}
              </Box>
              <Typography
                sx={{
                  fontSize: 11,
                  fontWeight: 700,
                }}
              >
                {TEMPLATE_SUB_TYPE_DATA[scenario.templateSubType]?.shortLabel ??
                  TEMPLATE_SUB_TYPE_DATA[scenario.templateSubType]?.label ??
                  "Scenario"}
              </Typography>
            </Stack>
            {renderPersona()}
            <Typography
              sx={{
                fontSize: 11,
                color: getDynamicColor("dark5"),
              }}
            >
              By: {(scenario as OrgScenario).createdByEmail} (
              {getHumanReadableDate((scenario as OrgScenario).createdAt, {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })}
              )
            </Typography>
          </Stack>
        </Stack>
        {isExtraSmallScreen && renderScenarioOptionsMenu()}
      </Stack>
      <Stack
        direction={{ xs: "row-reverse", sm: "column" }}
        sx={{
          gap: { xs: 1, sm: 3 },
          flex: "0 0 auto",
          width: { xs: "100%", sm: "auto" },
          justifyContent: "space-between",
        }}
      >
        {!isExtraSmallScreen && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={{ xs: "space-between", sm: "flex-end" }}
            gap={2}
            sx={{
              width: { xs: "100%", sm: "unset" },
            }}
          >
            {renderPracticeLinks()}
            {renderScenarioOptionsMenu()}
          </Stack>
        )}
        {isExtraSmallScreen && renderPracticeLinks()}
        <GroupAssignmentMenu
          activeGroupIds={activeHubIds}
          handleUpdateGroupAssignment={handleUpdateGroupAssignment}
        />
      </Stack>
      {renderLtiIntegrationModal()}
      <YoodliCtaModal
        open={deleteModalOpen}
        loading={deleteScenarioMutation.isPending}
        theme={YoodliCtaModalTheme.Danger}
        close={() => {
          setDeleteModalOpen(false);
        }}
        ctaBody={{
          title: `Are you sure you want to delete the scenario "${scenario.title}"?`,
          subtitle: "This action cannot be undone.",
        }}
        buttons={
          {
            primary: {
              text: "Delete Scenario",
              handler: async () => await deleteScenarioMutation.mutateAsync(),
            },
            secondary: { text: "Cancel", handler: () => setDeleteModalOpen(false) },
          } as CtaButtonHandlers
        }
      />
    </Stack>
  );
};
