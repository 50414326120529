import React from "react";

// Components
import { Link, Stack, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { LandingPageExternalPath, getLandingPageExternalUrl } from "lib-fullstack/utils/paths";
import { getClientEnvConfig } from "lib-fullstack/client_env";

const SHARED_TEXT_STYLES = {
  fontWeight: 700,
  lineHeight: 1.3,
  fontFamily: "poppins",
  fontSize: 14,
};

const TryEnterpriseCta = (): JSX.Element => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        color: getDynamicColor("primary"),
        textAlign: "center",
        lineHeight: 1.3,
        mt: 1,
      }}
    >
      <Typography
        sx={{
          ...SHARED_TEXT_STYLES,
        }}
      >
        Want Yoodli for your whole team?
      </Typography>
      <Link
        href={getLandingPageExternalUrl(
          getClientEnvConfig(),
          LandingPageExternalPath.TALK_TO_SALES,
        )}
        sx={{
          ...SHARED_TEXT_STYLES,
          cursor: "pointer",
          textDecoration: "underline",
        }}
      >
        Talk to Sales about an Enterprise plan
      </Link>
    </Stack>
  );
};

export default TryEnterpriseCta;
